import React, {useEffect, useState} from "react";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import LockIcon from '@mui/icons-material/Lock';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import Migrado from '@mui/icons-material/SwapHorizontalCircleRounded';
import InativoBloqueado from '@mui/icons-material/RemoveCircleRounded';
import "./DisplayStatus.css"

const DisplayStatusUser = ({status}) => {

    const [display, setDisplay] = useState("")

    useEffect(() => {

        switch (status) {
            case "ACTIVE":
                setDisplay(<span className="status-active">
                    <CheckCircleOutlineIcon fontSize="medium"/> Ativo
                </span>)
                break;
            case "INACTIVE":
                setDisplay(<span className="status-inactive">
                    <HighlightOffIcon fontSize="medium"/> Inativo
                </span>)
                break;
            case "BLOCKED":
                setDisplay(<span className="status-blocked">
                    <LockIcon fontSize="medium"/> Bloqueado
                </span>)
                break;
            case "BLOCKED_INACTIVED":
                setDisplay(<span className="status-blocked">
                    <InativoBloqueado fontSize="medium"/> Inativo Bloqueado
                </span>)
                break;
            case "PASSWORD_EXPIRED":
                setDisplay(<span className="status-expired">
                    <AccessTimeFilledIcon fontSize="medium"/> Senha Expirada
                </span>)
                break;
            case "MIGRATED":
                setDisplay(<span className="status-canceled">
                    <Migrado fontSize="medium"/> Migrado
                </span>)
                break;
        }

    }, [status]);

    // const displayDate = ({dias}) => {
    //     switch (dias) {
    //         case "ATUAL":
    //             setDisplay(<span className="status-active">
    //                 <CheckCircleOutlineIcon fontSize="medium"/> Atual
    //             </span>)
    //             break;
    //         case "SEMANA":
    //             setDisplay(<span className="status-active">
    //                  <CheckCircleOutlineIcon fontSize="medium"/> Semanal
    //             </span>)
    //             break;
    //         case "MÊS":
    //             setDisplay(<span className="status-active">
    //                  <CheckCircleOutlineIcon fontSize="medium"/> Mês
    //             </span>)
    //             break;
    //     }
    // }, [dias]);


    return (
        <>
            {display}
        </>
    )
}

export default DisplayStatusUser
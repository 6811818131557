import TextField from "@mui/material/TextField";
import React, {useState} from "react";
import {AutoCompleteInput} from "../../../components/FilteredSelectInput";

export default function InputsLoadCard({
                                           value, setValue,
                                           logicCodeFilter, setLogicCodeFilter,
                                           store, setStore
                                       }) {

    const [storeList, setStoreList] = useState([])

    return (
        <>
            <TextField
                label="Valor da carga"
                required={true}
                inputProps={{
                    min: 1,
                    step: ".01"
                }}
                type="number"
                erortext="Campo obrigatório!"
                value={value}
                onChange={(e) => setValue(e.target.value)}
                fullWidth
            />
            <AutoCompleteInput
                sizeToSearch={3}
                filter={`internalCode=${logicCodeFilter}`}
                value={logicCodeFilter}
                setValue={setLogicCodeFilter}
                firstInputLabel={'Digite 3 dígitos do código interno da loja e uma lista de lojas aparecerá.'}
                secondInputLabel={'Selecione a loja:'}
                url={`http://${process.env.REACT_APP_GLOBAL_PROCESS_LINK}/Store?size=200000000`}
                placeholder={"loja disponíveis para carga "}
                list={storeList}
                set={setStoreList}
                setObj={setStore}
                defaultValue={store}
                required={true}
                fullWidth
                getLabel={(item) => {
                    return `${item.internalCode} - ${item.corporateName}`
                }}
                getValueForList={(item) => {
                    return item.logicCode
                }}
            />
        </>
    )
}
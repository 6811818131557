import { useState } from 'react';
import Alert from 'react-bootstrap/Alert';


function AlertDismissibleExample({ showError, setShow }) {

    const handleClose = () => {
        setShow(false);
    };

        return showError ? (
            <Alert variant="danger" onClose={() => setShow(false)} dismissible>
                <Alert.Heading>Alerta!!!</Alert.Heading>
                <p>
                    Identificamos uma situação que pode indicar a ocorrência de um erro em nosso sistema, e queremos que
                    você saiba que estamos focados em resolver essa questão o mais rápido possível.
                    A equipe técnica está trabalhando incansavelmente para investigar a raiz do problema e implementar
                    uma solução eficaz.
                    Entendemos o quão importante é manter nosso sistema funcionando de maneira confiável e eficiente
                    para você.
                    Reconhecemos que esse incidente pode causar frustração e inconvenientes, e queremos assegurar-lhe
                    que estamos comprometidos em minimizar qualquer impacto negativo em sua experiência.
                </p>
            </Alert>
        ) : null;
}

export default AlertDismissibleExample;

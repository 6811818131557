import MainNavbar from "../../components/MainNavbar";
import {Paper} from "@mui/material";
import React, {useState} from "react";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import "./report.css";
import PopupAddReport from "./components/PopupAddReport";
import ListReport from "./components/ListReport";
import PaginationControl from "../../components/Pagination/PaginationControl";
import Footer from "../../examples/Footer";
import FilterFormReport from "./components/FilterFormReport";

/* eslint-disable */
export default function Report() {

    const baseUrl = `http://${process.env.REACT_APP_REPORTS_SERVICE_LINK}`
    const [loadData, setLoadData] = useState(true);

    const [size, setSize] = useState(10);
    const [page, setPage] = useState(0);
    const [sort, setSort] = useState("");
    const [filter, setFilter] = useState("");

    const [totalPages, setTotalPages] = useState(0);
    const [totalElements, setTotalElements] = useState(0);

    return (
        <>
            <MainNavbar/>
            <DashboardLayout>
                <Paper className="paper-list mb-3">
                    <h1>Lista de Relatórios Agendados</h1>

                    <PopupAddReport
                        baseUrl={baseUrl}
                        setLoadData={setLoadData}
                    />

                    <FilterFormReport
                        filter={filter}
                        setFilter={setFilter}
                        setLoadData={setLoadData}
                        setPage={setPage}
                    />

                    <ListReport
                        baseUrl={baseUrl}
                        loadData={loadData}
                        setLoadData={setLoadData}
                        page={page}
                        size={size}
                        setTotalPages={setTotalPages}
                        setTotalElements={setTotalElements}
                        sort={sort}
                    />

                    <PaginationControl
                        totalElements={totalElements}
                        page={page}
                        setPage={setPage}
                        size={size}
                        setSize={setSize}
                        setLoadData={setLoadData}
                    />
                </Paper>
            </DashboardLayout>
            <Footer/>
        </>
    );
}

import React, {useState} from "react";
import TableList from "../../../components/TableList/TableList";
import DisplayStatus from "../../../components/Status/DisplayStatus";
import FilterFormCard from "./FilterFormCard";
import {NumberWithPoints} from "../../../utils/numberWithPoints";
import CardActions from "./CardActions";

const ListCard = ({
                      baseUrl, loadData,
                      setLoadData, size, page,
                      setTotalPages, setTotalElements,
                      sort, setSort, updateCode, setPage
                  }) => {

    const [data, setData] = useState([])
    const [error, setError] = useState(false)
    const [filter, setFilter] = useState({})

    const titles = [
        "Numero do cartão",
        "Unidade de negócio",
        "Saldo",
        "Tipo de cartão",
        "Expiração",
        "Status"
    ]


    return (
        <>
            <FilterFormCard filter={filter} setFilter={setFilter} setLoadData={setLoadData} setPage={setPage}/>
            <TableList
                titles={titles} baseUrl={baseUrl}
                page={page} sort={sort} size={size}
                loadData={loadData}
                setTotalPages={setTotalPages} setTotalElements={setTotalElements}
                setData={setData} setLoadData={setLoadData} filter={filter}
                error={error} setError={setError}
            >
                {data.map((item) =>
                    <tr key={item.id}>
                        <td>{item.number}</td>
                        <td>{item.businessUnitName}</td>
                        <td>R$ {NumberWithPoints(item.balance)}</td>
                        <td>{item.digital
                            ? <p>Digital</p>
                            : <p>Físico</p>
                        }</td>
                        <td>{item.expirationDate}</td>
                        <td><DisplayStatus status={item.status}/></td>
                        <td>
                            <CardActions setLoadData={setLoadData} card={item}/>
                        </td>
                    </tr>
                )}
            </TableList>
        </>

    )
}

export default ListCard
import MDBox from "../../../../../components/MDBox";
import MDTypography from "../../../../../components/MDTypography";
import Icon from "@mui/material/Icon";
import TimelineItem from "../../../../../examples/Timeline/TimelineItem";
import Card from "@mui/material/Card";
import React, {useEffect, useState} from "react";
import {NumberWithPoints} from "../../../../../utils/numberWithPoints";


export default function Subtitle({data, setMaxValue}) {

    const [stateList, setStateList] = useState([])

    useEffect(() => {

        const list = []

        const keys = Object.keys(data).sort();
        const dataOrdered = {};
        for (const key of keys) {
            dataOrdered[key] = data[key];
        }

        for (const sigla in dataOrdered) {
            if (sigla !== "Total" && data[sigla] > 0) {
                list.push([sigla, NumberWithPoints(data[sigla] / 100)])
            }
        }

        setStateList(list.sort((a, b) => {
            return parseFloatStateValue(b[1]) - parseFloatStateValue(a[1])
        }))

    }, [data])

    useEffect(() => {
        if (stateList.length > 0)
            setMaxValue(parseFloatStateValue(stateList[0][1]))
    }, [stateList])

    const parseFloatStateValue = (value) => {
        return parseFloat(value.replaceAll('.', '').replace(',', '.'))
    }

    return <Card sx={{height: "100%"}}>
        <MDBox pt={3} px={3}>
            <MDTypography variant="h6" fontWeight="medium">
                Lista de Estados
            </MDTypography>
            <MDBox mt={0} mb={2}>
                <MDTypography variant="button" color="text" fontWeight="regular">
                    <MDTypography display="inline" variant="body2" verticalAlign="middle">
                        <Icon sx={{color: ({palette: {success}}) => success.main}}>arrow_upward</Icon>
                    </MDTypography>
                    &nbsp;
                    <MDTypography variant="button" color="text" fontWeight="medium">
                        Transações
                    </MDTypography>{" "}
                </MDTypography>
            </MDBox>
        </MDBox>
        <MDBox p={2}>
            {stateList.map((state) => (
                <div className='d-inline-block' style={{minWidth: '48%'}}>
                    <TimelineItem
                        key={state[0]}
                        color="dark"
                        icon="gps_not_fixed"
                        title={state[0] + ': R$ ' + state[1]}
                        searchFunction={() => {
                        }}
                        dateTime=''
                        lastItem
                    />
                </div>
            ))}
        </MDBox>
    </Card>
}
import { IconButton, Paper } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MainNavbar from "components/MainNavbar";
import PaginationControl from "components/Pagination/PaginationControl";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useState } from "react";
import ListInvoice from "./components/ListInvoice";
import { useParams, useNavigate } from "react-router-dom";

export default function Invoice() {
    const { id } = useParams();
    const navigate = useNavigate();

    const baseUrl = `http://${process.env.REACT_APP_FINANCIAL_MANAGER_LINK}/invoices/store/${id}`;
    const [loadData, setLoadData] = useState(true);
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [sort, setSort] = useState("id");

    return (
        <>
            <MainNavbar/>
            <DashboardLayout>
                <Paper className="paper-list mb-3">
                    <IconButton
                        onClick={() => navigate(-1)}
                        style={{ marginBottom: '16px' }}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    <h1>Lista de Faturas</h1>
                    <ListInvoice
                        storeId={id}
                        baseUrl={baseUrl}
                        loadData={loadData}
                        setLoadData={setLoadData}
                        page={page}
                        size={size}
                        setTotalPages={setTotalPages}
                        setTotalElements={setTotalElements}
                        sort={sort}
                        setSort={setSort}
                    />
                    <PaginationControl
                        totalPages={totalPages}
                        totalElements={totalElements}
                        page={page}
                        setPage={setPage}
                        size={size}
                        setSize={setSize}
                        setLoadData={setLoadData}
                    />
                </Paper>
            </DashboardLayout>
        </>
    );
}

import MainNavbar from "../../components/MainNavbar";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import {Paper} from "@mui/material";
import Footer from "../../examples/Footer";
import React, {useState} from "react";
import {Link, useParams} from "react-router-dom";
import PaginationControl from "../../components/Pagination/PaginationControl";
import TableList from "../../components/TableList/TableList";
import {NumberWithPoints} from "../../utils/numberWithPoints";
import PopupMonitoringDetails from "./components/PopupMonitoringDetails";
import "./MonitoringDetails.css"
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import displayType from "../monitoring/components/displayType";

export default function MonitoringDetails() {

    const {type, period, status} = useParams();

    const baseUrl = `http://${process.env.REACT_APP_MONITOR_LINK}/list/status`
    const [loadData, setLoadData] = useState(true)

    const [size, setSize] = useState(100)
    const [page, setPage] = useState(0)
    const [sort, setSort] = useState("id")

    const [totalPages, setTotalPages] = useState(0)
    const [totalElements, setTotalElements] = useState(0)

    const [data, setData] = useState([])
    const [error, setError] = useState(false)

    const filter = {
        period: period.replace(' ', '').replace('ê', 'e'),
        type: type.replaceAll(' ', ''),
        status: status.replaceAll(' ', '').replace('NaoAutorizada', 'Nao Autorizada')
    }

    const titles = [
        "Id",
        "Data da Transação",
        "Status",
        "Tipo",
        "Valor",
        "Id do Cartão",
        "Cartão",
        "Cod Adm",
        "Cod Lógico",
    ]

    return (
        <>
            <MainNavbar/>
            <DashboardLayout>
                <Link to={'/monitoring/' + period + '/' + type} className={'position-absolute m-lg-1'}>
                    <KeyboardBackspaceIcon/> Voltar &nbsp;
                </Link>

                <Paper className="paper-list mb-3 w-100">
                    <h1>Transações</h1>

                    <PopupMonitoringDetails
                        period={period}
                        type={type}
                        status={status}
                    />

                    <p>{period} - {displayType(type)} - {status}</p>

                    <TableList
                        titles={titles} baseUrl={baseUrl}
                        page={page} sort={sort} size={size}
                        loadData={loadData} filter={filter}
                        setTotalPages={setTotalPages} setTotalElements={setTotalElements}
                        setData={setData} setLoadData={setLoadData}
                        error={error} setError={setError}
                    >
                        {data.map((item) =>
                            <tr key={item.id}>
                                <td>{item.id}</td>
                                <td>{item.dataTransacao}</td>
                                <td>{item.status}</td>
                                <td>{item.tipo}</td>
                                <td>{NumberWithPoints(item.valor / 100)}</td>
                                <td>{item.carId}</td>
                                <td>{item.cart}</td>
                                <td>{item.codAdm}</td>
                                <td>{item.codLogico}</td>
                            </tr>
                        )}
                    </TableList>

                    <PaginationControl
                        totalElements={totalElements}
                        page={page}
                        setPage={setPage}
                        size={size}
                        setSize={setSize}
                        setLoadData={setLoadData}
                        rowsPerPageOptions={[100, 200, 300, 400, 500]}
                    />
                </Paper>
            </DashboardLayout>
            <Footer/>
        </>
    )
}
import { Grid, Paper } from "@mui/material";

export default function ShortInvoice({ invoice}) {

    const fontBold = {
        fontWeight: 'bold'
    }   

    return (
        <Paper sx={{ minWidth: 275, elevation: 24, margin: 5 }}>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <label sx={fontBold}>Código Interno</label>
                    <p>{invoice.storeCode}</p>
                </Grid>
                <Grid item xs={8}>
                    <label sx={fontBold}>Loja</label>
                    <p>{invoice.storeName}</p>
                </Grid>
                <Grid item xs={4}>
                    <label sx={fontBold}>Data Vencimento</label>
                    <p>{invoice.dueDate}</p>
                </Grid>
            </Grid>
        </Paper>
    );
}
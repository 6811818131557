import axios from "axios";

export function fetchData(endpoint, params = null) {

    const accessToken = localStorage.getItem('_USER_ACCESS_TOKEN');
    axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

    let queryString = '';
    if (params) {
        queryString = Object.keys(params).map( prop => `${prop}=${params[prop]}`).join('&');
        queryString = `?${queryString}`;
    }

    return axios.get(`${endpoint}${queryString}`)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                console.log('Error fetching data - '+ error);
                return null;
            });
}


export function postData(endpoint, params) {

    // const accessToken = localStorage.getItem('_USER_ACCESS_TOKEN');
    // axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

    return axios.post(endpoint, params)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                console.log('Error fetching data - '+ error);
                return null;
            });
}
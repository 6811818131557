import React, {useEffect, useState} from "react";
import MDTypography from "../../../../components/MDTypography";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import MDBox from "../../../../components/MDBox";
import {AutoCompleteInput} from "../../../../components/FilteredSelectInput";

export default function SinteticosCargaResgateLojaFilter({filter, setFilter}) {
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [storeCode, setStoreCode] = useState("");
    const [errorCode, setErrorCode] = useState(false);
    const [errorCodeText, setErrorCodeText] = useState(false);

    const [storeCodeFilter, setStoreCodeFilter] = useState("");
    const [storeList, setStoreList] = useState([]);

    useEffect(() => {
        setFilter({
            startDate,
            endDate,
            storeCode,
        });
    }, [startDate, endDate, storeCode]);

    const InputLabelProps = {
        style: {
            zIndex: 0,
        },
    };

    return (
        <>
            <MDBox mt={2}>
                <MDTypography variant="h6">Período</MDTypography>
                <Stack
                    direction="row"
                    spacing={2}
                    mt={1}
                    sx={{display: "flex", justifyContent: "center"}}
                >
                    <TextField
                        label="Início"
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        InputLabelProps={{
                            ...InputLabelProps,
                            shrink: true,
                            size: "small",
                        }}
                        sx={{width: 180}}
                        required
                    />
                    <TextField
                        label="Fim"
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        InputLabelProps={{
                            ...InputLabelProps,
                            shrink: true,
                            size: "small",
                        }}
                        sx={{width: 180}}
                        required
                    />
                </Stack>
                <AutoCompleteInput
                    sizeToSearch={3}
                    filter={`internalCode=${storeCodeFilter}`}
                    value={storeCodeFilter}
                    setValue={setStoreCodeFilter}
                    firstInputLabel={'Digite 3 dígitos do código interno da loja e uma lista de lojas aparecerá.'}
                    secondInputLabel={'Selecione a loja:'}
                    url={`http://${process.env.REACT_APP_GLOBAL_PROCESS_LINK}/Store?size=200000000`}
                    list={storeList}
                    set={setStoreList}
                    setObj={setStoreCode}
                    defaultValue={storeCode}
                    required={true}
                    fullWidth
                    getLabel={(item) => {
                        return `${item.internalCode} - ${item.corporateName}`
                    }}
                    getValueForList={(item) => {
                        return item.internalCode
                    }}
                />
            </MDBox>
        </>
    );
}

import PopupEdit from "components/PopupEdit/PopupEdit";
import InputsUser from "./InputsUser";
import { useState } from "react";
import axios from "axios";
import InputsUserEdit from "./InputsUserEdit";

function PopupEditUser({ baseUrl, item, setLoadData, clearForm}) {

  const [id, setId] = useState("")
  const [username, setUsername] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [roles, setRoles] = useState([]);
  const [scopes, setScopes] = useState([]);
  const [scope, setScope] = useState("");
  const [internalCode, setInternalCode] = useState("");
  const [owner, setOwner] = useState("");
  const [isTwoFactorEnabled, setIsTwoFactorEnabled] = useState(item.isTwoFactorEnabled);

  const [errorMessage, setErrorMessage] = useState("")
  const [errorColumn, setErrorColumn] = useState("")


  const itemToState = () => {

    const baseUrl = `http://${process.env.REACT_APP_CARD_PROCESS_SECURITY_LINK}/users/${item.id}`;
    axios.get(baseUrl)
      .then(resp => {
        setId(resp.data.id)
        setName(resp.data.name)
        setUsername(resp.data.username)
        setEmail(resp.data.email)
        setRoles(resp.data.roles)
        setInternalCode(resp.data.internalCode || "")
        setScopes(resp.data.scopes)
        setOwner(resp.data.owner)
        const selectedScope = resp.data.scopes?.find(role => role.selected);
        setScope(selectedScope?.id)
        setIsTwoFactorEnabled(resp.data.isTwoFactorEnabled)

      })
      .catch(error => {
        console.error('Erro ao recuperar usuário =>', error);
        setErrorMessage(error.response.data.message);
      });
  }

  const inputs = <InputsUserEdit
    name={name} setName={setName}
    username={username} setUsername={setUsername}
    email={email} setEmail={setEmail}
    roles={roles} setRoles={setRoles}
    scopes={scopes} setScopes={setScopes}
    scope={scope} setScope={setScope}
    internalCode={internalCode} setInternalCode={setInternalCode}
    owner={owner} setOwner={setOwner}
    errorMessage={errorMessage}
    errorColumn={errorColumn}
    setIsTwoFactorEnabled={setIsTwoFactorEnabled}
    isTwoFactorEnabled={isTwoFactorEnabled}
  />

  const title = "Editar Usuário";

  const returnData = () => {
    return {
      id,
      name,
      username,
      email,
      roles,
      scopes,
      scope,
      internalCode,
      isTwoFactorEnabled
    };
  }


  return (
    <>
      <PopupEdit
          baseUrl={baseUrl} 
          setLoadData={setLoadData}
          item={item} 
          title={title}
          inputs={inputs} 
          returnData={returnData}
          itemToState={itemToState} 
          clearForm={clearForm}
      />
    </>
  );
}

export default PopupEditUser;
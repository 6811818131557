import React, {useState} from "react";
import TableList from "../../../components/TableList/TableList";
import DisplayStatus from "../../../components/Status/DisplayStatus";
import PopupEditGroup from "./PopupEditGroup";

const ListGroup = ({
                       baseUrl, loadData,
                       setLoadData, size, page,
                       setTotalPages, setTotalElements,
                       sort, setSort, updateCode, filter
                   }) => {
    const [data, setData] = useState([])
    const [error, setError] = useState(false)

    const titles = [
        "Nome",
        "Código",
        "Status"
    ]

    return (
        <TableList
            titles={titles} baseUrl={baseUrl}
            page={page} sort={sort} size={size}
            loadData={loadData}
            setTotalPages={setTotalPages} setTotalElements={setTotalElements}
            setData={setData} setLoadData={setLoadData}
            error={error} setError={setError}
            filter={filter}
        >
            {data.map((item) =>
                <tr key={item.id}>
                    <td>{item.name}</td>
                    <td>{item.code}</td>
                    <td><DisplayStatus status={item.status}/></td>
                    <td>
                        <PopupEditGroup
                            item={item}
                            baseUrl={baseUrl}
                            setLoadData={setLoadData}
                            updateCode={updateCode}
                        />
                    </td>
                </tr>
            )}
        </TableList>
    )
}

export default ListGroup
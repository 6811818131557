// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button {
    float: right;
}

.buttons {
    position: absolute;
    display: flex;
    gap: 10px;
    right: 70px;
}
`, "",{"version":3,"sources":["webpack://./src/layouts/monitoringDetails/MonitoringDetails.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,SAAS;IACT,WAAW;AACf","sourcesContent":[".button {\r\n    float: right;\r\n}\r\n\r\n.buttons {\r\n    position: absolute;\r\n    display: flex;\r\n    gap: 10px;\r\n    right: 70px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

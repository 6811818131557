import React from "react";
import SettingsIcon from '@mui/icons-material/Settings';
import CancelIcon from '@mui/icons-material/Cancel';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import PopupLoadCard from "./PopupLoadCard";
import PopupBalanceTransfer from "./PopupBalanceTransfer";
import { NumberWithPoints } from "../../../utils/numberWithPoints";
import { Dropdown } from "react-bootstrap";
import ChangeStatus from "./ChangeStatus";
import DisplayStatus from "../../../components/Status/DisplayStatus";
import PopupRevalidateCard from "./PopupRevalidateCard";

export default function CardActions({ card, setLoadData }) {

    // Função para formatar valores monetários
    const formatValue = (value) => {
        if (typeof value === 'number') {
            return `R$ ${value.toFixed(2).replace('.', ',')}`;
        }
        return value; // Retorna o valor original se não for um número
    };

    const cardData = (
        <>
            <br />
            <b>Número do Cartão: </b>
            <>{card.number}</>
            <br />
            <b>Unidade de Negócio: </b>
            <>{card.businessUnitName}</>
            <br />
            <b>Tipo de Cartão: </b>
            <>{card.isDigital
                ? <>Digital</>
                : <>Físico</>
            }</>
            <br />
            <b>Status: </b>
            <><DisplayStatus status={card.status} /></>
            <br />
            <b>Saldo: </b>
            <>{formatValue(card.balance)}</>
            <br />
            <br />
        </>
    );

    const hasOptions = !(
        card.status === "CANCELADO" ||
        card.status === "EM_RESOLUCAO" ||
        (card.status === "BAIXADO" && card.balanceWrittenOff <= 0)
    );

    return (
        <Dropdown drop='down-centered'>
            <Dropdown.Toggle variant="secondary" disabled={!hasOptions}>
                Ações &nbsp;
                <SettingsIcon fontSize="medium" />
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {card.status === "CRIADO" && (
                    <PopupLoadCard
                        cardNumber={card.number}
                        setLoadData={setLoadData}
                        buttonClass='dropdown-item'
                    />
                )}

                {card.status === "ATIVO" && card.balance > 0 && (
                    <PopupBalanceTransfer
                        cardNumber={card.number}
                        balance={formatValue(card.balance)}
                        setLoadData={setLoadData}
                        buttonClass='dropdown-item'
                    />
                )}

                {(card.status === "ATIVO" || card.status === "CRIADO") && (
                    <ChangeStatus
                        id={card.id}
                        status={'CANCELADO'}
                        button={<>
                            <CancelIcon fontSize="medium" />
                            &nbsp;Cancelar Cartão
                        </>}
                        message={'cancelamento do cartão'}
                        successMessage='Cartão cancelado com sucesso!'
                        setLoadData={setLoadData}
                        buttonClass='dropdown-item'
                        buttonVariant='danger'
                    >
                        {cardData}
                        <p className="mb-3">
                            Você deseja realmente cancelar este cartão? <br />
                            *Esta operação é definitiva e o saldo do cartão será perdido.
                        </p>
                    </ChangeStatus>
                )}

                {(card.status === "ATIVO" || card.status === "CRIADO") && (
                    <ChangeStatus
                        id={card.id}
                        status={'BLOQUEADO'}
                        button={<>
                            <LockIcon fontSize="medium" />
                            &nbsp;Bloquear Cartão
                        </>}
                        message={'bloqueio do cartão'}
                        successMessage='Cartão bloqueado com sucesso!'
                        setLoadData={setLoadData}
                        buttonClass='dropdown-item'
                        buttonVariant='danger'
                    >
                        {cardData}
                        <p className="mb-3">Você deseja realmente bloquear este cartão?</p>
                    </ChangeStatus>
                )}

                {card.status === "BLOQUEADO" && (
                    <ChangeStatus
                        id={card.id}
                        status={card.balance === 0 ? 'CRIADO' : 'ATIVO'}
                        button={<>
                            <LockOpenIcon fontSize="medium" />
                            &nbsp;Desbloquear Cartão
                        </>}
                        message={'desbloqueio do cartão'}
                        successMessage='Cartão desbloqueado com sucesso!'
                        setLoadData={setLoadData}
                        buttonClass='dropdown-item'
                        buttonVariant='primary'
                    >
                        {cardData}
                        <p className="mb-3">Você deseja realmente desbloquear este cartão?</p>
                    </ChangeStatus>
                )}

                {card.status === "BAIXADO" && card.balanceWrittenOff > 0 && (
                    <PopupRevalidateCard
                        id={card.id}
                        cardNumber={card.number}
                        balanceWrittenOff={formatValue(card.balanceWrittenOff)}
                        setLoadData={setLoadData}
                        buttonClass='dropdown-item'
                    />
                )}
            </Dropdown.Menu>
        </Dropdown>
    );
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#form_select{
    font-size: 1rem;

}


`, "",{"version":3,"sources":["webpack://./src/components/SelectInput/SelectInput.css"],"names":[],"mappings":"AAAA;IACI,eAAe;;AAEnB","sourcesContent":["#form_select{\r\n    font-size: 1rem;\r\n\r\n}\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, {useEffect, useState} from "react";

export default function MapParams({ values, valueSelected, setFunction, loadData, name, className}){


    const [buttons, setButtons] = useState(<> </>)

    useEffect(() => {
        setButtons(
            <div className={'select-monitoring-param ' + className}>
                {name}: &nbsp;
                {
                    values.map((value, index) => (
                        <button key={index}
                                className={
                                    (value === valueSelected) ? 'btn btn-primary' : 'btn btn-outline-primary'
                                }
                                onClick={() => {
                                    setFunction(value)
                                }}
                                disabled={loadData}
                        >
                            {value}
                        </button>
                    ))
                }
            </div>
        )
    }, [valueSelected, loadData])

    return buttons
}
import React, { useState, useEffect } from "react";
import { Modal, Button, Dropdown } from "react-bootstrap";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import StorefrontIcon from "@mui/icons-material/Storefront";
import axios from 'axios';
import StoreDetails from "./StoreDetails";
import {Domain} from "@mui/icons-material";

const AssociatedStoresPopup = ({ item, baseUrl }) => {
    const [show, setShow] = useState(false);
    const [associatedStores, setAssociatedStores] = useState([]);
    const [buttonDisabled, setButtonDisabled] = useState(!item.hasCentralizedStore);

    useEffect(() => {
        let isMounted = true;

        if (!!item.hasCentralizedStore) {
            axios.get(`${baseUrl}/centralized/${item.id}`)
                .then(response => {
                    if (isMounted) {
                        const data = response.data;
                        const stores = Array.isArray(data.collection) ? data.collection : data;

                        setAssociatedStores(stores);
                        setButtonDisabled(stores.length === 0);
                    }
                })
                .catch(error => {
                    if (isMounted) {
                        console.error('Error fetching associated stores:', error);
                        setButtonDisabled(true);
                    }
                });
        }

        return () => {
            isMounted = false;
        };
    }, [item, baseUrl]);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Dropdown.Item as="button" onClick={handleShow} disabled={buttonDisabled}>
                <Domain className="me-2" /> Lojas Centralizadas
            </Dropdown.Item>

            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Lojas Centralizadas</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Grid container spacing={2}>
                        {Array.isArray(associatedStores) && associatedStores.length > 0 ? (
                            associatedStores.map(store => (
                                <Grid item xs={12} sm={6} md={4} key={store.id}>
                                    <Card
                                        variant="outlined"
                                        sx={{
                                            transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                                            '&:hover': {
                                                transform: 'translateY(-5px)',
                                                boxShadow: '0 4px 8px rgba(0,0,0,0.2)'
                                            }
                                        }}
                                    >
                                        <CardHeader
                                            avatar={
                                                <Avatar>
                                                    <StorefrontIcon />
                                                </Avatar>
                                            }
                                            title={<Typography variant="h6" sx={{ color: 'black' }}>{store.corporateName}</Typography>}
                                            subheader={<Typography variant="subtitle1" sx={{ color: 'black' }}>CNPJ: {store.cnpj}</Typography>}
                                        />
                                        <CardContent>
                                            <Typography variant="body2" sx={{ color: 'black', fontWeight: 'bold' }}>
                                                Código Interno: {store.internalCode}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))
                        ) : (
                            <Grid item xs={12}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography sx={{ color: 'black' }}>Nenhuma loja centralizada encontrada.</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        )}
                    </Grid>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default AssociatedStoresPopup;

import "./FilterForm.css";
import React from "react";
import TuneIcon from '@mui/icons-material/Tune';
import Popup from "../Popup/Popup";
import { Button, Form } from "react-bootstrap";
import SearchIcon from '@mui/icons-material/Search';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import ClearAllIcon from '@mui/icons-material/ClearAll'; // Ícone para limpar todos os campos

const FilterForm = ({ lgShow, setLgShow, handleSubmit, clearFilter, children, showSecondaryButton, clearAll }) => {

    const button = <>
        Filtros <TuneIcon fontSize="medium" />
    </>

    const title = <>
        Filtros
    </>

    const iconSpan = <>
        <TuneIcon fontSize="large" />
        <h3>Escolha quais campos filtrar</h3>
    </>

    const secondaryButton = <Button className='btn-secondary'
                                    onClick={() => {
                                        clearFilter()
                                    }}
    >
        Limpar Filtros <CleaningServicesIcon fontSize="medium" />
    </Button>

    const clearAllButton = <Button className='btn-clear-all'
                                   onClick={() => {
                                       clearAll && clearAll()
                                   }}
    >
        Limpar Todos <ClearAllIcon fontSize="medium" />
    </Button>

    return (
        <Popup
            buttonText={button}
            title={title}
            iconSpan={iconSpan}
            lgShow={lgShow} setLgShow={setLgShow}
            buttonVariant={""}
            buttonClass={'btn btn-primary'}
            additionalContent={showSecondaryButton ? secondaryButton : null}
        >
            <Form onSubmit={handleSubmit} className="form-inputs form-filter">
                {children}
                <Button type="submit">
                    Filtrar <SearchIcon fontSize="medium" />
                </Button>
                {clearAll && clearAllButton}
                <Button type="reset" onClick={clearFilter} className="btn btn-secondary">
                    Remover Filtros <CleaningServicesIcon fontSize="medium" />
                </Button>
            </Form>
        </Popup>
    )
}

export default FilterForm;

import PopupLayout from "../PopupLayout";


export default function PopupLayoutLoadCard({}) {

    const text = <>
        O arquivo deve seguir um formato específico,
        com apenas dois dados em cada linha: o número do cartão e o valor da carga,
        separados por um ponto e vírgula (;).
        O número do cartão pode ser inserido completo ou com os últimos 13, 12 ou 9 dígitos. <br/>
        O arquivo precisa ser salvo no formato <b>CSV</b>,
        com uma extensão de arquivo representativa, por exemplo: arquivo-carga-lote1.csv.
    </>

    const layout = <span>
        6049246034060452194;30<br/>
        6034060452186;30<br/>
        034060452095;100<br/>
        060452079;100
    </span>

    return <PopupLayout processName="Carga de Cartões" text={text} layout={layout}/>
}
import React, {useEffect, useState} from "react";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import LockIcon from '@mui/icons-material/Lock';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import CancelIcon from '@mui/icons-material/Cancel';
import DoneIcon from '@mui/icons-material/Done';
import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import "./DisplayStatus.css"

const DisplayStatus = ({status}) => {

    const [display, setDisplay] = useState("")

    useEffect(() => {

        switch (status) {
            case "ATIVO":
                setDisplay(<span className="status-active">
                    <CheckCircleOutlineIcon fontSize="medium"/> Ativo
                </span>)
                break;
            case "INATIVO":
                setDisplay(<span className="status-inactive">
                    <HighlightOffIcon fontSize="medium"/> Inativo
                </span>)
                break;
            case "BLOQUEADO":
                setDisplay(<span className="status-blocked">
                    <LockIcon fontSize="medium"/> Bloqueado
                </span>)
                break;
            case "EXPIRADO":
                setDisplay(<span className="status-expired">
                    <AccessTimeFilledIcon fontSize="medium"/> Expirado
                </span>)
                break;
            case "CANCELADO":
                setDisplay(<span className="status-canceled">
                    <CancelIcon fontSize="medium"/> Cancelado
                </span>)
                break;
            case "BAIXADO":
                setDisplay(<span className="status-blocked">
                    <RequestQuoteIcon fontSize="medium"/> Baixado
                </span>)
                break;
            case "DESFEITA":
                setDisplay(<span className="status-canceled">
                    <CancelIcon fontSize="medium"/> Desfeita
                </span>)
                break;
            case "NEGADA":
                setDisplay(<span className="status-canceled">
                    <CancelIcon fontSize="medium"/> Negada
                </span>)
                break;
            case "CRIADO":
                setDisplay(<span className="status-criated">
                    <DoneIcon fontSize="medium"/> Criado
                </span>)
                break;
            case "EM_RESOLUCAO":
                setDisplay(<span className="status-in_resolution">
                    <PublishedWithChangesIcon fontSize="medium"/> Em Resolucão
                </span>)
                break;
            case "ATUAL":
                setDisplay(<span className="status-active">
                    <CheckCircleOutlineIcon fontSize="medium"/> Atual
                </span>)
                break;
            case "SEMANA":
                setDisplay(<span className="status-active">
                     <CheckCircleOutlineIcon fontSize="medium"/> Semanal
                </span>)
                break;
            case "MÊS":
                setDisplay(<span className="status-active">
                     <CheckCircleOutlineIcon fontSize="medium"/> Mês
                </span>)
                break;
            // para status de processamento
            case "ERRO":
                setDisplay(<span className="status-inactive">
                    <HighlightOffIcon fontSize="medium"/> Erro
                </span>)
                break;
            case "PENDENTE":
                setDisplay(<span className="status-blocked">
                    <AccessAlarmsIcon fontSize="medium"/> Pendente
                </span>)
                break;
            case "REJEITADA":
                setDisplay(<span className="status-inactive">
                    <DoDisturbAltIcon fontSize="medium"/> Rejeitada
                </span>)
                break;
            case "EFETUADA":
                setDisplay(<span className="status-active">
                    <CheckCircleOutlineIcon fontSize="medium"/> Efetuada
                </span>)
                break;
            case "CONFIRMADA":
                setDisplay(<span className="status-active">
                    <CheckCircleOutlineIcon fontSize="medium"/> Confirmada
                </span>)
                break;
        }

    }, [status]);

    // const displayDate = ({dias}) => {
    //     switch (dias) {
    //         case "ATUAL":
    //             setDisplay(<span className="status-active">
    //                 <CheckCircleOutlineIcon fontSize="medium"/> Atual
    //             </span>)
    //             break;
    //         case "SEMANA":
    //             setDisplay(<span className="status-active">
    //                  <CheckCircleOutlineIcon fontSize="medium"/> Semanal
    //             </span>)
    //             break;
    //         case "MÊS":
    //             setDisplay(<span className="status-active">
    //                  <CheckCircleOutlineIcon fontSize="medium"/> Mês
    //             </span>)
    //             break;
    //     }
    // }, [dias]);


    return (
        <>
            {display}
        </>
    )
}

export default DisplayStatus
import React, {useState} from "react";
import TableList from "../../../../components/TableList/TableList";
import {NumberWithPoints} from "../../../../utils/numberWithPoints";
import DisplayStatus from "../../../../components/Status/DisplayStatus";
import FilterBalanceTransfer from "./FilterBalanceTransfer";
import Popup from "../../../../components/Popup/Popup";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import HelpIcon from '@mui/icons-material/Help';
import {normalizeTimestemp} from "../../../../utils/normalizeTimestemp";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const ListBalanceTransferHistory = ({
                                        baseUrl,
                                        loadData,
                                        setLoadData,
                                        size,
                                        page,
                                        setTotalPages,
                                        setTotalElements,
                                        sort,
                                        setSort,
                                        updateCode,
                                        setPage
                                    }) => {

    const [lgShow, setLgShow] = useState(false)
    const [currentItem, setCurrentItem] = useState(false)

    const [data, setData] = useState([])
    const [error, setError] = useState(false)
    const [filter, setFilter] = useState({})

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Clique no status da transferência para ver os
            detalhes em caso de erro ou rejeição
        </Tooltip>
    );

    const statusDisplay = <OverlayTrigger
        placement="right"
        delay={{show: 250, hide: 400}}
        overlay={renderTooltip}
    >
        <span>Status <HelpIcon fontSize="medium"/></span>
    </OverlayTrigger>


    const titles = [
        "Cartão de Origem",
        "Cartão de destino",
        "Valor",
        statusDisplay,
        "Arquivo"
    ]

    const onClickStatus = (item) => {
        if (item.status.status === 'REJEITADA' || item.status.status === 'ERRO') {
            setLgShow(true)
            setCurrentItem(item)
        }
    }

    const iconSpan = <>
        <MonetizationOnIcon fontSize="large" id="icon-svg"/>
    </>


    return (
        <>
            <FilterBalanceTransfer
                filter={filter} setFilter={setFilter}
                setLoadData={setLoadData} setPage={setPage}
                baseUrl={baseUrl}
            />

            <TableList
                titles={titles} baseUrl={baseUrl}
                page={page} sort={sort} size={size}
                loadData={loadData}
                setTotalPages={setTotalPages} setTotalElements={setTotalElements}
                setData={setData} setLoadData={setLoadData} filter={filter}
                error={error} setError={setError}
            >
                {data.map((item) => <tr key={item.id}>
                    <td>{item.originCardNumber}</td>
                    <td>{item.destinationCardNumber}</td>
                    <td>
                        {item.value !== null ? <span>R$ {NumberWithPoints(item.value)}</span> : <span>----</span>}
                    </td>
                    <td>
                        <span title={item.denialReason} onClick={() => onClickStatus(item)}
                        style={{cursor: 'pointer'}}
                        >
                            <DisplayStatus status={item.status.status}/>
                        </span>
                    </td>
                    <td>
                        {item.balanceTransferFile ?
                            <span>{item.balanceTransferFile.fileName}</span> :
                            <span>-------</span>
                        }
                    </td>
                </tr>)}
            </TableList>
            {/* Popup para mostrar detalhes de cada transferência de saldo */}
            <Popup
                buttonText={''}
                title={'Transferência de saldo'}
                iconSpan={iconSpan}
                lgShow={lgShow} setLgShow={setLgShow}
                buttonVariant={""}
                buttonClass={'btn'}
                width='lg'
            >
                <h3 className='text-center'>Dados detalhados</h3>
                <div className='mt-2'>

                    <p>
                        <span className='fw-bold'>Catão de origem: &nbsp;</span>
                        {currentItem.originCardNumber}
                    </p>
                    <p>
                        <span className='fw-bold'>Catão de destino: &nbsp;</span>
                        {currentItem.destinationCardNumber}
                    </p>
                    <p>
                        <span className='fw-bold'>Status: &nbsp;</span>
                        <DisplayStatus status={currentItem?.status?.status}/>
                    </p>
                    <p>
                        <span className='fw-bold'>Data de última alteração: &nbsp;</span>
                        {normalizeTimestemp(currentItem.lastUpdated)}
                    </p>
                    <p>
                        {currentItem.balanceTransferFile &&
                            <p>
                                <span className='fw-bold'>Arquivo: &nbsp;</span>
                                {currentItem.balanceTransferFile.fileName}
                            </p>
                        }
                    </p>
                    <p>
                        <span className='fw-bold'>Erro: &nbsp;</span>
                        <span className='text-danger'>
                            {currentItem?.denialReason}
                        </span>
                    </p>
                </div>
            </Popup>
        </>
    )
}

export default ListBalanceTransferHistory
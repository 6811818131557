// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
#div-input-file {
    margin-bottom: 1vw;
    cursor: pointer;
    display: inline-block;
}

#div-input-file span {
    display: inline-block;
}

#input-file {
    margin: 1vw 1rem 0 0;
    padding: .2rem 1rem;
    border: 1px solid #a7a7a7;
    border-radius: 0.3vw;
    color: #7f7f7f;
    font-size: 1.3rem;
    transition: 0.8s;
}

#input-file:hover {
    background-color: #7f7f7f;
    color: #ffffff;
}`, "",{"version":3,"sources":["webpack://./src/components/FileInput/FileInput.css"],"names":[],"mappings":";AACA;IACI,kBAAkB;IAClB,eAAe;IACf,qBAAqB;AACzB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,oBAAoB;IACpB,mBAAmB;IACnB,yBAAyB;IACzB,oBAAoB;IACpB,cAAc;IACd,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,cAAc;AAClB","sourcesContent":["\r\n#div-input-file {\r\n    margin-bottom: 1vw;\r\n    cursor: pointer;\r\n    display: inline-block;\r\n}\r\n\r\n#div-input-file span {\r\n    display: inline-block;\r\n}\r\n\r\n#input-file {\r\n    margin: 1vw 1rem 0 0;\r\n    padding: .2rem 1rem;\r\n    border: 1px solid #a7a7a7;\r\n    border-radius: 0.3vw;\r\n    color: #7f7f7f;\r\n    font-size: 1.3rem;\r\n    transition: 0.8s;\r\n}\r\n\r\n#input-file:hover {\r\n    background-color: #7f7f7f;\r\n    color: #ffffff;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

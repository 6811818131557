import Box from "@mui/material/Box";
import {Link} from "react-router-dom";
import LockIcon from '@mui/icons-material/Lock';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import React, {useState} from "react";
import axios from "axios";
import displayAlertMsg from "../../components/AlertMsg/displayAlertMsg";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ContentContainer from "../../components/ContentContainer";
import botImage from "../../assets/images/BotiAdministrativo.jpg";
import Card from "@mui/material/Card";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import KeyIcon from "@mui/icons-material/Key";
import {Form} from "react-bootstrap";
import CoverLayout from "../authentication/components/CoverLayout";
import MDButton from "../../components/MDButton";

export default function ForgetPassword() {

    const [error, setError] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [sent, setSent] = useState(false)
    const [id, setId] = useState('')

    const sendEmail = (event) => {
        event.preventDefault()

        const data = new FormData(event.currentTarget);

        const email = data.get('email')
        console.log(email)

        // axios.post(`http://localhost:3002/forgot-password/send-email/`, {email} )
        axios.post(`http://${process.env.REACT_APP_CARD_PROCESS_SECURITY_LINK}/forgot-password/send-email`, {email} )
            .then(function (response) {
                if (response.data){
                console.log(response.data)
                    setId(response.data.id)
                    setSent(true)
                }
            }).catch(function (errorReceived) {
            // aqui temos acesso ao erro, quando alguma coisa inesperada acontece:
            console.log(errorReceived);
            setError(true)
        })

    }

    const button = isLoading ?
        <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{mt: 1, mb: 2, backgroundColor: "secondary"}}
            disabled
        >
            Aguarde...
        </Button> :
        <MDButton
            color='success'
            type="submit"
            fullWidth
            variant="contained"
            sx={{mt: 1, mb: 2, color: "#FFF", backgroundColor: "primary"}}
        >
            Enviar link para alteração
        </MDButton>

    return (

        <>
            <CoverLayout image={botImage}>
                <Card>
                    <MDBox
                        variant="gradient"
                        bgColor="success"
                        borderRadius="lg"
                        coloredShadow="success"
                        mx={2}
                        mt={-3}
                        p={3}
                        mb={1}
                        textAlign={"center"}
                        alignItems={"center"}
                        justifyContent={"center"}
                    >
                        <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                            Enfrentando dificuldades para acessar sua conta?
                            <br/>
                            <LockIcon fontSize='medium' sx={{fontSize: '3rem'}}/><br/>
                        </MDTypography>
                    </MDBox>
                    <MDBox pt={4} pb={3} px={3}>
                        {!sent ?
                        <Form component="form" role="form" onSubmit={sendEmail}>
                            <MDTypography display="block" variant="button" color="black" my={1}
                                          textAlign={"center"} alignItems={"center"} justifyContent={"center"}
                                          marginTop={"-20px"}
                            >
                                Digite seu endereço de email e nós lhe enviaremos um link para restabelecer o acesso à sua conta.
                            </MDTypography>
                            <MDBox mb={2} textAlign={"center"} alignItems={"center"} justifyContent={"center"}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    name="email"
                                    type='email'
                                    autoComplete="email"
                                    variant='standard'
                                    autoFocus
                                    sx={{
                                        width: '20rem',
                                        marginTop: '1rem',
                                    }}
                                />
                            </MDBox>
                            <Form.Control
                                hidden
                                isInvalid={error} // Define a propriedade isInvalid
                            />

                            {button}

                            {/*<div className="text-center mb-1" style={{fontSize:"1rem"}}>*/}
                            {/*    <Link to='/authentication/sign-in' style={{color:"green", textDecoration:"underline"}}>*/}
                            {/*        Voltar para o login*/}
                            {/*    </Link>*/}
                                <Link to='/authentication/sign-in' style={{fontSize:"1rem", color:"green"}}>
                                    <p className='text-center mb-1' style={{textDecoration:"underline"}}>Voltar para o login</p>
                                </Link>
                        </Form> :
                            <MDTypography display="block" variant="button" color="black" my={1}
                                          textAlign={"center"} alignItems={"center"} justifyContent={"center"}
                                          marginTop={"-20px"}
                            >
                                <p style={{color: 'green'}}>

                                    <CheckCircleIcon sx={{
                                        fontSize: 40,
                                        height: '5vw',
                                        width: '5vw',
                                    }}/><br/>
                                    <h4>Enviamos um email para a alteração da senha, verifique sua caixa de entrada e spam.</h4>
                                </p>
                                <Link to='/authentication/sign-in' style={{fontSize:"1rem", color:"green"}}>
                                    <p className='text-center mb-1' style={{textDecoration:"underline"}}>Voltar para o login</p>
                                </Link>
                            </MDTypography>
                        }
                    </MDBox>
                </Card>
            </CoverLayout>
        </>
    )
}
import React, {useState} from "react";
import MainNavbar from "../../components/MainNavbar";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import {Paper} from "@mui/material";
import PaginationControl from "../../components/Pagination/PaginationControl";
import PopupAddGroup from "./components/PopupAddGroup";
import ListGroup from "./components/ListGroup";
import Footer from "../../examples/Footer";
import FilterFormGroup from "./components/FilterFormGroup";


const FranchiseGroup = () => {
    const baseUrl = `http://${process.env.REACT_APP_GLOBAL_PROCESS_LINK}/FranchiseGroup`
    const [loadData, setLoadData] = useState(true)

    const [size, setSize] = useState(10)
    const [page, setPage] = useState(0)
    const [sort, setSort] = useState("")
    const [filter, setFilter] = useState({})

    const [totalPages, setTotalPages] = useState(0)
    const [totalElements, setTotalElements] = useState(0)

    // const updateCode2 = (code, setCode) => {
    //     let codeToSend = code.replace(/\D/g, '');
    //     if(codeToSend.length > 6) {
    //         codeToSend = codeToSend.slice(0, 6);
    //     }
    //     while (codeToSend.length < 6) {
    //         codeToSend = "0" + codeToSend
    //     }
    //     setCode(codeToSend)
    // }

    // const updateCode = (code, setCode) => {
    //     let codeToSend = parseInt(code)+""
    //     if (code.includes(NaN)) {
    //         code = code.length - 1
    //     }
    //     if(codeToSend.length > 6) {
    //         codeToSend = codeToSend.slice(0, 6);
    //     }
    //     while(codeToSend.length < 6){
    //         codeToSend = "0"+codeToSend
    //     }
    //     setCode(codeToSend)
    // }

    return (
        <>
            <MainNavbar/>

            <DashboardLayout>
                <Paper className="paper-list mb-3">

                    <h1>Lista de Grupos Franquia</h1>

                    <PopupAddGroup baseUrl={baseUrl} setLoadData={setLoadData}/>
                                   {/*updateCode={updateCode}/>*/}

                    <FilterFormGroup filter={filter} setFilter={setFilter} setLoadData={setLoadData} setPage={setPage}/>

                    <ListGroup
                        baseUrl={baseUrl}
                        loadData={loadData}
                        setLoadData={setLoadData}
                        page={page}
                        size={size}
                        setTotalPages={setTotalPages}
                        setTotalElements={setTotalElements}
                        sort={sort} setSort={setSort}
                        // updateCode={updateCode}
                        filter={filter}
                    />

                    <PaginationControl
                        totalElements={totalElements}
                        page={page}
                        setPage={setPage}
                        size={size}
                        setSize={setSize}
                        setLoadData={setLoadData}
                    />

                </Paper>
            </DashboardLayout>
            <Footer/>
        </>
    )
}

export default FranchiseGroup
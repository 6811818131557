import React, {useState} from "react";
import TableList from "../../../../components/TableList/TableList";
import {NumberWithPoints} from "../../../../utils/numberWithPoints";
import DisplayStatus from "../../../../components/Status/DisplayStatus";
import FilterLoadCard from "./FilterBalanceTransfer";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import HelpIcon from "@mui/icons-material/Help";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import Popup from "../../../../components/Popup/Popup";
import {normalizeTimestemp} from "../../../../utils/normalizeTimestemp";

const ListLoadCardHistory = ({
                                 baseUrl, loadData,
                                 setLoadData, size, page,
                                 setTotalPages, setTotalElements,
                                 sort, setSort, updateCode, setPage,
                             }) => {
    const [lgShow, setLgShow] = useState(false)
    const [currentItem, setCurrentItem] = useState(false)

    const [data, setData] = useState([])
    const [error, setError] = useState(false)
    const [filter, setFilter] = useState({})

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Clique no status da carga para ver os
            detalhes em caso de erro ou rejeição
        </Tooltip>
    );

    const statusDisplay = <OverlayTrigger
        placement="right"
        delay={{show: 250, hide: 400}}
        overlay={renderTooltip}
    >
        <span>Status <HelpIcon fontSize="medium"/></span>
    </OverlayTrigger>

    const titles = [
        "Cartão",
        "Valor",
        statusDisplay,
        "Arquivo",
        "Será (re)processado",
    ]



    const onClickStatus = (item) => {
        if (item.status.status === 'REJEITADA' || item.status.status === 'ERRO') {
            setLgShow(true)
            setCurrentItem(item)
        }
    }

    const iconSpan = <>
        <MonetizationOnIcon fontSize="large" id="icon-svg"/>
    </>

    return (
        <>
            <FilterLoadCard
                filter={filter} setFilter={setFilter}
                setLoadData={setLoadData} setPage={setPage}
                baseUrl={baseUrl}
            />
            <TableList
                titles={titles} baseUrl={baseUrl}
                page={page} sort={sort} size={size}
                loadData={loadData}
                setTotalPages={setTotalPages} setTotalElements={setTotalElements}
                setData={setData} setLoadData={setLoadData} filter={filter}
                error={error} setError={setError}
            >
                {data.map((item) =>
                    <tr key={item.id}>
                        <td>{item.cardNumber}</td>
                        <td>
                            {item.value !== null ?
                                <span>R$ {NumberWithPoints(item.value)}</span> :
                                <span>----</span>
                            }
                        </td>
                        <td>
                            <span title={item.denialReason} onClick={() => onClickStatus(item)}>
                                <DisplayStatus status={item.status.status}/>
                            </span>
                        </td>
                        <td>
                            {item.loadCardFile ?
                                <span>{item.loadCardFile.fileName}</span> :
                                <span>-------</span>
                            }
                        </td>
                        <td>
                            {item.loaded ?
                                <span>Não</span> :
                                <span>Sim</span>
                            }
                        </td>
                    </tr>
                )}
            </TableList>
            {/* Popup para mostrar detalhes de cada carga */}
            <Popup
                buttonText={''}
                title={'Carga de cartão'}
                iconSpan={iconSpan}
                lgShow={lgShow} setLgShow={setLgShow}
                buttonVariant={""}
                buttonClass={'btn'}
                width='lg'
            >
                <h3 className='text-center'>Dados detalados</h3>
                <div className='mt-2'>

                    <p>
                        <span className='fw-bold'>Número do catão: &nbsp;</span>
                        {currentItem.cardNumber}
                    </p>
                    <p>
                        <span className='fw-bold'>Status: &nbsp;</span>
                        <DisplayStatus status={currentItem?.status?.status}/>
                    </p>
                    <p>
                        <span className='fw-bold'>Data da tentativa: &nbsp;</span>
                        {normalizeTimestemp(currentItem.loadDate)}
                    </p>
                    <p>
                        {currentItem.loadCardFile &&
                            <p>
                                <span className='fw-bold'>Arquivo: &nbsp;</span>
                                {currentItem.loadCardFile.fileName}
                            </p>
                        }
                    </p>
                    <p>
                        <span className='fw-bold'>Erro: &nbsp;</span>
                        <span className='text-danger'>
                            {currentItem?.denialReason}
                        </span>
                    </p>
                    {!currentItem.loaded && (
                        <b>
                            * Esta carga ainda será reprocessada!
                        </b>
                    )}
                </div>
            </Popup>
        </>

    )
}

export default ListLoadCardHistory
import CustomCard from "../../../../components/CustomCard/CustomCard";
import React, {useEffect, useState} from "react";
import FileInput from "../../../../components/FileInput/FileInput";
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import {Button, Form} from "react-bootstrap";
import Popup from "../../../../components/Popup/Popup";
import axios from "axios";
import BasicLoader from "../../../../components/BasicLoader/BasicLoader";
import DefaultProblemMessage from "../../../../components/DefaultProblemMessage/DefaultProblemMessage";
import DefaultSuccessMessage from "../../../../components/DefaultSuccessMessage/DefaultSuccessMessage";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from '@mui/icons-material/Close';
import {Link} from "react-router-dom";
import {AutoCompleteInput} from "../../../../components/FilteredSelectInput";
import PopupLayoutLoadCard from "./PopupLayoutLoadCard";

export default function LoadCard() {

    const [lgShow, setLgShow] = useState(false)

    const [storeList, setStoreList] = useState([])
    const [internalCode, setInternalCode] = useState('')

    const [logicCode, setLogicCode] = useState('')
    const [file, setFile] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [sendingData, setSendingData] = useState(false);
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    const baseUrl = `http://${process.env.REACT_APP_CARD_MANAGER_LINK}/LoadValueCard/upload`

    const title = 'Carga de Cartões'

    const buttonText = 'Processar'

    const iconSpan = <>
        <CreateNewFolderIcon fontSize="large"/><br/>
        <h3>{title}</h3>
    </>

    const handleSubmit = (e) => {
        e.preventDefault()

        let data = {
            logicCode,
            file
        }

        setSendingData(true)
        axios.post(baseUrl, data, {
            headers: {
                'Content-Type': 'multipart/form-data', // Importante definir o cabeçalho correto para multipart
            },
        }).then(function (response) {
            let processStatus = response.data.status.status
            if (processStatus === 'PROCESSADO') {
                setLoaded(true)
                setError(false)
            }
            if (processStatus === 'REJEITADO') {
                setError(true)
                setErrorMsg(response.data.error)
            }
            if (processStatus === 'ERRO') {
                setError(true)
                setErrorMsg('')
            }
        }).catch(function (error) {
            // aqui temos acesso ao erro, quando alguma coisa inesperada acontece:
            console.log("Error: " + error);
            setError(true)
            setErrorMsg('')
        }).finally(() => {
            setSendingData(false)
        })
    }

    const inputs = <>
        <AutoCompleteInput
            sizeToSearch={3}
            filter={`internalCode=${internalCode}`}
            value={internalCode}
            setValue={setInternalCode}
            firstInputLabel={'Digite 3 dígitos do código interno da loja e uma lista de lojas aparecerá.'}
            secondInputLabel={'Selecione a loja:'}
            url={`http://${process.env.REACT_APP_GLOBAL_PROCESS_LINK}/Store?size=200000000&status=ATIVO`}
            placeholder={"lojas disponíveis para carga"}
            list={storeList}
            set={setStoreList}
            setObj={setLogicCode}
            defaultValue={logicCode}
            required={true}
            fullWidth
            autoFocus
            getLabel={(item) => {
                return `${item.internalCode} - ${item.corporateName}`
            }}
            getValueForList={(item) => {
                return item.logicCode
            }}
        />
        <FileInput selectedFile={file} setSelectedFile={setFile}/>
    </>

    const retry = () => {
        setError(false)
        setLoaded(false)
        setSendingData(false)
        setErrorMsg('')
        setLogicCode('')
        setFile('')
    }

    const additionalContent = (<>
        <Link to={'/loadCard'}
              className='btn btn-outline-secondary'
              style={{marginLeft: '2%'}}
        >
            Ver Histórico
        </Link>
        <PopupLayoutLoadCard/>
    </>)

    const popUp = <Popup
        buttonText={buttonText}
        title={title}
        iconSpan={iconSpan}
        lgShow={lgShow} setLgShow={setLgShow}
        buttonClass={"button-show"}
        additionalContent={additionalContent}
    >
        <div>
            {sendingData ?
                <BasicLoader height={20} message={"Efetuando carga..."}/> :
                error ?
                    <>
                        <DefaultProblemMessage message={errorMsg !== '' ? errorMsg : false}/>
                        <div className="form-inputs">
                            <Button onClick={retry}> Tentar novamente </Button>
                        </div>
                    </> :
                    loaded ?
                        <>
                            <DefaultSuccessMessage
                                message={'As cargas foram agendadas com sucesso e serão efetuadas posteriormente!'}/>

                            <div className="form-inputs">
                                <Button onClick={() => {
                                    retry()
                                    setLgShow(false)
                                }} className='btn-secondary'>
                                    Fechar <CloseIcon fontSize="medium"/>
                                </Button> &nbsp;
                                <Button onClick={retry}>
                                    Nova carga <AddIcon fontSize="medium"/>
                                </Button>
                            </div>
                        </>
                        :
                        <Form onSubmit={handleSubmit} className="form-inputs">
                            {inputs}
                            <Button type="submit" disabled={(!file)}>
                                {buttonText}
                            </Button>
                        </Form>
            }
        </div>
    </Popup>

    useEffect(() => {
        console.log(file)
    }, [file])


    return (
        <CustomCard
            title={title}
            width={'22vw'}
            button={popUp}
        >
            Importar arquivo de carga.
        </CustomCard>
    )
}
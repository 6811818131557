import TableList from "components/TableList/TableList";
import { useEffect, useState } from "react";
import { NumberWithPoints } from "utils/numberWithPoints";
import DetailInvoice from "./DetailInvoice";
import CustomCard from "components/CustomCard/CustomCard";
import { useAuthContext } from "components/AuthenticationProvider/AuthenticationProvider";
import axios from "axios";

export default function ListInvoice({
    storeId,
    baseUrl,
    loadData,
    setLoadData,
    page,
    sort,
    size,
    setTotalPages,
    setTotalElements,
}) {

    const [data, setData] = useState([])
    const [error, setError] = useState(false)
    
    const [store, setStore] = useState({})

    const titles = [
        "Referência",
        "Total"
    ]

    const authContext = useAuthContext();

    useEffect(() => {
        const accessToken = authContext.accessToken;
        axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
        const baseUrl = `http://${process.env.REACT_APP_GLOBAL_PROCESS_LINK}/Store/${storeId}`;

        axios.get(baseUrl)
            .then(function (response) {
                setStore(response.data);
            }).catch(function (errorReceived) {
                console.log(errorReceived);
            });
    }, []);


    
    return (
        <>
            <CustomCard
                title="Loja"
                width={'22vw'}
            >
                <p>Código Interno: {store.internalCode}</p>
                <p>CNPJ: {store.cnpj}</p>
                <p>Razão Social: {store.corporateName}</p>
            </CustomCard>
            <TableList
                titles={titles}
                data={data}
                emptyMessage="Sem dados encontrados nesta lista de faturas no momento"
                baseUrl={baseUrl}
                setData={setData}
                page={page}
                sort={sort}
                size={size}
                loadData={loadData}
                setLoadData={setLoadData}
                setTotalPages={setTotalPages}
                setTotalElements={setTotalElements}
                error={error}
                setError={setError}
            >
                {data.map((item) =>
                    <tr key={item.id}>
                        <td>{item.monthYear}</td>
                        <td>R$ {NumberWithPoints(item.total)}</td>
                        <td><DetailInvoice item={item}/></td>
                    </tr>
                )}
            </TableList>
        </>
    );
}
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-filter button{
    margin-inline: 1%;
    margin-bottom: 5%;
}
.btn-secondary{
    margin-left: 1%;
}`, "",{"version":3,"sources":["webpack://./src/components/FilterForm/FilterForm.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,iBAAiB;AACrB;AACA;IACI,eAAe;AACnB","sourcesContent":[".form-filter button{\r\n    margin-inline: 1%;\r\n    margin-bottom: 5%;\r\n}\r\n.btn-secondary{\r\n    margin-left: 1%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

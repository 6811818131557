import FilterForm from "../../../components/FilterForm/FilterForm";
import TextField from "@mui/material/TextField";
import {cnpjMask} from "../../../utils/cnpjMask";
import SelectInput from "../../../components/SelectInput";
import MDBox from "../../../components/MDBox";
import React, {useState} from "react";
import {FormControlLabel, FormLabel, Radio, RadioGroup} from "@mui/material";
import DisplayStatus from "../../../components/Status/DisplayStatus";

const FilterFormGroup = ({filter, setFilter, setLoadData, setPage}) => {

    const [lgShow, setLgShow] = useState(false);

    const [name, setName] = useState("")
    const [code, setCode] = useState("")

    const handlesSubmit = (e) => {
        if (e)
            e.preventDefault()

        setFilter({
            ...(name && {name}),
            ...(code && {code}),
        })

        setLgShow(false)
        setLoadData(true)

        setPage(0);
    }

    const clearFilter = () => {
        setName("")
        setCode("")

        setFilter({})
        setLgShow(false)
        setLoadData(true)
    }

    return (
        <FilterForm
            handleSubmit={handlesSubmit} clearFilter={clearFilter}
            lgShow={lgShow} setLgShow={setLgShow}
            showSecondaryButton={Object.keys(filter).length !== 0}
        >
            <TextField
                label="Nome do Relatório"
                inputProps={{
                    maxLength: 20
                }}
                errortext="Campo obrigatório!"
                value={name}
                onChange={(e) => setName(e.target.value.toUpperCase())}
                fullWidth
                autoFocus
            />
            <TextField
                label="Código do Relatório"
                inputProps={{
                    maxLength: 20
                }}
                erortext="Campo obrigatório!"
                value={code}
                onChange={(e) => setCode(e.target.value.toUpperCase())}
                id="input-code"
                fullWidth
            />
        </FilterForm>
    )
}

export default FilterFormGroup
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `tbody, td, tfoot, th, thead, tr {
    padding: 7px;
}

.row {
    margin-bottom: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/layouts/financialClose/financial-close.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":["tbody, td, tfoot, th, thead, tr {\r\n    padding: 7px;\r\n}\r\n\r\n.row {\r\n    margin-bottom: 1rem;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon-span{
    text-align: center;
}

#icon-svg{
    width: 100%;
}
#icon-svg path{
    width: 30vw;
}
.button-show{
    float: right;
}`, "",{"version":3,"sources":["webpack://./src/components/Popup/Popup.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,WAAW;AACf;AACA;IACI,WAAW;AACf;AACA;IACI,YAAY;AAChB","sourcesContent":[".icon-span{\r\n    text-align: center;\r\n}\r\n\r\n#icon-svg{\r\n    width: 100%;\r\n}\r\n#icon-svg path{\r\n    width: 30vw;\r\n}\r\n.button-show{\r\n    float: right;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useEffect, useState } from 'react';

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";
import botImage from "../../assets/images/BotiAnuncio.jpg";

// react-router-dom components
import { Link, useNavigate, useParams } from "react-router-dom";


import UserMessage from 'components/UserMessage/UserMessage';
import axios from 'axios';
import verifyPassword from "../../utils/verifyPassword";
import displayAlertMsg from "../../components/AlertMsg/displayAlertMsg";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export default function ResetPassword() {

    const params = useParams();

    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [show, setShow] = useState(false);
    const toggleSnackbar = () => setShow(!show);

    const [messageContent, setMessageContent] = useState('');
    const [messageType, setMessageType] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [changed, setChanged] = useState(false)

    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const handleToggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    useEffect(() => {
        axios.get(`http://${process.env.REACT_APP_CARD_PROCESS_SECURITY_LINK}/forgot-password/open/${params.token}`)
            .then(function (response) {
                console.log(response.data);
                showSuccess(response.data.message);
            })
            .catch(function (error) {
                console.log('Erro ao recuperar dados da requisição de senha:')
                console.log(error);
                showError(error.response.data.message ? error.response.data.message : "Erro ao recuperar dados da requisição de senha");
            });
    },[]);


    const navigate = useNavigate();

    function showError(error) {
        setMessageType('error');
        setMessageContent(error);
        setShow(true);
    }

    function showSuccess(message) {
        setMessageType('success');
        setMessageContent(message);
        setShow(true);
    }

    function submitForm(event) {
        event.preventDefault();
        const formData = new FormData(event.target);
        const username = formData.get('username')
        const pwd = formData.get('password');
        const confirm = formData.get('confirmPassword');

        let passwordValid = verifyPassword(pwd)
        if (passwordValid !== 'Senha válida') {
            showError(displayAlertMsg(passwordValid))
            return
        }

        if (pwd !== confirm) {
            showError('As senhas não conferem. Tente novamente');
            return;
        } else {
            axios.post(`http://${process.env.REACT_APP_CARD_PROCESS_SECURITY_LINK}/forgot-password/reset-password/${params.token}`, {username: username, newPassword: pwd, confirmNewPassword: confirm})
                .then(function (response) {
                    console.log(response.data);
                    setChanged(true)
                })
                .catch(function (error) {
                    console.log(error);
                    showError(error.response.data.message);
                });
        }
    }

    return (
        <CoverLayout image={botImage}>
            <Card>
                <MDBox
                    variant="gradient"
                    bgColor="success"
                    borderRadius="lg"
                    coloredShadow="success"
                    mx={2}
                    mt={-3}
                    p={3}
                    mb={1}
                    textAlign="center"
                >
                    <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                        Troca de Senha
                    </MDTypography>
                    <MDTypography display="block" variant="button" color="white" my={1}>
                        Realize Aqui a Sua Mudança!
                    </MDTypography>
                </MDBox>
                <MDBox pt={4} pb={3} px={3}>
                    {!changed ?

                    <MDBox component="form" role="form" onSubmit={submitForm}>
                        <MDBox mb={2}>
                            <MDInput name="username" type="text" label="Nome do Usuário" variant="standard" fullWidth required/>
                        </MDBox>
                        {/*<MDBox mb={2}>*/}
                        {/*    <MDInput name="email" type="email" label="Email" variant="standard" fullWidth required/>*/}
                        {/*</MDBox>*/}
                        <MDBox mb={2}>
                            <MDInput name="password" type={showPassword ? 'text' : 'password'} label="Senha" variant="standard"
                                     InputProps={{
                                         endAdornment: (
                                             <InputAdornment position="end">
                                                 <IconButton onClick={handleTogglePasswordVisibility}>
                                                     {showPassword ? <Visibility /> : <VisibilityOff />}
                                                 </IconButton>
                                             </InputAdornment>
                                         ),
                                     }}
                                     fullWidth required
                            />
                        </MDBox>
                        <MDBox mb={2}>
                            <MDInput name="confirmPassword" type={showConfirmPassword ? 'text' : 'password'} label="Confirme Senha" variant="standard"
                                     InputProps={{
                                         endAdornment: (
                                             <InputAdornment position="end">
                                                 <IconButton onClick={handleToggleConfirmPasswordVisibility}>
                                                     {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                                 </IconButton>
                                             </InputAdornment>
                                         ),
                                     }}
                                     fullWidth required
                            />
                        </MDBox>
                        <MDBox display="flex" alignItems="center" ml={-1}>
                        </MDBox>
                        <MDBox mt={4} mb={1}>
                            <MDButton variant="gradient" color="success" fullWidth type="submit">
                                Confirmar Alteração
                            </MDButton>
                        </MDBox>
                    </MDBox> :
                        <MDTypography display="block" variant="button" color="black" my={1}
                                      textAlign={"center"} alignItems={"center"} justifyContent={"center"}
                                      marginTop={"-20px"}
                        >
                            <p style={{color: 'green'}}>

                                <CheckCircleIcon sx={{
                                    fontSize: 40,
                                    height: '5vw',
                                    width: '5vw',
                                }}/><br/>
                                <h4>Senha trocado com sucesso, retorne a tela principal e realize seu login.</h4>
                            </p>
                            <Link to='/authentication/sign-in' style={{fontSize:"1rem", color:"green"}}>
                                <p className='text-center mb-1' style={{textDecoration:"underline"}}>Voltar para o login</p>
                            </Link>
                        </MDTypography>
                    }
                </MDBox>
            </Card>

            <UserMessage
                type={messageType}
                content={messageContent}
                open={show}
                close={toggleSnackbar}
            />
        </CoverLayout>
    );

}
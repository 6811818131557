import React, {useEffect, useState} from "react";
import InputsStore from "./InputsStore";
import PopupEdit from "../../../components/PopupEdit/PopupEdit";

const PopupEditStore = ({ item, baseUrl, setLoadData, updateCode, isDisabled }) => {

    const [id, setId] = useState("");
    const [corporateName, setCorporateName] = useState("");
    const [tradingName, setTradingName] = useState("");
    const [cnpj, setCnpj] = useState("");
    const [internalCode, setInternalCode] = useState("");
    // const [supplierCode, setSupplierCode] = useState("");

    // contact
    const [phoneNumber, setPhoneNumber] = useState("");
    const [email, setEmail] = useState("");

    // address
    const [cityId, setCityId] = useState("");
    const [thoroughfare, setThoroughfare] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [neighborhood, setNeighborhood] = useState("");
    const [addressNumber, setAddressNumber] = useState("");
    const [complement, setComplement] = useState("");

    const [businessUnit, setBusinessUnit] = useState("");
    const [regional, setRegional] = useState("");
    const [franchiseGroup, setFranchiseGroup] = useState("");

    const [status, setStatus] = useState("ATIVO");

    // data of centralize
    const [centralized, setCentralized] = useState(item.centralizedStoreId !== null);
    const [centralizedStoreId, setCentralizedStoreId] = useState(item.centralizedStoreId);
    const [centralizedStoreCode, setCentralizedStoreCode] = useState(item.centralizedStoreCode);

    // bank details
    const [agency, setAgency] = useState("");
    const [account, setAccount] = useState("");
    const [bankCode, setBankCode] = useState("");

    // fees
    const [loadFee, setLoadFee] = useState("");
    const [redeemFee, setRedeemFee] = useState("");
    const [administrationFee, setAdministrationFee] = useState("");

    const [errorMessage, setErrorMessage] = useState("");
    const [errorColumn, setErrorColumn] = useState("");
    const [blockSubmit, setBlockSubmit] = useState(false);

    //habilita/desabilita o input de taxas
    const [disableFees, setDisableFees] = useState()


    const [disableInputs, setDisableInputs] = useState()

    useEffect(() => {
        if(item.status === "BLOQUEADO"){
            setDisableFees(true)
        }

        if (item.status === "INATIVO"){
            setDisableFees(true)
            setDisableInputs(true)
        }
    }, [])

    const itemToState = () => {
        setId(item.id);
        setTradingName(item.tradingName);
        setCorporateName(item.corporateName);
        setCnpj(item.cnpj);
        setInternalCode(item.internalCode);
        // setSupplierCode(item.supplierCode);
        setPhoneNumber(item.phoneNumber);
        setEmail(item.email);
        setCityId(item.cityId);
        setThoroughfare(item.thoroughfare);
        setPostalCode(item.postalCode);
        setNeighborhood(item.neighborhood);
        setAddressNumber(item.addressNumber);
        setComplement(item.complement);
        setAgency(item.agency);
        setAccount(item.account);
        setBankCode(item.bankCode);
        setStatus(item.status);
        setBusinessUnit(item.businessUnitId);
        setCentralized(item.centralizedStoreId !== null);
        setCentralizedStoreId(item.centralizedStoreId);
        setCentralizedStoreCode(item.centralizedStoreCode);

        if (item.loadFeeSpecific)
            setLoadFee(item.loadFee);
        if (item.redeemFreeSpecific)
            setRedeemFee(item.redeemFee);
        if (item.administrationFeeSpecific)
            setAdministrationFee(item.administrationFee);

        setRegional(item.regionalId);
        setFranchiseGroup(item.franchiseGroupId);
        setErrorMessage("");
        setErrorColumn("");
        setBlockSubmit(false);
    };

    const inputs = (
        <InputsStore
            setCorporateName={setCorporateName} corporateName={corporateName}
            setTradingName={setTradingName} tradingName={tradingName}
            setCnpj={setCnpj} cnpj={cnpj}
            setInternalCode={setInternalCode} internalCode={internalCode}
            // setSupplierCode={setSupplierCode} supplierCode={supplierCode}
            setPhoneNumber={setPhoneNumber} phoneNumber={phoneNumber}
            setEmail={setEmail} email={email}
            setCityId={setCityId} cityId={cityId}
            setThoroughfare={setThoroughfare} thoroughfare={thoroughfare}
            setPostalCode={setPostalCode} postalCode={postalCode}
            setNeighborhood={setNeighborhood} neighborhood={neighborhood}
            setAddressNumber={setAddressNumber} addressNumber={addressNumber}
            setComplement={setComplement} complement={complement}
            setAgency={setAgency} agency={agency}
            setAccount={setAccount} account={account}
            setBankCode={setBankCode} bankCode={bankCode}
            setStatus={setStatus} status={status}
            centralized={centralized} setCentralized={setCentralized}
            centralizedStoreId={centralizedStoreId} setCentralizedStoreId={setCentralizedStoreId}
            centralizedStoreCode={centralizedStoreCode} setCentralizedStoreCode={setCentralizedStoreCode}
            setBusinessUnit={setBusinessUnit} businessUnit={businessUnit}
            setLoadFee={setLoadFee} loadFee={loadFee}
            setRedeemFee={setRedeemFee} redeemFee={redeemFee}
            setAdministrationFee={setAdministrationFee} administrationFee={administrationFee}
            setRegional={setRegional} regional={regional}
            setFranchiseGroup={setFranchiseGroup} franchiseGroup={franchiseGroup}
            setErrorMessage={setErrorMessage} errorMessage={errorMessage}
            setErrorColumn={setErrorColumn} errorColumn={errorColumn}
            setBlockSubmit={setBlockSubmit}
            item={item} isCentralizer={item.centralizedStoreId === null}
            disabledFee={disableFees}
            // naoObrigatorio={true}
        />
    );

    const title = "Editar Loja";

    const businessUnitId = parseInt(businessUnit);
    const regionalId = parseInt(regional);
    const franchiseGroupId = parseInt(franchiseGroup);

    const validateData = () => {
        return !(!centralized && !bankCode);
    };

    const returnData = () => {
        return {
            id,
            corporateName,
            tradingName,
            cnpj,
            internalCode,
            // supplierCode,
            phoneNumber,
            email,
            cityId,
            thoroughfare,
            postalCode,
            neighborhood,
            addressNumber,
            complement,
            agency,
            account,
            bankCode,
            status,
            businessUnitId,
            loadFee,
            redeemFee,
            administrationFee,
            regionalId,
            franchiseGroupId,
            centralizedStoreId: centralized ? centralizedStoreId : null
        };
    };

    return (
        <>
            <PopupEdit
                baseUrl={baseUrl} setLoadData={setLoadData}
                inputs={inputs} title={title}
                returnData={returnData} itemToState={itemToState}
                setErrorMessage={setErrorMessage} setErrorColumn={setErrorColumn}
                blockSubmit={blockSubmit} validateData={validateData}
                errorMessage={errorMessage}
                isDisabled={disableInputs}
            />

        </>
    );
};

export default PopupEditStore;

import React, {useState} from "react";
import "./ListReport.css";
import TableList from "../../../components/TableList/TableList";
import displayByAlias from "../../../utils/displayByAlias";
import {typeList} from "./Enums/typeList";
import {statusList} from "./Enums/statusList";
import {formatList} from "./Enums/formatList";
import {normalizeTimestemp} from "../../../utils/normalizeTimestemp";
import DownloadIcon from "@mui/icons-material/Download";
import {LoadingButton} from "@mui/lab";
import {downloadFile} from "./downloadFile";

const ListReport = ({
                        baseUrl,
                        loadData,
                        setLoadData,
                        size,
                        page,
                        setTotalPages,
                        setTotalElements,
                        sort
                    }) => {
    const [data, setData] = useState([]);
    const [error, setError] = useState(false);

    const [downloadingList, setDownloadingList] = useState([])

    const handleDownload = async (item) => {

        const url = baseUrl + `/report-request/download/${item.id}`
        const fileName = item.filePath.split("/").at(-1)
        const format = item.format

        setDownloadingList(preevList => [...preevList, item.id])
        await downloadFile(url, fileName, format)
        setDownloadingList(preevList =>
            preevList.filter( i => i !== item.id)
        )
    }

    const titles = [
        "Tipo do Relatório",
        "Solicitado Em",
        "Processado Em",
        "Status do Relatório",
        "Formato",
        "Posição na Fila",
    ];

    return <TableList
        titles={titles} baseUrl={baseUrl + '/report-request'}
        page={page} sort={sort} size={size}
        loadData={loadData}
        setTotalPages={setTotalPages} setTotalElements={setTotalElements}
        setData={setData} setLoadData={setLoadData}
        error={error} setError={setError}
    >
        {data.map((item) =>
            <tr key={item.id}>
                <td>{displayByAlias(item.type.type, typeList)}</td>
                <td>{normalizeTimestemp(item.dateCreated)}</td>
                <td>{!!(item.processingDate) ? normalizeTimestemp(item.processingDate) : '-----'}</td>
                <td>
                    {displayByAlias(item.status.status, statusList)}
                    <LoadingButton
                        title='Fazer download do relatório'
                        loading={downloadingList.some(id => id === item.id)}
                        onClick={() => handleDownload(item)}
                        variant="text"
                        disabled={item.status.status !== "AVAILABLE"}
                        size='large'
                    >
                        <DownloadIcon/>
                    </LoadingButton>
                </td>
                <td>{displayByAlias(item.format, formatList)}</td>
                <td>{item.status.status === 'PENDING' ? item.queuePosition : '-----'}</td>
            </tr>
        )}
    </TableList>

};

export default ListReport;

import PopupDelete from "../../../components/PopupDelete/PopupDelete";
import React from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import AccessAlarmsIcon from "@mui/icons-material/AccessAlarms";
import {normalizeTimestemp} from "../../../utils/normalizeTimestemp";

const PopupDeleteCardSolicitation = ({
                                         baseUrl,
                                         setLoadData,
                                         item
                                     }) => {
    return (
        <PopupDelete
            baseUrl={baseUrl} setLoadData={setLoadData}
            title={"Confirmar Exclusão de Solicitação"} id={item.id} buttonText={"Deletar"}
        >

            <div className="mt-3 mb-3">
                <b>Tipo de Cartão: </b>
                <>{item.digital
                    ? <>Digital</>
                    : <>Físico</>
                }</>
                <br/>
                <b>Quantidade de Cartões: </b>
                <>{item.amount}</>
                <br/>

                <b>Unidade de Negócio: </b>
                <>{item.businessUnitName}</>
                <br/>
                <b>Data de Criação: </b>
                <>{normalizeTimestemp(item.dateCreated)}</>
                <br/>
            </div>

            <p className="mb-3">Você deseja realmente excluir este registro?</p>

        </PopupDelete>
    )
}
export default PopupDeleteCardSolicitation
import React, {useState} from "react";
import PopupEdit from "../../../components/PopupEdit/PopupEdit";
import InputsGroup from "./InputsGroup";

const PopupEditGroup = ({item, baseUrl, setLoadData, updateCode}) => {

    const [name, setName] = useState(item.name)
    const [code, setCode] = useState(item.code)
    const [status, setStatus] = useState(item.status)
    const [errorMessage, setErrorMessage] = useState("")
    const [errorColumn, setErrorColumn] = useState("")

    const inputs = <InputsGroup
        name={name} setName={setName}
        code={code} setCode={setCode}
        status={status} setStatus={setStatus}
        errorMessage={errorMessage}
        errorColumn={errorColumn}
        updateCode={updateCode}
    />

    const title = "Editar Grupo Franquia"

    const returnData = () => {
        return {
            id: item.id,
            name,
            code,
            status
        }
    }

    return (
        <>
            <PopupEdit
                baseUrl={baseUrl} setLoadData={setLoadData}
                inputs={inputs} title={title} returnData={returnData}
                setErrorMessage={setErrorMessage} setErrorColumn={setErrorColumn}
                errorMessage={errorMessage}
            />
        </>
    )
}

export default PopupEditGroup
import { Paper, Table,  TableContainer } from "@mui/material";
import { useEffect, useState } from "react";
import VisibilityIcon from '@mui/icons-material/Visibility';
import CpGroupMerchantInvoices from "./CpGroupMerchantInvoices";
import BasicLoader from "components/BasicLoader/BasicLoader";
import { NumberWithPoints } from "utils/numberWithPoints";
import { fetchData } from "api/axiosMethods";

import '../../financialClose/financial-close.css';


export default function SummarizedFinancialClose({ params}) {

    const baseUrl = `http://${process.env.REACT_APP_FINANCIAL_MANAGER_LINK}`;

    const [summarize, setSummarize] = useState([]);
    const [callMerchantInvoices, setCallMerchantInvoices] = useState(false);
    const [loadingData, setLoadingData] = useState(true);

    useEffect(async () => {
        console.log("Params: ", params);
        const data = await fetchData(`${baseUrl}/financialClose/summarize`, params);
        if (data) {
            setSummarize(data.collection);
        }
        setLoadingData(false);

    },[params]);


    function handleDetails() {
        setCallMerchantInvoices(true);
    }

    return (
        <>
            {loadingData  ? <BasicLoader/>

            :

                <Paper sx={{ minWidth: 275, elevation: 24, mt: 5 }}>
                    <TableContainer >

                        {callMerchantInvoices ? <CpGroupMerchantInvoices params={params} />
                        
                        :
                            <Table sx={{ minWidth: 700 }} size="small" >
                                <thead>
                                    <tr>
                                        <th>Vencimento</th>
                                        <th>Cargas</th>
                                        <th>Repasse Franquia - Mooz NFS</th>
                                        <th>Repasse Mooz</th>
                                        <th>Resgates</th>
                                        <th>Repasse Mooz - Franquia ND</th>
                                        <th>Repasse Franquia</th>
                                        <th>Ajustes</th>
                                        <th>Total</th>
                                        <th></th>
                                    </tr>
                                </thead>    
                                <tbody>
                                    {summarize?.map((row) => (
                                        <tr key={row.dueDate}>
                                            <td>{row.dueDate}</td>
                                            <td>R$ {NumberWithPoints(row.totalLoadCard)}</td>
                                            <td>R$ {NumberWithPoints(row.totalStoreComission)}</td>
                                            <td>R$ {NumberWithPoints(row.diffLoadCardStoreCommission)}</td>
                                            <td>R$ {NumberWithPoints(row.totalReedem)}</td>
                                            <td>R$ {NumberWithPoints(row.totalAcquiredCommission)}</td>
                                            <td>R$ {NumberWithPoints(row.diffReedemAcquiredCommission)}</td>
                                            <td>R$ {NumberWithPoints(row.totalAdjustment ?? 0.0)}</td>
                                            <td>R$ {NumberWithPoints(row.total)}</td>
                                            <td><a href='#' onClick={() => handleDetails()}>Detalhes <VisibilityIcon fontSize="medium" /></a></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        }
                    </TableContainer>
                </Paper>
            }
        </>
    );
}
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#custom-btn  {
    font-size: 24px;
    border: none;
    border-radius: 10px;
    color: #fff;
    line-height: 1.2;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    height: 50px;
    padding: 40px;
    margin-left: 70px;
    margin-top: 80px;
    text-decoration: none;
    background: #00A470;
}

#custom-btn:hover {
    cursor: pointer;
    background: #00BCA7;
    /* background: -webkit-linear-gradient(to left, #b721ff, #21d4fd);
    background: -o-linear-gradient(to left, #b721ff, #21d4fd);
    background: -moz-linear-gradient(to left, #b721ff, #21d4fd);
    background: linear-gradient(to left, #b721ff, #21d4fd); */
}

@media (max-width:450px){
    #custom-btn{
        font-size: 24px;
        border: none;
        border-radius: 10px;
        color: #fff;
        line-height: 1.2;
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 70%;
        height: 50px;
        padding: 40px;
        margin-left: 40px;
        margin-top: 80px;
        text-decoration: none;
        background: #00A470;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/PrimaryButton/PrimaryButton.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;IACZ,mBAAmB;IACnB,WAAW;IACX,gBAAgB;IAChB,yBAAyB;IACzB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,UAAU;IACV,YAAY;IACZ,aAAa;IACb,iBAAiB;IACjB,gBAAgB;IAChB,qBAAqB;IACrB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,mBAAmB;IACnB;;;6DAGyD;AAC7D;;AAEA;IACI;QACI,eAAe;QACf,YAAY;QACZ,mBAAmB;QACnB,WAAW;QACX,gBAAgB;QAChB,yBAAyB;QACzB,aAAa;QACb,uBAAuB;QACvB,mBAAmB;QACnB,UAAU;QACV,YAAY;QACZ,aAAa;QACb,iBAAiB;QACjB,gBAAgB;QAChB,qBAAqB;QACrB,mBAAmB;IACvB;AACJ","sourcesContent":["#custom-btn  {\r\n    font-size: 24px;\r\n    border: none;\r\n    border-radius: 10px;\r\n    color: #fff;\r\n    line-height: 1.2;\r\n    text-transform: uppercase;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    width: 70%;\r\n    height: 50px;\r\n    padding: 40px;\r\n    margin-left: 70px;\r\n    margin-top: 80px;\r\n    text-decoration: none;\r\n    background: #00A470;\r\n}\r\n\r\n#custom-btn:hover {\r\n    cursor: pointer;\r\n    background: #00BCA7;\r\n    /* background: -webkit-linear-gradient(to left, #b721ff, #21d4fd);\r\n    background: -o-linear-gradient(to left, #b721ff, #21d4fd);\r\n    background: -moz-linear-gradient(to left, #b721ff, #21d4fd);\r\n    background: linear-gradient(to left, #b721ff, #21d4fd); */\r\n}\r\n\r\n@media (max-width:450px){\r\n    #custom-btn{\r\n        font-size: 24px;\r\n        border: none;\r\n        border-radius: 10px;\r\n        color: #fff;\r\n        line-height: 1.2;\r\n        text-transform: uppercase;\r\n        display: flex;\r\n        justify-content: center;\r\n        align-items: center;\r\n        width: 70%;\r\n        height: 50px;\r\n        padding: 40px;\r\n        margin-left: 40px;\r\n        margin-top: 80px;\r\n        text-decoration: none;\r\n        background: #00A470;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

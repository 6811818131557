import MainNavbar from "../../components/MainNavbar";
import {Paper} from "@mui/material";
import React, {useState} from "react";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import "./businessUnit.css"
import ListBusinessUnit from "./components/ListBusinessUnit";
import PopupAddBusinessUnit from "./components/PopupAddBusinessUnit";
import PaginationControl from "../../components/Pagination/PaginationControl";
import Footer from "../../examples/Footer";

function BusinessUnit() {

    const baseUrl = `http://${process.env.REACT_APP_GLOBAL_PROCESS_LINK}/BusinessUnit`
    const [loadData, setLoadData] = useState(true)

    const [size, setSize] = useState(10)
    const [page, setPage] = useState(0)
    const [sort, setSort] = useState("id")

    const [totalPages, setTotalPages] = useState(0)
    const [totalElements, setTotalElements] = useState(0)

    return (
        <>
            <MainNavbar/>

            <DashboardLayout>
                <Paper className="paper-list mb-3">

                    <h1>Lista de Unidades de Negócio</h1>

                    <PopupAddBusinessUnit baseUrl={baseUrl} setLoadData={setLoadData}/>

                    <ListBusinessUnit
                        baseUrl={baseUrl}
                        loadData={loadData}
                        setLoadData={setLoadData}
                        page={page}
                        setPage={setPage}
                        size={size}
                        setTotalPages={setTotalPages}
                        setTotalElements={setTotalElements}
                        sort={sort} setSort={setSort}
                    />

                    <PaginationControl
                        totalElements={totalElements}
                        page={page}
                        setPage={setPage}
                        size={size}
                        setSize={setSize}
                        setLoadData={setLoadData}
                    />

                </Paper>
            </DashboardLayout>
            <Footer/>
        </>
    )
}

export default BusinessUnit;
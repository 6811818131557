import PopupLayout from "../PopupLayout";


export default function PopupLayoutBalanceTransfer({}) {

    const text = <>
        O arquivo deve seguir um formato específico,
        com apenas dois dados em cada linha: o número do cartão de origem,
        ou seja, de onde o saldo sairá e o número do cartão de destino,
        ou seja, para onde o saldo irá,
        separados por um ponto e vírgula (;).
        Os números dos cartões podem ser inseridos completos ou com os últimos 13, 12 ou 9 dígitos. <br/>
        O arquivo precisa ser salvo no formato <b>CSV</b>,
        com uma extensão de arquivo representativa, por exemplo: arquivo-transferencia-lote1.csv.
    </>

    const layout = <span>
        6049246034060452194;060452079<br/>
        6034060452186;034060452095<br/>
        034060452095;6034060452186<br/>
        060452079;6049246034060452194
    </span>

    return <PopupLayout processName="Transferência de Carga" text={text} layout={layout}/>
}
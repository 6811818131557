import React, {useEffect, useState} from "react";
import Popup from "../../../components/Popup/Popup";
import BasicLoader from "../../../components/BasicLoader/BasicLoader";
import DefaultProblemMessage from "../../../components/DefaultProblemMessage/DefaultProblemMessage";
import {Button, Form} from "react-bootstrap";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import InputsBalanceCheck from "./InputsBalanceCheck";
import axios from "axios";
import ListBalanceCheck from "./ListBalanceCheck";

const PopupCheck = ({buttonClass}) => {

    const baseUrl = `http://${process.env.REACT_APP_CARD_MANAGER_LINK}/Card`

    const [lgShow, setLgShow] = useState(false);
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState();
    const [sucess, setSucess] = useState(false);
    const [sendingData, setSendingData] = useState(false);

    const [cardNumber, setCardNumber] = useState('')

    const [loadData, setLoadData] = useState(true)

    const [size, setSize] = useState(10)
    const [page, setPage] = useState(0)
    const [sort, setSort] = useState("")

    const [totalPages, setTotalPages] = useState(0)
    const [totalElements, setTotalElements] = useState(0)

    const title = "Consultas saldo/extrato ";

    const iconSpan = <>
        <MonetizationOnIcon fontSize="large" id="icon-svg"/>
        {title}
    </>



    const handleSubmit = (e) => {
        e.preventDefault()

        const data = {
            number: cardNumber
        };

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };

        setSendingData(true)

        axios.get(baseUrl, {
            params: data,  // Add your data as query parameters
            headers: config.headers  // Copy headers from the original POST request config
        })
            .then(function (response) {

                console.log('resp: ')
                console.log(response.data)
                if (response.data.content.length === 0) {
                    setError(true);
                    setErrorMsg('Número do cartão não encontrado ou inexistente.');
                } else if (response.data.content[0].number !== null) {
                    setSucess(true);
                } else {
                    // Status desconhecido
                    setError(true);
                    setErrorMsg('Número do cartão não encontrado ou inexistente.');
                }
            })
            .catch(function (error) {
                // aqui temos acesso ao erro, quando alguma coisa inesperada acontece:
                // console.log("Error: " + error);
                setError(true)
                setErrorMsg("Error: " + error)
            })
            .finally(() => {
                setSendingData(false)
            });
    }

    const retry = () => {
        setError(false)
        setSucess(false)
        setSendingData(false)
        setErrorMsg('')
        setCardNumber('')
    }

    const button = <>
        <MonetizationOnIcon fontSize="medium"/>
        &nbsp;Consultar Saldo/Transferência
    </>

    useEffect(() => {
        if (!lgShow) {
            // Resetar os estados para o valor inicial
            setError(false);
            setErrorMsg('');
            setSucess(false);
            setSendingData(false);
            setCardNumber('');

            // Atualizar estados relacionados à lista conforme necessário
            setLoadData(true);
            setPage(0);
            setSort("");
            setSize(10);
            // Outras atualizações de estados, se aplicável

            // Remover a recarga da página
            // window.location.reload();
        }
    }, [lgShow]);


    return (
        <Popup
            buttonText={button}
            title={title}
            iconSpan={iconSpan}
            lgShow={lgShow}
            setLgShow={setLgShow}
            buttonClass={buttonClass}
        >
            {sendingData ?
                <BasicLoader height={20} message={"Consultando saldo..."}/> :
                error ?
                    <>
                        <DefaultProblemMessage message={errorMsg !== '' ? errorMsg : false}/>
                        <div className="form-inputs">
                            <Button onClick={retry}> Tentar novamente </Button>
                        </div>
                    </> :
                    sucess ?
                        <ListBalanceCheck
                            baseUrl={baseUrl}
                            cardNumber={cardNumber}
                            setLoadData={setLoadData}
                            setPage={setPage}
                            setTotalElements={setTotalElements}
                            sort={sort} setSort={setSort}
                            size={size} page={page}
                            loadData={loadData} setTotalPages={setTotalPages}
                            lgShow={lgShow}
                        /> :
                        <Form onSubmit={handleSubmit} className="form-inputs">

                            <InputsBalanceCheck
                                cardNumber={cardNumber} setCardNumber={setCardNumber}
                            />
                            <Button type="submit">
                                Confirmar Consulta
                            </Button>
                        </Form>
            }


        </Popup>
    )
}
export default PopupCheck
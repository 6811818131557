import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import "./Popup.css";

const Popup = ({
                   lgShow, setLgShow,
                   buttonText, buttonClass,
                   buttonId, title, iconSpan,
                   children, buttonVariant,
                   backdrop, hideCloseButton,
                   additionalContent, width,
                   centered, buttonDisabled,
               }) => {

    const modalWidth = width ? `modal-${width}` : 'modal-xl';

    return (
        <>
            <Button
                onClick={() => setLgShow(true)}
                className={buttonClass}
                id={buttonId}
                variant={buttonVariant}
                disabled={buttonDisabled}
            >
                {buttonText}
            </Button>

            {additionalContent}

            <Modal
                className={modalWidth}
                show={lgShow}
                onHide={() => setLgShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                backdrop={backdrop}
                centered={centered}
            >
                <Modal.Header closeButton={!hideCloseButton}>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        {title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row contact-info">
                        <div className="col-md-4"></div>
                        <div className="icon-span col-md-4">
                            {iconSpan}
                        </div>
                        <div className="col-md-4"></div>
                    </div>

                    {children}
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Popup;

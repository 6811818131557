import React, {useEffect, useState} from "react";
import MDTypography from "../../../../components/MDTypography";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import {InputLabelProps} from "../../../../utils/InputLabelProps";
import MDBox from "../../../../components/MDBox";
import Grid from "@mui/material/Grid";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import SelectInput from "../../../../components/SelectInput";

export default function CartoesBaixadosFilter({filter, setFilter}){

    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const [filterScope, setFilterScope] = useState('nenhum');
    const [filterScopeValue, setFilterScopeValue] = useState("");

    const globalProcUrl = `http://${process.env.REACT_APP_GLOBAL_PROCESS_LINK}`

    const [businessUnitList, setBusinessUnitList] = useState([]);

    useEffect(() => {
        setFilter({
            startDate,
            endDate,
            filterScope: filterScope !== 'nenhum' ? filterScope : null,
            filterScopeValue: filterScope ? filterScopeValue : null,
        })
    }, [startDate,endDate,filterScope,filterScopeValue]);


    return <>
        <MDBox mt={2}>
            <MDTypography variant="h6">Período</MDTypography>
            <Stack
                direction="row"
                spacing={2}
                mt={1}
                sx={{display: "flex", justifyContent: "center"}}
            >
                <TextField
                    label="Início"
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    InputLabelProps={{
                        ...InputLabelProps,
                        shrink: true,
                        size: "small",
                    }}
                    sx={{width: 180}}
                    required
                />
                <TextField
                    label="Fim"
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    InputLabelProps={{
                        ...InputLabelProps,
                        shrink: true,
                        size: "small",
                    }}
                    sx={{width: 180}}
                    required
                />
            </Stack>
            <Grid mb={1}>
                <Grid item xs={12}>
                    <MDTypography variant="h6">Filtros</MDTypography>
                </Grid>
                <Grid item xs={12}>
                    <RadioGroup
                        mt={2}
                        row
                        value={filterScope}
                        onChange={(e) => setFilterScope(e.target.value)}
                        style={{
                            'display': 'flex',
                            'justify-content': 'center',
                            'flex-direction': 'row',
                            'margin': '0 0 2%'
                        }}
                    >
                        <FormControlLabel
                            value={'nenhum'}
                            control={<Radio/>}
                            label="Sem Filtros"
                            defaultChecked
                        />
                        <FormControlLabel
                            value="BUSINESS_UNIT"
                            control={<Radio/>}
                            label="Unidade de Negócio"
                        />
                    </RadioGroup>
                </Grid>
            </Grid>

            {filterScope === "BUSINESS_UNIT" && (
                <MDBox mb={1}>
                    <SelectInput
                        url={`${globalProcUrl}/BusinessUnit?size=200000000`}
                        placeholder={"Unidade de negócio *"}
                        list={businessUnitList}
                        set={setBusinessUnitList}
                        setObj={setFilterScopeValue}
                        required={true}
                        fullWidth
                    />
                </MDBox>
            )}
        </MDBox>
    </>
}
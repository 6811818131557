import {Button, Form} from "react-bootstrap";
import Popup from "../../../components/Popup/Popup";
import React, {useEffect, useState} from "react";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import axios from "axios";
import BasicLoader from "../../../components/BasicLoader/BasicLoader";
import DefaultSuccessMessage from "../../../components/DefaultSuccessMessage/DefaultSuccessMessage";
import DefaultProblemMessage from "../../../components/DefaultProblemMessage/DefaultProblemMessage";
import InputsBalanceTransfer from "./InputsBalanceTransfer";

export default function PopupBalanceTransfer({cardNumber, balance, setLoadData, buttonClass}) {

    const baseUrl = `http://${process.env.REACT_APP_CARD_MANAGER_LINK}/BalanceTransfer`

    const [lgShow, setLgShow] = useState(false);

    const [value, setValue] = useState('');

    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState();
    const [transfered, setTransfered] = useState(false);
    const [sendingData, setSendingData] = useState(false);
    const [logicCode, setLogicCode] = useState('')

    const title = "Transferir saldo";

    useEffect(() => {
        if (!lgShow && transfered)
            setLoadData(true)
    }, [lgShow]);

    const iconSpan = <>
        <MonetizationOnIcon fontSize="large" id="icon-svg"/>
        {title}
    </>

    const button = <>
        <MonetizationOnIcon fontSize="medium"/>
        &nbsp;Transferir Saldo
    </>

    const handleSubmit = (e) => {
        e.preventDefault()

        const data = {
            originCardNumber: cardNumber,
            destinationCardNumber: value,
            logicCode
        };

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };

        setSendingData(true)

        axios.post(baseUrl, data, config)
            .then(function (response) {
                if (response.data.status.status === 'EFETUADA') {
                    setTransfered(true);
                } else if (response.data.status.status === 'REJEITADA') {
                    // A transferência foi rejeitada
                    setError(true);
                    setErrorMsg(response.data.denialReason);
                } else {
                    // Status desconhecido
                    setError(true);
                    setErrorMsg('');
                }
            }).catch(function (error) {
            // aqui temos acesso ao erro, quando alguma coisa inesperada acontece:
            // console.log("Error: " + error);
            setError(true)
            setErrorMsg('')
        }).finally(() => {
            setSendingData(false)
        })
    }

    const retry = () => {
        setError(false)
        setTransfered(false)
        setSendingData(false)
        setErrorMsg('')
        setValue('')
    }

    return (
        <Popup
            buttonText={button}
            title={title}
            iconSpan={iconSpan}
            lgShow={lgShow}
            setLgShow={setLgShow}
            buttonId={"links-action"}
            buttonClass={buttonClass}
        >
            {sendingData ?
                <BasicLoader height={20} message={"Efetuando transferência..."}/> :
                error ?
                    <>
                        <DefaultProblemMessage message={errorMsg !== '' ? errorMsg : false}/>
                        <div className="form-inputs">
                            <Button onClick={retry}> Tentar novamente </Button>
                        </div>
                    </> :
                    transfered ?
                        <DefaultSuccessMessage message={'Transferência efetuada com sucesso!'}/> :
                        <Form onSubmit={handleSubmit} className="form-inputs">

                            <InputsBalanceTransfer
                                value={value} setValue={setValue}
                                cardNumber={cardNumber} balance={balance}
                                logicCode={logicCode} setLogicCode={setLogicCode}
                            />
                            <Button type="submit">
                                Confirmar Transferência
                            </Button>
                        </Form>
            }


        </Popup>
    )
}
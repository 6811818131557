import React, {useState} from "react";
import FilterForm from "../../../components/FilterForm/FilterForm";
import TextField from "@mui/material/TextField";
import {Button, FormControlLabel, FormLabel, IconButton, InputAdornment, Radio, RadioGroup} from "@mui/material";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import HelpIcon from "@mui/icons-material/Help";
import DisplayStatus from "../../../components/Status/DisplayStatus";
import MDBox from "../../../components/MDBox";
import Tooltip from "react-bootstrap/Tooltip";
import ClearIcon from "@mui/icons-material/Clear";

const FilterFormBusinessUnit = ({filter, setFilter, setLoadData, setPage}) => {

    const [lgShow, setLgShow] = useState(false);

    const baseUrl = `http://${process.env.REACT_APP_GLOBAL_PROCESS_LINK}`


    const [name, setName] = useState("")
    const [maxChargeValue, setMaxChargeValue] = useState("")
    const [minChargeValue, setMinChargeValue] = useState("")
    // const [range, setRange] = useState("")
    const [status, setStatus] = useState("")


    const handlesSubmit = (e) => {
        if (e) e.preventDefault();

        // Substitui a vírgula por ponto antes de enviar para o backend
        const formattedMinChargeValue = minChargeValue.replace(',', '.');
        const formattedMaxChargeValue = maxChargeValue.replace(',', '.');

        setFilter({
            name,
            minChargeValue: formattedMinChargeValue,
            maxChargeValue: formattedMaxChargeValue,
            // range,
            status
        });

        setLgShow(false);
        setLoadData(true);
        setPage(0);
    };

    const changeMaxChargeValue = (e) => {
        const { value } = e.target;
        setMaxChargeValue(value); // Mantém o valor como o usuário digitou, incluindo vírgula
    };

    const changeMinChargeValue = (e) => {
        const { value } = e.target;
        setMinChargeValue(value); // Mantém o valor como o usuário digitou, incluindo vírgula
    };

    const onChangeWithLimiter = (e, value, set) => {
        let valueSeted = e.target.value
        const numericValue = valueSeted.replace(/\D/g, ''); // Remove tudo exceto dígitos (0-9)
        if (numericValue.length <= value) {
            set(numericValue)
        }
    }

    // const renderTooltipRange = (props) => (
    //     <Tooltip id="button-tooltip" {...props}>
    //         Número(digíto) de identificação da unidade de negócio no cartão, Ex: "XXXXXXYZZZABBBBBBBB",
    //         neste exemplo a letra "A" seria o Range da unidade de negócio.
    //     </Tooltip>
    // );



    // const rangeDisplay = <OverlayTrigger
    //     placement="right"
    //     delay={{show: 250, hide: 400}}
    //     overlay={renderTooltipRange}
    // >
    //     <span>Range<HelpIcon fontSize="small"/></span>
    // </OverlayTrigger>


    const clearFilter = () => {
        setName("");
        setMinChargeValue("");
        setMaxChargeValue("");
        // setRange("");
        setStatus("");

        setFilter({});

        setLgShow(false);
        setLoadData(true);
    };

    const clearAll = () => {
        setName("");
        setMinChargeValue("");
        setMaxChargeValue("");
        // setRange("");
        setStatus("");

        setFilter({});
    };

    return (
        <FilterForm
            lgShow={lgShow}
            setLgShow={setLgShow}
            handleSubmit={handlesSubmit}
            clearFilter={clearFilter}
            clearAll={clearAll}
            showSecondaryButton={Object.keys(filter).length !== 0}
        >
            <TextField
                label="Nome da unidade de negócio"
                inputProps={{maxLength: 45}}
                value={name}
                onChange={(e) => setName(e.target.value.toUpperCase())}
                fullWidth
                autoFocus
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {name && (
                                <IconButton size="small" onClick={() => setName("")}>
                                    <ClearIcon />
                                </IconButton>

                            )}
                        </InputAdornment>
                    ),
                }}
            />
            <TextField
                label="Valor máximo de carga"
                InputProps={{
                    min: 1,
                    step: '.01',
                    startAdornment: <span>R$</span>,
                    endAdornment: (
                        <InputAdornment position="end">
                            {maxChargeValue && (
                                <IconButton size="small" onClick={() => setMaxChargeValue("")}>
                                    <ClearIcon />
                                </IconButton>
                            )}
                        </InputAdornment>
                    ),
                }}
                type="text"
                value={maxChargeValue}
                onChange={changeMaxChargeValue}
                fullWidth
            />

            <TextField
                label="Valor mínimo de carga"
                InputProps={{
                    min: 1,
                    step: '.01',
                    startAdornment: <span>R$</span>,
                    endAdornment: (
                        <InputAdornment position="end">
                            {minChargeValue && (
                                <IconButton size="small" onClick={() => setMinChargeValue("")}>
                                    <ClearIcon />
                                </IconButton>
                            )}
                        </InputAdornment>
                    ),
                }}
                value={minChargeValue}
                type="text"
                onChange={changeMinChargeValue}
                fullWidth
            />
            {/*<TextField*/}
            {/*    label="Range"*/}
            {/*    InputProps={{*/}
            {/*        min: 1,*/}
            {/*        max: 100,*/}
            {/*        startAdornment: (*/}
            {/*            <InputAdornment position="start">*/}
            {/*                {rangeDisplay}*/}
            {/*            </InputAdornment>*/}
            {/*        ),*/}
            {/*        endAdornment: (*/}
            {/*            <InputAdornment position="end">*/}
            {/*                {range && (*/}
            {/*                    <Button*/}
            {/*                        size="small"*/}
            {/*                        onClick={() => setRange("")}*/}
            {/*                    >*/}
            {/*                        <ClearIcon/>*/}
            {/*                    </Button>*/}
            {/*                )}*/}
            {/*            </InputAdornment>*/}
            {/*        ),*/}
            {/*    }}*/}
            {/*    value={range}*/}
            {/*    type="text"*/}
            {/*    onChange={(e) => onChangeWithLimiter(e, 2, setRange)}*/}
            {/*    fullWidth*/}
            {/*/>*/}
            <MDBox mb={0.5} pb={0.5}>
                <FormLabel id="demo-row-radio-buttons-group-label" className="text-center">
                    Status
                </FormLabel>
                <RadioGroup
                    row
                    name="row-radio-buttons-group"
                    id="radios-status"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                >
                    <FormControlLabel
                        value=""
                        control={<Radio/>}
                        label={<span>Todos</span>}
                    />
                    <FormControlLabel
                        value="ATIVO"
                        control={<Radio/>}
                        label={<DisplayStatus status="ATIVO"/>}
                    />
                    <FormControlLabel
                        value="INATIVO"
                        control={<Radio/>}
                        label={<DisplayStatus status="INATIVO"/>}
                    />
                    <FormControlLabel
                        value="BLOQUEADO"
                        control={<Radio/>}
                        label={<DisplayStatus status="BLOQUEADO"/>}
                    />
                </RadioGroup>
            </MDBox>
        </FilterForm>
    );
};

export default FilterFormBusinessUnit
function formatDateTimeBr(dateTime) {

    if (dateTime === null) {
      return '';
    }

    const formatted = Intl.DateTimeFormat('pt-BR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    }).format(new Date(dateTime));

    return formatted.replace(',', ' ');
}

export default formatDateTimeBr;
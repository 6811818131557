import React, {useState} from "react";
import SouthAmericaIcon from '@mui/icons-material/SouthAmerica';
import PopupAdd from "../../../components/PopupAdd/PopupAdd";
import InputsRegional from "./InputsRegional";


const PopupAddRegional = ({baseUrl, setLoadData, updateCode}) => {

    const [name, setName] = useState("")
    const [code, setCode] = useState("")
    const [errorMessage, setErrorMessage] = useState("")
    const [errorColumn, setErrorColumn] = useState("")


    const icon = <SouthAmericaIcon fontSize="large" id="icon-svg"/>

    const title = "Regional"

    const inputs = <InputsRegional
        name={name} setName={setName}
        code={code} setCode={setCode}
        errorMessage={errorMessage}
        errorColumn={errorColumn}
        updateCode={updateCode}
    />


    const clearForm = () => {
        setName("")
        setCode("")
        setErrorColumn("")
        setErrorMessage("")
    }

    const returnData = () => {
        return {
            name,
            code
        }
    }

    return (
        <>
            <PopupAdd
                baseUrl={baseUrl} setLoadData={setLoadData}
                inputs={inputs} title={title} icon={icon}
                clearForm={clearForm} returnData={returnData}
                setErrorMessage={setErrorMessage} setErrorColumn={setErrorColumn}
            />
        </>
    )
}

export default PopupAddRegional
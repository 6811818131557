import React, {useState} from "react";
import MainNavbar from "../../components/MainNavbar";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import {Paper} from "@mui/material";
import PopupAddCardSolicitation from "./components/PopupAddCardSolicitation";
import ListCardSolicitation from "./components/ListCardSolicitation";
import PaginationControl from "../../components/Pagination/PaginationControl";
import Footer from "../../examples/Footer";


const CardSolicitation = () => {


    const baseUrl = `http://${process.env.REACT_APP_CARD_MANAGER_LINK}/CardRequest`
    const [loadData, setLoadData] = useState(true)


    const [size, setSize] = useState(10)
    const [page, setPage] = useState(0)
    const [sort, setSort] = useState("id,desc")

    const [totalPages, setTotalPages] = useState(0)
    const [totalElements, setTotalElements] = useState(0)


    return(
        <>
            <MainNavbar/>
            <DashboardLayout>
                <Paper className="paper-list mb-3">
                    <h1>Listar Solicitações De Cartões</h1>

                    <PopupAddCardSolicitation baseUrl={baseUrl} setLoadData={setLoadData}/>

                    <ListCardSolicitation
                        baseUrl={baseUrl}
                        loadData={loadData}
                        setLoadData={setLoadData}
                        setPage={setPage}
                        page={page}
                        size={size}
                        setTotalPages={setTotalPages}
                        setTotalElements={setTotalElements}
                        sort={sort} setSort={setSort}
                    />
                    <PaginationControl
                        totalElements={totalElements}
                        page={page}
                        setPage={setPage}
                        size={size}
                        setSize={setSize}
                        setLoadData={setLoadData}
                    />
                </Paper>
            </DashboardLayout>
            <Footer/>
        </>
    )
}
export default CardSolicitation
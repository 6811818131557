import React, { useEffect, useState } from "react";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import axios from "axios";
import Popup from "../Popup/Popup";
import { Button, Form, Dropdown } from "react-bootstrap";
import {Domain} from "@mui/icons-material";

const PopupEdit = ({
                       baseUrl,
                       setLoadData,
                       inputs,
                       itemToState,
                       title,
                       returnData,
                       setErrorMessage,
                       setErrorColumn,
                       blockSubmit,
                       validateData,
                       errorMessage,
                       isDisabled,
                       buttonText,
                       errorMessageBusinessUnit,
                       setErrorMessageBusinessUnit
                   }) => {


    const [lgShow, setLgShow] = useState(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        if (!lgShow && itemToState) {
            itemToState();
        }
    }, [lgShow]);

    const iconSpan = (
        <>
            <DriveFileRenameOutlineIcon fontSize="large" id="icon-svg" />
            {title}
        </>
    );

    const button = (
        <>
            <Dropdown.Item as="button" onClick={() => setLgShow(true)}>
                <DriveFileRenameOutlineIcon className="me-2" /> Editar
            </Dropdown.Item>
        </>
    );

    const handleSubmit = (e) => {
        e.preventDefault();

        const data = returnData();

        let dataIsValid = true;
        if (typeof validateData === "function") dataIsValid = validateData();

        if (!blockSubmit && dataIsValid) {
            axios
                .put(baseUrl, data)
                .then((response) => {
                    setLoadData(true);
                    setLgShow(false);
                    if (typeof setErrorColumn === "function") {
                        setErrorMessage("");
                        setErrorColumn("");
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setErrorMessage(error.response.data.message);
                    if (error.response.status === 409) {
                        setErrorMessage(error.response.data.message);
                        setErrorColumn(error.response.data.column);
                    }
                });
        }
    };

    return (
        <>

            <Popup
                buttonText={buttonText ? buttonText : button}
                buttonDisabled={isDisabled}
                title={title}
                iconSpan={iconSpan}
                lgShow={lgShow}
                setLgShow={setLgShow}
                buttonId={"links-action"}
            >
                <div>
                    <Form onSubmit={handleSubmit} className="form-inputs">
                        {inputs}

                        {errorMessage && (
                            <div
                                style={{
                                    color: "red",
                                    fontWeight: "bold",
                                    marginTop: "10px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textAlign: "center",
                                }}
                            >
                                {errorMessage}
                            </div>
                        )}

                        <Button type="submit" disabled={isDisabled}>
                            Salvar Alterações
                        </Button>
                    </Form>
                </div>

            </Popup>
        </>
    );
};

export default PopupEdit;

import React, {useEffect, useState} from 'react';

const PopupMovel = ({content}) => {
    const [position, setPosition] = useState({x: 0, y: 0});

    const handleMouseMove = (e) => {

        const targetX = e.clientX;
        const targetY = e.clientY;

        const newX = position.x + (targetX - position.x) + 20;
        const newY = position.y + (targetY - position.y) ;

        setPosition({ x: newX, y: newY });
    };

    useEffect(() => {
        // Adicione um ouvinte de evento de mousemove ao componente
        window.addEventListener('mousemove', handleMouseMove);

        // Remova o ouvinte de evento quando o componente for desmontado
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    return (
        content ?
            <div
                className="popup-movel"
                style={{
                    position: 'fixed', left: position.x, top: position.y,
                    backgroundColor: 'white', cursor: 'pointer',
                    borderRadius: '5px', padding: '0.5rem 1rem',
                    border: '1px solid black'
                }}
            >
                {content}
            </div> :
            <></>
    );
};

export default PopupMovel;

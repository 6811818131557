import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import axios from "axios";
import MainNavbar from "../../components/MainNavbar";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import {Paper} from "@mui/material";
import MonitoringTemplate from "../monitoringTemplate";
import Footer from "../../examples/Footer";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import displayType from "../monitoring/components/displayType";

export default function MonitoringByStatus() {

    const {type, period} = useParams();

    const totalText = "Nesta tela podemos monitorar o total de transações feitas e analisar de forma detalhada."
    const processName = "Transações " + displayType(type)

    const [loadData, setLoadData] = useState(true)
    const [firstLoad, setFirstLoad] = useState(true)
    const [error, setError] = useState(false)

    const [data, setData] = useState([])


    const returnButtonAction = (status) => {
        return <Link to={"/monitoring/" + period + "/" + type + "/" + status} color="primary"
                     className="btn btn-primary">
            Ver transações
        </Link>
    }

    const pageHeader = <>
        <h1>Transações {displayType(type)} - {period} </h1>
    </>

    const searchFunction = () => {
        console.log("Searching data")

        let params = {
            period: period.replace(' ', '').replace('ê', 'e'),
            type: type.replaceAll(' ', '')
        }
        axios.get(`http://${process.env.REACT_APP_MONITOR_LINK}/total/status`, {params})
            .then((response) => {
                // console.log(response.data)
                setData(response.data)
                setError(false)
                setLoadData(false)
                setFirstLoad(false)
            }).catch(function (errorReceived) {
            // aqui temos acesso ao erro, quando alguma coisa inesperada acontece:
            console.log(errorReceived)
            setLoadData(false)
            setError(true)
        })
    }

    return (
        <>
            <MainNavbar/>
            <DashboardLayout>
                <Link to={'/monitoring/' + period} className={'position-absolute m-lg-1'}>
                    <KeyboardBackspaceIcon/> Voltar &nbsp;
                </Link>
                <Paper className="paper-list mb-3 w-100">
                    <MonitoringTemplate
                        totalText={totalText} pageHeader={pageHeader}
                        processName={processName}
                        searchFunction={searchFunction}
                        loadData={loadData} setLoadData={setLoadData}
                        firstLoad={firstLoad} setFirstLoad={setFirstLoad}
                        error={error} setError={setError} data={data}
                        returnButtonAction={returnButtonAction}
                    />
                </Paper>
            </DashboardLayout>
            <Footer/>
        </>
    )
}
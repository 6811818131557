import MainNavbar from "../../components/MainNavbar";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import {Paper} from "@mui/material";
import React, {useState} from "react";
import PopupAddStore from "./components/PopupAddStore";
import PaginationControl from "../../components/Pagination/PaginationControl";
import ListStore from "./components/ListStore";
import "./Store.css"
import Footer from "../../examples/Footer";
import { useAuthContext } from "components/AuthenticationProvider/AuthenticationProvider";

const Store = () => {
    
    const baseUrl = `http://${process.env.REACT_APP_GLOBAL_PROCESS_LINK}/Store`
    const [loadData, setLoadData] = useState(true)

    const [size, setSize] = useState(10)
    const [page, setPage] = useState(0)
    const [sort, setSort] = useState("")

    const [totalPages, setTotalPages] = useState(0)
    const [totalElements, setTotalElements] = useState(0)

    const authContext = useAuthContext();
    
    return (
        <>
            <MainNavbar/>
            <DashboardLayout>
                <Paper className="paper-list mb-3">
                    <h1>Lista de Lojas</h1>

                    { authContext.hasPermission('store', 'create') ?

                        <PopupAddStore
                            baseUrl={baseUrl}
                            setLoadData={setLoadData}
                        /> : null
                    }

                    <ListStore
                        baseUrl={baseUrl}
                        loadData={loadData}
                        setLoadData={setLoadData}
                        page={page}
                        setPage={setPage}
                        size={size}
                        setTotalPages={setTotalPages}
                        setTotalElements={setTotalElements}
                        sort={sort} setSort={setSort}
                    />
                    <PaginationControl
                        totalElements={totalElements}
                        page={page}
                        setPage={setPage}
                        size={size}
                        setSize={setSize}
                        setLoadData={setLoadData}
                    />
                </Paper>

            </DashboardLayout>
            <Footer/>
        </>
    );
}
export default Store

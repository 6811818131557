import VisibilityIcon from '@mui/icons-material/Visibility';
import { Paper, TableContainer } from "@mui/material";
import { useEffect, useState } from "react";
import ShortInvoice from "./ShortInvoice";
import BasicLoader from "components/BasicLoader/BasicLoader";
import { NumberWithPoints } from 'utils/numberWithPoints';
import DetailMerchantInvoice from './DetailMerchantInvoice';
import { fetchData } from 'api/axiosMethods';

export default function TransactionTypeInvoice({invoiceId, type}) {

    const baseUrl = `http://${process.env.REACT_APP_FINANCIAL_MANAGER_LINK}`;

    const [loadingData, setLoadingData] = useState(true);
    const [invoice, setInvoice] = useState({});
    const [callDetailMerchantInvoice, setCallDetailMerchantInvoice] = useState(false);
    const [storeCode, setStoreCode] = useState('');

    useEffect(async () => {
        const complementaryType = type === 'RESGATE' ? 'TAXA_ADM' : type === 'CARGA' ? 'COMISSAO_CARGA' : 'AJUSTE';
        const data = await fetchData(`${baseUrl}/financialClose/getItemsInvoiceByTransactionType`, {invoiceId, type, complementaryType});
        if (data) {
            console.log(data);
            setInvoice(data);
        }
        setLoadingData(false);
        
    }, [invoiceId]);

    function handleDetails(storeCode) {
        setStoreCode(storeCode);
        setCallDetailMerchantInvoice(true);
    }

    return (
        <>
            {
                loadingData ? <BasicLoader/> 
                : 
                <div>
                    { 
                        callDetailMerchantInvoice ? <DetailMerchantInvoice invoiceId={invoiceId} type={type} storeCode={storeCode}></DetailMerchantInvoice>
                        :
                        <div>
                            <ShortInvoice invoice={invoice}></ShortInvoice>

                            <TableContainer component={Paper}>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Unidade de Negócio</th>
                                            <th>Loja</th>
                                            <th>Estabelecimento</th>
                                            <th>{type === 'RESGATE' ? 'Resgate' : type === 'CARGA' ? 'Carga' : 'Ajuste'}</th>
                                            <th>{type === 'RESGATE' ? 'Repasse s/ Resgate' : type === 'CARGA' ? 'Repasse s/ Carga' : 'Repasse s/ Ajuste'}</th>
                                            <th>Repasse</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {invoice.items.map( item => {
                                            return (
                                                <tr key={item.entryType}>
                                                    <td>{item.businessUnit}</td>
                                                    <td>{item.detailStoreCode}</td>
                                                    <td>{item.detailStoreName}</td>
                                                    <td>R$ {NumberWithPoints(item.totalTransactionType)}</td>
                                                    <td>R$ {NumberWithPoints(item.totalComplementaryType)}</td>
                                                    <td>R$ {NumberWithPoints(item.totalTransactionType + item.totalComplementaryType)}</td>
                                                    <td><a href='#' onClick={handleDetails(item.detailStoreCode)}>Detalhes <VisibilityIcon fontSize="medium" /></a></td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </TableContainer>
                        </div>
                    }
                </div>
            }
        </>
    );
}

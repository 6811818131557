import React, {useEffect, useState} from "react";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import AddIcon from "@mui/icons-material/Add";
import InputsBusinessUnit from "../../businessUnit/components/InputsBusinessUnit";
import PopupAdd from "../../../components/PopupAdd/PopupAdd";
import "./stylesComponents/PopupAddCardSolicitation.css"
import InputCardSolicitation from "./InputCardSolicitation";


const PopupAddCardSolicitation = ({baseUrl, setLoadData}) => {

    const [cardType, setCardType] = useState(false) // false para cartao físicoe true para digital
    const [amount, setAmount] = useState("")
    const [businessUnitId, setBusinessUnitId] = useState("")
    const [errorMessage, setErrorMessage] = useState("")
    const [errorColumn, setErrorColumn] = useState("")

    const icon = <WorkOutlineIcon fontSize="large" id="icon-svg"/>

    const button = <>
        Adicionar
        <AddIcon fontSize="medium"/>
    </>

    const title = "Solicitação de Cartões"

    const inputs = <InputCardSolicitation
        cardType={cardType} setCardType={setCardType}
        amount={amount} setAmount={setAmount}
        businessUnitId={businessUnitId} setBusinessUnitId={setBusinessUnitId}
        setErrorColumn={setErrorColumn}
        setErrorMessage={setErrorMessage}
        errorMessage={errorMessage}
        errorColumn={errorColumn}
    />
    const clearForm = () => {
        setCardType(false)
        setAmount("")
        setBusinessUnitId("")
    }
    const returnData = () => {
        return {
            cardType,
            amount,
            businessUnitId
        }
    }

    return(
        <>
            <PopupAdd
                baseUrl={baseUrl} setLoadData={setLoadData}
                inputs={inputs} title={title} icon={icon}
                clearForm={clearForm} returnData={returnData}
                setErrorMessage={setErrorMessage} setErrorColumn={setErrorColumn}
            />
        </>
    )
}
export default PopupAddCardSolicitation
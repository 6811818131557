import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import SettingsIcon from "@mui/icons-material/Settings";
import StoreDetails from "./StoreDetails";
import AssociatedStoresPopup from "./AssociatedStorePopup";
import PopupInativeStore from "./PopupInativeStore";
import FilterFormStore from "./FilterFormStore";
import TableList from "../../../components/TableList/TableList";
import DisplayStatus from "../../../components/Status/DisplayStatus";
import PopupEditStore from "./PopupEditStore";
import {cnpjMask} from "../../../utils/cnpjMask";
import { useAuthContext } from "components/AuthenticationProvider/AuthenticationProvider";

const ListStore = ({
                       baseUrl,
                       loadData,
                       setLoadData,
                       size,
                       page,
                       setTotalPages,
                       setTotalElements,
                       sort,
                       setSort,
                       updateCode,
                       setPage,
                       disable,
                   }) => {
    const [data, setData] = useState([]);
    const [error, setError] = useState(false);
    const [filter, setFilter] = useState({});
    const [showInactivateModal, setShowInactivateModal] = useState(false);
    const [selectedStore, setSelectedStore] = useState(null);

    const authContext = useAuthContext();

    const titles = ["Código interno", "Cnpj", "Razão Social", "Nome do grupo", "Status"];

    const openInactivateModal = (item) => {
        setSelectedStore(item);
        setShowInactivateModal(true);
    };

    const closeInactivateModal = () => {
        setShowInactivateModal(false);
        setSelectedStore(null);
    };

    return (
        <>
            {
                authContext.hasPermission("store", "filter") && 

                    <FilterFormStore
                        filter={filter}
                        setFilter={setFilter}
                        setLoadData={setLoadData}
                        setPage={setPage}
                    />
            }    

            <TableList
                titles={titles}
                baseUrl={baseUrl}
                page={page}
                sort={sort}
                size={size}
                loadData={loadData}
                setTotalPages={setTotalPages}
                setTotalElements={setTotalElements}
                setData={setData}
                setLoadData={setLoadData}
                filter={filter}
                error={error}
                setError={setError}
            >
                {data.map((item) => (
                    <tr key={item.id}>
                        <td>{item.internalCode}</td>
                        <td>{cnpjMask(item.cnpj)}</td>
                        <td>{item.corporateName}</td>
                        <td>{item.franchiseGroupName}</td>
                        <td>
                            <DisplayStatus status={item.status} />
                        </td>
                        <td>
                            <Dropdown drop="down-centered">
                                <Dropdown.Toggle variant="secondary">
                                    Ações &nbsp;
                                    <SettingsIcon fontSize="medium" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdown-custom">
                                    
                                    { authContext.hasPermission("store", "update") && 
                                    
                                        <PopupEditStore
                                            item={item}
                                            baseUrl={baseUrl}
                                            setLoadData={setLoadData}
                                            updateCode={updateCode}
                                            disable={disable}
                                        />
                                    }
                                    
                                    { authContext.hasPermission("store", "read") &&
                                        <StoreDetails item={item} /> 
                                    }
                                    <AssociatedStoresPopup item={item} baseUrl={baseUrl} />
                                    {authContext.hasPermission("store", "inactivate") && item.status !== "INATIVO" && (
                                        <PopupInativeStore
                                            item={item}
                                            baseUrl={baseUrl}
                                            setLoadData={setLoadData}
                                        />
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>
                        </td>
                        <td>
                        </td>
                    </tr>
                ))}
            </TableList>
            {selectedStore && authContext.hasPermission("store", "inactivate") && (
                <PopupInativeStore
                    show={showInactivateModal}
                    handleClose={closeInactivateModal}
                    item={selectedStore}
                    baseUrl={baseUrl}
                    setLoadData={setLoadData}
                    isDisabled={selectedStore.status === "INATIVO"}
                />
            )}
        </>
    );
};

export default ListStore;

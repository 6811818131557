import Grid from "@mui/material/Grid";
import BrazilMap from "./BrazilMap/BrazilMap";
import {useEffect, useState} from "react";
import axios from "axios";
import Subtitle from "./Subtitle/Subtitle";
import MapParams from "./Components/MapParams";
import Card from "@mui/material/Card";
import displayType from "../../../monitoring/components/displayType";
import BasicLoader from "../../../../components/BasicLoader/BasicLoader";

const aliasTypes = [
    {received: 'TOTAL', display: 'Total'},
    {received: 'Compra A Vista', display: 'Compra à Vista'},
    {received: 'Carga Gift', display: 'Carga de Cartão'},
]

export default function Map() {

    const timeToRefresh = 20
    const initialPeriod = 'Hoje'
    const [count, setCount] = useState(timeToRefresh);
    const [loadData, setLoadData] = useState(true)

    const periods = [
        'Hoje',
        'Ontem',
        'Este Mês',
        'Este Semestre',
        'Este Ano',
    ]

    const types = aliasTypes.map((item) => item.display)

    // const [loadData, setLoadData] = useState(true)
    const [firstLoad, setFirstLoad] = useState(true)
    const [error, setError] = useState(false)

    const [loading, setLoading] = useState(false)

    const [data, setData] = useState({})
    const [type, setType] = useState('TOTAL')
    const [period, setPeriod] = useState(initialPeriod)

    const [maxValue, setMaxValue] = useState(0)

    const searchFunction = () => {
        console.log("Searching data")

        const dataToSend = `?type=${type.replaceAll(' ', '')}`
            + `&period=${period.replace(' ', '').replace('ê', 'e')}`

        setLoading(true)
        axios.get(`http://${process.env.REACT_APP_MONITOR_LINK}/map${dataToSend}`)
            .then((response) => {
                // console.log(response.data)
                setData(response.data)
                setError(false)
                setLoadData(false)
                setFirstLoad(false)
            }).catch(function (errorReceived) {
            // aqui temos acesso ao erro, quando alguma coisa inesperada acontece:
            console.log(errorReceived)
            setLoadData(false)
            setError(true)
        }).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (initialPeriod === period) {
                setLoadData(true);
                setCount(timeToRefresh - 1)
            }
        }, timeToRefresh * 1000);

        return () => clearInterval(intervalId);
    }, [period]);


    useEffect(() => {
        const countdownInterval = setInterval(() => {
            if (count > 0) {
                setCount(prevCount => prevCount - 1);
            }
        }, 1000);

        return () => clearInterval(countdownInterval);
    }, [count]);

    useEffect(() => {
        if (loadData)
            searchFunction()
    }, [loadData])

    useEffect(() => {
        setLoadData(true)
    }, [period, type])


    return <>
        <Grid item xs={12} md={12} lg={12} p={2}>

            <Card sx={{padding: '1%'}}>
                <h6>Parâmetros para o mapa</h6>
                <MapParams
                    loadData={loading}
                    values={types}
                    valueSelected={displayType(type)}
                    setFunction={(value) => {
                        setType(aliasTypes.filter(alias => alias.display === value)[0].received)
                    }}
                    name={'Tipo'}
                    className={'mb-2'}
                />
                <MapParams
                    loadData={loading}
                    values={periods}
                    valueSelected={period}
                    setFunction={setPeriod}
                    name={'Periodo'}
                />
                {loading  &&
                    <BasicLoader
                        height={'0'}
                        loaderSize={'3%'}
                    />
                }
            </Card>
        </Grid>

        <Grid item xs={12} md={6} lg={8}>
            <BrazilMap data={data} count={initialPeriod === period ? count : false} loadData={loadData}
                       setLoadData={setLoadData} maxValue={maxValue}/>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
            <Subtitle data={data} setMaxValue={setMaxValue}/>
        </Grid>
    </>
}
import React, {useEffect} from 'react';
import Typography from "@mui/material/Typography";
import {CardActions, CardContent} from "@mui/material";
import Card from "@mui/material/Card";
import "./CustomCard.css"
import Divider from "@mui/material/Divider";

export default function CustomCard({
                                       width,
                                       children, title,
                                       height, className,
                                       buttonOff, button
                                   }) {


    useEffect(() => {
        if (height === undefined)
            height = 'auto'
        if (className === undefined)
            className = ''
    })

    return (
        <div className={"custom-card " + className} style={{width: width}}>
            <Card>
                <CardContent
                    sx={{
                        height: height,
                        justifyContent: 'center',
                        alignContent: 'center'
                    }}
                >
                    <Typography variant="h4" level="title-lg" id="custom-title">
                        {title}
                    </Typography>
                    <Divider/>
                    {children}
                </CardContent>

                {!buttonOff &&
                    <CardActions sx={{justifyContent: 'center'}}>
                        {button}
                    </CardActions>
                }
            </Card>
        </div>
    );
}
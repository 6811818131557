import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import RTL from "layouts/rtl";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/signUp";
import Initial from "layouts/initial";
import Choice from "layouts/choice";
import Report from "layouts/report";
import Popup from "layouts/popup";
import BusinessUnit from "layouts/businessUnit"
import Icon from "@mui/material/Icon";
import Regional from "./layouts/regional";
import FranchiseGroup from "./layouts/franchiseGroup";
import Store from "./layouts/store";
import CardSolicitation from "./layouts/CardSolicitation";
import User from "layouts/user";
import Monitoring from "./layouts/monitoring";
import MonitoringDetails from "./layouts/monitoringDetails";
import Card from "./layouts/card";
import MonitoringByStatus from "./layouts/monitoringByStatus";
import MonitoringLog from "./layouts/monitoringLog";
import BatchProcessing from "./layouts/batchProcessing";
import BalanceTransferHistory from "./layouts/batchProcessingHistory/BalanceTransfer";
import LoadCardHistory from "./layouts/batchProcessingHistory/LoadCard";
import Transaction from "./layouts/transaction";
import CardRevalidationHistory from "./layouts/batchProcessingHistory/CardRevalidation";
import BalanceCheck from "./layouts/BalanceCheck";
import Invoice from "layouts/invoice";
import Clearing from "layouts/clearing";
import FinancialClose from "layouts/financialClose";
import ForgetPassword from "./layouts/ForgetPassword/ForgetPassword";
import ResetPassword from "./layouts/ResetPassword/ResetPassword";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  {
    type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
  },
  {
    type: "collapse",
    name: "Report",
    key: "report",
    icon: <Icon fontSize="small">report</Icon>,
    route: "/report",
    component: <Report />,
  },
  {
    type: "collapse",
    name: "Monitoring",
    key: "monitoring",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/monitoring",
    component: <Monitoring />,
  },
  {
    type: "collapse",
    name: "Monitoring",
    key: "monitoring",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/monitoring/:period",
    component: <Monitoring />,
  },
  {
    type: "collapse",
    name: "Monitoring",
    key: "monitoring",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/monitoring/:period/:type",
    component: <MonitoringByStatus />,
  },
  {
    type: "collapse",
    name: "Monitoring",
    key: "monitoring",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/monitoring/:period/:type/:status",
    component: <MonitoringDetails />,
  },
  {
    type: "collapse",
    name: "Monitoring",
    key: "monitoring",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/monitoringLog/",
    component: <MonitoringLog />,
  },
  {
    type: "collapse",
    name: "Popup",
    key: "popup",
    icon: <Icon fontSize="small">popup</Icon>,
    route: "/popup",
    component: <Popup />,
  },
  {
    type: "collapse",
    name: "BusinessUnit",
    key: "businessUnit",
    icon: <Icon fontSize="small">businessUnit</Icon>,
    route: "/businessUnit",
    component: <BusinessUnit />,
  },
  {
    type: "collapse",
    name: "Regional",
    key: "regional",
    icon: <Icon fontSize="small">Regional</Icon>,
    route: "/regional",
    component: <Regional />,
  },
  {
    type: "collapse",
    name: "FranchiseGroup",
    key: "franchiseGroup",
    icon: <Icon fontSize="small">FranchiseGroup</Icon>,
    route: "/franchiseGroup",
    component: <FranchiseGroup />,
  },
  {
    type: "collapse",
    name: "store",
    key: "store",
    icon: <Icon fontSize="small">Store</Icon>,
    route: "/store",
    component: <Store/>,
  },
  {
    type: "collapse",
    name: "cardSolicitation",
    key: "cardSolicitation",
    icon: <Icon fontSize="small">CardSolicitation</Icon>,
    route: "/cardSolicitation",
    component: <CardSolicitation/>,
  },
  {
    type: "collapse",
    name: "user",
    key: "user",
    icon: <Icon fontSize="small">User</Icon>,
    route: "/user",
    component: <User/>,
  },
  {
    type: "collapse",
    name: "sigUp",
    key: "sigUp",
    icon: <Icon fontSize="small">SignUp</Icon>,
    route: "/signup/:id",
    component: <SignUp/>,
  },

  {
    type: "collapse",
    name: "card",
    key: "card",
    icon: <Icon fontSize="small">Card</Icon>,
    route: "/card",
    component: <Card/>,
  },
  {
    type: "collapse",
    name: "batchProcessing",
    key: "batchProcessing",
    icon: <Icon fontSize="small">Card</Icon>,
    route: "/batchProcessing",
    component: <BatchProcessing/>,
  },
  {
    type: "collapse",
    name: "balanceTransfer",
    key: "balanceTransfer",
    icon: <Icon fontSize="small">BalanceTransferHistory</Icon>,
    route: "/balanceTransfer",
    component: <BalanceTransferHistory/>,
  },
  {
    type: "collapse",
    name: "loadCard",
    key: "loadCard",
    icon: <Icon fontSize="small">LoadCardHistory</Icon>,
    route: "/loadCard",
    component: <LoadCardHistory/>,
  },
  {
    type: "collapse",
    name: "cardRevalidation",
    key: "cardRevalidation",
    icon: <Icon fontSize="small">LoadCardHistory</Icon>,
    route: "/cardRevalidation",
    component: <CardRevalidationHistory/>,
  },
  {
    type: "collapse",
    name: "transaction",
    key: "transaction",
    icon: <Icon fontSize="small">Transaction</Icon>,
    route: "/transaction",
    component: <Transaction/>,
  },,
  {
    type: "collapse",
    name: "balanceCheck",
    key: "balanceCheck",
    icon: <Icon fontSize="small">balanceCheck</Icon>,
    route: "/balanceCheck",
    component: <BalanceCheck/>,
  },
  {
    type: "collapse",
    name: "invoices",
    key: "invoices",
    icon: <Icon fontSize="small">Faturas</Icon>,
    route: "/invoice/store/:id",
    component: <Invoice/>,
  },
  {
    type: "collapse",
    name: "clearings",
    key: "clearings",
    icon: <Icon fontSize="small">Arquivos Clearing</Icon>,
    route: "/clearing",
    component: <Clearing/>,
  },
  {
    type: "collapse",
    name: "fechamentoFinanceiro",
    key: "fechamentoFinanceiro",
    icon: <Icon fontSize="small">Fechamento Financeiro</Icon>,
    route: "/financialClose",
    component: <FinancialClose/>,
  },
  {
    type: "collapse",
    name: "forgetPassword",
    key: "forgetPassword",
    icon: <Icon fontSize="small">Esqueceu a Senha</Icon>,
    route: "/forget-password",
    component: <ForgetPassword/>,
  },
  {
    type: "collapse",
    name: "resetPassword",
    key: "resetPassword",
    icon: <Icon fontSize="small">Troca de Senha</Icon>,
    route: "/authentication/reset-password",
    component: <ResetPassword/>,
  },
  {
    type: "collapse",
    name: "resetPassword",
    key: "resetPassword",
    icon: <Icon fontSize="small">Troca de Senha</Icon>,
    route: "/reset-password/:token",
    component: <ResetPassword/>,
  },
];

export default routes;

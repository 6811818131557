import TextField from "@mui/material/TextField";
import React from "react";

export default function InputsBalanceCheck({cardNumber, setCardNumber}) {


    const handleNumberInput = (e) => {
        const inputValue = e.target.value;

        if (/^[0-9]*$/.test(inputValue)) {
            setCardNumber(inputValue);
        }
    };

    return (
        <>
            <p className={'text-danger'}> Lembre-se de verificar o número do cartão* </p>
            <TextField
                label="Numero do cartão"
                required={true}
                inputProps={{
                    min: 1,
                    maxLength: 19
                }}
                erortext="Campo obrigatório!"
                value={cardNumber}
                onChange={handleNumberInput}
                fullWidth
                autoFocus
            />

        </>
    )
}
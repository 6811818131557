export const downloadFile = async (url, fileName, format) => {
    try {
        let acceptHeader;
        let fileExtension;

        switch (format) {
            case 'CSV':
                acceptHeader = 'text/csv';
                fileExtension = 'csv';
                break;
            case 'TXT':
                acceptHeader = 'text/plain';
                fileExtension = 'txt';
                break;
            case 'XLSX':
                acceptHeader =
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                fileExtension = 'xlsx';
                break;
            default:
                throw new Error('Unsupported format');
        }

        const response = await fetch(url, {
            method: 'GET',
            headers: {
                Accept: acceptHeader,
                'Content-Type': 'application/json',
                Authorization:
                    'eyJhbGciOiJIUzI1NiIsImtpZCI6InNpbTIiLCJ0eXAiOiJKV1QifQ.eyJhdXRob3JpemF0aW9ucyI6W3siYWN0aW9uIjoiY3JlYXRlIiwicmVzb3VyY2UiOiJidXNpbmVzcy11bml0Iiwicm9sZSI6IlJvb3QifSx7ImFjdGlvbiI6ImNyZWF0ZSIsInJlc291cmNlIjoicmVnaW9uYWwiLCJyb2xlIjoiUm9vdCJ9LHsiYWN0aW9uIjoiY3JlYXRlIiwicmVzb3VyY2UiOiJmcmFuY2hpc2UtZ3JvdXAiLCJyb2xlIjoiUm9vdCJ9LHsiYWN0aW9uIjoiY3JlYXRlIiwicmVzb3VyY2UiOiJzdG9yZSIsInJvbGUiOiJSb290In0seyJhY3Rpb24iOiJjcmVhdGUiLCJyZXNvdXJjZSI6ImNhcmQtcmVxdWVzdCIsInJvbGUiOiJSb290In0seyJhY3Rpb24iOiIvY2FuY2VsIiwicmVzb3VyY2UiOiJjYXJkIiwicm9sZSI6IlJvb3QifSx7ImFjdGlvbiI6ImV4ZWN1dGUiLCJyZXNvdXJjZSI6ImJhdGNoLXByb2Nlc3NpbmciLCJyb2xlIjoiUm9vdCJ9LHsiYWN0aW9uIjoibGlzdCIsInJlc291cmNlIjoidHJhbnNhY3Rpb24iLCJyb2xlIjoiUm9vdCJ9LHsiYWN0aW9uIjoic2hvdyIsInJlc291cmNlIjoiYmFsYW5jZS1jaGVjayIsInJvbGUiOiJSb290In1dLCJleHAiOjE3MTg3MzYxODY5MjYsImp0aSI6IjQiLCJvd25lciI6Ijk4NzIiLCJzY29wZUF1dGgiOiJDYXJkUHJvY2Vzc29yIiwic3ViIjoiNCIsInVzZXJuYW1lIjoia21lbmRvbmNhIn0.k3FqAbISg50HXA-EqhcUJ6h8gT-L2wii0dwnmAnLA6U',
            }
        });

        if (!response.ok) {
            console.log(response.status);
            throw new Error('Erro ao gerar relatório');
        }

        const blob = await response.blob();
        const blobUrl = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = fileName

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
        console.error('Erro ao gerar relatório:', error);
    } finally {
    }
}
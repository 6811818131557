// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.paper-list{
    padding: 2%;
}

tr{
    text-align: center;
}

#table-list {
    margin-top: 1%;
}

#table-list td{
    padding: 0.5% 0;
    border-top: 1px solid gray;
    border-bottom: 1px solid gray;
}`, "",{"version":3,"sources":["webpack://./src/components/TableList/TableList.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,0BAA0B;IAC1B,6BAA6B;AACjC","sourcesContent":[".paper-list{\r\n    padding: 2%;\r\n}\r\n\r\ntr{\r\n    text-align: center;\r\n}\r\n\r\n#table-list {\r\n    margin-top: 1%;\r\n}\r\n\r\n#table-list td{\r\n    padding: 0.5% 0;\r\n    border-top: 1px solid gray;\r\n    border-bottom: 1px solid gray;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

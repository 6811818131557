import React, {useState} from "react";
import SummarizeIcon from '@mui/icons-material/Summarize';
import PopupAddNameTrade from "./PopupAddNameTrade";
import InputsReport from "./InputsReport";
import axios from "axios";
import {saveAs} from 'file-saver';
import AddIcon from "@mui/icons-material/Add"; // Importando FileSaver.js

const PopupAddReport = ({baseUrl, setLoadData}) => {

    const [lgShow, setLgShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [reportsList, setReportsList] = useState([]);
    const [report, setReport] = useState("");

    const [errorMessage, setErrorMessage] = useState("");
    const [errorColumn, setErrorColumn] = useState("");

    const [format, setFormat] = useState("XLSX");
    const [filter, setFilter] = useState({});

    const icon = <SummarizeIcon fontSize="large" id="icon-svg"/>;
    const title = "Relatório";

    const buttonText = report?.synchronous? 'Extrair Relatório' : 'Agendar Relatório'
    const button = <>
        {buttonText}
        <AddIcon fontSize="medium"/>
    </>

    const clearForm = () => {
        setReport({})
        setErrorColumn("")
        setErrorMessage("")
        setFilter({})
        setFormat("XLSX")
    };

    const inputs = <InputsReport
        reportsList={reportsList}
        setReportsList={setReportsList}
        report={report}
        setReport={setReport}
        filter={filter}
        setFilter={setFilter}
        format={format}
        setFormat={setFormat}
        baseUrl={baseUrl}
        isLoading={isLoading}
    />

    const handleSubmit = (e) => {
        e.preventDefault();

        const data = {
            format,
            filter
        };

        const reportName = report.name || 'relatorio';

        setIsLoading(true);

        if (report.synchronous) {
            downloadFile(baseUrl + report.url, `${reportName}.${format.toLowerCase()}`, data);
        } else {
            saveReportRequest(baseUrl + report.url, data);
        }
    };

    const downloadFile = (endpoint, fileName, data) => {
        axios.post(endpoint, data, {
            responseType: 'blob'
        })
            .then(function (response) {
                saveAs(response.data, fileName); // Usando o nome correto do arquivo
                setLgShow(false);
                if (setLoadData) setLoadData(true);
                if (clearForm) clearForm();
            })
            .catch(function (error) {
                console.log(data);
                console.log("Error: " + error);
                if (error.response && error.response.status === 409) {
                    setErrorMessage(error.response.data.message);
                    setErrorColumn(error.response.data.column);
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };


    const saveReportRequest = (endpoint, data) => {
        axios
            .post(endpoint, data)
            .then(function (response) {
                setLgShow(false);
                if (setLoadData) setLoadData(true);
                if (clearForm) clearForm();
            })
            .catch(function (error) {
                // aqui temos acesso ao erro, quando alguma coisa inesperada acontece:
                console.log("Error: " + error);
                if (error.response && error.response.status === 409) {
                    setErrorMessage(error.response.data.message);
                    setErrorColumn(error.response.data.column);
                }
            })
            .finally(() => {
                setIsLoading(false); // Desativar o estado de isLoading após o término da solicitação
            });
    }

    return <PopupAddNameTrade
        lgShow={lgShow}
        setLgShow={setLgShow}
        inputs={inputs}
        title={title}
        button={button}
        icon={icon}
        isLoading={isLoading}
        handleSubmit={handleSubmit}
    />
};

export default PopupAddReport;

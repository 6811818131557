import React, {useState} from "react";
import FilterForm from "../../../components/FilterForm/FilterForm";
import SelectInput from "../../../components/SelectInput";


const FilterCardSolicitation = ({filter, setFilter, setLoadData, setPage,businessUnitId,setBusinessUnitId}) => {

    const [lgShow, setLgShow] = useState(false);

    const baseUrl = `http://${process.env.REACT_APP_GLOBAL_PROCESS_LINK}`

    const [businessUnitList, setBusinessUnitList] = useState([])


    const handlesSubmit = (e) => {
        if (e) e.preventDefault();

        setFilter({
            businessUnitList,
        });

        setLgShow(false);
        setLoadData(true);

        setPage(0);
    };

    const clearFilter = () => {
        setBusinessUnitList([])

        setFilter({});

        setLgShow(false);
        setLoadData(true);
    };

    return(
        <FilterForm
            handleSubmit={handlesSubmit}
            clearFilter={clearFilter}
            lgShow={lgShow}
            setLgShow={setLgShow}
            showSecondaryButton={Object.keys(filter).length !== 0}
        >
            <SelectInput
                url={`http://${process.env.REACT_APP_GLOBAL_PROCESS_LINK}/BusinessUnit?size=200000000`}
                placeholder={"Unidade de negócio"}
                list={businessUnitList}
                set={setBusinessUnitList}
                setObj={setBusinessUnitId}
                defaultValue={businessUnitId}
                required={true}
                fullWidth
            />

        </FilterForm>
    )
}
export default FilterCardSolicitation
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Paper, TableContainer } from "@mui/material";
import BasicLoader from "components/BasicLoader/BasicLoader";
import { useEffect, useState } from "react";
import { NumberWithPoints } from "utils/numberWithPoints";
import ShortInvoice from "./ShortInvoice";
import TransactionTypeInvoice from "./TransactionTypeInvoice";
import { fetchData } from 'api/axiosMethods';

export default function SummarizedInvoice({ invoiceId }) {

    const baseUrl = `http://${process.env.REACT_APP_FINANCIAL_MANAGER_LINK}`;

    const [loadingData, setLoadingData] = useState(true);
    const [invoice, setInvoice] = useState({});
    const [callTransactionTypeInvoice, setCallTransactionTypeInvoice] = useState(false);

    const [transactionType, setTransactionType] = useState('');

    useEffect(async () => {
        const data = await fetchData(`${baseUrl}/financialClose/summarizedInvoice`, {invoiceId});
        if (data) {
            setInvoice(data);
        }
        setLoadingData(false);
    }, 
    [invoiceId]);

    function handleDetails(entryType) {
        setTransactionType(entryType);
        setCallTransactionTypeInvoice(true);
    }

    return (
        <>
            {
                loadingData ? <BasicLoader/>
                :
                <div>

                    {callTransactionTypeInvoice ? <TransactionTypeInvoice invoiceId={invoiceId} type={transactionType} /> 
                    :
                    <div>
                        <ShortInvoice invoice={invoice}></ShortInvoice>
                        

                        <Paper sx={{elevation: 24, margin: 5}}>
                            <TableContainer>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Descrição</th>
                                            <th>Valor</th>
                                            <th>Repasse Franquia</th>
                                            <th>Repasse Boticário</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {invoice.items.map( item => {
                                            return (
                                                <tr key={item.entryType}>
                                                    <td>{item.entryType}</td>
                                                    <td>R$ {NumberWithPoints(item.value)}</td>
                                                    <td>R$ {NumberWithPoints(item.comission)}</td>
                                                    <td>R$ {NumberWithPoints(item.diff)}</td>
                                                    <td><a href='#' onClick={() => handleDetails(item.entryType)}>Detalhes <VisibilityIcon fontSize="medium" /></a></td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </TableContainer>
                        </Paper>

                        
                    </div>
                    }

                </div>
            }
        </>
    );
}
import { Badge, Paper, TextField } from "@mui/material";
import MainNavbar from "components/MainNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import SearchIcon from '@mui/icons-material/Search';
import SummarizedFinancialClose from "./components/SummarizedFinancialClose";
import SelectInput from "components/SelectInput";
import ReportingMonitoring from "./components/ReportingMonitoring";
import { useNavigate } from "react-router-dom";


export default function FinancialClose() {

    const navigate = useNavigate();

    const baseUrl = `http://${process.env.REACT_APP_FINANCIAL_MANAGER_LINK}`;

    const [cpGroup, setCpGroup] = useState("");
    const [callSummarize, setCallSummarize] = useState(false);
    const [clearing, setClearing] = useState({});
    const [clearingList, setClearingList] = useState([]);

    function handleSubmit() {
        setCallSummarize(true);
    }

    function handleClear() {
        window.location.reload();
    }

    return (
        <>
            <MainNavbar/>
            <DashboardLayout>
                <Paper className="paper-list mb-3">
                    <h1>Fechamento Financeiro Clearing</h1>

                    <div className="row">
                        <div className="col-md-2">

                            <SelectInput
                                url={`${baseUrl}/clearings/last`}
                                placeholder={"Clearing *"}
                                list={clearingList}
                                set={setClearingList}
                                setObj={setClearing}
                                required={true}
                                fullWidth
                                returnList={() => {
                                    return clearingList.collection.map((item) => ({
                                        value: item.id,
                                        label: item.monthYear,
                                    }));
                                }}
                            />

                        </div>
                        <div className="col-md-2">
                            <TextField
                                id="grupoCP"
                                label="Grupo CP"
                                variant="outlined"
                                fullWidth
                                onChange={(e) => setCpGroup(e.target.value)}
                                value={cpGroup}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-2">
                            {!callSummarize && 
                                <Button onClick={() => handleSubmit()}>
                                    Consultar  <SearchIcon fontSize="medium"/>
                                </Button>
                            }
                        </div>
                        <div className="col-md-6">
                            {callSummarize && 
                                <Button onClick={() => handleClear()}>
                                    Limpar  <SearchIcon fontSize="medium"/>
                                </Button>
                            }
                        </div>
                        <div className="col-md-2">
                            <ReportingMonitoring/>
                        </div>
                    </div>

                    {callSummarize ? <SummarizedFinancialClose params={{clearing, cpGroup}}></SummarizedFinancialClose> : null}

                </Paper>
            </DashboardLayout>
        </>
    );
}
import {Button, Form} from "react-bootstrap";
import Popup from "../../../components/Popup/Popup";
import React, {useEffect, useState} from "react";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import InputsLoadCard from "./InputsLoadCard";
import axios from "axios";
import BasicLoader from "../../../components/BasicLoader/BasicLoader";
import DefaultSuccessMessage from "../../../components/DefaultSuccessMessage/DefaultSuccessMessage";
import DefaultProblemMessage from "../../../components/DefaultProblemMessage/DefaultProblemMessage";

export default function PopupLoadCard({cardNumber, setLoadData, buttonClass}) {

    const baseUrl = `http://${process.env.REACT_APP_CARD_MANAGER_LINK}/LoadValueCard`

    const [lgShow, setLgShow] = useState(false);
    const [value, setValue] = useState('');
    const [logicCode, setLogicCode] = useState('')

    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [loaded, setLoaded] = useState(false);
    const [sendingData, setSendingData] = useState(false);

    const [logicCodeFilter, setLogicCodeFilter] = useState('')

    const title = "Efetuar carga"

    useEffect(() => {
        if (!lgShow && loaded)
            setLoadData(true)
    }, [lgShow]);

    const iconSpan = <>
        <MonetizationOnIcon fontSize="large" id="icon-svg"/>
        {title}
    </>

    const button = <>
        <MonetizationOnIcon fontSize="medium"/>
        &nbsp;Efetuar carga
    </>

    const retry = () => {
        setError(false)
        setLoaded(false)
        setSendingData(false)
        setErrorMsg('')
        setLogicCode('')
        setLogicCodeFilter('')
        setValue('')
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        setSendingData(true)
        axios.post(baseUrl + '/load', {value, cardNumber, logicCode})
            .then(function (response) {
                let processStatus = response.data.status.status
                if (processStatus === 'EFETUADA') {
                    setLoaded(true)
                    setError(false)
                }
                if (processStatus === 'REJEITADA') {
                    setError(true)
                    setErrorMsg(response.data.denialReason)
                }
            }).catch(function (error) {
            // aqui temos acesso ao erro, quando alguma coisa inesperada acontece:
            // console.log("Error: " + error);
            setError(true)
            setErrorMsg('')
        }).finally(() => {
            setSendingData(false)
        })
    }


    return (
        <Popup
            buttonText={button}
            title={title}
            iconSpan={iconSpan}
            lgShow={lgShow}
            setLgShow={setLgShow}
            buttonId={"links-action"}
            buttonClass={buttonClass}
        >
            {sendingData ?
                <BasicLoader height={20} message={"Efetuando carga..."}/> :
                error ?
                    <>
                        <DefaultProblemMessage message={errorMsg !== '' ? errorMsg : false}/>
                        <div className="form-inputs">
                            <Button onClick={retry}> Tentar novamente </Button>
                        </div>
                    </> :
                    loaded ?
                        <DefaultSuccessMessage message={'Carga efetuada com sucesso!'}/> :
                        <Form onSubmit={handleSubmit} className="form-inputs">
                            <InputsLoadCard
                                value={value} setValue={setValue}
                                store={logicCode} setStore={setLogicCode}
                                logicCodeFilter={logicCodeFilter}
                                setLogicCodeFilter={setLogicCodeFilter}
                            />
                            <Button type="submit">
                                Confirmar Carga
                            </Button>
                        </Form>
            }
        </Popup>
    )
}
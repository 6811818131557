import React, {useState} from "react";
import "./stylesComponents/ListPopupEditCardSolicitation.css"
import TableList from "../../../components/TableList/TableList";
import PopupEditCardSolicitation from "./PopupEditCardSolicitation";
import {normalizeTimestemp} from "../../../utils/normalizeTimestemp";
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import PopupDeleteCardSolicitation from "./PopupDeleteCardSolicitation";
import SolicitationDetails from "./SolicitationDetails";
import FilterFormBusinessUnit from "../../businessUnit/components/FilterFormBusinessUnit";
import FilterCardSolicitation from "./FilterCardSolicitation";

const ListCardSolicitation = ({
                                  baseUrl, loadData,
                                  setLoadData, size, page,
                                  setTotalPages, setTotalElements,
                                  sort, setSort, setPage
                              }) => {

    const [data, setData] = useState([])
    const [error, setError] = useState(false)
    const [filter, setFilter] = useState({})

    const titles = [
        "Id",
        "Tipo de Cartão",
        "Quantidade de Cartões",
        "Status",
        "Unidade de Negócio",
        "Data de Criação"
    ]

    return (
        <>
            {/*<FilterCardSolicitation filter={filter} setFilter={setFilter} setLoadData={setLoadData} setPage={setPage}/>
            SO VAI SE ADICIONADO SE OPTAREM POR AQUELES PROBLEMAS DE TROCAR PARA INATIVO
            VAI FICAR SEM USAR NENHUM FILTRO ATÉ ATIVAR NOVAMENTE
            */}
            <TableList
                titles={titles} baseUrl={baseUrl}
                page={page} sort={sort} size={size}
                loadData={loadData}
                setTotalPages={setTotalPages} setTotalElements={setTotalElements}
                setData={setData} setLoadData={setLoadData}
                error={error} setError={setError}
            >
                {data.map((item) =>
                    <tr key={item.id}>
                        <td>{item.id}</td>
                        <td>{item.digital
                            ? <p>Digital</p>
                            : <p>Físico</p>
                        }</td>
                        <td>{item.amount}</td>
                        <td>{item.processed
                            ? <span className="status-active">
                            <CheckCircleOutlineIcon fontSize="medium"/> Processada
                    </span>
                            : <span className="status-blocked">
                            <AccessAlarmsIcon fontSize="medium"/>  Pendente
                        </span>
                        }</td>
                        <td>{item.businessUnitName}</td>
                        <td>{normalizeTimestemp(item.dateCreated)}</td>
                        <td>
                            {(!item.processed) ?
                                <span>
                                <PopupEditCardSolicitation
                                    item={item}
                                    baseUrl={baseUrl}
                                    setLoadData={setLoadData}
                                />

                                <PopupDeleteCardSolicitation
                                    item={item}
                                    baseUrl={baseUrl}
                                    setLoadData={setLoadData}
                                />
                            </span> :
                                <span>
                                <SolicitationDetails
                                    item={item} baseUrl={baseUrl}
                                />
                            </span>
                            }
                        </td>
                    </tr>
                )}
            </TableList>
        </>
    )
}

export default ListCardSolicitation
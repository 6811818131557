// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
/* Responssive style*/

@media screen and (min-width: 1200px) and (max-width: 1440px) {
    .Value-response li {
        font-size: 17px
    }
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
    .Value-response li {
        font-size: 13.5px
    }

}
`, "",{"version":3,"sources":["webpack://./src/layouts/monitoring/cssResponse/MonitoringItens.css"],"names":[],"mappings":";AACA,qBAAqB;;AAErB;IACI;QACI;IACJ;AACJ;;AAEA;IACI;QACI;IACJ;;AAEJ","sourcesContent":["\r\n/* Responssive style*/\r\n\r\n@media screen and (min-width: 1200px) and (max-width: 1440px) {\r\n    .Value-response li {\r\n        font-size: 17px\r\n    }\r\n}\r\n\r\n@media screen and (min-width: 769px) and (max-width: 1200px) {\r\n    .Value-response li {\r\n        font-size: 13.5px\r\n    }\r\n\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, {useEffect, useState} from "react";
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import axios from "axios";
import Popup from "../../../components/Popup/Popup";
import BasicLoader from "../../../components/BasicLoader/BasicLoader";
import DefaultProblemMessage from "../../../components/DefaultProblemMessage/DefaultProblemMessage";
import {Button} from "react-bootstrap";
import DefaultSuccessMessage from "../../../components/DefaultSuccessMessage/DefaultSuccessMessage";

export default function ChangeStatus({
                                         setLoadData, id, status,
                                         message, successMessage,
                                         buttonClass, buttonVariant,
                                         button, children}) {

    const baseUrl = `http://${process.env.REACT_APP_CARD_MANAGER_LINK}/Card/change-status`

    const [show, setShow] = useState(false);
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [updated, setUpdated] = useState(false);
    const [sendingData, setSendingData] = useState(false);


    const title = "Confirmar " + message

    useEffect(() => {
        if (!show && updated)
            setLoadData(true)
    }, [show]);

    const iconSpan = <>
        <ThumbUpAltIcon fontSize="large" id="icon-svg"/>
        {title}
    </>

    const retry = () => {
        setError(false)
        setUpdated(false)
        setSendingData(false)
        setErrorMsg('')
    }

    const confirmUpdate = (e) => {
        e.preventDefault()

        setSendingData(true)
        axios.put(baseUrl, {id, status})
            .then(function (response) {
                setUpdated(true)
            }).catch(function (error) {
            // aqui temos acesso ao erro, quando alguma coisa inesperada acontece:
            // console.log("Error: " + error);
            setError(true)
            setErrorMsg(error.response.data)
        }).finally(() => {
            setSendingData(false)
        })
    }


    return (
        <Popup
            buttonText={button}
            title={title}
            iconSpan={iconSpan}
            lgShow={show}
            setLgShow={setShow}
            buttonId={"links-action"}
            buttonClass={buttonClass}
        >
            {sendingData ?
                <BasicLoader height={20} message={"Efetuando alteração..."}/> :
                error ?
                    <>
                        <DefaultProblemMessage message={errorMsg !== '' ? errorMsg : false}/>
                    </> :
                    updated ?
                        <DefaultSuccessMessage message={successMessage}/> :
                        <div className='text-center'>
                            {children}
                            <Button variant={buttonVariant} onClick={confirmUpdate}>
                                Confirmar {message}
                            </Button>
                        </div>
            }
        </Popup>
    )
}
import Grid from "@mui/material/Grid";
import CustomCard from "../../../components/CustomCard/CustomCard";
import React from "react";
import {NumberWithPoints} from "../../../utils/numberWithPoints";
import "../cssResponse/MonitoringItens.css";
import displayType from "./displayType";

export default function MonitoringItens({
                                            data, totalText, processName,
                                            processButton, returnButtonAction
                                        }) {

    return (
        <Grid container spacing={2}>
            <>
                <Grid item xs={2.5}>
                    <h4>Entrada</h4>
                    {
                        data.map((item, index) => {
                            if (item.tipo === 'TOTAL') {
                                return (
                                    <CustomCard
                                        width='90%'
                                        title="Total"
                                        button={returnButtonAction(item.tipo)}
                                        key={index}
                                    >
                                        <p>
                                            {totalText}
                                        </p>
                                        <div className="Value-response">
                                            <ul key={index}>
                                                <li>Quantidade: <b>{NumberWithPoints(item.quantidade, 0)}</b></li>
                                                <li>Valor: <b>R${NumberWithPoints(item.valor / 100)}</b></li>
                                            </ul>
                                        </div>
                                    </CustomCard>

                                )
                            }
                        })
                    }
                </Grid>
                <Grid item xs={2} sx={{
                    minWidth: '250px'
                }}>
                    <h4>Processo</h4>
                    <CustomCard
                        width='90%'
                        title=""
                        button={processButton}
                    >
                        <h3 className="text-center">{processName}</h3>
                    </CustomCard>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={7}
                    md={7}
                    sx={{
                        display: 'inline-block'
                        // border: '2px solid black'
                    }}
                >
                    <h4>Saída</h4>
                    {
                        data.map((item, index) => {
                            if (item.tipo !== 'TOTAL') {
                                return (
                                    <CustomCard title={displayType(item.tipo)} key={index} button={returnButtonAction(item.tipo)}>
                                        <div className="Value-response">
                                            <ul>
                                                <li>Quantidade: <b>{NumberWithPoints(item.quantidade, 0)}</b></li>
                                                <li>Valor: <b>R${NumberWithPoints(item.valor / 100)}</b></li>
                                            </ul>
                                        </div>
                                    </CustomCard>)
                            }
                        })
                    }
                </Grid>
            </>
        </Grid>
    )
}
import BasicLoader from "components/BasicLoader/BasicLoader";
import ShortInvoice from "./ShortInvoice";
import { Paper, TableContainer } from "@mui/material";
import { useEffect, useState } from "react";
import { NumberWithPoints } from "utils/numberWithPoints";
import { fetchData } from "api/axiosMethods";

export default function DetailMerchantInvoice({invoiceId, storeCode, type}) {

    const baseUrl = `http://${process.env.REACT_APP_FINANCIAL_MANAGER_LINK}`;

    const [invoice, setInvoice] = useState({});
    const [loadingData, setLoadingData] = useState(true);

    useEffect(async () => {
        const data = await fetchData(`${baseUrl}/financialClose/getDetailedMerchantInvoice`, {invoiceId, storeCode, type});
        if (data) {
            setInvoice(data);
        }
        setLoadingData(false);
    }, [invoiceId]);

    return (
        <>
            {
                loadingData ? <BasicLoader/>
                :
                <div>
                    <ShortInvoice invoice={invoice}></ShortInvoice>

                    <Paper sx={{elevation: 24, margin: 5}}>
                        <TableContainer>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Cartão</th>
                                        <th>Data {type}</th>
                                        <th>Valor {type}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {invoice.items.map( item => {
                                        return (
                                            <tr key={item.maskedCardNumber}>
                                                <td>{item.maskedCardNumber}</td>
                                                <td>{item.date}</td>
                                                <td>R$ {NumberWithPoints(item.value)}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>           
                        </TableContainer>
                    </Paper>
                </div>
            }
        </>
    );
}
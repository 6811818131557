import React, {useState} from "react";
import TextField from "@mui/material/TextField";
import {Button, FormControlLabel, FormLabel, IconButton, Radio, RadioGroup} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import ClearIcon from "@mui/icons-material/Clear";
import SelectInput from "../../../components/SelectInput";
import MDBox from "../../../components/MDBox";
import DisplayStatus from "../../../components/Status/DisplayStatus";
import {cnpjMask} from "../../../utils/cnpjMask";
import FilterForm from "../../../components/FilterForm/FilterForm";

const FilterFormStore = ({filter, setFilter, setLoadData, setPage}) => {
    const [lgShow, setLgShow] = useState(false);
    const [storeName, setStoreName] = useState("");
    const [tradingName, setTradingName] = useState("");
    const [cnpj, setCnpj] = useState("");
    const [internalCode, setInternalCode] = useState("");
    const [logicCode, setLogicCode] = useState("");
    const [groupId, setGroupId] = useState("");
    const [groupList, setGroupList] = useState([]);
    const [businessUnit, setBusinessUnit] = useState("")
    const [businessUnitList, setBusinessUnitList] = useState([])
    const [status, setStatus] = useState("");
    const [centralize, setCentralize] = useState("");

    const baseUrl = `http://${process.env.REACT_APP_GLOBAL_PROCESS_LINK}`;

    const handleSubmit = (e) => {
        if (e) e.preventDefault();

        setFilter({
            ...(storeName && {storeName}),
            ...(cnpj && {cnpj}),
            ...(internalCode && {internalCode}),
            ...(logicCode && {logicCode}),
            ...(groupId && {groupId}),
            ...(status && {status}),
            ...(businessUnit && {businessUnit}),
            ...(centralize !== "" && { centralized: centralize === "true" }),
        });

        setLgShow(false);
        setLoadData(true);
        setPage(0);
    };

    const clearFilter = () => {
        setStoreName("");
        setCnpj("");
        setInternalCode("");
        setLogicCode("");
        setGroupId("");
        setStatus("");
        setCentralize("");
        setBusinessUnit("")

        setFilter({});

        setLgShow(false);
        setLoadData(true);
    };

    const clearAll = () => {
        setStoreName("");
        setCnpj("");
        setInternalCode("");
        setLogicCode("");
        setGroupId("");
        setStatus("");
        setCentralize("");
        setBusinessUnit("")

        setFilter({});
    };

    return (
        <FilterForm
            handleSubmit={handleSubmit}
            clearFilter={clearFilter}
            clearAll={clearAll}
            lgShow={lgShow}
            setLgShow={setLgShow}
            showSecondaryButton={Object.keys(filter).length !== 0}
        >
            <TextField
                label="Razão Social"
                inputProps={{maxLength: 80}}
                error={storeName === "" && false}
                // helperText={storeName === "" ? "Campo obrigatório!" : ""}
                value={storeName}
                onChange={(e) => setStoreName(e.target.value.toUpperCase())}
                fullWidth
                autoFocus
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {storeName && (
                                <IconButton size="small" onClick={() => setStoreName("")}>
                                    <ClearIcon />
                                </IconButton>
                            )}
                        </InputAdornment>
                    ),
                }}
            />
            <TextField
                label="CNPJ"
                inputProps={{maxLength: 18}}
                value={cnpjMask(cnpj)}
                onChange={(e) =>
                    setCnpj(
                        e.target.value
                            .replaceAll(".", "")
                            .replaceAll("-", "")
                            .replaceAll("/", "")
                    )
                }
                id="input-cnpj"
                fullWidth
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {cnpj && (
                                <IconButton size="small" onClick={() => setCnpj("")}>
                                    <ClearIcon />
                                </IconButton>
                            )}
                        </InputAdornment>
                    ),
                }}
            />
            <TextField
                label="Código Interno"
                inputProps={{max: 999999, min: 1}}
                error={internalCode === "" && false}
                // helperText={internalCode === "" ? "Campo obrigatório!" : ""}
                value={internalCode}
                onChange={(e) => setInternalCode(e.target.value.replace(/\D/, ""))}
                id="input-code"
                fullWidth
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {internalCode && (
                                <IconButton size="small" onClick={() => setInternalCode("")}>
                                    <ClearIcon />
                                </IconButton>
                            )}
                        </InputAdornment>
                    ),
                }}
            />
            <TextField
                label="Código Lógico"
                inputProps={{maxLength: 15}}
                value={logicCode}
                onChange={(e) => setLogicCode(e.target.value.replace(/\D/, ""))}
                id="input-code"
                fullWidth
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {logicCode && (
                                <IconButton size="small" onClick={() => setLogicCode("")}>
                                    <ClearIcon />
                                </IconButton>
                            )}
                        </InputAdornment>
                    ),
                }}
            />
            <SelectInput
                url={`${baseUrl}/FranchiseGroup?size=200000000`}
                placeholder={"Grupo"}
                list={groupList}
                set={setGroupList}
                setObj={setGroupId}
                value={groupId}
                defaultValue={groupId}
                isClearable
                returnList={() => {
                    let returnList = [];
                    groupList.forEach((item) => {
                        returnList.push({
                            value: item.id,
                            label: `${item.code} - ${item.name}`,
                        });
                    });
                    return returnList;
                }}
                fullWidth
                // A linha abaixo assume que SelectInput pode lidar com valor vazio
                onChange={(value) => setGroupId(value)}
            />
            <SelectInput
                url={`${baseUrl}/BusinessUnit?size=200000000`}
                placeholder={"Unidade de negócio *"}
                list={businessUnitList}
                set={setBusinessUnitList}
                setObj={setBusinessUnit}
                isClearable
                defaultValue={businessUnit}
                returnList={() => {
                    let returnList = [];
                    businessUnitList.forEach((item) => {
                        returnList.push({
                            value: item.name,
                            label: `${item.name}`,
                        });
                    });
                    return returnList;
                }}
                fullWidth
                onChange={(value) => setBusinessUnit(value)}
            />
            <MDBox mb={0.5} pb={0.5}>
                <FormLabel id="demo-row-radio-buttons-group-label" className="text-center">
                    Status
                </FormLabel>
                <RadioGroup
                    row
                    name="row-radio-buttons-group"
                    id="radios-status"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    required
                >
                    <FormControlLabel value="" control={<Radio/>} label={<span>Todos</span>}/>
                    <FormControlLabel value="ATIVO" control={<Radio/>} label={<DisplayStatus status="ATIVO"/>}/>
                    <FormControlLabel value="INATIVO" control={<Radio/>} label={<DisplayStatus status="INATIVO"/>}/>
                    <FormControlLabel value="BLOQUEADO" control={<Radio/>} label={<DisplayStatus status="BLOQUEADO"/>}/>
                </RadioGroup>
            </MDBox>
            <MDBox mb={0.5} pb={0.5}>
                <FormLabel id="centralizer-radio-buttons-group-label" className="text-center">
                    É Centralizada?
                </FormLabel>
                <RadioGroup
                    row
                    name="centralizer-radio-buttons-group"
                    id="radios-status"
                    value={centralize}
                    onChange={(e) => setCentralize(e.target.value)}
                >
                    <FormControlLabel value="" control={<Radio />} label={<span>Indiferente</span>} />
                    <FormControlLabel value="true" control={<Radio />} label="Sim" />
                    <FormControlLabel value="false" control={<Radio />} label="Não" />
                </RadioGroup>
            </MDBox>

        </FilterForm>
    );
};

export default FilterFormStore;

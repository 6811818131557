import React, {useEffect, useState} from "react";
import MDTypography from "../../../../components/MDTypography";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import {InputLabelProps} from "../../../../utils/InputLabelProps";
import MDBox from "../../../../components/MDBox";

export default function TransferenciaSaldo({filter, setFilter}){

    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    useEffect(() => {
        setFilter({
            startDate,
            endDate
        })
    }, [startDate,endDate]);


    return <>
        <MDBox mt={2}>
            <MDTypography variant="h6">Período</MDTypography>
            <Stack
                direction="row"
                spacing={2}
                mt={1}
                sx={{display: "flex", justifyContent: "center"}}
            >
                <TextField
                    label="Início"
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    InputLabelProps={{
                        ...InputLabelProps,
                        shrink: true,
                        size: "small",
                    }}
                    sx={{width: 180}}
                    required
                />
                <TextField
                    label="Fim"
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    InputLabelProps={{
                        ...InputLabelProps,
                        shrink: true,
                        size: "small",
                    }}
                    sx={{width: 180}}
                    required
                />
            </Stack>
        </MDBox>
    </>
}
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Radio,
    RadioGroup,
    TextField,
} from "@mui/material";
import {withStyles} from '@material-ui/core/styles';
import axios from "axios";
import {AutoCompleteInput} from "components/FilteredSelectInput";
import React, {useEffect, useState} from "react";
import AlertMsg from "../../../components/AlertMsg/AlertMsg";

export default function InputsUser({
                                       username,
                                       setUsername,
                                       name,
                                       setName,
                                       email,
                                       setEmail,
                                       roles,
                                       setRoles,
                                       scopes,
                                       setScopes,
                                       scope,
                                       setScope,
                                       internalCode,
                                       setInternalCode,
                                       owner,
                                       setOwner,
                                       errorMessage,
                                       errorColumn,
                                   }) {

    const [storeList, setStoreList] = useState([]);
    const [internalCodeFilter, setInternalCodeFilter] = useState("");

    const [errorName, setErrorName] = useState(false);
    const [errorNameText, setErrorNameText] = useState(false);
    const [errorUsername, setErrorUsername] = useState(false);
    const [errorUsernameText, setErrorUsernameText] = useState(false);
    const [errorEmail, setErrorEmail] = useState(false);
    const [errorEmailText, setErrorEmailText] = useState(false);

    useEffect(() => {
        setErrorName(false);
        setErrorNameText(false);
        switch (errorColumn) {
            case "name":
                setErrorName(true);
                setErrorNameText(errorMessage);
                break;
            case "username":
                setErrorUsername(true);
                setErrorUsernameText(errorMessage);
                break;
            case "email":
                setErrorEmail(true);
                setErrorEmailText(errorMessage);
                break;
        }
    }, [errorColumn, errorMessage]);

    useEffect(() => {

        if (roles?.length === 0) {
            axios.get(`http://${process.env.REACT_APP_CARD_PROCESS_SECURITY_LINK}/roles`)
                .then(function (response) {
                    setRoles(response.data.collection);
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
        if (scopes?.length === 0) {
            axios.get(`http://${process.env.REACT_APP_CARD_PROCESS_SECURITY_LINK}/scopes`)
                .then(function (response) {
                    setScopes(response.data.collection);
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }, []);

    //  async function handleRole(evt) {
    //    const roleId = evt.target.id.split("_")[1];
    //    const selectedRole = roles.find(role => role.id === parseInt(roleId));
    //    const suffleRoles = [{...selectedRole, checked: evt.target.checked}, ...roles.filter(role => role.id !== parseInt(roleId))];
    //    await setRoles([(suffleRoles[0].id === 1 ? suffleRoles[0] : suffleRoles[1]), (suffleRoles[1].id === 2 ? suffleRoles[1] : suffleRoles[0])]);
    // }

    const handleRoleChange = (roleId) => {
        setRoles((prevRoles) =>
            prevRoles.map((role) => {
                if (role.id === roleId) {
                    return {...role, checked: !role.checked};
                }
                return role;
            })
        );
    };

    // Lógica para limitar a seleção a no máximo 2 roles
    const selectedRoles = roles.filter((role) => role.checked);

    const styles = theme => ({
        root: {
            color: 'green',
        },
    });
    const customFormLabel = withStyles(styles)(FormLabel);

    useEffect(() => {
        console.log(internalCode)
    }, [internalCode])

    return (
        <>
            <TextField
                id="input-with-icon-textfield"
                label="Nome do Usuário"
                inputProps={{
                    maxLength: 20,
                }}
                required={true}
                size="large"
                erortext="Campo obrigatório!"
                value={username}
                onChange={(e) => setUsername(e.target.value.toUpperCase())}
                error={errorUsername}
                helperText={errorUsernameText}
                fullWidth
                autoFocus
            />
            <TextField
                label="Nome Completo"
                required={true}
                inputProps={{
                    maxLength: 70,
                }}
                type="text"
                erortext="Campo obrigatório!"
                value={name}
                onChange={(e) => setName(e.target.value)}
                error={errorName}
                helperText={errorNameText}
                fullWidth
            />
            <TextField
                label="Email"
                required={true}
                inputProps={{
                    maxLength: 50,
                }}
                type="email"
                erortext="Campo obrigatório!"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={errorEmail}
                helperText={errorEmailText}
                fullWidth
            />

            <FormControl>
                <customFormLabel>Organização à qual usuário pertence</customFormLabel>
                <RadioGroup
                    name="controlled-radio-buttons-group"
                    value={scope}
                    onChange={e => setScope(e.target.value)}
                    sx={{my: 1}}
                >
                    {scopes?.map(item => (
                        <FormControlLabel key={item.id}
                                          value={item.id}
                                          control={<Radio/>}
                                          label={item.name}
                        />
                    ))}
                </RadioGroup>
            </FormControl>

            {scope === 3 && (<p>{owner.logicCode} - {owner.name}</p>)}

            {scope === "3" && (<>
                <br/>
                <AutoCompleteInput
                    sizeToSearch={3}
                    filter={`internalCode=${internalCodeFilter}`}
                    value={internalCodeFilter}
                    setValue={setInternalCodeFilter}
                    firstInputLabel={'Digite 3 dígitos do código interno da loja e uma lista de lojas aparecerá.'}
                    secondInputLabel={'Selecione a loja:'}
                    url={`http://${process.env.REACT_APP_GLOBAL_PROCESS_LINK}/Store?size=200000000`}
                    list={storeList}
                    set={setStoreList}
                    setObj={setInternalCode}
                    defaultValue={internalCode}
                    required={true}
                    fullWidth
                    getLabel={(item) => {
                        return `${item.internalCode} - ${item.corporateName}`
                    }}
                    getValueForList={(item) => {
                        return item.internalCode
                    }}
                />
            </>)}

            <FormGroup>
                <customFormLabel>Perfis de Acesso</customFormLabel>
                <p style={{fontSize: '0.8vw'}}>
                    * Selecione até 2 perfis.
                </p>
                {roles.map((role) => (
                    <FormControlLabel
                        key={role.id}
                        control={
                            <Checkbox
                                checked={role.checked}
                                onChange={() => handleRoleChange(role.id)}
                                disabled={selectedRoles.length === 2 && !role.checked}
                            />
                        }
                        label={role.name}
                    />
                ))}
            </FormGroup>

            { !!errorMessage &&
                <AlertMsg message={errorMessage}/>
            }
        </>
    );
}

import TableList from "components/TableList/TableList"
import React, { useEffect, useState } from "react"
import PopupEditUser from "./PopupEditUser"
import FilterFormUser from "./FilterFormUser"
import DisplayStatusUser from "../../../components/Status/DisplayStatusUser";
import {Dropdown} from "react-bootstrap";
import SettingsIcon from "@mui/icons-material/Settings";
import PopupStatusChangeUser from "./PopupStatusChangeUser";

export default function ListUser({
  baseUrl, loadData,
  size, page,
  setTotalPages, 
  setTotalElements,
  setLoadData,
  sort, setSort, setPage}) {

  const [data, setData] = useState([])
  const [error, setError] = useState(false)
  const [filter, setFilter] = useState({})

  const titles = [
    "Nome do Usuário",
    "Nome Completo",
    // "Organização",
    "Permissão",
    "Email",
    "Status",
  ]

    const getStatusLabel = (status) => {
        const statusMap = {
        "ACTIVE":"Ativo",
        "INACTIVE" : "Inativo",
        "BLOCKED" : "Bloqueado",
        "BLOCKED_INACTIVED": "Inativo Bloqueado",
        "PASSWORD_EXPIRED": "Senha Expirada",
        "MIGRATED" : "Migrado"
        };
        return statusMap[status] || status;
    };

  // useEffect(() => {
  //   console.log(loadData.data)
  // }, [filter])

    const getUserRoles = (roles) => {
        const checkedRoles = roles
            .filter(role => role.checked)
            .map(role => role.name)
            .join(', ');

        return checkedRoles || 'Nenhuma';
    };

  return (
      <>
      <FilterFormUser filter={filter} setFilter={setFilter} setLoadData={setLoadData} setPage={setPage}/>
      <TableList
          titles={titles} baseUrl={baseUrl}
          page={page} sort={sort} size={size}
          loadData={loadData}
          setTotalPages={setTotalPages} setTotalElements={setTotalElements}
          setData={setData} setLoadData={setLoadData} filter={filter}
          error={error} setError={setError}
      >
          {data.map((item) => 
              <tr key={item.id}>
                  {console.log(item)}
                  <td>{item.username}</td>
                  <td>{item.name}</td>
                  {/*<td>{item.owner ? item.owner.logicCode ? `${item.owner.logicCode} - ${item.owner.name}` : item.owner.name : ''}</td>*/}
                  <td>{getUserRoles(item.roles)}</td>
                  <td>{item.email}</td>
                  <td><DisplayStatusUser status={item.status}/></td>
                  <td>
                      <Dropdown drop="down-centered">
                          <Dropdown.Toggle variant="secondary">
                              Ações &nbsp;
                              <SettingsIcon fontSize="medium" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="dropdown-custom">
                                  <PopupEditUser
                                      item={item}
                                      baseUrl={baseUrl}
                                      setLoadData={setLoadData}
                                  />
                              {item.status !== "INACTIVE" && (
                                  <PopupStatusChangeUser
                                      item={item}
                                      baseUrl={baseUrl}
                                      setLoadData={setLoadData}
                                  />
                              )}
                          </Dropdown.Menu>
                      </Dropdown>
                  </td>
              </tr>
          )}
      </TableList>
      </>
  )

}
import {useEffect, useMemo, useState} from "react";
import {Navigate, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Configurator from "examples/Configurator";
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";
import rtlPlugin from "stylis-plugin-rtl";
import {CacheProvider} from "@emotion/react";
import createCache from "@emotion/cache";
import routes from "routes";
import {useMaterialUIController} from "context";
import "bootstrap/dist/css/bootstrap.min.css";
import {useAuthContext} from "components/AuthenticationProvider/AuthenticationProvider";

export default function App() {
    const [controller] = useMaterialUIController();
    const {direction, layout, darkMode} = controller;
    const [rtlCache, setRtlCache] = useState(null);
    const {pathname} = useLocation();

    const authContext = useAuthContext();
    const navigate = useNavigate();

    useEffect(() => {
        // console.log('App - useEffect:');
        // console.log(authContext);
        // console.log('Pathname: ' + pathname);

        if (!pathname.startsWith('/signup') && !pathname.startsWith('/reset-password')) {
            if (authContext.accessToken) {
                if (Date.now() > new Date(authContext.userInfo.exp))
                    navigate('/authentication/sign-in');
            } else
                navigate('/authentication/sign-in');
        }

    }, []);

    // Cache for the rtl
    useMemo(() => {
        const cacheRtl = createCache({
            key: "rtl",
            stylisPlugins: [rtlPlugin],
        });

        setRtlCache(cacheRtl);
    }, []);

    // Setting the dir attribute for the body element
    useEffect(() => {
        document.body.setAttribute("dir", direction);
    }, [direction]);

    // Setting page scroll to 0 when changing the route
    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
    }, [pathname]);

    const getRoutes = (allRoutes) =>
        allRoutes.map((route) => {
            if (route.collapse) {
                return getRoutes(route.collapse);
            }

            if (route.route) {
                return <Route exact path={route.route} element={route.component} key={route.key}/>;
            }

            return null;
        });

    return direction === "rtl" ? (
        <CacheProvider value={rtlCache}>
            <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
                <CssBaseline/>
                {layout === "vr" && <Configurator/>}
                <Routes>
                    {getRoutes(routes)}
                    <Route path="*" element={<Navigate to="/dashboard"/>}/>
                </Routes>
            </ThemeProvider>
        </CacheProvider>
    ) : (
        <ThemeProvider theme={darkMode ? themeDark : theme}>
            <CssBaseline/>
            {layout === "vr" && <Configurator/>}
            <Routes>
                {getRoutes(routes)}
                <Route path="*" element={<Navigate to="/dashboard"/>}/>
            </Routes>
        </ThemeProvider>
    );
}

import React, {useEffect} from "react";
import Divider from "@mui/material/Divider";
import BasicLoader from "../../components/BasicLoader/BasicLoader";
import DefaultErrorMsg from "../../components/DefaultErrorMsg/DefaultErrorMsg";
import MonitoringItens from "../monitoring/components/MonitoringItens";


export default function MonitoringTemplate({
                                               totalText, pageHeader,
                                               processName, processButton,
                                               searchFunction,
                                               loadData, setLoadData,
                                               firstLoad, setFirstLoad,
                                               error, setError, data,
                                               returnButtonAction
                                           }) {

    useEffect(() => {
        if (loadData) {
            searchFunction()
        }
    }, [loadData])

    return (
        <>
            {pageHeader}
            <Divider/>

            {loadData ?
                firstLoad ?
                    <BasicLoader/> :
                    error ?
                        <DefaultErrorMsg/> :
                        <MonitoringItens
                            data={data}
                            processName={processName}
                            totalText={totalText}
                            processButton={processButton}
                            returnButtonAction={returnButtonAction}
                        /> :
                error ?
                    <DefaultErrorMsg/> :
                    <MonitoringItens
                        data={data}
                        processName={processName}
                        totalText={totalText}
                        processButton={processButton}
                        returnButtonAction={returnButtonAction}
                    />
            }
        </>
    )
}
import React from "react";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import "./avatar.css";
import {
    INVALID_TOKEN,
    useAuthContext,
    useAuthDispatchContext
} from "components/AuthenticationProvider/AuthenticationProvider";
import {useNavigate} from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

/* eslint-disable */
function Avatar() {

    const authContext = useAuthContext();
    const authDispatch = useAuthDispatchContext();

    const navigate = useNavigate();

    function logout() {
        authDispatch({type: INVALID_TOKEN});
        navigate('/authentication/sign-in');
    }

    return (
        <Nav className="navbar-custom">
            {/*<img*/}
            {/*    src="https://github.com/GabrielSRezende.png"*/}
            {/*    width="40"*/}
            {/*    height="40"*/}
            {/*    className="rounded-circle"*/}
            {/*    style={{marginRight: "10px", marginTop: "4px"}}*/}
            {/*/>*/}
            <AccountCircleIcon
                color='white' fontSize='large'
                className="rounded-circle"
                style={{marginRight: "0px", marginTop: "3%"}}
            />
            <NavDropdown
                title={'Olá, ' + authContext?.userInfo?.username}
                id="dropdown-basic-button"
                style={{marginRight: "20px"}}
            >
                {/* <NavDropdown.Divider/> */}
                <NavDropdown.Item href="#" onClick={logout}>Sair</NavDropdown.Item>
            </NavDropdown>
        </Nav>
    );
}

export default Avatar;

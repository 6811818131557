// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-inputs {
    text-align: center;
    /*border: 2px solid black;*/
    padding-inline: 25%;
}
.form-inputs div {
    margin-bottom: 2%;
}

#form_select div {
    margin-bottom: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/PopupAdd/PopupAdd.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,2BAA2B;IAC3B,mBAAmB;AACvB;AACA;IACI,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".form-inputs {\r\n    text-align: center;\r\n    /*border: 2px solid black;*/\r\n    padding-inline: 25%;\r\n}\r\n.form-inputs div {\r\n    margin-bottom: 2%;\r\n}\r\n\r\n#form_select div {\r\n    margin-bottom: 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

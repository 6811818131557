import React, { useState } from "react";
import FilterForm from "../../../../components/FilterForm/FilterForm";
import TextField from "@mui/material/TextField";
import SelectInput from "../../../../components/SelectInput";
import MDBox from "../../../../components/MDBox";
import { FormControlLabel, FormLabel, Radio, RadioGroup, Button, InputAdornment } from "@mui/material";
import DisplayStatus from "../../../../components/Status/DisplayStatus";
import ClearIcon from "@mui/icons-material/Clear";

const FilterBalanceTransfer = ({ filter, setFilter, setLoadData, setPage, baseUrl }) => {

    const [lgShow, setLgShow] = useState(false);

    const [originCardNumber, setOriginCardNumber] = useState("");
    const [destinationCardNumber, setDestinationCardNumber] = useState("");

    const [fileId, setFileId] = useState('');
    const [fileList, setFileList] = useState([]);

    const [status, setStatus] = useState("");

    const handlesSubmit = (e) => {
        if (e) {
            e.preventDefault();
        }

        setFilter({
            ...(originCardNumber && { originCardNumber }),
            ...(destinationCardNumber && { destinationCardNumber }),
            ...(fileId > 0 && { fileId }),
            ...(status && { status }),
        });

        setLgShow(false);
        setLoadData(true);
        setPage(0);
    };

    const clearFilter = () => {
        setOriginCardNumber("");
        setDestinationCardNumber("");
        setFileId('');
        setStatus("");

        setFilter({});
        setLgShow(false);
        setLoadData(true);
    };

    const clearAll = () => {
        setOriginCardNumber("");
        setDestinationCardNumber("");
        setFileId('');
        setStatus("");

        setFilter({});
    };

    return (
        <FilterForm
            handleSubmit={handlesSubmit}
            clearFilter={clearFilter}
            clearAll={clearAll}
            lgShow={lgShow}
            setLgShow={setLgShow}
            showSecondaryButton={Object.keys(filter).length !== 0}
        >
            <TextField
                label="Número do Cartão de Origem"
                inputProps={{
                    maxLength: 19,  // Define o número máximo de dígitos para um número de cartão de crédito comum
                }}
                value={originCardNumber}
                onChange={(e) => {
                    const inputNumber = e.target.value.replace(/\D/g, ''); // Remove todos os caracteres que não são dígitos
                    setOriginCardNumber(inputNumber);
                }}
                fullWidth
                autoFocus
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {originCardNumber && (
                                <Button size="small" onClick={() => setOriginCardNumber("")}>
                                    <ClearIcon />
                                </Button>
                            )}
                        </InputAdornment>
                    ),
                }}
            />
            <TextField
                label="Número do Cartão de Destino"
                inputProps={{
                    maxLength: 19,  // Define o número máximo de dígitos para um número de cartão de crédito comum
                }}
                value={destinationCardNumber}
                onChange={(e) => {
                    const inputNumber = e.target.value.replace(/\D/g, ''); // Remove todos os caracteres que não são dígitos
                    setDestinationCardNumber(inputNumber);
                }}
                fullWidth
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {destinationCardNumber && (
                                <Button size="small" onClick={() => setDestinationCardNumber("")}>
                                    <ClearIcon />
                                </Button>
                            )}
                        </InputAdornment>
                    ),
                }}
            />
            <SelectInput
                url={`${baseUrl}/files?size=200000000`}
                placeholder={"Arquivo"}
                list={fileList}
                set={setFileList}
                setObj={setFileId}
                defaultValue={fileId}
                returnList={() => {
                    let returnList = [];
                    fileList.forEach((item) => {
                        returnList.push({ value: item.id, label: item.fileName });
                    });
                    return returnList;
                }}
                isClearable
                fullWidth
            />

            <MDBox mb={0.5} pb={0.5}>
                <FormLabel id="demo-row-radio-buttons-group-label" className="text-center">
                    Status
                </FormLabel>
                <RadioGroup
                    row
                    name="row-radio-buttons-group"
                    id="radios-status"
                    value={status}
                    onChange={(e) => {
                        setStatus(e.target.value);
                    }}
                >
                    <FormControlLabel value="" control={<Radio />} label={<span>Todos</span>} />
                    <FormControlLabel value="EFETUADA" control={<Radio />} label={<DisplayStatus status="EFETUADA" />} />
                    <FormControlLabel value="PENDENTE" control={<Radio />} label={<DisplayStatus status="PENDENTE" />} />
                    <FormControlLabel value="REJEITADA" control={<Radio />} label={<DisplayStatus status="REJEITADA" />} />
                    <FormControlLabel value="ERRO" control={<Radio />} label={<DisplayStatus status="ERRO" />} />
                </RadioGroup>
            </MDBox>
        </FilterForm>
    );
};

export default FilterBalanceTransfer;

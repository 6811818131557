import Grid from "@mui/material/Grid";
import MDBox from "../../../../components/MDBox";
import ReportsBarChart from "../../../../examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "../../../../examples/Charts/LineCharts/ReportsLineChart";
import {useEffect, useState} from "react";
import reportData from "../../data/reportData";
import {normalizeTimestemp} from "../../../../utils/normalizeTimestemp";
import { useAuthContext } from "components/AuthenticationProvider/AuthenticationProvider";

export default function Reports({count, loadData, setLoadData}) {


    const authContext = useAuthContext();

    const loadingData = {
        labels: [".", ".", ".", ".", ".", ".", "."],
        datasets: {label: "Buscando dados...", data: [0, 0, 0, 0, 0, 0, 0]},
    }

    const [dataWeek, setDataWeek] = useState(loadingData)
    const [dataWeekLoading, setDataWeekLoading] = useState(true)

    const [dataBuy, setDataBuy] = useState(loadingData)
    const [dataBuyTime, setDataBuyTime] = useState('...')
    const [dataBuyLoading, setDataBuyLoading] = useState(true)

    const [dataSell, setDataSell] = useState(loadingData)
    const [dataSellTime, setDataSellTime] = useState('...')
    const [dataSellLoading, setDataSellLoading] = useState(true)

    useEffect(() => {

        reportData('buyInCash')
            .then((data) => {
                data !== {} ? setDataBuy(data) : ''
                setDataBuyLoading(false)
                setDataBuyTime(
                    formatarData(normalizeTimestemp(Date.now()))
                )
            })
        reportData('charge')
            .then((data) => {
                data !== {} ? setDataSell(data) : ''
                setDataSellLoading(false)
                setDataSellTime(
                    formatarData(normalizeTimestemp(Date.now()))
                )
            })

    }, [])

    useEffect(() => {
        if (loadData) {

            reportData('transaction')
                .then((data) => {
                    data !== {} ? setDataWeek(data) : ''
                    setDataWeekLoading(false)
                })
            setLoadData(false)
        }
    }, [loadData])

    // Função para formatar a data
    function formatarData(dataString) {
        // Divida a string da data em data e hora
        const [data, hora] = dataString.split(' ');

        // Divida a data em dia, mês e ano
        const [dia, mes, ano] = data.split('/');

        // Formate a data como "dd/mm/yyyy às hh:mm:ss"
        const dataFormatada = `${dia}/${mes}/${ano} às ${hora}`;

        return dataFormatada;
    }

    return <MDBox mt={4.5}>
        <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={3}>
                    <ReportsBarChart
                        color="info"
                        title="Quantidade de transações nos últimos 7 dias (a partir de hoje)"
                        description="(São considerados todos os status)"
                        date={"Atualizando em: " + count + " segundo(s)"}
                        chart={dataWeek}
                        loading={dataWeekLoading}
                    />
                </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={3}>
                    <ReportsLineChart
                        color="success"
                        title="Compras à vista"
                        description='Quantidade de transações nos ultimos 12 meses'
                        date={"Atualizado no dia " + dataBuyTime}
                        chart={dataBuy}
                        loading={dataBuyLoading}
                    />
                </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={3}>
                    <ReportsLineChart
                        color="dark"
                        title="Cargas de cartão"
                        description='Quantidade de transações nos ultimos 12 meses'
                        date={"Atualizado no dia " + dataSellTime}
                        chart={dataSell}
                        loading={dataSellLoading}
                    />
                </MDBox>
            </Grid>
        </Grid>
    </MDBox>
}
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.type-physicist{
    color: black;
}

.type-digital{
    color: blue;
}

#select-status {
    width: 20%;
}

#radios-status {
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin: 0 0 2%;
}

`, "",{"version":3,"sources":["webpack://./src/components/Type/DisplayType.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,cAAc;AAClB","sourcesContent":[".type-physicist{\r\n    color: black;\r\n}\r\n\r\n.type-digital{\r\n    color: blue;\r\n}\r\n\r\n#select-status {\r\n    width: 20%;\r\n}\r\n\r\n#radios-status {\r\n    display: flex;\r\n    justify-content: center;\r\n    flex-direction: row;\r\n    margin: 0 0 2%;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

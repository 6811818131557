// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.status-active{
    color: #4da24d;
}

.status-inactive{
    color: #ff0000;
}

.status-blocked{
    color: #ff7400;
}

.status-expired{
    color: #FF0000;
}

.status-criated{
    color: #4da24d;
}

status-in_resolution{
    color: greenyellow;
}

#select-status {
    width: 20%;
}

#radios-status {
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin: 0 0 2%;
}

`, "",{"version":3,"sources":["webpack://./src/components/Status/DisplayStatus.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,cAAc;AAClB","sourcesContent":[".status-active{\r\n    color: #4da24d;\r\n}\r\n\r\n.status-inactive{\r\n    color: #ff0000;\r\n}\r\n\r\n.status-blocked{\r\n    color: #ff7400;\r\n}\r\n\r\n.status-expired{\r\n    color: #FF0000;\r\n}\r\n\r\n.status-criated{\r\n    color: #4da24d;\r\n}\r\n\r\nstatus-in_resolution{\r\n    color: greenyellow;\r\n}\r\n\r\n#select-status {\r\n    width: 20%;\r\n}\r\n\r\n#radios-status {\r\n    display: flex;\r\n    justify-content: center;\r\n    flex-direction: row;\r\n    margin: 0 0 2%;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import MainNavbar from "../../components/MainNavbar";
import Footer from "../../examples/Footer";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import React, {useEffect, useState} from "react";
import {Paper} from "@mui/material";
import SelectMonitoringParam from "./components/SelectMonitoringParam";
import axios from "axios";
import {Link, useNavigate, useParams} from "react-router-dom";
import MonitoringTemplate from "../monitoringTemplate";
import AlertDismissibleExample from "../../components/Alert/AlertComponent";


export default function Monitoring() {

    const {period} = useParams();
    const navigate = useNavigate();

    const totalText = "Nesta tela podemos monitorar o total de transações feitas e analisar de forma detalhada."
    const processName = "Autorizador de transações"

    const initialPeriod = 'Hoje'
    const [periodChosed, setPeriodChosed] = useState(initialPeriod)
    const [loadData, setLoadData] = useState(false)
    const [firstLoad, setFirstLoad] = useState(true)
    const [error, setError] = useState(false)
    const [errorAPI, setErrorAPI] = useState(false)

    const [data, setData] = useState([])

    const timeToRefresh = 30
    const [count, setCount] = useState(timeToRefresh);

    const processButton = period === initialPeriod || period === undefined ?
        <Link to={"/monitoringLog/"} color="primary" className="btn btn-primary">
            Ver log
        </Link> : <></>

    useEffect(() => {
        if (period !== periodChosed)
            setFirstLoad(true)

        if (period === initialPeriod)
            setCount(timeToRefresh - 1)

        if (period)
            setPeriodChosed(period)

        setLoadData(true)
    }, [period])

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (periodChosed === initialPeriod) {
                setLoadData(true);
                setCount(timeToRefresh - 1)
            }
        }, timeToRefresh * 1000);

        return () => clearInterval(intervalId);
    }, [periodChosed]);

    useEffect(() => {
        const countdownInterval = setInterval(() => {
            if (count > 0) {
                setCount(prevCount => prevCount - 1);
            }
        }, 1000);

        return () => clearInterval(countdownInterval);
    }, [count]);

    function isWithinAllowedTime() {
        const currentHour = new Date().getHours();
        return currentHour >= 8 && currentHour < 23;
    }

    useEffect(() => {
        const intervalId = setInterval(() => {

            if(isWithinAllowedTime()){
                axios
                    .get(`http://${process.env.REACT_APP_MONITOR_LINK}/healthcheck`)
                    .then(response => {
                        setErrorAPI(response.data <= 0);
                    })
                    .catch(errorReceived => {
                        setErrorAPI(true);
                    });
            }

        }, 10 * 1000);
        return () => clearInterval(intervalId);
    }, []);


    const returnButtonAction = (type) => {
        return <Link to={"/monitoring/" + periodChosed + "/" + type} color="primary" className="btn btn-primary">
            Ver Detalhes
        </Link>
    }

    const pageHeader = <>
        <h1>{periodChosed} </h1>
        {periodChosed === initialPeriod && (<p>Atualizando em: {count} </p>)}
        <SelectMonitoringParam
            valueSelected={periodChosed}
            setFunction={setPeriodChosed}
            setLoadData={(check) => {
                setLoadData(check)
                setFirstLoad(check)
                setCount(timeToRefresh - 1)
            }}
            loadData={loadData}
        />
    </>

    const searchFunction = () => {
        console.log("Searching data")

        let params = {
            period: periodChosed.replace(' ', '').replace('ê', 'e')
        }
        axios.get(`http://${process.env.REACT_APP_MONITOR_LINK}/total`, {params})
            .then((response) => {
                // console.log(response.data)
                setData(response.data)
                setError(false)
                setLoadData(false)
                setFirstLoad(false)
            }).catch(function (errorReceived) {
            // aqui temos acesso ao erro, quando alguma coisa inesperada acontece:
            console.log(errorReceived)
            setLoadData(false)
            setError(true)
        })
    }
    return (
        <>
            <MainNavbar/>
            <AlertDismissibleExample showError={errorAPI} setShow={setErrorAPI}/>
            <DashboardLayout>
                <Paper className="paper-list mb-3 w-100">
                    <MonitoringTemplate
                        totalText={totalText} pageHeader={pageHeader}
                        processName={processName} processButton={processButton}
                        searchFunction={searchFunction}
                        loadData={loadData} setLoadData={setLoadData}
                        firstLoad={firstLoad} setFirstLoad={setFirstLoad}
                        error={error} setError={setError} data={data}
                        returnButtonAction={returnButtonAction}
                    />
                </Paper>
            </DashboardLayout>
            <Footer/>
        </>
    )
}
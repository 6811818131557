import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import {CircularProgress} from "@mui/material";
import React from "react";


export default function BasicLoader({height, message, loaderSize}) {

    const heightValue = height ? `${height}vh` : '50vh'
    const text = message ? message : 'Buscando dados...'

    return (
        <Container maxWidth="xs">
            <Box display="flex" justifyContent="center" alignItems="center" minHeight={heightValue}>
                <CircularProgress color='success' size={loaderSize? loaderSize : '6%'}/> &nbsp; {text}
            </Box>
        </Container>
    )
}
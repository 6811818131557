import React, {useEffect, useState} from "react";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import {Stack} from "@mui/material";
import Switch from "@mui/material/Switch";
import SelectInput from "../../../components/SelectInput";


const InputCardSolicitation = ({
                                      cardType, setCardType,
                                      amount, setAmount,
                                      businessUnitId, setBusinessUnitId,
                                      maxAmout, setMaxAmount,
                                      errorMessage, setErrorMessage,
                                      errorColumn, setErrorColumn,
                                      baseUrl
                                  }) => {

    const [businessUnitList, setBusinessUnitList] = useState([])

    const [errorName, setErrorName] = useState(false)
    const [errorNameText, setErrorNameText] = useState(false)

    const changeMaxCharrgeValue = (e) => {
        let valueSeted = e.target.value
        if (valueSeted < amount) {
            setAmount(valueSeted)
        }
        setMaxAmount(valueSeted)
    }

    useEffect(() => {
        setErrorName(false)
        setErrorNameText(false)
        switch (errorColumn) {
            case "name":
                setErrorName(true)
                setErrorNameText(errorMessage)
                break
        }
    }, [errorColumn, errorMessage])

    const changeMinCharrgeValue = (e) => {
        let valueSeted = e.target.value
        if (valueSeted > maxAmout) {
            setMaxAmount(valueSeted)
        }
        setAmount(valueSeted)
    }
    return (
        <>
            <TextField
                label="Quantidade de Cartões"
                inputProps={{
                    max: 100000,
                    min: 1
                }}
                required={true}
                type="number"
                erortext="Campo obrigatório!"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                fullWidth
                autoFocus
            />

            <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                <Typography>Físico</Typography>
                <Switch checked={cardType} onChange={() => setCardType(!cardType)}/>
                <Typography>Digital</Typography>
            </Stack>

            <SelectInput
                url={`http://${process.env.REACT_APP_GLOBAL_PROCESS_LINK}/BusinessUnit?size=200000000`}
                placeholder={"Unidade de negócio"}
                list={businessUnitList}
                set={setBusinessUnitList}
                setObj={setBusinessUnitId}
                defaultValue={businessUnitId}
                required={true}
                fullWidth
            />
        </>
    )
}
export default InputCardSolicitation
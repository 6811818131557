import Popup from "../../../components/Popup/Popup";
import React, { useState } from "react";
import List from "@mui/material/List";
import VisibilityIcon from '@mui/icons-material/Visibility';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import BadgeIcon from '@mui/icons-material/Badge';
import KeyIcon from '@mui/icons-material/Key';
import DateRangeIcon from '@mui/icons-material/DateRange';
import NumbersIcon from '@mui/icons-material/Numbers';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { NumberWithPoints } from "../../../utils/numberWithPoints";
import { normalizeTimestemp } from "../../../utils/normalizeTimestemp";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import DisplayDetailsData from "../../../components/DisplayDetailsData/DisplayDetailsData";

const TransactionDetails = ({ item }) => {
    const [lgShow, setLgShow] = useState(false);

    const button = (
        <>
            Detalhes <VisibilityIcon fontSize="medium" />
        </>
    );

    const title = "Detalhes da Transação";

    const iconSpan = (
        <>
            <VisibilityIcon fontSize="large" id="icon-svg" />
            {title}
        </>
    );

    const getTypeLabel = (type) => {
        const typeMap = {
            'TRANSF_DEBITO': 'Transf. de débito',
            'TRANSF_CREDITO': 'Transf. de crédito',
            'BAIXA_SALDO_VENCIDO': 'Baixa',
            'CARGA': 'Carga'
        };
        return typeMap[type] || type;
    };

    const getStatusLabel = (status) => {
        const statusMap = {
            'PENDENDTE': 'Pendente',
            'NEGADA': 'Negada',
            'CONFIRMADA': 'Confirmada',
            'DESFEITA': 'Desfeita'
        };
        return statusMap[status] || status;
    };

    const filterItems = (items) => {
        return items.filter(
            i => i.display && !i.display.includes('undefined') && !i.display.includes('null') && !i.display.endsWith(': ')
        );
    };


    const primaryDataItems = filterItems([
        {
            icon: <CreditCardIcon />,
            display: `Número do cartão:  ${item.cardNumber}`
        },
        {
            icon: <KeyIcon />,
            display: `Código lógico:  ${item.mid}`
        },
        {
            icon: <DateRangeIcon />,
            display: `Data da transação:  ${normalizeTimestemp(item.dateTime)}`
        },
        {
            icon: <MonetizationOnIcon />,
            display: `Valor:  R$ ${NumberWithPoints(item.value)}`
        },
        {
            icon: <NumbersIcon />,
            display: `NSU:  ${item.hostNsu}`
        },
        {
            icon: <NumbersIcon />,
            display: `Terminal NSU:  ${item.termNsu}`
        },
        {
            icon: <DateRangeIcon />,
            display: item.termDateTime ? `Data terminal:  ${normalizeTimestemp(item.termDateTime)}` : null
        },
        {
            icon: <KeyIcon />,
            display: `Status:  ${getStatusLabel(item.status?.status)}`
        },
        {
            icon: <KeyIcon />,
            display: `Tipo:  ${getTypeLabel(item.type?.type)}`
        }
    ]);

    const storeDetailsItems = filterItems([
        {
            icon: <BadgeIcon />,
            display: `Nome da loja:  ${item.corporateName}`
        },
        {
            icon: <KeyIcon />,
            display: `Código interno:  ${item.storeInternalCode}`
        },
        {
            icon: <KeyIcon />,
            display: `Código Lógico:  ${item.storeLogicCode}`
        },
        {
            icon: <PermIdentityIcon />,
            display: `Cnpj:  ${item.storeCnpj}`
        }
    ]);

    return (
        <Popup
            buttonText={button}
            title={title}
            iconSpan={iconSpan}
            lgShow={lgShow}
            setLgShow={setLgShow}
            buttonId={"links-action"}
        >
            <List
                sx={{ width: '60%', bgcolor: 'background.paper', marginInline: '20%' }}
                component="nav"
                aria-labelledby="nested-list-subheader"
            >
                <DisplayDetailsData
                    key="primary-data"
                    title='Dados primários'
                    itens={primaryDataItems}
                    openDefault
                />

                {storeDetailsItems.length > 0 && (
                    <DisplayDetailsData
                        key="store-details"
                        title='Detalhes da franquia'
                        itens={storeDetailsItems}
                        openDefault={false}
                    />
                )}
            </List>
        </Popup>
    );
};

export default TransactionDetails;

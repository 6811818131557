import React, {useEffect, useState} from "react";
import BorderAllIcon from '@mui/icons-material/BorderAll';
import SuperscriptIcon from '@mui/icons-material/Superscript';
import NoteIcon from '@mui/icons-material/Note';
import axios from "axios";
import Popup from "../../../components/Popup/Popup";
import BasicLoader from "../../../components/BasicLoader/BasicLoader";
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';
import DefaultErrorMsg from "../../../components/DefaultErrorMsg/DefaultErrorMsg";
import DefaultSuccessMessage from "../../../components/DefaultSuccessMessage/DefaultSuccessMessage";


const PopupMonitoringDetails = ({period, type, status}) => {

    const [extension, setExtension] = useState("")
    const [lgShow, setLgShow] = useState("")
    const [lgShow2, setLgShow2] = useState("")
    const [downloadedText, setDownloadedText] = useState(false);
    const [showCloseButton, setShowCloseButton] = useState(true);
    const [backdrop, setBackdrop] = useState("static");

    const title = "Extrair Relátório"

    const iconSpan = <>
        <NoteIcon fontSize="large" id="icon-svg"/> <br/>
        <h3>{title}</h3>
    </>

    const button = <>
        Baixar Excel
        <SuperscriptIcon fontSize="medium" />
    </>

    const button2 = <>
        Baixar CSV
        <BorderAllIcon fontSize="medium" />
    </>

    useEffect(() => {
        if (lgShow === true){
            handleDownload("xlsx")
        }else{
            handleModalHide()
        }
    }, [lgShow])

    useEffect(() => {
        if (lgShow2 === true){
            handleDownload("csv")
        }else{
            handleModalHide()
        }
    }, [lgShow2])

    const handleDownload = (extensionSelect) => {

        // Configurar os dados para a requisição aqui
        const requestData = {
            type: type.replaceAll(' ', ''),
            period: period.replace(' ', '').replace('ê', 'e'),
            status: status.replaceAll(' ', '').replace('NaoAutorizada', 'Nao Autorizada'),
            extension: extensionSelect
        };

        // Fazer a requisição usando o axios
        axios({
            url: `http://${process.env.REACT_APP_MONITOR_LINK}/export`,
            params: requestData,
            method: 'GET',
            responseType: 'blob', // Important: This tells Axios to treat the response as binary data
        }).then(response => {
            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            console.log(type + '-' + period + '.' + extensionSelect)
            link.download = type + '-' + period + '.' + extensionSelect; // You can customize the downloaded file name
            link.click();


            setDownloadedText("Download efetuado com sucesso!");
            setShowCloseButton(false);
            setBackdrop(true)
        });
    };

    const handleModalHide = () => {
        // Define todos os estados de volta aos valores iniciais
        setLgShow(false);
        setLgShow2(false);
        setShowCloseButton(true);
        setBackdrop("static")
        setDownloadedText(false)
    };

    return (
        <>
            <span className="buttons">
                <span className="btn-csv">
                    <Popup
                        title={title}
                        iconSpan={iconSpan}
                        buttonText={button2}
                        lgShow={lgShow2}
                        setLgShow={setLgShow2}
                        backdrop={backdrop}
                        hideCloseButton={showCloseButton}
                    >
                        {downloadedText ? ( // Verifica se há texto de download efetuado
                            <DefaultSuccessMessage message={downloadedText}/>
                        ) : (
                            <BasicLoader
                                message="O relatório está sendo gerado, isso pode demorar um pouco..."
                            />
                        )}
                    </Popup>
                </span>
                <span className="btn-xlsx">

                    <Popup
                        title={title}
                        iconSpan={iconSpan}
                        buttonText={button}
                        lgShow={lgShow}
                        setLgShow={setLgShow}
                        backdrop={backdrop}
                        hideCloseButton={showCloseButton}
                        buttonClass={'btn-success'}
                    >
                        {downloadedText ? ( // Verifica se há texto de download efetuado
                            <DefaultSuccessMessage message={downloadedText}/>
                        ) : (
                            <BasicLoader
                                message="O relatório está sendo gerado, isso pode demorar um pouco..."
                            />
                        )}
                    </Popup>
                </span>
            </span>
        </>
    );
};

export default PopupMonitoringDetails;
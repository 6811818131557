import React, {useEffect, useState} from "react";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import TextField from "@mui/material/TextField";
import {Form} from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";
import {useAuthDispatchContext, USER_FETCHED} from "components/AuthenticationProvider/AuthenticationProvider";
import axios from "axios";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import GoogleRecaptcha from "../../../recaptcha/GoogleRecaptcha";
import "./sign-in.css"

//Images
import botImage from "../../../assets/images/BotiAdministrativo.jpg";
import {InputAdornment} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import TokenVerificationForm from "./Components/TokenVerificationForm";
import Alert from "react-bootstrap/Alert";

function Basic() {
    const [rememberMe, setRememberMe] = useState(false);
    const [user, setUser] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(false);
    const [helperText, setHelperText] = useState("");
    const [loginData, setLoginData] = useState(false);
    const [isInSecondStep, setIsInSecondStep] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [captcha, setCaptcha] = useState(false);

    const [showPassword, setShowPassword] = useState(false);

    const handleSetRememberMe = () => setRememberMe(!rememberMe);

    const navigate = useNavigate();

    const authDispatch = useAuthDispatchContext();
    const authContext = useAuthDispatchContext();
    const [counter, setCounter] = useState(30); // Inicializa o contador em 30 segundos
    const [isLinkDisabled, setIsLinkDisabled] = useState(true); // Inicializa o estado do link como desativado

    useEffect(() => {
        if (counter === 0) {
            setIsLinkDisabled(false);
            return;
        }

        const timerId = setInterval(() => {
            setCounter((prevCounter) => prevCounter - 1);
        }, 1000);

        return () => clearInterval(timerId);
    }, [counter]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setCounter(30); // Reinicia o contador para 30 segundos
        setIsLinkDisabled(true); // Desativa o link novamente
        setIsLoading(true);

        if (rememberMe) {
            setError(false);
            setHelperText("");
            setIsLoading(false);
        } else {
            axios
                .post(`http://${process.env.REACT_APP_CARD_PROCESS_SECURITY_LOGIN}/`, {
                    user,
                    password,
                }, {
                    headers: {
                        'Authorization': null
                    }
                })
                .then(function (response) {
                    if(response.data.userMessage == "Usuário bloqueado! Contate um administrador"){
                        setError(true);
                        setHelperText("Usuário bloqueado! Contate um administrador");

                    } else if(response.data.userMessage == "Usuário bloqueado por inatividade! Contate seu gestor para efetuar o desbloqueio"){
                        setError(true);
                        setHelperText("Usuário bloqueado por inatividade! Contate seu gestor para efetuar o desbloqueio");

                    } else if(response.data.userMessage == "Senha expirada! Confirme sua caixa de entrada do email para efetuar a troca de senha."){
                        setError(true);
                        setHelperText("Senha expirada! Verifique sua caixa de entrada do email para efetuar a troca de senha.");
                    } else if(response.data.userMessage == "Usuário Inativo! Contate um administrador."){
                        setError(true);
                        setHelperText("Usuário Inativo! Contate um administrador.");
                    }
                    else if (response.data.goto == "PAGE_INPUT_CODE"){
                        setIsInSecondStep(true)
                    } else{
                        authDispatch({
                            type: USER_FETCHED,
                            payload: response.data.access_token,
                        });
                        //   console.log("Login:");
                        //   console.log(authContext);
                        navigate("/dashboard");
                    }

                })
                .catch(function (error) {
                    console.log(error);
                    setError(true);
                    setHelperText("Usuário e/ou senha inválido(s)!");
                }).finally(() => {
                    setIsLoading(false);
                })
        }
    };

    const verifyTwoStepsCode = async (event, token) => {
        event.preventDefault(); // Previne o comportamento padrão do formulário

        try {
            const response = await axios.post(`http://${process.env.REACT_APP_CARD_PROCESS_SECURITY_LINK}/twoStepVerify`, {
                user: user,
                code: token
            })
            .then(function (response) {
                    authDispatch({
                        type: USER_FETCHED,
                        payload: response.data.access_token,
                    });
                    //   console.log("Login:");
                    //   console.log(authContext);
                    navigate("/dashboard");
            })

            console.log('Requisição bem-sucedida:', response.data);
        } catch (error) {
            setError(
                <Alert key='danger' variant='danger'>
                    Token incorreto!
                </Alert>
            )
        }
    }

    const button = isLoading ? (
        <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{mt: 1, mb: 2, backgroundColor: "secondary"}}
            disabled
        >
            Aguarde...
        </Button>
    ) : (
        <MDButton variant="gradient" color="success" fullWidth mb={-10}

                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{mt: 1, mb: 2, color: "#FFF", backgroundColor: "primary"}}
                  disabled={!captcha}
        >
            Entrar
        </MDButton>
    );

    return ( !isInSecondStep ? (
        <CoverLayout image={botImage}>
            <Card>
                <MDBox
                    variant="gradient"
                    bgColor="success"
                    borderRadius="lg"
                    coloredShadow="success"
                    mx={2}
                    mt={-3}
                    p={3}
                    mb={1}
                    textAlign="center"
                >
                    <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                        Área administrativa
                    </MDTypography>
                    <MDTypography display="block" variant="button" color="white" my={1}>
                        Acesse o portal administrativo!
                    </MDTypography>
                </MDBox>
                <MDBox pt={4} pb={3} px={3}>
                    <Form component="form" role="form" onSubmit={handleSubmit}>
                        <MDBox mb={2}>
                            <TextField
                                label="Usuário"
                                inputProps={{
                                    maxLength: 80,
                                }}
                                required={true}
                                erortext="Campo obrigatório!"
                                value={user}
                                fullWidth
                                onChange={(e) => setUser(e.target.value)}
                            />
                        </MDBox>
                        <MDBox mb={2}>
                            <TextField
                                label="Senha"
                                inputProps={{
                                    maxLength: 80,
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => setShowPassword(!showPassword)}
                                            >
                                                {showPassword ? <VisibilityOff/> : <Visibility/>}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                type={showPassword ? "text" : "password"}
                                required={true}
                                erortext="Campo obrigatório!"
                                value={password}
                                fullWidth
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </MDBox>
                        {/*<MDBox display="flex" alignItems="center" ml={-1}>*/}
                        {/*    <Switch checked={rememberMe} onChange={handleSetRememberMe}/>*/}
                        {/*    <MDTypography*/}
                        {/*        variant="button"*/}
                        {/*        fontWeight="regular"*/}
                        {/*        color="text"*/}
                        {/*        onClick={handleSetRememberMe}*/}
                        {/*        sx={{cursor: "pointer", userSelect: "none", ml: -1}}*/}
                        {/*    >*/}
                        {/*        &nbsp;&nbsp;Mantenha-me conectado(a)*/}
                        {/*    </MDTypography>*/}
                        {/*</MDBox>*/}

                        <Form.Control
                            hidden
                            isInvalid={error} // Define a propriedade isInvalid
                        />

                        <Form.Control.Feedback type="invalid">
                            {helperText}
                        </Form.Control.Feedback>

                        <GoogleRecaptcha captcha={captcha} setCaptcha={setCaptcha}/>
                        {button}
                        <MDBox mt={1} mb={1} textAlign="center">
                            <div className="text-center mb-1" style={{fontSize:"1rem"}}>
                                <Link to='/forget-password' style={{color:"green"}}>
                                    <p style={{textDecoration:"underline"}}>Esqueceu a senha?</p>
                                </Link>
                            </div>

                        </MDBox>
                    </Form>
                </MDBox>
            </Card>
        </CoverLayout>
            ) :
            <TokenVerificationForm
                error={error}
                isLoading={isLoading}
                handleSubmit={verifyTwoStepsCode}
                isLinkDisabled={isLinkDisabled}
                counter={counter}
                handleResendCode={handleSubmit}
            />
    );
}

export default Basic;

import { Table } from "@mui/material";
import { useAuthContext } from "components/AuthenticationProvider/AuthenticationProvider";
import Popup from "components/Popup/Popup";
import { useEffect, useState } from "react";
import VisibilityIcon from '@mui/icons-material/Visibility';
import axios from "axios";
import { NumberWithPoints } from "utils/numberWithPoints";

export default function DetailInvoice({item}) {

    const [items, setItems] = useState([]);
    const [lgShow, setLgShow] = useState(false);

    const button = <>
        Detalhes <VisibilityIcon fontSize="medium"/>
    </>

    const title = "Detalhes da Fatura"

    const iconSpan = <>
        <VisibilityIcon fontSize="large" id="icon-svg"/>
        {title}
    </>

    const authContext = useAuthContext();

    useEffect(() => {

        const accessToken = authContext.accessToken;
        axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
        const baseUrl = `http://${process.env.REACT_APP_FINANCIAL_MANAGER_LINK}/invoices/items/${item.id}`;

        axios.get(baseUrl)
            .then(function (response) {
                setItems(response.data.collection)
            }).catch(function (errorReceived) {
                console.log(errorReceived);
            })

    }, []);


    return (
        <Popup
            buttonText={button}
            title={title}
            iconSpan={iconSpan}
            lgShow={lgShow} 
            setLgShow={setLgShow}
            buttonId={"links-action"}
        >
            <Table>
                <thead>
                    <tr>
                        <th>Data</th>
                        <th>Descrição</th>
                        <th>Valor</th>
                    </tr>
                </thead>
                <tbody>
                    {items?.map((item) =>
                        <tr key={item.id}>
                            <td>{item.date}</td>
                            <td>{item.description}</td>
                            <td>R$ {NumberWithPoints(item.value)}</td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </Popup>
    );
}
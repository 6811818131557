import TextField from "@mui/material/TextField";
import React, {useEffect, useState} from "react";

const InputsRegional = ({
                            name, setName,
                            code, setCode,
                            errorMessage, errorColumn
                        }) => {

    const [errorName, setErrorName] = useState(false)
    const [errorNameText, setErrorNameText] = useState(false)
    const [errorCode, setErrorCode] = useState(false)
    const [errorCodeText, setErrorCodeText] = useState(false)


    useEffect(() => {
        setErrorName(false)
        setErrorNameText(false)
        setErrorCode(false)
        setErrorCodeText(false)
        switch (errorColumn) {
            case "name":
                setErrorName(true)
                setErrorNameText(errorMessage)
                break
            case "code":
                setErrorCode(true)
                setErrorCodeText(errorMessage)
                break
        }
    }, [errorColumn, errorMessage])

    return (
        <>
            <TextField
                id="input-with-icon-textfield"
                label="Nome da Regional"
                inputProps={{
                    maxLength: 20
                }}
                required={true}
                size="large"
                erortext="Campo obrigatório!"
                value={name}
                onChange={(e) => setName(e.target.value.toUpperCase())}
                error={errorName}
                helperText={errorNameText}
                type="text"
                fullWidth
                autoFocus
            />

            <TextField
                label="Código da Regional"
                required={true}
                inputProps={{
                    maxLength: 20
                }}
                type="text"
                erortext="Campo obrigatório!"
                value={code}
                onChange={(e) => setCode(e.target.value.toUpperCase())}
                error={errorCode}
                helperText={errorCodeText}
                fullWidth
            />
        </>
    )
}

export default InputsRegional
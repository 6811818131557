import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import SelectInput from "../../../../components/SelectInput";
import {AutoCompleteInput} from "../../../../components/FilteredSelectInput";
import React, {useEffect, useState} from "react";
import {InputLabelProps} from "../../../../utils/InputLabelProps";


export default function ResgatePorGrupoFilter({filter, setFilter}) {

    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const [groupId, setGroupId] = useState("");
    const [groupList, setGroupList] = useState([]);

    const globalProcUrl = `http://${process.env.REACT_APP_GLOBAL_PROCESS_LINK}`

    useEffect(() => {
        setFilter({
            startDate,
            endDate,
            groupId
        })
    }, [startDate, endDate, groupId])


    return <>
        <MDBox mt={2}>
            <MDTypography variant="h6">Período</MDTypography>
            <Stack
                direction="row"
                spacing={2}
                mt={1}
                sx={{display: "flex", justifyContent: "center"}}
            >
                <TextField
                    label="Início"
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    InputLabelProps={{
                        ...InputLabelProps,
                        shrink: true,
                        size: "small",
                    }}
                    sx={{width: 180}}
                    required
                />
                <TextField
                    label="Fim"
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    InputLabelProps={{
                        ...InputLabelProps,
                        shrink: true,
                        size: "small",
                    }}
                    sx={{width: 180}}
                    required
                />
            </Stack>
        </MDBox>

        <div className="mb-3">
            <SelectInput
                url={`${globalProcUrl}/FranchiseGroup?size=200000000`}
                placeholder={"Grupo *"}
                list={groupList}
                set={setGroupList}
                setObj={setGroupId}
                returnList={() => {
                    return groupList.map((item) => ({
                        value: item.id,
                        label: `${item.code} - ${item.name}`,
                    }));
                }}
                required={true}
                fullWidth
            />
        </div>

    </>
}
import React, {useState} from "react";
import MainNavbar from "../../../components/MainNavbar";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import {Paper} from "@mui/material";
import PaginationControl from "../../../components/Pagination/PaginationControl";
import Footer from "../../../examples/Footer";
import ListCardRevalidationHistory from "./components/ListCardRevalidationHistory";


export default function CardRevalidationHistory() {
    const baseUrl = `http://${process.env.REACT_APP_CARD_MANAGER_LINK}/CardRevalidation`
    const [loadData, setLoadData] = useState(true)

    const [size, setSize] = useState(10)
    const [page, setPage] = useState(0)
    const [sort, setSort] = useState("")

    const [totalPages, setTotalPages] = useState(0)
    const [totalElements, setTotalElements] = useState(0)

    return (
        <>
            <MainNavbar/>
            <DashboardLayout>
                <Paper className="paper-list mb-3">
                    <h1>Histórico de Revalidação em lote</h1>

                    <ListCardRevalidationHistory
                        baseUrl={baseUrl}
                        loadData={loadData}
                        setLoadData={setLoadData}
                        page={page}
                        setPage={setPage}
                        size={size}
                        setTotalPages={setTotalPages}
                        setTotalElements={setTotalElements}
                        sort={sort} setSort={setSort}
                    />

                    <PaginationControl
                        totalElements={totalElements}
                        page={page}
                        setPage={setPage}
                        size={size}
                        setSize={setSize}
                        setLoadData={setLoadData}
                    />
                </Paper>
            </DashboardLayout>
            <Footer/>
        </>
    );
}
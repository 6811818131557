import TextField from "@mui/material/TextField";
import React, {useState} from "react";
import SelectInput from "../../../components/SelectInput";
import {AutoCompleteInput} from "../../../components/FilteredSelectInput";

export default function InputsBalanceTransfer({
                                                  value, setValue,
                                                  cardNumber, balance,
                                                  logicCode, setLogicCode
}) {

    const [storeList, setStoreList] = useState([])
    const [internalCode, setInternalCode] = useState('')

    const handleNumberInput = (e) => {
        const inputValue = e.target.value;

        if (/^[0-9]*$/.test(inputValue)) {
            setValue(inputValue);
        }
    };

    return (
        <>
            <p className={'text-danger'}> Lembre-se de verificar o cartão de origem e o valor* </p>
            <TextField
                label="Numero do cartão de origem (De onde o saldo sairá)"
                value={cardNumber}
                disabled
                fullWidth
            />
            <TextField
                label="Valor da transferência"
                value={balance}
                disabled
                fullWidth
            />
            <TextField
                label="Numero do cartão de destino"
                required={true}
                inputProps={{
                    min: 1,
                    maxLength: 19
                }}
                erortext="Campo obrigatório!"
                value={value}
                onChange={handleNumberInput}
                fullWidth
            />
            <AutoCompleteInput
                sizeToSearch={3}
                filter={`internalCode=${internalCode}`}
                value={internalCode}
                setValue={setInternalCode}
                firstInputLabel={'Digite 3 dígitos do código interno da loja e uma lista de lojas aparecerá.'}
                secondInputLabel={'Selecione a loja:'}
                url={`http://${process.env.REACT_APP_GLOBAL_PROCESS_LINK}/Store?size=200000000&status=ATIVO`}
                placeholder={"lojas disponíveis para transferência"}
                list={storeList}
                set={setStoreList}
                setObj={setLogicCode}
                defaultValue={logicCode}
                required={true}
                fullWidth
                getLabel={(item) => {
                    return `${item.internalCode} - ${item.corporateName}`
                }}
                getValueForList={(item) => {
                    return item.logicCode
                }}
            />
        </>
    )
}
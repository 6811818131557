import React, {useEffect, useState} from "react";
import "./PopupEditBusinessUnit.css"
import InputsBusinessUnit from "./InputsBusinessUnit";
import PopupEdit from "../../../components/PopupEdit/PopupEdit";
import {formatFloat} from "../../../utils/formatFloat";
import {NumberWithPoints} from "../../../utils/numberWithPoints";

const PopupEditBusinessUnit = ({item, baseUrl, setLoadData, updateCode}) => {

    const [name, setName] = useState("")
    const [minChargeValue, setMinChargeValue] = useState("")
    const [maxChargeValue, setMaxChargeValue] = useState("")
    const [chargeMultiplicity, setChargeMultiplicity] = useState("")
    const [chargeValidity, setChargeValidity] = useState("")
    const [range, setRange] = useState("")
    const [errorMessage, setErrorMessage] = useState("")
    const [errorColumn, setErrorColumn] = useState("")
    const [loadFee, setLoadFee] = useState("")
    const [redeemFee, setRedeemFee] = useState("")
    const [administrationFee, setAdministrationFee] = useState("")
    const [status, setStatus] = useState("")

    //habilita/desabilita o input de taxas
    const [disabledFees, setDisabledFees] = useState()

    useEffect(() => {
        if(item.status === "BLOQUEADO"){
            setDisabledFees(true)
        }
    }, [])

    const itemToState = () => {
        const stringValueMin = String(item.minChargeValue);
        const stringValueMax = String(item.maxChargeValue);
        setName(item.name)
        setMinChargeValue(stringValueMin.replace('.', ','));
        setMaxChargeValue(stringValueMax.replace('.', ','));
        setChargeMultiplicity(item.chargeMultiplicity)
        setChargeValidity(item.chargeValidity)
        setRange(item.range)
        setLoadFee(item.loadFee)
        setRedeemFee(item.redeemFee)
        setAdministrationFee(item.administrationFee)
        setStatus(item.status)
    }

    const inputs = <InputsBusinessUnit
        name={name} setName={setName}
        minChargeValue={minChargeValue} setMinChargeValue={setMinChargeValue}
        maxChargeValue={maxChargeValue} setMaxChargeValue={setMaxChargeValue}
        chargeMultiplicity={chargeMultiplicity}
        setChargeMultiplicity={setChargeMultiplicity}
        chargeValidity={chargeValidity} setChargeValidity={setChargeValidity}
        range={range} setRange={setRange}
        errorMessage={errorMessage} setErrorMessage={setErrorMessage}
        errorColumn={errorColumn} setErrorColumn={setErrorColumn}
        loadFee={loadFee} setLoadFee={setLoadFee}
        redeemFee={redeemFee} setRedeemFee={setRedeemFee}
        administrationFee={administrationFee} setAdministrationFee={setAdministrationFee}
        status={status} setStatus={setStatus}
        disabledFees={disabledFees}
    />



    const title = "Editar Unidade de negócio"


    const returnData = () => {

        console.log()
        return {
            id: item.id,
            name,
            minChargeValue : formatFloat(minChargeValue),
            maxChargeValue : formatFloat(maxChargeValue),
            chargeMultiplicity,
            chargeValidity,
            range,
            loadFee,
            redeemFee,
            administrationFee,
            status
        }
    }

    const validateData = () => {

        setErrorMessage("")

        const maxChargeValueInt = parseInt(maxChargeValue)
        const minChargeValueInt = parseInt(minChargeValue)
        const chargeMultiplicityInt = parseInt(chargeMultiplicity)

        if (maxChargeValueInt < minChargeValueInt){
            setErrorMessage("A carga máxima deve ser maior ou igual à carga mínima!")
            return false
        }

        if (maxChargeValueInt % chargeMultiplicityInt !== 0) {
            setErrorMessage("A carga máxima deve ser múltipla da multiplicidade!")
            return false
        }

        if (minChargeValueInt % chargeMultiplicityInt !== 0) {
            setErrorMessage("A carga mínima deve ser múltipla da multiplicidade!")
            return false
        }

        return true
    }

    return (
        <>
            <PopupEdit
                baseUrl={baseUrl} setLoadData={setLoadData}
                inputs={inputs} title={title}
                returnData={returnData} itemToState={itemToState}
                setErrorMessage={setErrorMessage} setErrorColumn={setErrorColumn}
                errorMessage={errorMessage} validateData={validateData}
            />
        </>
    )
}

export default PopupEditBusinessUnit
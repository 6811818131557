import React, { useState } from "react";
import FilterForm from "../../../../components/FilterForm/FilterForm";
import TextField from "@mui/material/TextField";
import SelectInput from "../../../../components/SelectInput";
import MDBox from "../../../../components/MDBox";
import { FormControlLabel, FormLabel, Radio, RadioGroup, Button, InputAdornment } from "@mui/material";
import DisplayStatus from "../../../../components/Status/DisplayStatus";
import ClearIcon from "@mui/icons-material/Clear";

const FilterCardRevalidation = ({ filter, setFilter, setLoadData, setPage, baseUrl }) => {

    const [lgShow, setLgShow] = useState(false);

    const [cardNumber, setCardNumber] = useState("");
    const [fileId, setFileId] = useState('');
    const [fileList, setFileList] = useState([]);
    const [status, setStatus] = useState("");

    const handlesSubmit = (e) => {
        if (e) {
            e.preventDefault();
        }

        const filterData = {};

        if (cardNumber !== "") {
            filterData.cardNumber = cardNumber;
        }
        if (fileId > 0) {
            filterData.fileId = fileId;
        }
        if (status !== "") {
            filterData.status = status;
        }

        setFilter(filterData);

        setLgShow(false);
        setLoadData(true);
        setPage(0);
    };

    const clearFilter = () => {
        setCardNumber("");
        setFileId('');
        setStatus("");

        setFilter({});
        setLgShow(false);
        setLoadData(true);
    };

    const clearAll = () => {
        setCardNumber("");
        setFileId('');
        setStatus("");

        setFilter({});
    };

    return (
        <FilterForm
            handleSubmit={handlesSubmit}
            clearFilter={clearFilter}
            clearAll={clearAll}
            lgShow={lgShow}
            setLgShow={setLgShow}
            showSecondaryButton={Object.keys(filter).length !== 0}
        >
            <TextField
                label="Número do Cartão"
                inputProps={{
                    maxLength: 19,  // Define o número máximo de dígitos para um número de cartão de crédito comum
                }}
                value={cardNumber}
                onChange={(e) => {
                    const inputNumber = e.target.value.replace(/\D/g, ''); // Remove todos os caracteres que não são dígitos
                    setCardNumber(inputNumber);
                }}
                fullWidth
                autoFocus
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {cardNumber && (
                                <Button size="small" onClick={() => setCardNumber("")}>
                                    <ClearIcon />
                                </Button>
                            )}
                        </InputAdornment>
                    ),
                }}
            />
            <SelectInput
                url={`${baseUrl}/files?size=200000000&status=PROCESSADO`}
                placeholder={"Arquivo"}
                list={fileList}
                set={setFileList}
                setObj={setFileId}
                defaultValue={fileId}
                returnList={() => {
                    let returnList = [];
                    returnList.push({ value: '', label: "Todos" });
                    fileList.forEach((item) => {
                        returnList.push({ value: item.id, label: item.fileName });
                    });
                    return returnList;
                }}
                fullWidth
            />

            <MDBox mb={0.5} pb={0.5}>
                <FormLabel id="demo-row-radio-buttons-group-label" className="text-center">
                    Status
                </FormLabel>
                <RadioGroup
                    row
                    name="row-radio-buttons-group"
                    id="radios-status"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                >
                    <FormControlLabel value="" control={<Radio />} label={<span>Todos</span>} />
                    <FormControlLabel value="EFETUADA" control={<Radio />} label={<DisplayStatus status="EFETUADA" />} />
                    <FormControlLabel value="PENDENTE" control={<Radio />} label={<DisplayStatus status="PENDENTE" />} />
                    <FormControlLabel value="REJEITADA" control={<Radio />} label={<DisplayStatus status="REJEITADA" />} />
                    <FormControlLabel value="ERRO" control={<Radio />} label={<DisplayStatus status="ERRO" />} />
                </RadioGroup>
            </MDBox>
        </FilterForm>
    );
};

export default FilterCardRevalidation;


export function formatCode(code, setCode, length, maxLength)  {
    let codeToSend = parseInt(code)+""

    if (codeToSend.length > maxLength) {
        codeToSend = codeToSend.substring(0, maxLength);
    }

    while (codeToSend.length < length) {
        codeToSend = '0' + codeToSend;
    }

    setCode(codeToSend)
}
import React, {useState} from "react";
import MainNavbar from "../../components/MainNavbar";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import {Paper} from "@mui/material";
import Footer from "../../examples/Footer";
import PopupCheck from "./components/PopupCheck";

const BalanceCheck = () => {
    const baseUrl = `http://${process.env.REACT_APP_CARD_MANAGER_LINK}/Card`
    const [loadData, setLoadData] = useState(true)

    return (
        <>
            <MainNavbar/>
            <DashboardLayout>
                <Paper className="paper-list mb-3">
                    <h1>Consulta de Saldo/Extrato</h1>
                    <PopupCheck
                        setLoadData={setLoadData}
                        buttonClass='primary'
                    />
                </Paper>
            </DashboardLayout>
            <Footer/>
        </>
    );
}
export default BalanceCheck
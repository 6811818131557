export default function verifyPassword(senha) {
    // Verifica o comprimento mínimo
    if (senha.length < 14) {
        return "A senha deve ter pelo menos 14 caracteres.";
    }

    // Verifica se contém pelo menos uma letra maiúscula
    if (!/[A-Z]/.test(senha)) {
        return "A senha deve conter pelo menos uma letra maiúscula.";
    }

    // Verifica se contém pelo menos uma letra minúscula
    if (!/[a-z]/.test(senha)) {
        return "A senha deve conter pelo menos uma letra minúscula.";
    }

    // Verifica se contém pelo menos um número
    if (!/[0-9]/.test(senha)) {
        return "A senha deve conter pelo menos um número.";
    }

    // Verifica se contém pelo menos um caractere especial
    if (!/[^A-Za-z0-9]/.test(senha)) {
        return "A senha deve conter pelo menos um caractere especial.";
    }

    // Se a senha atender a todos os critérios
    return "Senha válida";
}

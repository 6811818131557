import React from "react";
import AddIcon from "@mui/icons-material/Add";
import Popup from "../../../components/Popup/Popup";
import {Button, Form} from "react-bootstrap";
import "../../../components/Popup/Popup.css";

const PopupAddNameTrade = ({
                               lgShow,
                               setLgShow,
                               inputs,
                               title,
                               icon,
                               button,
                               errorMessage,
                               handleSubmit,
                               isLoading
                           }) => {

    const iconSpan = (
        <>
            {icon}
            <h3>{title}</h3>
        </>
    );

    const buttonText = button ? button : (
        <>
            Solicitar Relatório
            <AddIcon fontSize="medium"/>
        </>
    );

    return <Popup
        buttonText={buttonText}
        title={title}
        iconSpan={iconSpan}
        lgShow={lgShow}
        setLgShow={setLgShow}
        buttonClass={"button-show"}
    >
        <div>
            <Form onSubmit={handleSubmit} className="form-inputs">
                {inputs}

                <span style={{fontSize: '0.8em', color: 'red', marginBottom: '8px', display: 'block'}}>
                            {errorMessage ? errorMessage : ''}
                        </span>
                <Button type="submit" disabled={isLoading}>
                    {isLoading ? (
                        <>
                                    <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                            Aguarde...
                        </>
                    ) : (
                        buttonText
                    )}
                </Button>
            </Form>
        </div>
    </Popup>
};

export default PopupAddNameTrade;

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import KeyIcon from '@mui/icons-material/Key';
import React, {useEffect, useState} from "react";
import ContentContainer from "../../../../components/ContentContainer";
import botImage from "../../../../assets/images/BotiAdministrativo.jpg";
import Card from "@mui/material/Card";
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import {Form} from "react-bootstrap";
import {InputAdornment} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import GoogleRecaptcha from "../../../../recaptcha/GoogleRecaptcha";
import CoverLayout from "../../components/CoverLayout";
import MDButton from "../../../../components/MDButton";
import {Link} from "react-router-dom";



export default function TokenVerificationForm({error, isLoading, handleSubmit, isLinkDisabled, counter, handleResendCode}) {

    const [token, setToken] = useState('');
    const [helperText, setHelperText] = useState("");

    const handleTokenChange = (event) => {
        const inputToken = event.target.value.replace(/[^0-9]/g, '').slice(0, 6); // Aceita apenas números e limita a 6 dígitos
        setToken(inputToken);
    };


    const zeroPadding = '000000';


    const button = isLoading ?
        <Button
            type="submit"
            size="medium"
            variant="contained"
            sx={{mt: 1, mb: 2,}}
            disabled
        >
            Aguarde ...
        </Button> :
        <MDButton variant="gradient" color="success" fullWidth mb={-10}

                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{mt: 1, mb: 2, color: "#FFF", backgroundColor: "primary"}}
        >
            Confirmar código
        </MDButton>

    return (<CoverLayout image={botImage}>
            <Card>
                <MDBox
                    variant="gradient"
                    bgColor="success"
                    borderRadius="lg"
                    coloredShadow="success"
                    mx={2}
                    mt={-3}
                    p={3}
                    mb={1}
                    textAlign={"center"}
                    alignItems={"center"}
                    justifyContent={"center"}
                >
                    <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                        Verificação em duas etapas
                        <br/>
                        <KeyIcon fontSize='medium' sx={{fontSize: '3rem'}}/><br/>
                    </MDTypography>
                </MDBox>
                <MDBox pt={4} pb={3} px={3}>
                    <Form component="form" role="form" onSubmit={(event) => handleSubmit(event, token)}>
                        <MDTypography display="block" variant="button" color="black" my={1}
                                      textAlign={"center"} alignItems={"center"} justifyContent={"center"}
                                      marginTop={"-20px"}
                        >
                            Por favor, insira o código de verificação de duas etapas
                            para concluir o processo de login. Este código foi enviado
                            para o seu endereço de e-mail.
                        </MDTypography>
                        <MDBox mb={2} textAlign={"center"} alignItems={"center"} justifyContent={"center"}>
                            <TextField
                                label=""
                                variant='standard'
                                id='token-2-steps'
                                fullWidth
                                value={token}
                                onChange={handleTokenChange}
                                InputProps={{
                                    startAdornment: (
                                        <span onClick={() => {
                                            document.getElementById('token-2-steps').focus()
                                        }} style={{
                                            color: 'gray', fontSize: '1.4rem',
                                            textAlign: 'center',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            marginLeft: '50px'
                                        }}>
                                                    {zeroPadding.slice(0, 6 - token.length)}
                                                </span>
                                    ),
                                    inputProps: {
                                        style: {
                                            fontSize: '1.4rem',
                                            textAlign: 'center',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            marginRight: '45px'
                                        }
                                    }

                                }}
                                sx={{
                                    width: '11rem',
                                    marginTop: '1rem',
                                }}
                            />
                        </MDBox>
                        <Form.Control
                            hidden
                            isInvalid={error} // Define a propriedade isInvalid
                        />

                        {button}

                        <MDTypography style={{textAlign: 'center', justifyContent: 'center', alignItems: 'center' ,fontSize:"1rem"}}>
                            Seu código não chegou? Espere {counter} segundos para reenviar:{' '}
                                <Link
                                    to='#'
                                    onClick={handleResendCode}
                                    style={{
                                        pointerEvents: isLinkDisabled ? 'none' : 'auto',
                                        color: isLinkDisabled ? 'gray' : 'green',
                                        textDecoration: isLinkDisabled ? 'none' : 'underline'
                                    }}
                                >
                                    Reenviar
                                </Link>
                        </MDTypography>
                    </Form>
                </MDBox>
            </Card>
        </CoverLayout>
    );
}
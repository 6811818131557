import TextField from "@mui/material/TextField";
import SelectInput from "../../../../components/SelectInput";
import React, {useState} from "react";

export default function BankDetailsInputs({
                                              baseUrl,
                                              agency, setAgency,
                                              account, setAccount,
                                              bankCode, setBankCode,
                                              InputLabelProps,
                                              disabled
                                              // naoObrigatorio
}){

    const [bankList, setBankList] = useState([])

    return <>
        <TextField
            label="Agência"
            required={true}
            inputProps={{
                maxLength: 20
            }}
            type="text"
            erortext="Campo obrigatório!"
            value={agency}
            onChange={(e) => setAgency(e.target.value.replace(/\D/, ''))}
            InputLabelProps={InputLabelProps}
            fullWidth
            disabled={disabled}
        />
        <TextField
            label="Conta"
            required={true}
            inputProps={{
                maxLength: 10
            }}
            type="text"
            erortext="Campo obrigatório!"
            value={account}
            onChange={(e) => setAccount(e.target.value.replace(/\D/, ''))}
            InputLabelProps={InputLabelProps}
            fullWidth
            disabled={disabled}
        />
        <SelectInput
            url={`${baseUrl}/Bank?size=200000000`}
            title={'Banco'}
            list={bankList}
            set={setBankList}
            setObj={setBankCode}
            returnList={() => {
                let returnList = []
                bankList.forEach((item) => {
                    returnList.push({value: item.code, label: item.name})
                })
                return returnList
            }}
            placeholder={"Banco *"}
            defaultValue={bankCode}
            required={true}
            InputLabelProps={InputLabelProps}
            fullWidth
            disabled={disabled}
        />
    </>
}
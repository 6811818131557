import React, { useEffect, useState } from "react";
import { Dropdown } from 'react-bootstrap';
import List from "@mui/material/List";
import AbcIcon from '@mui/icons-material/Abc';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Button } from "@mui/material";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory';
import BadgeIcon from '@mui/icons-material/Badge';
import KeyIcon from '@mui/icons-material/Key';
import DateRangeIcon from '@mui/icons-material/DateRange';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ShareLocationIcon from '@mui/icons-material/ShareLocation';
import ExploreIcon from '@mui/icons-material/Explore';
import Looks3Icon from '@mui/icons-material/Looks3';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { cnpjMask } from "../../../utils/cnpjMask";
import { normalizeTimestemp } from "../../../utils/normalizeTimestemp";
import { phoneMask } from "../../../utils/phoneMask";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import LockIcon from "@mui/icons-material/Lock";
import DisplayStatus from "../../../components/Status/DisplayStatus";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import DisplayDetailsData from "../../../components/DisplayDetailsData/DisplayDetailsData";
import Popup from "../../../components/Popup/Popup";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";

const StoreDetails = ({ item, buttonText }) => {
    const [lgShow, setLgShow] = useState(false);
    const [displayStatus, setDisplayStatus] = useState("");

    const title = "Detalhes da loja";

    useEffect(() => {
        switch (item.status) {
            case "ATIVO":
                setDisplayStatus(<CheckCircleOutlineIcon fontSize="medium" />);
                break;
            case "INATIVO":
                setDisplayStatus(<HighlightOffIcon fontSize="medium" />);
                break;
            case "BLOQUEADO":
                setDisplayStatus(<LockIcon fontSize="medium" />);
                break;
            default:
                setDisplayStatus(null);
        }
    }, [item.status]);

    const displayCity = item.city
        ? `${item.city} - ${item.uf}`
        : 'Não Cadastrada*';

    const displayNullableData = (data) => {
        return data
            ? `${data}`
            : 'Não Cadastrado*';
    };

    const button = (
        <>
            <Dropdown.Item as="button" onClick={() => setLgShow(true)}>
                <VisibilityIcon className="me-2" /> Detalhes
            </Dropdown.Item>
        </>
    );

    return (
        <>
            {/*<Dropdown.Item as="button" onClick={() => setLgShow(true)}>*/}
            {/*    <VisibilityIcon fontSize="medium" /> Detalhes*/}
            {/*</Dropdown.Item>*/}

            <Popup
                buttonText={buttonText ? buttonText : button}
                title={title}
                iconSpan={<VisibilityIcon fontSize="large" id="icon-svg" />}
                lgShow={lgShow} setLgShow={setLgShow}
                buttonId={"links-action"}
            >
                <List
                    sx={{ width: '60%', bgcolor: 'background.paper', marginInline: '20%' }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                >
                    {/* Primary Data */}
                    <DisplayDetailsData
                        key="data"
                        title='Dados primários'
                        openDefault
                        itens={[
                            {
                                icon: <BadgeIcon />,
                                display: `Razão Social:  ${item.corporateName}`
                            },
                            {
                                icon: <BadgeIcon />,
                                display: `Nome Fantasia: ${displayNullableData(item.tradingName) || "Não cadastrado"}`
                            },
                            {
                                icon: <KeyIcon />,
                                display: `CNPJ:  ${item.cnpj ? cnpjMask(item.cnpj) : "Não cadastrado"}`
                            },
                            {
                                icon: <DateRangeIcon />,
                                display: `Criado em:  ${item.dateCreated ? normalizeTimestemp(item.dateCreated) : "Não cadastrado"}`
                            },
                            {
                                icon: <DateRangeIcon />,
                                display: `Alterado em:  ${item.lastUpdated ? normalizeTimestemp(item.lastUpdated) : "Não cadastrado"}`
                            },
                            {
                                icon: <KeyIcon />,
                                display: `Código interno:  ${item.internalCode || "Não cadastrado"}`
                            },
                            {
                                icon: <StoreMallDirectoryIcon />,
                                display: `Código de estabelecimento:  ${displayNullableData(item.establishmentCode) || "Não cadastrado"}`
                            },
                            {
                                icon: <KeyIcon />,
                                display: `Código lógico:  ${displayNullableData(item.logicCode) || "Não cadastrado"}`
                            },
                            {
                                icon: displayStatus,
                                display: <span>Status:  <DisplayStatus status={item.status || "Não cadastrado"} /></span>
                            }
                        ]}
                    />

                    {/* Business Unit */}
                    <DisplayDetailsData
                        key="business-unit"
                        title='Unidade de negócio'
                        itens={[
                            {
                                icon: <StoreMallDirectoryIcon />,
                                display: `Nome:  ${item.businessUnitName}`
                            }
                        ]}
                    />

                    {/* Regional */}
                    <DisplayDetailsData
                        key="regional"
                        title='Regional'
                        itens={[
                            {
                                icon: <StoreMallDirectoryIcon />,
                                display: `Nome:  ${item.regionalName}`
                            },
                            {
                                icon: <KeyIcon />,
                                display: `Código:  ${item.regionalCode}`
                            }
                        ]}
                    />

                    {/* Group */}
                    <DisplayDetailsData
                        key="group"
                        title='Grupo'
                        itens={[
                            {
                                icon: <StoreMallDirectoryIcon />,
                                display: `Nome:  ${item.franchiseGroupName}`
                            },
                            {
                                icon: <KeyIcon />,
                                display: `Código:  ${item.franchiseGroupCode}`
                            }
                        ]}
                    />

                    {/* Contact */}
                    <DisplayDetailsData
                        key="contact"
                        title='Contato'
                        itens={[
                            {
                                icon: <LocalPhoneIcon />,
                                display: `Número de telefone:  ${displayNullableData(phoneMask(item.phoneNumber))}`
                            },
                            {
                                icon: <AlternateEmailIcon />,
                                display: `Email:  ${displayNullableData(item.email)}`
                            }
                        ]}
                    />

                    {/* Address */}
                    <DisplayDetailsData
                        key="endereco"
                        title='Endereço'
                        itens={[
                            {
                                icon: <ExploreIcon />,
                                display: `CEP:  ${item.postalCode}`
                            },
                            {
                                icon: <LocationCityIcon />,
                                display: `Cidade:  ${displayNullableData(item.city)}`
                            },
                            {
                                icon: <ShareLocationIcon />,
                                display: `Bairro:  ${item.neighborhood}`
                            },
                            {
                                icon: <LocationOnIcon />,
                                display: `Logradouro:  ${item.thoroughfare}`
                            },
                            {
                                icon: <Looks3Icon />,
                                display: `Número:  ${item.addressNumber}`
                            },
                            {
                                icon: <AbcIcon />,
                                display: `Complemento:  ${item.complement}`
                            }
                        ]}
                    />

                    {/* Bank Details */}
                    {!item.centralizedStoreId ?
                        <DisplayDetailsData
                            key="bank-details"
                            title='Dados Bancários'
                            itens={[
                                {
                                    icon: <BadgeIcon />,
                                    display: `Conta: ${displayNullableData(phoneMask(item.account))}`
                                },
                                {
                                    icon: <StoreMallDirectoryIcon />,
                                    display: `Agencia: ${displayNullableData(item.agency)}`
                                },
                                {
                                    icon: <AccountBalanceIcon />,
                                    display: `Banco: ${displayNullableData(item.bankName)}`
                                }
                            ]}
                        /> :
                        <DisplayDetailsData
                            key="centralize-store"
                            title='Dados da Centralizadora'
                            itens={[
                                {
                                    icon: <BadgeIcon />,
                                    display: `Razão Social:  ${displayNullableData(item.centralizedStoreName)}`
                                },
                                {
                                    icon: <KeyIcon />,
                                    display: `CNPJ:  ${displayNullableData(cnpjMask(item.centralizedStoreCnpj))}`
                                },
                                {
                                    icon: <KeyIcon />,
                                    display: `Código interno:  ${displayNullableData(item.centralizedStoreCode)}`
                                },
                            ]}
                        />
                    }

                    {/* Fee */}
                    <DisplayDetailsData
                        key="fee"
                        title='Taxas'
                        itens={[
                            {
                                icon: <MonetizationOnIcon />,
                                display: <span>
                                    Taxa de Carga: {item.loadFee}{'% '}
                                    <span style={{ fontWeight: 'bold' }}>
                                        {item.loadFeeSpecific ? '(Taxa específica da loja)' : '(Taxa padrão da unidade de negócio)'}
                                    </span>
                                </span>
                            },
                            {
                                icon: <MonetizationOnIcon />,
                                display: <span>
                                    Taxa de Resgate: {item.redeemFee}{'% '}
                                    <span style={{ fontWeight: 'bold' }}>
                                        {item.redeemFreeSpecific ? '(Taxa específica da loja)' : '(Taxa padrão da unidade de negócio)'}
                                    </span>
                                </span>
                            },
                            {
                                icon: <MonetizationOnIcon />,
                                display: <span>
                                    Taxa de Administração: {item.administrationFee}{'% '}
                                    <span style={{ fontWeight: 'bold' }}>
                                        {item.administrationFeeSpecific ? '(Taxa específica da loja)' : '(Taxa padrão da unidade de negócio)'}
                                    </span>
                                </span>
                            }
                        ]}
                    />
                    <DisplayDetailsData
                        key="invoices"
                        title='Faturas'
                        itens={[
                            {
                                icon: <MonetizationOnIcon />,
                                display:
                                    <span>
                                        <Button href={`/invoice/store/${item.id}`} style={{
                                            color: "white",
                                            backgroundColor: "#4da240"
                                        }}>Mais detalhes</Button>
                                    </span>
                            },
                        ]}
                    />
                </List>
            </Popup>
        </>
    );
}

export default StoreDetails;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#links-action{
    background: none;
    color: #5b5b5b;
    border: none;
    transition: .7s;
}

#links-action:hover {
    background: none;
    color: #000;
    border: none;
    text-decoration: underline;
    box-shadow: none;
}
#links-action::selection {
    background: none;
    color: #000;
    border: none;
    text-decoration: underline;
}

#link-remove{

}
#link-remove{
    background: none;
    color: #ff0000;
    border: none;
    transition: .7s;
}

#link-remove:hover {
    background: none;
    color: #930000;
    border: none;
    text-decoration: underline;
}
#link-remove::selection {
    background: none;
    color: #930000;
    border: none;
    text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/layouts/businessUnit/components/PopupEditBusinessUnit.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,cAAc;IACd,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,WAAW;IACX,YAAY;IACZ,0BAA0B;IAC1B,gBAAgB;AACpB;AACA;IACI,gBAAgB;IAChB,WAAW;IACX,YAAY;IACZ,0BAA0B;AAC9B;;AAEA;;AAEA;AACA;IACI,gBAAgB;IAChB,cAAc;IACd,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,cAAc;IACd,YAAY;IACZ,0BAA0B;AAC9B;AACA;IACI,gBAAgB;IAChB,cAAc;IACd,YAAY;IACZ,0BAA0B;AAC9B","sourcesContent":["#links-action{\r\n    background: none;\r\n    color: #5b5b5b;\r\n    border: none;\r\n    transition: .7s;\r\n}\r\n\r\n#links-action:hover {\r\n    background: none;\r\n    color: #000;\r\n    border: none;\r\n    text-decoration: underline;\r\n    box-shadow: none;\r\n}\r\n#links-action::selection {\r\n    background: none;\r\n    color: #000;\r\n    border: none;\r\n    text-decoration: underline;\r\n}\r\n\r\n#link-remove{\r\n\r\n}\r\n#link-remove{\r\n    background: none;\r\n    color: #ff0000;\r\n    border: none;\r\n    transition: .7s;\r\n}\r\n\r\n#link-remove:hover {\r\n    background: none;\r\n    color: #930000;\r\n    border: none;\r\n    text-decoration: underline;\r\n}\r\n#link-remove::selection {\r\n    background: none;\r\n    color: #930000;\r\n    border: none;\r\n    text-decoration: underline;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

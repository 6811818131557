import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Avatar from "../Avatar";
import "./Navbar.css";
import { useEffect, useState } from "react";
import { useAuthContext } from "components/AuthenticationProvider/AuthenticationProvider";
import { useAuthDispatchContext } from "components/AuthenticationProvider/AuthenticationProvider";
import axios from "axios";
import { useNavigate } from "react-router-dom";

/* eslint-disable */
export default function MainNavbar() {

    const [menus, setMenus] = useState([]);

    const authContext = useAuthContext();
    const authDispatch = useAuthDispatchContext();

    useEffect(async () => {
        const accessToken = authContext.accessToken;
        if (accessToken) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
            axios.get(`http://${process.env.REACT_APP_CARD_PROCESS_SECURITY_LINK}/menus/users/${authContext.userInfo.sub}`)
                .then((response) => {
                    setMenus(response.data.collection);
                })                
                .catch((error) => {
                    if (error.response) {
                    if (error.response.status === 401) {
                        const navigate = useNavigate();
                        authDispatch({ type: INVALID_TOKEN });
                    }
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('Error', error.message);
                    }
                })
        }
    },[]);

    return (
        <Navbar bg="success" expand="lg">
            <Container fluid style={{marginLeft: "20px"}}>
                <Navbar.Brand style={{fontSize: "25px", color: "white"}} href="/dashboard">oBoticario</Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarScroll"/>
                <Navbar.Collapse id="navbarScroll">
                    <Nav className="me-auto my-2 my-lg-0" style={{maxHeight: "100px"}} navbarScroll>
                        <Nav.Link style={{color: "#d0d0d0"}} href="/dashboard">Home</Nav.Link>
                        {menus?.map((menu) => {
                            return (
                                <NavDropdown key={menu.id} title={menu.name} id="navbarScrollingDropdown">
                                    {menu.actions.map((submenu) => {
                                        return (
                                            <NavDropdown.Item key={submenu.id} href={submenu.url}>{submenu.name}</NavDropdown.Item>
                                        )
                                    })}
                                </NavDropdown>
                            )
                        })}
                    </Nav>
                    <Avatar/>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

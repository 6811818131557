import React, {useState} from "react";
import "./stylesComponents/PopupEditCardSolicitation.css"
import InputsBusinessUnit from "../../businessUnit/components/InputsBusinessUnit";
import PopupEdit from "../../../components/PopupEdit/PopupEdit";
import InputCardSolicitation from "./InputCardSolicitation";


const PopupEditCardSolicitation = ({item, baseUrl, setLoadData}) => {

    const [cardType, setCardType] = useState(false) // false para cartao físicoe true para digital
    const [amount, setAmount] = useState("")
    const [businessUnitId, setBusinessUnitId] = useState("")

    const [errorMessage, setErrorMessage] = useState("")
    const [errorColumn, setErrorColumn] = useState("")

    const inputs = <InputCardSolicitation
        cardType={cardType} setCardType={setCardType}
        amount={amount} setAmount={setAmount}
        businessUnitId={businessUnitId} setBusinessUnitId={setBusinessUnitId}
        setErrorColumn={setErrorColumn}
        setErrorMessage={setErrorMessage}
        errorMessage={errorMessage}
        errorColumn={errorColumn}
    />
    const itemToState = () => {
        setCardType(item.digital)
        setAmount(item.amount)
        setBusinessUnitId(item.businessUnitId)
    }

    const title = "Editar Solicitação do Cartão"

    const returnData = () => {
        return {
            id: item.id,
            digital: cardType,
            amount,
            businessUnitId
        }
    }

    return(
        <>
            <PopupEdit
                baseUrl={baseUrl} setLoadData={setLoadData}
                inputs={inputs} title={title}
                returnData={returnData} itemToState={itemToState}
                setErrorMessage={setErrorMessage} setErrorColumn={setErrorColumn}
            />
        </>
    )
}
export default PopupEditCardSolicitation
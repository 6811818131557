import React, {useState} from 'react';
import {ComposableMap, Geographies, Geography} from 'react-simple-maps';
import brazilGeoJSON from './data/brazil_geo.json';
import PopupMovel from "./components/PopupMovel";
import {NumberWithPoints} from "../../../../../utils/numberWithPoints";
import Card from "@mui/material/Card";
import MDTypography from "../../../../../components/MDTypography";
import MDBox from "../../../../../components/MDBox";
import Icon from "@mui/material/Icon";

const BrazilMap = ({data, count, loadData, setLoadData, maxValue}) => {
    const [tooltipContent, setTooltipContent] = useState('');

    const colorScale = [
        // '#FFEDA0',
        'rgba(117,211,245,0.33)',
        'rgba(117,211,245,0.66)',
        'rgba(117,211,245,0.88)',
        '#75d3f5',
        '#6dc3ea',
        '#66b3df',
        '#5ea2d4',
        '#5692c9',
        '#4f82be',
        '#4772b3',
        '#3f61a8',
        '#37519d',
        '#304192',
        '#283187',
        '#20207c',
        '#191071',
        '#110066',
    ];

    const getMaxValue = () => {
        const values = Object.values(data)
        values.sort((a, b) => b - a);
        return values[1];
    };

    const getFillColor = (stateCode) => {
        const value = data[stateCode] || 0;
        if (value === 0)
            return '#ffffff'
        const max = getMaxValue();
        const colorIndex = Math.min(Math.floor((value / max) * colorScale.length), colorScale.length - 1);
        return colorScale[colorIndex];
    };


    const mapLegend = {
        // margin: '-60% 0 0 78%',
        // border: '1px solid black',
        margin: 'auto',
        marginLeft: '60%'
        // width: '20,4rem'
    }
    const mapLegendData = {
        // margin: '-60% 0 0 78%',
        padding: '0',
        margin: 'auto',
        marginLeft: '59%',
        marginRight: '17%'
        // width: '20,4rem'
    }

    return (
        <Card>

            <MDBox pt={3} px={3}>
                <MDTypography variant="h6" fontWeight="medium">
                    Valores por Estado
                </MDTypography>
                <MDBox mt={0} mb={2}>
                    {count !== false &&
                        (<MDTypography variant="button" color="text" fontWeight="regular">
                            <MDTypography display="inline" variant="body2" verticalAlign="middle">
                                <Icon sx={{color: ({palette: {success}}) => success.main}}>update</Icon>
                            </MDTypography>
                            &nbsp;
                            <MDTypography variant="button" color="text" fontWeight="medium">
                                Atualizando em {count}
                            </MDTypography>{" "}
                        </MDTypography>)
                    }
                </MDBox>
            </MDBox>

            <ComposableMap
                data-tip=""
                style={{
                    // border: '2px solid black',
                    width: '70rem',
                    // minWidth: '400px',
                    alignSelf: 'center'
                }}

                projection="geoMercator"
                projectionConfig={{
                    scale: 800,
                    center: [-50, -15]
                }}
            >
                <Geographies geography={brazilGeoJSON}>
                    {({geographies}) =>
                        geographies.map((geo) => (
                            <Geography

                                stroke="#000"
                                strokeWidth={0.4}

                                key={geo.properties.id}

                                geography={geo}
                                onMouseEnter={() => {
                                    const stateCode = geo.properties.sigla; // Obtém o código do estado
                                    const value = data[stateCode] || 0; // Obtém o valor de transações
                                    setTooltipContent(`${geo.properties.name}: R\$ ${NumberWithPoints(value / 100)} `);
                                }}
                                onMouseLeave={() => {
                                    setTooltipContent('');
                                }}
                                onClick={() => {
                                    // Lida com o clique no estado aqui, se necessário
                                }}

                                style={{
                                    default: {
                                        fill: getFillColor(geo.properties.sigla),
                                        outline: 'none',
                                    },
                                    hover: {
                                        fill: getFillColor(geo.properties.sigla),
                                        outline: 'none',
                                        cursor: 'pointer'
                                    },
                                    pressed: {
                                        fill: getFillColor(geo.properties.sigla),
                                        outline: 'none',
                                    },
                                }}
                            />
                        ))
                    }
                </Geographies>
            </ComposableMap>
            <div style={mapLegend}>
                <span key={0} style={{backgroundColor: '#fff', color: '#fff', padding: '5px'}}>
                        .
                </span>
                {colorScale.map((color, index) => (
                    <span key={index} style={{backgroundColor: color, color: color, padding: '5px'}}>
                        .
                    </span>
                ))}
            </div>
            <div >
                <span style={mapLegendData}>
                    R$ 0
                </span>
                <span>R$ {NumberWithPoints(maxValue)}</span>
            </div>
            <br/>
            <PopupMovel content={tooltipContent}/>
        </Card>
    );
};

export default BrazilMap;

import React, { useEffect, useState } from 'react';
import { Button, Modal, Dropdown } from 'react-bootstrap';
import axios from 'axios';
import DisplayStatus from "../../../components/Status/DisplayStatus";
import Container from "@mui/material/Container";

import StorefrontIcon from '@mui/icons-material/Storefront';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';

const PopupInativeStore = ({ item, baseUrl, setLoadData, isDisabled }) => {
    const [show, setShow] = useState(false);
    const [error, setError] = useState(null);
    const [storeDetails, setStoreDetails] = useState(null);

    useEffect(() => {
        if (show && item?.id) {
            const fetchStoreDetails = async () => {
                try {
                    const response = await axios.get(`${baseUrl}/${item.id}`);
                    setStoreDetails(response.data);
                } catch (error) {
                    setError("Erro ao carregar detalhes da loja.");
                }
            };
            fetchStoreDetails();
        } else {
            setStoreDetails(null);
            setError(null);
        }
    }, [show, item, baseUrl]);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleInactivate = async () => {
        if (!item?.id) {
            setError("ID da loja não está disponível.");
            return;
        }

        try {
            const response = await axios.put(`${baseUrl}/inactivate/${item.id}`);
            if (response.status === 400) {
                setError(response.data);
            } else {
                setLoadData(prev => !prev);
                handleClose();
            }
        } catch (error) {
            if (error.response && error.response.data) {
                setError(error.response.data);
            } else {
                setError("Erro ao inativar a loja.");
            }
            console.error("Erro ao inativar a loja:", error);
        }
    };

    return (
        <>
            <Dropdown.Item as="button" onClick={handleShow} disabled={isDisabled}>
                <StorefrontIcon className="me-2" /> Inativar Loja
            </Dropdown.Item>

            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title><CloseIcon /> Inativar Loja</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <div className="text-center mb-4">
                            <h5>Deseja realmente inativar esta loja?</h5>
                            {error && <p className="text-danger">{error}</p>}
                        </div>
                        {storeDetails && (
                            <Container className="store-details p-3 border rounded">
                                <h5 className="mb-3">
                                    <StorefrontIcon className="me-2" /> Dados da loja:
                                </h5>
                                <p><strong>Código interno:</strong> {storeDetails.internalCode}</p>
                                <p><strong>CNPJ:</strong> {storeDetails.cnpj}</p>
                                <p><strong>Razão Social:</strong> {storeDetails.corporateName}</p>
                                <p><strong>Nome do Grupo:</strong> {item.franchiseGroupName}</p>
                                <p><strong>Status:</strong> <DisplayStatus status={item.status} /></p>
                            </Container>
                        )}
                    </Container>
                    <div className="text-center mb-4" style={{color:"red"}}>
                        <h5>Obs: Após realizar esta função, a loja não poderá ser reativada, a menos que você entre em contato com um de nossos administradores.</h5>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        <CloseIcon /> Cancelar
                    </Button>
                    <Button
                        variant="danger"
                        onClick={handleInactivate}
                        disabled={isDisabled}
                    >
                        <CheckCircleIcon /> Inativar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default PopupInativeStore;

import React, { useEffect, useState } from 'react';
import { Button, Modal, Dropdown } from 'react-bootstrap';
import axios from 'axios';
import DisplayStatusUser from "../../../components/Status/DisplayStatusUser";
import Container from "@mui/material/Container";
import MDBox from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormLabel from '@mui/material/FormLabel';

import StorefrontIcon from '@mui/icons-material/Storefront';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';

import Ativo from '@mui/icons-material/CheckCircle';
import Migrado from '@mui/icons-material/SwapHorizontalCircleRounded';
import Inativo from '@mui/icons-material/CancelRounded';
import Bloqueado from '@mui/icons-material/BlockRounded';
import Expirado from '@mui/icons-material/AccessTimeFilled';
import InativoBloqueado from '@mui/icons-material/RemoveCircleRounded';

const PopupStatusChangeUser = ({ item, baseUrl, setLoadData, isDisabled }) => {
    const [show, setShow] = useState(false);
    const [error, setError] = useState(null);
    const [userDetails, setUserDetails] = useState(null);
    const [userStatus, setUserStatus] = useState(item.status);

    useEffect(() => {
        if (show && item?.id) {
            const fetchUserDetails = async () => {
                try {
                    const response = await axios.get(`${baseUrl}/${item.id}`);
                    setUserDetails(response.data);
                } catch (error) {
                    setError("Erro ao carregar detalhes do usuário.");
                }
            };
            fetchUserDetails();
        } else {
            setUserDetails(null);
            setError(null);
        }
    }, [show, item, baseUrl]);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleStatusChange = async () => {
        if (!item?.id) {
            setError("ID do usuário não está disponível.");
            return;
        }

        const data = {
            id: item.id,
            status: userStatus
        };

        try {
            const response = await axios.put(`${baseUrl}/status-change/`, data);
            if (response.status === 400) {
                setError(response.data);
            } else {
                setLoadData(prev => !prev);
                handleClose();
            }
        } catch (error) {
            if (error.response && error.response.data) {
                setError(error.response.data);
            } else {
                setError("Erro ao atualizar o status do usuário.");
            }
            console.error("Erro ao atualizar o status:", error);
        }
    };

    return (
        <>
            <Dropdown.Item as="button" onClick={handleShow} disabled={isDisabled} style={{color: isDisabled ? "grey" : "blue"}}>
                <StorefrontIcon className="me-2" /> Alterar Status do Usuário
            </Dropdown.Item>

            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title><CloseIcon /> Alterar Status do Usuário</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <div className="text-center mb-1">
                            <h5>Deseja alterar o status deste usuário?</h5>
                            {error && <p className="text-danger">{error}</p>}
                        </div>
                        {userDetails && (
                            <Container className="store-details p-3 border rounded">
                                <h5 className="mb-3">
                                    <StorefrontIcon className="me-2" /> Dados do usuário:
                                </h5>
                                <p><strong>Nome de usuário:</strong> {userDetails.username}</p>
                                <p><strong>Nome completo:</strong> {userDetails.name}</p>
                                <p><strong>Email:</strong> {userDetails.email}</p>
                                <p><strong>Status atual:</strong> <DisplayStatusUser status={item.status} /></p>
                            </Container>
                        )}

                        {/* Componente de RadioGroup para seleção do status */}
                        <MDBox mb={1} pb={1}>
                            <RadioGroup
                                row
                                name="row-radio-buttons-group"
                                id="radios-status"
                                value={userStatus}
                                onChange={(e) => setUserStatus(e.target.value)}
                                sx={{
                                    '& .MuiFormControlLabel-root': {  // Aplica estilos para cada FormControlLabel (os inputs de rádio)
                                        mx: 1,  // Aumenta o espaçamento horizontal entre os radios
                                        '& .MuiRadio-root': {  // Aplica estilos aos inputs de radio
                                            transform: 'scale(1.5)',  // Aumenta o tamanho do botão de rádio
                                        },
                                        '& .MuiTypography-root': {  // Aplica estilo ao texto de cada label
                                            fontSize: '1.2rem',  // Aumenta o tamanho da fonte do label
                                        }
                                    }
                                }}
                            >
                                <FormControlLabel value="ACTIVE" control={<Radio />} label={<span>Ativo <Ativo color={"success"}/></span>} />
                                <FormControlLabel value="INACTIVE" control={<Radio />} label={<span>Inativo <Inativo color={"error"}/></span>} />
                                <FormControlLabel value="BLOCKED" control={<Radio />} label={<span>Bloqueado <Bloqueado color={"warning"}/></span>} />
                                <FormControlLabel value="BLOCKED_INACTIVED" control={<Radio />} label={<span>Inativo Bloqueado <InativoBloqueado color={"disabled"}/></span>} />
                                <FormControlLabel value="PASSWORD_EXPIRED" control={<Radio />} label={<span>Senha Expirada <Expirado color={"error"}/></span>} />
                                <FormControlLabel value="MIGRATED" control={<Radio />} label={<span>Migrado <Migrado color={"info"}/></span>} />
                            </RadioGroup>
                        </MDBox>

                        {userStatus === "INACTIVE" && (
                            <div className="text-center mb-4" style={{color:"red"}}>
                                <h7>Obs: Ao mudar o status para inativo, o usuário jamais poderá ser ativo novamente! A menos que entre em contato com um de nossos administradores.</h7>
                            </div>
                        )}
                        {userStatus === "PASSWORD_EXPIRED" && (
                            <div className="text-center mb-4" style={{color:"red"}}>
                                <h7>Obs: Ao expirar a senha, o usuário não poderá acessar a conta novamente até que uma nova senha seja definida. Um e-mail será enviado automaticamente para redefinição na próxima tentativa de login. Caso não receba, entre em contato com um de nossos administradores.</h7>
                            </div>
                        )}
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        <CloseIcon /> Cancelar
                    </Button>
                    <Button
                        onClick={handleStatusChange}
                        disabled={isDisabled}
                    >
                        <CheckCircleIcon /> Alterar Status
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default PopupStatusChangeUser;

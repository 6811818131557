import TextField from "@mui/material/TextField";
import React, {useEffect, useState} from "react";
import "./InputsBusinessUnit.css"
import InputStatus from "../../../components/Status/InputStatus";
import {formatCode} from "../../../utils/formatCode";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import HelpIcon from "@mui/icons-material/Help";
import Tooltip from "react-bootstrap/Tooltip";
import {FormLabel, InputAdornment} from "@mui/material";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import applyRegex from "../../../utils/applyRegex";

const InputsBusinessUnit = ({
                                name, setName,
                                minChargeValue, setMinChargeValue,
                                maxChargeValue, setMaxChargeValue,
                                chargeMultiplicity, setChargeMultiplicity,
                                chargeValidity, setChargeValidity,
                                range, setRange,
                                status, setStatus,
                                errorMessage, setErrorMessage,
                                errorColumn, setErrorColumn,
                                loadFee, setLoadFee,
                                redeemFee, setRedeemFee,
                                administrationFee, setAdministrationFee,
                                disabledFees,
                                StatusAddOrEdit,
                            }) => {

    const [errorName, setErrorName] = useState(false)
    const [errorNameText, setErrorNameText] = useState(false)

    const renderTooltipMultiplicity = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            A carga será multipla do valor digitado.
        </Tooltip>
    );

    const multiplicityDisplay = <OverlayTrigger
        placement="right"
        delay={{show: 250, hide: 400}}
        overlay={renderTooltipMultiplicity}
    >
        <span><HelpIcon fontSize="small"/></span>
    </OverlayTrigger>

    const renderTooltipRange = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Número(digíto) de identificação da unidade de negócio no cartão, Ex: "XXXXXXYZZZABBBBBBBB",
            neste exemplo a letra "A" seria o Range da unidade de negócio.
        </Tooltip>
    );

    const rangeDisplay = <OverlayTrigger
        placement="right"
        delay={{show: 250, hide: 400}}
        overlay={renderTooltipRange}
    >
        <span><HelpIcon fontSize="small"/></span>
    </OverlayTrigger>

    const renderTooltipStatus = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Ao adicionar uma UN o status sempre será ativo.
        </Tooltip>
    );

    const statusDisplay = <OverlayTrigger
        placement="right"
        delay={{show: 250, hide: 400}}
        overlay={renderTooltipStatus}
    >
        <span><HelpIcon fontSize="small"/></span>
    </OverlayTrigger>

    const handleNameChange = (e) => {
        const inputValue = e.target.value.toUpperCase();

        if (/^[A-Za-z\s]+$/.test(inputValue) || inputValue === '') {
            setName(inputValue);
        }
    };


    const changeMultiplicityChargeValue = (e) => {
        const { value } = e.target;
        const numericValue = value.replace(/\D/g, ''); // Remove tudo exceto dígitos (0-9)

        if (numericValue.length <= 5) {
            setChargeMultiplicity(numericValue);
        }
    };

    const onChangeWithLimiter = (e, value, set) => {
        let valueSeted = e.target.value
        const numericValue = valueSeted.replace(/\D/g, ''); // Remove tudo exceto dígitos (0-9)
        if(numericValue.length <= value){
            set(numericValue)
        }
    }

    useEffect(() => {
        setErrorName(false)
        setErrorNameText(false)
        switch (errorColumn) {
            case "name":
                setErrorName(true)
                setErrorNameText(errorMessage)
                break
        }
    }, [errorColumn, errorMessage])

    return (
        <>
            <TextField
                id="input-with-icon-textfield"
                label="Nome da Unidade"
                inputProps={{
                    maxLength: 70
                }}
                required={true}
                size="large"
                erortext="Campo obrigatório!"
                value={name}
                onChange={handleNameChange}
                error={errorName}
                helperText={errorNameText}
                fullWidth
                autoFocus
            />

            <TextField
                label="Valor máximo de carga"
                required={true}
                InputProps={{
                    min: 1,
                    step: '.01',
                    inputMode: 'numeric', // Define o modo de entrada como numérico
                    pattern: '[0-9]*[.]?[0-9]*', // Permite apenas números e um ponto decima
                    startAdornment: <span>R$</span>,
                }}
                inputProps={{maxlength: 9}}
                type="text"
                erortext="Campo obrigatório!"
                error={errorName}
                value={maxChargeValue}
                onChange={(e) => setMaxChargeValue(applyRegex(e.target.value, /[0-9]/g))}
                fullWidth
            />

            <TextField
                label="Valor mínimo de carga"
                required
                InputProps={{
                    min: 1,
                    step: '.01',
                    inputMode: 'numeric', // Define o modo de entrada como numérico
                    pattern: '[0-9]*[.]?[0-9]*', // Permite apenas números e um ponto decima
                    startAdornment: <span>R$</span>,
                }}
                value={minChargeValue}
                type="text"
                error={errorName}
                onChange={(e) => setMinChargeValue(applyRegex(e.target.value, /[0-9]/g))}
                fullWidth
            />

            <TextField
                label="Multiplicidade de carga"
                required={true}
                InputProps={{
                    startAdornment: (
                        <InputAdornment>
                            {multiplicityDisplay}
                        </InputAdornment>
                    ),
                    inputProps: {min: 1},
                }}
                type="text"
                erortext="Campo obrigatório!"
                value={chargeMultiplicity}
                onChange={changeMultiplicityChargeValue}
                fullWidth
            />
            <TextField
                label="Validade de carga(em dias)"
                required={true}
                InputProps={{
                    min: 1,
                    endAdornment: <span>Dias</span>,
                }}
                type="text"
                erortext="Campo obrigatório!"
                value={chargeValidity}
                onChange={(e) => onChangeWithLimiter(e, 5, setChargeValidity)}
                fullWidth
            />
            <TextField
                label="Range"
                required={true}
                InputProps={{
                    min: 1,
                    max: 100,
                    startAdornment: (
                        <InputAdornment>
                            {rangeDisplay}
                        </InputAdornment>
                    ),
                }}
                type="text"
                erortext="Campo obrigatório!"
                value={range}
                onChange={(e) => onChangeWithLimiter(e, 2, setRange)}
                fullWidth
            />
            <MDBox mb={0.5} pb={0.5}>
                <MDTypography variant="h5" textTransform="capitalize" fontWeight="light">
                    Taxas
                </MDTypography>
            </MDBox>
            <p style={{fontSize: '0.8vw'}}>
                * Se o status da unidade de negócio estiver como 'bloqueado' não é permitido a alteração de taxas.
            </p>
            <TextField
                label="Taxa Carga"
                required={true}
                InputProps={{
                    min: 0,
                    max: 100,
                    endAdornment: <span>%</span>,
                }}
                type="text"
                erortext="Campo não obrigatório!"
                value={loadFee}
                onChange={(e) => onChangeWithLimiter(e, 3, setLoadFee)}
                fullWidth
                disabled={disabledFees}
            />
            <TextField
                label="Taxa Resgate"
                required={true}
                InputProps={{
                    min: 0,
                    max: 100,
                    endAdornment: <span>%</span>,
                }}
                type="text"
                erortext="Campo não obrigatório!"
                value={redeemFee}
                onChange={(e) => onChangeWithLimiter(e, 3, setRedeemFee)}
                fullWidth
                disabled={disabledFees}
            />
            <TextField
                label="Taxa Administrativa"
                required={true}
                InputProps={{
                    min: 0,
                    max: 100,
                    endAdornment: <span>%</span>,
                }}
                type="text"
                erortext="Campo não obrigatório!"
                value={administrationFee}
                onChange={(e) => onChangeWithLimiter(e, 3, setAdministrationFee)}
                fullWidth
                disabled={disabledFees}
            />
            {StatusAddOrEdit ?(
                <FormLabel id="demo-row-radio-buttons-group-label" className="text-center">
                    Status
                    {statusDisplay}
                </FormLabel>
                ):(
                <InputStatus  status={status} setStatus={setStatus}/>)
            }
        </>
    )
}

export default InputsBusinessUnit
import "./Choice.css";
import { useNavigate } from "react-router-dom";
import AdmImage from "../../assets/images/adm.png";

// import FadeIn from "@bit/formidablelabs.react-animations.fade-in";
import CentralImage from "../../assets/images/central.png";
// import { FadeIn } from 'react-animate';

export default function Choice() {
  
  const navigate = useNavigate();
  
//   const FadeInAnimation = keyframes`${FadeIn}`;
//   const FadeInDiv = styled.div`
//   animation: infinite 5s ${FadeInAnimation};
// `;
  /* eslint-disable */
  return (
  // <FadeInDiv>
    <div className="container-images">
      <div className="card">
        <img
          onClick={() => navigate("/dashboard")}
          className="adm-image"
          src={AdmImage}
          alt="oBoticario"
        />
        <label onClick={() => navigate("/dashboard")} className="adm-label" id="linkADM">
        Administrativo
        </label>
      </div>
      <div className="card">
        <img
          onClick={() => navigate("/central-atendimento")}
          className="central-image"
          src={CentralImage}
          alt="oBoticario"
        />
        <label onClick={() => navigate("/central-atendimento")} className="central-label" id="linkCA">
          Central de atendimento
        </label>
      </div>
    </div>
  // </FadeInDiv>
  );
}

import { FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import DisplayStatus from "./DisplayStatus";
import React from "react";
import "./DisplayStatus.css";

const InputStatus = ({ status, setStatus }) => {
    return (
        <>
            <FormLabel id="demo-row-radio-buttons-group-label" className="text-center">
                Status
            </FormLabel>
            <RadioGroup
                row
                name="row-radio-buttons-group"
                id="radios-status"
                value={status}
                onChange={(e) => {
                    setStatus(e.target.value);
                }}
                required
            >
                <FormControlLabel value="ATIVO" control={<Radio />} label={<DisplayStatus status="ATIVO" />} />
                {/*<FormControlLabel value="INATIVO" control={<Radio />} label={<DisplayStatus status="INATIVO" />} />*/}
                <FormControlLabel value="BLOQUEADO" control={<Radio />} label={<DisplayStatus status="BLOQUEADO" />} />
            </RadioGroup>
        </>
    );
};

export default InputStatus;

import SelectInput from "../SelectInput";
import {useEffect, useState} from "react";
import TextField from "@mui/material/TextField";


export const AutoCompleteInput = ({
                                      // data to control autocomplete
                                      sizeToSearch, filter,
                                      value, setValue,
                                      firstInputLabel, secondInputLabel,
                                      // data to generate SelectInput
                                      url, list, set, setObj,
                                      defaultValue, returnList,
                                      required,
                                      getLabel, getValueForList,
                                      maxMenuHeight, changeMessage,
                                      disabled
                                  }) => {

    const handleChange = (e) => {
        const inputValue = e.target.value;
        const newValue = inputValue.replace(/[^0-9]/g, ''); // Mantém apenas números

        if(newValue !== undefined) {
            setValue(newValue);
        }
    };
    const [placeholder, setPlaceholder] = useState(secondInputLabel? secondInputLabel: '')

    useEffect(() => {
        setPlaceholder(secondInputLabel)
    }, [value])

    return (!value || value?.length < sizeToSearch) ?
        <>
            {firstInputLabel &&
                <p className='mb-2'>
                    <b className='fs-6'>
                        {firstInputLabel}
                    </b>
                </p>
            }
            <TextField
                value={value}
                onChange={handleChange}
                label={'Código interno da loja'}
                fullWidth
                required={required}
                disabled={disabled}
            />
        </> : <>
            {secondInputLabel &&
                <>
                    <b style={{fontSize: '1vw'}} className='fs-6'>
                        {secondInputLabel}
                    </b> <br/>
                </>
            }
            <SelectInput
                url={url + '&' + filter}
                list={list}
                set={set}
                setObj={setObj}
                defaultValue={defaultValue}
                returnList={returnList}
                setPlaceholder={setPlaceholder}
                placeholder={placeholder}
                required={required}
                getLabel={getLabel}
                getValueForList={getValueForList}
                maxMenuHeight={maxMenuHeight}
                disabled={disabled}
            />
            <p className='fs-6'>
                {changeMessage ?
                    changeMessage :
                    'Digitou errado?'}
                &nbsp;
                <span
                    className='link-info'
                    style={{cursor: "pointer"}}
                    onClick={() => setValue('')}
                >
                    Clique aqui!
                </span>
            </p>
        </>

}
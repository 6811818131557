import { Paper, TableContainer } from "@mui/material";
import BasicLoader from "components/BasicLoader/BasicLoader";
import { useEffect, useState } from "react";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { NumberWithPoints } from "utils/numberWithPoints";
import SummarizedInvoice from "./SummarizedInvoice";

import RequestPageIcon from '@mui/icons-material/RequestPage';
import { Button } from "react-bootstrap";
import { fetchData, postData } from "api/axiosMethods";
import { useAuthContext } from "components/AuthenticationProvider/AuthenticationProvider";
import PaginationControl from "components/Pagination/PaginationControl";
import UserMessage from "components/UserMessage/UserMessage";

export default function CpGroupMerchantInvoices({ params}) {

    const baseUrl = `http://${process.env.REACT_APP_FINANCIAL_MANAGER_LINK}`;
    const reportUrl = `http://${process.env.REACT_APP_PYREPORT_LINK}`;

    const [invoices, setInvoices] = useState([]);
    const [loadingData, setLoadingData] = useState(true);
    const [callSummarizedInvoice, setCallSummarizedInvoice] = useState(false);
    const [invoiceId, setInvoiceId] = useState(null);

    let currCpGroup = null;

    const authContext = useAuthContext();

    // Paginação
    const [page, setPage] = useState(0);
	const [size, setSize] = useState(25);
    const [sort, setSort] = useState("")
	const [totalPages, setTotalPages] = useState(0);
	const [totalElements, setTotalElements] = useState(0);
    const [loadData, setLoadData] = useState(true);

    // Mensagens
    const [show, setShow] = useState(false);
    const toggleSnackbar = () => setShow(!show);
  
    const [messageContent, setMessageContent] = useState('');
    const [messageType, setMessageType] = useState('');


    useEffect(async () => {
        if (loadData) {
            const displaySort = sort && sort !== '' ? sort : 'id,desc';
            params = { page, size, sort: displaySort, ...params };

            const data = await fetchData(`${baseUrl}/financialClose/findInvoices`, params);
            if (data) {
                setInvoices(data.content);
                setTotalPages(data.totalPages)
                setTotalElements(data.totalElements)
            }
            setLoadingData(false);
        }
    },
    [params, loadData]);

    function handleDetails(id) {
        setInvoiceId(id);
        setCallSummarizedInvoice(true);
    }

    function exportReport() {

        const userId = authContext.userInfo.sub;

        const resp = postData(`${reportUrl}/financial-close`, 
                                {...params, 
                                    userId }
                            );
        if (resp) {
            
            setMessageType('success');
            setMessageContent('Sua solicitação de relatório foi registrada com sucesso! Aguarde alguns minutos e acesse a opção de Arquivos de Relatórios para baixá-lo');
            setShow(true);
        }
    }


    function renderRows() {
        return invoices.map( invoice => {
            let row = "";
            if (! currCpGroup || currCpGroup != invoice.cpCode) {
                row = (
                    <tr>
                        <td>{invoice.cpCode} - {invoice.cpName}</td>
                    </tr>
                );
            } else {
                row = (
                    <tr key={invoice.id} >
                        <td>{invoice.businessUnit}</td>
                        <td>{(invoice.storeCode)}</td>
                        <td>{(invoice.storeName)}</td>
                        <td>{(invoice.dueDate)}</td>
                        <td>R$ {NumberWithPoints(invoice.totalLoadCard ?? 0.0)}</td>
                        <td>R$ {NumberWithPoints(invoice.totalReedem ?? 0.0)}</td>
                        <td>R$ {NumberWithPoints(invoice.totalAdjustment)}</td>
                        <td><a href='#' onClick={() => handleDetails(invoice.id)}>Detalhes <VisibilityIcon fontSize="medium" /></a></td>
                    </tr>
                );
            }
            currCpGroup = invoice.cpCode;
            return row;
        });
    }

    return (
        <>
            {loadingData  ? <BasicLoader/>
            :
                <TableContainer component={Paper}>

                    {callSummarizedInvoice ? <SummarizedInvoice invoiceId={invoiceId} />
                    :
                        <div>
                            <table>
                                <tr>
                                    <th>Unidade Negócio</th>
                                    <th>Loja</th>
                                    <th>Estabelecimento</th>
                                    <th>Vencimento</th>
                                    <th>Cargas</th>
                                    <th>Resgates</th>
                                    <th>Ajustes</th>
                                    <th>Total</th>
                                    <th></th>
                                </tr>
                                {renderRows()}
                            </table>

                            <PaginationControl
                                // totalPages={totalPages}
                                totalElements={totalElements}
                                page={page}
                                setPage={setPage}
                                size={size}
                                setSize={setSize}
                                setLoadData={setLoadData}
                            />

                            <div className="row">
                                <div className="col-md-2">
                                    {
                                        invoices && invoices.length > 0 &&   
                                            <Button type="button" onClick={() => exportReport()}>Exportar  <RequestPageIcon fontSize="medium"/>
                                            </Button>
                                    }
                                </div>
                            </div>

                            <UserMessage
                                type={messageType}
                                content={messageContent}
                                open={show}
                                close={toggleSnackbar}
                            />

                        </div>
                    }
                </TableContainer>
            }
        </>       
    );
}
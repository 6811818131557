import React, {useState} from "react";
import TableList from "../../../components/TableList/TableList";
import {NumberWithPoints} from "../../../utils/numberWithPoints";
import {normalizeTimestemp} from "../../../utils/normalizeTimestemp";
import DisplayStatus from "../../../components/Status/DisplayStatus";

const ListBalanceCheck = ({
                              baseUrl, loadData,
                              setLoadData, size, page,
                              setTotalPages, setTotalElements,
                              sort, setSort, updateCode, setPage,
                              cardNumber
                          }) => {

    const baseUrl2 = `http://${process.env.REACT_APP_FINANCIAL_MANAGER_LINK}/transaction`

    const requestDataTransaction = { cardNumber };
    const requestDataCard = { number: cardNumber };

    const [data, setData] = useState([])
    const [data2, setData2] = useState([])
    const [error, setError] = useState(false)
    const [filterCard, setFilterCard] = useState(requestDataCard)
    const [filterTransaction, setFilterTransaction] = useState(requestDataTransaction)

    const title1 = ["Numero do cartão", "Saldo", "Status"];
    const titles = ["Data da transação", "Valor", "NSU", "Status", "Tipo", "Loja"];

    const getTypeLabel = (type) => {
        const typeMap = {
            'TRANSF_DEBITO': 'Transf. de débito',
            'TRANSF_CREDITO': 'Transf. de crédito',
            'BAIXA_SALDO_VENCIDO': 'Baixa',
            'CARGA': 'Carga',
            'RESGATE': 'Resgate'
        };
        return typeMap[type] || type;
    };

    const getStatusLabel = (status) => {
        const statusMap = {
            'PENDENDTE': 'Pendente',
            'NEGADA': 'Negada',
            'CONFIRMADA': 'Confirmada',
            'DESFEITA': 'Desfeita'
        };
        return statusMap[status] || status;
    };

    return (
        <>
            <TableList
                titles={title1} baseUrl={baseUrl}
                page={page} sort={sort} size={size}
                loadData={loadData}
                setTotalPages={setTotalPages} setTotalElements={setTotalElements}
                setData={setData} setLoadData={setLoadData} filter={filterCard}
                error={error} setError={setError}
            >
                {data.map((item) =>
                    <tr key={item.id}>
                        <td>{item.number}</td>
                        <td>{"R$ " + NumberWithPoints(item.balance)}</td>
                        <td><DisplayStatus status={item.status}/></td>
                    </tr>
                )}
            </TableList>
            <TableList
                titles={titles} baseUrl={baseUrl2}
                page={page} sort={sort} size={size}
                loadData={loadData}
                setTotalPages={setTotalPages} setTotalElements={setTotalElements}
                setData={setData2} setLoadData={setLoadData} filter={filterTransaction}
                error={error} setError={setError}
            >
                {data2.map((item) =>
                    <tr key={item.id}>
                        <td>{normalizeTimestemp(item.dateTime)}</td>
                        <td>R$ {NumberWithPoints(item.value)}</td>
                        <td>{item.hostNsu || "---"}</td>
                        <td>{getStatusLabel(item.status.status)}</td>
                        <td>{getTypeLabel(item.type.type)}</td>
                        <td>{item.corporateName}</td>
                    </tr>
                )}
            </TableList>
        </>
    )
}
export default ListBalanceCheck
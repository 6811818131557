import React, {useState} from "react";
import InputsRegional from "./InputsRegional";
import PopupEdit from "../../../components/PopupEdit/PopupEdit";


const PopupEditRegional = ({item, baseUrl, setLoadData}) => {

    const [name, setName] = useState("")
    const [code, setCode] = useState("")
    const [errorMessage, setErrorMessage] = useState("")
    const [errorColumn, setErrorColumn] = useState("")

    const itemToState = () => {
        setName(item.name)
        setCode(item.code)
    }

    const inputs = <InputsRegional
        name={name} setName={setName}
        code={code} setCode={setCode}
        errorMessage={errorMessage}
        errorColumn={errorColumn}
    />

    const title = "Editar Regional"

    const returnData = () => {
        return {
            id: item.id,
            name,
            code
        }
    }

    return (
        <>
            <PopupEdit
                baseUrl={baseUrl} setLoadData={setLoadData}
                inputs={inputs} title={title}
                returnData={returnData} itemToState={itemToState}
                setErrorMessage={setErrorMessage} setErrorColumn={setErrorColumn}
            />
        </>
    )
}

export default PopupEditRegional
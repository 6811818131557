import Popup from "../../../components/Popup/Popup";
import React, {useEffect, useState} from "react";
import List from "@mui/material/List";
import VisibilityIcon from '@mui/icons-material/Visibility';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import SuperscriptIcon from '@mui/icons-material/Superscript';
import BadgeIcon from '@mui/icons-material/Badge';
import KeyIcon from '@mui/icons-material/Key';
import DateRangeIcon from '@mui/icons-material/DateRange';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import LockIcon from "@mui/icons-material/Lock";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import DisplayStatus from "../../../components/Status/DisplayStatus";
import DisplayDetailsData from "../../../components/DisplayDetailsData/DisplayDetailsData";

const BusinessUnitDetails = ({item}) => {

    const [lgShow, setLgShow] = useState(false);
    const [displayStatus, setDisplayStatus] = useState("");

    const button = <>
        Detalhes <VisibilityIcon fontSize="medium"/>
    </>

    const title = "Detalhes da Unidade de Negócio"

    const iconSpan = <>
        <VisibilityIcon fontSize="large" id="icon-svg"/>
        {title}
    </>

    const status = <span>Status da Unidade de Negócio:  <DisplayStatus status={item.status}/></span>

    useEffect(() => {
        switch (item.status) {
            case "ATIVO":
                setDisplayStatus(<CheckCircleOutlineIcon fontSize="medium"/>)
                break;
            case "INATIVO":
                setDisplayStatus(<HighlightOffIcon fontSize="medium"/>)
                break;
            case "BLOQUEADO":
                setDisplayStatus(<LockIcon fontSize="medium"/>)
                break;
        }
    }, [])


    return (
        <Popup
            buttonText={button}
            title={title}
            iconSpan={iconSpan}
            lgShow={lgShow} setLgShow={setLgShow}
            buttonId={"links-action"}
        >

            <List
                sx={{width: '60%', bgcolor: 'background.paper', marginInline: '20%'}}
                component="nav"
                aria-labelledby="nested-list-subheader"
            >
                {/*  Primary Data  */}
                <DisplayDetailsData
                    key="data"
                    title='Dados primários'
                    openDefault
                    itens={[
                        {
                            icon: <BadgeIcon/>,
                            display: `Razão Social:  ${item.name}`
                        },
                        {
                            icon: <TrendingDownIcon/>,
                            display: `Valor Mínimo Carga:  R$${(Math.round(item.minChargeValue * 100) / 100).toFixed(2)}`
                        },
                        {
                            icon: <TrendingUpIcon/>,
                            display: `Valor Máximo Carga:  R$${(Math.round(item.maxChargeValue * 100) / 100).toFixed(2)}`
                        },
                        {
                            icon: <SuperscriptIcon/>,
                            display: `Multiplicidade Carga:  ${item.chargeMultiplicity}`
                        },
                        {
                            icon: <DateRangeIcon/>,
                            display: `Validade Carga:  ${item.chargeValidity} dias`
                        },
                        {
                            icon: <KeyIcon/>,
                            display: `Range (Nº de identificação da Unidade de Negócio):  ${item.range}`
                        }, {
                            icon: displayStatus,
                            display: status
                        }]
                    }
                />
                {/*  Fees  */}
                <DisplayDetailsData
                    key="fee"
                    title='Taxas'
                    itens={[
                        {
                            icon: <MonetizationOnIcon/>,
                            display: `Taxa de Carga:  ${item.loadFee}%`
                        },
                        {
                            icon: <MonetizationOnIcon/>,
                            display: `Taxa de Resgate:  ${item.redeemFee}%`
                        },
                        {
                            icon: <MonetizationOnIcon/>,
                            display: `Taxa de Administração:  ${item.administrationFee}%`
                        }
                    ]}
                />
            </List>

        </Popup>
    )
}

export default BusinessUnitDetails;
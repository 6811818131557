import MainNavbar from "../../components/MainNavbar";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import {Paper} from "@mui/material";
import Footer from "../../examples/Footer";
import React from "react";
import CustomCard from "../../components/CustomCard/CustomCard";
import LoadCard from "./components/LoadCard/LoadCard";
import Divider from "@mui/material/Divider";
import BalanceTransfer from "./components/BalanceTransfer/BalanceTransfer";
import CardRevalidation from "./components/CardRevalidation/CardRevalidation";


export default function BatchProcessing() {
    return (<>
        <MainNavbar/>
        <DashboardLayout>
            <Paper className="paper-list mb-3 w-100 justify-content-center align-content-center">
                <h1>Processamentos em lote</h1>
                
                <Divider/>

                <LoadCard/>

                <BalanceTransfer/>

                <CardRevalidation/>

            </Paper>
        </DashboardLayout>
        <Footer/>
    </>)
}
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.downloadIcon {
    width: 1.8em !important;
    height: 1.8em !important;
}
.downloadButton.disabled:hover {
    cursor: default;
}`, "",{"version":3,"sources":["webpack://./src/layouts/report/components/ListReport.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,wBAAwB;AAC5B;AACA;IACI,eAAe;AACnB","sourcesContent":[".downloadIcon {\r\n    width: 1.8em !important;\r\n    height: 1.8em !important;\r\n}\r\n.downloadButton.disabled:hover {\r\n    cursor: default;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

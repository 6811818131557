import React, {useEffect, useState} from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import SelectInput from "../../../components/SelectInput";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import CargaResgateFilter from "./FilterInputs/CargaResgateFilter";
import ResgatePorGrupoFilter from "./FilterInputs/ResgatePorGrupoFilter";
import ResgateXCargaFilter from "./FilterInputs/ResgateXCargaFilter";
import RevalidadosFilter from "./FilterInputs/RevalidadosFilter";
import SinteticosCargaResgateLojaFilter from "./FilterInputs/SinteticosCargaResgateLojaFilter";
import TransferenciaSaldo from "./FilterInputs/TransferenciaSaldo";
import CartoesBaixadosFilter from "./FilterInputs/CartoesBaixadosFilter";
import CartoesVencidosXRevalidados from "./FilterInputs/CartoesVencidosXRevalidados";

const InputsReport = ({
                          reportsList,
                          setReportsList,
                          report,
                          setReport,
                          filter, setFilter,
                          baseUrl,
                          format,
                          setFormat
                      }) => {


    const [filterInputs, setFilterInputs] = useState(<></>);

    useEffect(() => {
        setFilterInputs(() => {
            switch (report?.id) {
                case 2:
                    return <CargaResgateFilter filter={filter} setFilter={setFilter}/>
                case 3:
                    return <ResgatePorGrupoFilter filter={filter} setFilter={setFilter}/>
                case 4:
                    return <ResgateXCargaFilter filter={filter} setFilter={setFilter}/>
                case 5:
                    return <RevalidadosFilter filter={filter} setFilter={setFilter}/>
                case 6:
                    return <SinteticosCargaResgateLojaFilter filter={filter} setFilter={setFilter}/>
                case 7:
                    return <TransferenciaSaldo filter={filter} setFilter={setFilter}/>
                case 8:
                    return <CartoesBaixadosFilter filter={filter} setFilter={setFilter}/>
                case 9:
                    return <CartoesVencidosXRevalidados filter={filter} setFilter={setFilter}/>
                default:
                    return <></>;
            }
        })
    }, [report])

    return (
        <>
            <MDBox mt={2}>
                <MDTypography variant="h6">Formato do Arquivo</MDTypography>
                <RadioGroup
                    sx={{display: "flex", justifyContent: "center"}}
                    row
                    value={format}
                    onChange={(e) => setFormat(e.target.value)}
                >
                    <FormControlLabel value="XLSX" control={<Radio/>} label="Excel"/>
                    <FormControlLabel value="CSV" control={<Radio/>} label="CSV"/>
                    <FormControlLabel value="TXT" control={<Radio/>} label="TXT"/>
                </RadioGroup>
            </MDBox>

            <SelectInput
                url={baseUrl + '/report-list'}
                list={reportsList}
                set={setReportsList}
                setObj={setReport}
                returnList={() => {
                    return reportsList.map((item) => ({
                        value: item,
                        label: `${item.name}`,
                    }));
                }}
                placeholder={"Relatório"}
                required={true}
                fullWidth
                style={{ position: "relative", zIndex: 3 }}
            />


            {filterInputs}
        </>
    );
};

export default InputsReport;

import React, {useEffect, useState} from "react";
import "./SelectMonitoringParam.css"
import {useNavigate} from "react-router-dom";

export default function SelectMonitoringParam({
                                                  valueSelected,
                                                  setFunction,
                                                  setLoadData,
                                                  loadData
                                              }) {

    const navigate = useNavigate();

    const values = [
        'Hoje',
        'Ontem',
        'Este Mês',
        'Mês Anterior',
        'Mês -2',
        'Mês -3',
        'Este Semestre',
        'Semestre Passado',
        'Este Ano',
        'Ano Passado',
        'Ano -2',
        'Ano -3',
    ]

    const [buttons, setButtons] = useState(<> </>)

    useEffect(() => {
        setButtons(
            <div className='select-monitoring-param'>
                {
                    values.map((value, index) => (
                        <button key={index}
                                className={
                                    (value === valueSelected) ? 'btn btn-primary' : 'btn btn-outline-primary'
                                }
                                onClick={() => {
                                    navigate('/monitoring/' + value)
                                }}
                                disabled={loadData}
                        >
                            {value}
                        </button>
                    ))
                }
            </div>
        )
    }, [valueSelected, loadData])

    return (
        <>
            {buttons}
        </>
    )
}
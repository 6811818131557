import TableList from "../../../components/TableList/TableList";
import React, {useState} from "react";
import PopupEditBusinessUnit from "./PopupEditBusinessUnit";
import DisplayStatus from "../../../components/Status/DisplayStatus";
import BusinessUnitDetails from "./businessUnitDetails";
import {NumberWithPoints} from "../../../utils/numberWithPoints";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import HelpIcon from "@mui/icons-material/Help";
import FilterFormStore from "../../store/components/FilterFormStore";
import FilterFormBusinessUnit from "./FilterFormBusinessUnit";


const ListBusinessUnit = ({
                              baseUrl, loadData,
                              setLoadData, size, page,
                              setTotalPages, setTotalElements,
                              sort, setSort, updateCode,setPage
                          }) => {

    const [data, setData] = useState([])
    const [error, setError] = useState(false)
    const [filter, setFilter] = useState({})

    const renderTooltipMultiplicity = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            A carga será multipla do valor digitado.
        </Tooltip>
    );

    const multiplicityDisplay = <OverlayTrigger
        placement="right"
        delay={{show: 250, hide: 400}}
        overlay={renderTooltipMultiplicity}
    >
        <span>Multiplicidade Carga<HelpIcon fontSize="small"/></span>
    </OverlayTrigger>

    const renderTooltipRange = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Número(digíto) que indica a unidade de negócio no cartão.
        </Tooltip>
    );

    const rangeDisplay = <OverlayTrigger
        placement="right"
        delay={{show: 250, hide: 400}}
        overlay={renderTooltipRange}
    >
        <span>Range<HelpIcon fontSize="small"/></span>
    </OverlayTrigger>

    const titles = [
        "Nome",
        "Valor Mínimo Carga",
        "Valor Máximo Carga",
        multiplicityDisplay,
        "Validade Carga",
        rangeDisplay,
        "Status"
    ]

    return (
        <>
            <FilterFormBusinessUnit filter={filter} setFilter={setFilter} setLoadData={setLoadData} setPage={setPage}/>
            <TableList
                titles={titles} baseUrl={baseUrl}
                page={page} sort={sort} size={size}
                loadData={loadData}
                setTotalPages={setTotalPages} setTotalElements={setTotalElements}
                setData={setData} setLoadData={setLoadData} filter={filter}
                error={error} setError={setError}
            >
                {data.map((item) =>
                    <tr key={item.id}>
                        <td>{item.name}</td>
                        <td>R$ {NumberWithPoints((Math.round(item.minChargeValue * 100) / 100).toFixed(2))}</td>
                        <td>R$ {NumberWithPoints((Math.round(item.maxChargeValue * 100) / 100).toFixed(2))}</td>
                        <td>{item.chargeMultiplicity}</td>
                        <td>{item.chargeValidity} dias</td>
                        <td>{item.range}</td>
                        <td><DisplayStatus status={item.status}/></td>
                        <td>
                            <BusinessUnitDetails item={item}/>
                            <PopupEditBusinessUnit
                                item={item}
                                baseUrl={baseUrl}
                                setLoadData={setLoadData}
                                updateCode={updateCode}
                            />
                        </td>
                    </tr>
                )}
            </TableList>
        </>
    )

}

export default ListBusinessUnit

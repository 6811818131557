import React, {useState} from "react";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import axios from "axios";
import Popup from "../Popup/Popup";
import {Button, Form} from "react-bootstrap";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const PopupDelete = ({
                         baseUrl, setLoadData,
                         title, id, buttonText,
                         children
                     }) => {
    // modal control
    const [lgShow, setLgShow] = useState(false);

    const iconSpan = <>
        <DeleteForeverIcon fontSize="large" id="icon-svg"/>
        {title}
    </>

    const button = <span className="link-danger">
        {buttonText}
        <DeleteForeverIcon fontSize="medium"/>
    </span>


    const handleSubmit = (e) => {
        e.preventDefault()
        axios.delete(`${baseUrl}/${id}`)
            .then((response) => {
                setLoadData(true)
                setLgShow(false)
            })
    }


    return (
        <>
            {/* Botão e popup */}
            <Popup
                buttonText={button}
                title={title}
                iconSpan={iconSpan}
                lgShow={lgShow} setLgShow={setLgShow}
                buttonId={"links-action"}
            >
                <div>
                    <Form onSubmit={handleSubmit} className="form-inputs">
                        {children}
                        <Button type="submit" className="btn-danger">
                            Confirmar
                        </Button>
                    </Form>
                </div>


            </Popup>
        </>
    )
}

export default PopupDelete
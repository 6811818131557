import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Radio,
    RadioGroup,
    TextField,
} from "@mui/material";
import { withStyles } from '@material-ui/core/styles';
import axios from "axios";
import { AutoCompleteInput } from "components/FilteredSelectInput";
import React, { useEffect, useState } from "react";
import Switch from "@mui/material/Switch";

export default function InputsUserEdit({
                                       username,
                                       setUsername,
                                       name,
                                       setName,
                                       status,
                                       setStatus,
                                       email,
                                       setEmail,
                                       roles,
                                       setRoles,
                                       scopes,
                                       setScopes,
                                       scope,
                                       setScope,
                                       internalCode,
                                       setInternalCode,
                                       owner,
                                       setOwner,
                                       errorMessage,
                                       errorColumn,
                                       isTwoFactorEnabled, setIsTwoFactorEnabled,
                                       StatusEdit
                                   }) {

    const [storeList, setStoreList] = useState([]);
    const [errorName, setErrorName] = useState(false);
    const [errorNameText, setErrorNameText] = useState(false);
    const [errorUsername, setErrorUsername] = useState(false);
    const [errorUsernameText, setErrorUsernameText] = useState(false);
    const [errorEmail, setErrorEmail] = useState(false);
    const [errorEmailText, setErrorEmailText] = useState(false);

    useEffect(() => {
        setErrorName(false);
        setErrorNameText(false);
        switch (errorColumn) {
            case "name":
                setErrorName(true);
                setErrorNameText(errorMessage);
                break;
            case "username":
                setErrorUsername(true);
                setErrorUsernameText(errorMessage);
                break;
            case "email":
                setErrorEmail(true);
                setErrorEmailText(errorMessage);
                break;
        }
    }, [errorColumn, errorMessage]);

    useEffect(() => {

        if (roles?.length === 0) {
            axios.get(`http://${process.env.REACT_APP_CARD_PROCESS_SECURITY_LINK}/roles`)
                .then(function (response) {
                    setRoles(response.data.collection);
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
        if (scopes?.length === 0) {
            axios.get(`http://${process.env.REACT_APP_CARD_PROCESS_SECURITY_LINK}/scopes`)
                .then(function (response) {
                    setScopes(response.data.collection);
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }, []);

    const handleRole = (roleId) => {
        setRoles((prevRoles) =>
            prevRoles.map((role) => {
                if (role.id === roleId) {
                    return { ...role, checked: !role.checked };
                }
                return role;
            })
        );
    };

    const selectedRoles = roles.filter((role) => role.checked);

    const styles = theme => ({
        root: {
            color: 'green',
        },
    });
    const customFormLabel = withStyles(styles)(FormLabel);

    return (
        <>
            <TextField
                id="input-with-icon-textfield"
                label="Nome do Usuário"
                inputProps={{
                    maxLength: 20,
                }}
                required={true}
                size="large"
                erortext="Campo obrigatório!"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                error={errorUsername}
                helperText={errorUsernameText}
                fullWidth
            />
            <TextField
                label="Nome Completo"
                required={true}
                inputProps={{
                    maxLength: 70,
                }}
                type="text"
                erortext="Campo obrigatório!"
                value={name}
                onChange={(e) => setName(e.target.value)}
                error={errorName}
                helperText={errorNameText}
                fullWidth
            />
            <TextField
                label="Email"
                required={true}
                inputProps={{
                    maxLength: 50,
                }}
                type="text"
                erortext="Campo obrigatório!"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={errorEmail}
                helperText={errorEmailText}
                fullWidth
            />

            <FormControlLabel style={{color: "blue"}} control={
                <Switch checked={isTwoFactorEnabled} onChange={
                    () => setIsTwoFactorEnabled(!isTwoFactorEnabled)
                } style={{color: "blue"}}/>
            } label="Verificação de duas etapas"/>

            <FormControl>
                <customFormLabel>Organização à qual usuário pertence</customFormLabel>
                <RadioGroup
                    name="controlled-radio-buttons-group"
                    value={scope}
                    onChange={e => setScope(e.target.value)}
                    sx={{ my: 1 }}
                >
                    {scopes?.map(item => (
                        <FormControlLabel key={item.id}
                                          value={item.id}
                                          control={<Radio />}
                                          label={item.name}
                        />
                    ))}
                </RadioGroup>
            </FormControl>

            {scope === 3 && ( <p>{owner.logicCode} - {owner.name}</p>  )}

            {scope === "3" && (
                <AutoCompleteInput
                    sizeToSearch={3}
                    filter={`internalCode=${internalCode}`}
                    value={internalCode}
                    setValue={setInternalCode}
                    firstInputLabel={'Digite 3 dígitos do código interno da loja e uma lista de lojas aparecerá.'}
                    secondInputLabel={'Selecione a loja:'}

                    url={`http://${process.env.REACT_APP_GLOBAL_PROCESS_LINK}/Store?size=200000000`}
                    list={storeList}
                    set={setStoreList}
                    setObj={setInternalCode}
                    defaultValue={internalCode}
                    required={true}
                    fullWidth
                    getLabel={(item) => {
                        return `${item.internalCode} - ${item.corporateName}`
                    }}
                    getValueForList={(item) => {
                        return item.internalCode
                    }}
                />
            )}

            <FormGroup>
                <customFormLabel>Perfis de Acesso</customFormLabel>
                <p style={{fontSize: '0.8vw'}}>
                    * Selecione até 2 perfis.
                </p>
                {roles.map((role) => (
                    <FormControlLabel
                        key={role.id}
                        control={
                            <Checkbox
                                checked={role.checked}
                                onChange={() => handleRole(role.id)}
                                disabled={selectedRoles.length === 2 && !role.checked}
                            />
                        }
                        label={role.name}
                    />
                ))}
            </FormGroup>

            {/*{StatusEdit && <FormControl>*/}
            {/*    <customFormLabel>Status do Usuário</customFormLabel>*/}
            {/*    <RadioGroup*/}
            {/*        row*/}
            {/*        name="row-radio-buttons-group"*/}
            {/*        id="radios-status"*/}
            {/*        value={status}*/}
            {/*        onChange={e => setStatus(e.target.value)}*/}
            {/*        sx={{ my: 1 }}*/}
            {/*    >*/}
            {/*        <FormControlLabel value="" control={<Radio />} label={<span>Todos</span>} />*/}
            {/*        <FormControlLabel value="ACTIVE" control={<Radio />} label={<span>Ativo</span>} />*/}
            {/*        <FormControlLabel value="INACTIVE" control={<Radio />} label={<span>Inativo</span>} />*/}
            {/*        <FormControlLabel value="BLOCKED" control={<Radio />} label={<span>Bloqueado</span>} />*/}
            {/*        <FormControlLabel value="BLOCKED_INACTIVED" control={<Radio />} label={<span>Inativo Bloqueado</span>} />*/}
            {/*        <FormControlLabel value="PASSWORD_EXPIRED" control={<Radio />} label={<span>Senha Expirada</span>} />*/}
            {/*        <FormControlLabel value="MIGRATED" control={<Radio />} label={<span>Migrado</span>} />*/}
            {/*    </RadioGroup>*/}
            {/*</FormControl>}*/}
            {/*<br/>*/}

        </>
    );
}

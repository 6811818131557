import MainNavbar from "components/MainNavbar";
import ListUser from "./components/ListUser";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import PaginationControl from "components/Pagination/PaginationControl";
import { Paper } from "@mui/material";
import { useEffect, useState } from "react";
import PopupAddUser from "./components/PopupAddUser";
import { useAuthContext } from "components/AuthenticationProvider/AuthenticationProvider";

export default function User() {
 
	const baseUrl = `http://${process.env.REACT_APP_CARD_PROCESS_SECURITY_LINK}/users`;
	const [loadData, setLoadData] = useState(true);
	const [page, setPage] = useState(0);
	const [size, setSize] = useState(25);
	const [totalPages, setTotalPages] = useState(0);
	const [totalElements, setTotalElements] = useState(0);
	const [sort, setSort] = useState("id");

    // Informação de Usuário e suas permissões
    const authContext = useAuthContext();

  return (
    <>
        <MainNavbar/>
        <DashboardLayout>
            <Paper className="paper-list mb-3">
                <h1>Lista de Usuários</h1>

                {/*  'hasPermission' => passa o tela e ação p/ validar se tem permissão */}

                {
                    authContext.hasPermission('user', 'create') ?
                        <PopupAddUser baseUrl={baseUrl} setLoadData={setLoadData}/>
                        : null
                }

                <ListUser
                    baseUrl={baseUrl}
                    loadData={loadData}
                    setLoadData={setLoadData}
                    setPage={setPage}
                    page={page}
                    size={size}
                    setTotalPages={setTotalPages}
                    setTotalElements={setTotalElements}
                    sort={sort} setSort={setSort}
                />
                <PaginationControl
                    totalPages={totalPages}
                    totalElements={totalElements}
                    page={page}
                    setPage={setPage}
                    size={size}
                    setSize={setSize}
                    setLoadData={setLoadData}
                    RowPerPages={[25, 50, 100]}
                />
            </Paper>
        </DashboardLayout>
    </>
  );
}
import React, { useState, useEffect } from 'react';
import axios from "axios";
import MainNavbar from "../../components/MainNavbar";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import Footer from "../../examples/Footer";
import { Paper } from "@mui/material";
import "./monitoringLog.css"
import BasicLoader from "../../components/BasicLoader/BasicLoader";

export default function MonitoringLog() {
    const [dateRequest, setDateRequest] = useState('');
    const [responseRequest, setResponseRequest] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios.get(`http://${process.env.REACT_APP_MONITOR_LINK}/log`)
            .then(response => {
                const data = response.data;
                const date = new Date(data.localDate);
                const formattedDate = formatDate(date);

                setDateRequest(formattedDate);
                setResponseRequest(data.logText);
            })
            .catch(error => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const formatDate = (date) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', timeZoneName: 'short' };
        return date.toLocaleDateString('pt-BR', options);
    };

    return (
        <>
            <MainNavbar />
            <DashboardLayout>
                <Paper className="paper-list mb-3 w-100">
                    <h1>Log de autorizador</h1>
                    {loading?
                        <BasicLoader message='Buscando Log'/>:
                        <div className="logMonitoringRequests">
                            <p>Feito em: {dateRequest}</p>
                            <>
                                <section>
                                <pre>
                                    {responseRequest}
                                </pre>
                                </section>
                            </>
                        </div>
                    }

                </Paper>
            </DashboardLayout>
            <Footer />
        </>
    )
}

import "./Initial.css";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "components/PrimaryButton";
import Logo from "../../assets/images/logo.png";

export default function Initial() {
  const navigate = useNavigate();
  return (
    <div className="initial-page">
      <div className="container">
        <div className="wrap">
          <img src={Logo} alt="oBoticario" />
          <div className="box">
            <span className="title">
              Bem-Vindo ao <br />
              Cartão Presente
            </span>
          </div>
          <div
            role="button"
            tabIndex={-1}
            onClick={() => navigate("/home")}
            onKeyDown={() => navigate("/home")}
          >
            <PrimaryButton />
          </div>
        </div>
      </div>
    </div>
  );
}

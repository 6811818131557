import PopupAdd from "components/PopupAdd/PopupAdd";
import InputsUser from "./InputsUser";
import { useState } from "react";
import SouthAmericaIcon from '@mui/icons-material/SouthAmerica';

export default function PopupAddUser({baseUrl, setLoadData}) {

  const [username, setUsername] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [roles, setRoles] = useState([]);
  const [scopes, setScopes] = useState([]);
  const [scope, setScope] = useState("");
  const [internalCode, setInternalCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("")
  const [errorColumn, setErrorColumn] = useState("")


  const inputs = <InputsUser
    name={name} setName={setName}
    username={username} setUsername={setUsername}
    email={email} setEmail={setEmail}
    roles={roles} setRoles={setRoles}
    internalCode={internalCode} setInternalCode={setInternalCode}
    scope={scope} setScope={setScope}
    scopes={scopes} setScopes={setScopes}
    errorMessage={errorMessage}
    errorColumn={errorColumn}
  />

  const icon = <SouthAmericaIcon fontSize="large" id="icon-svg"/>;

  const title = "Usuário";

  function clearForm() {
    setName("");
    setUsername("");
    setEmail("");
    setRoles([]);
    setScope("");
    setErrorColumn("");
    setErrorMessage("");
  }

  function validateRoles() {
    if (roles.filter(role => role.checked).length === 0) {
      setErrorMessage("Pelo menos um perfil de acesso é obrigatório.");
      setErrorColumn("roles");
      return false;  // Validação falhou
    }

    return true;  // Validação passou
  }


  function returnData() {

    if (!validateRoles()) {
      return null // Impede o envio dos dados caso a validação falhe
    }

    return {
      name,
      username,
      email,
      roles,
      scopes,
      scope,
      internalCode,
    };
  }

  return (
    <>
      <PopupAdd
          baseUrl={baseUrl} setLoadData={setLoadData}
          inputs={inputs} title={title} icon={icon}
          clearForm={clearForm} returnData={returnData}
          setErrorMessage={setErrorMessage} setErrorColumn={setErrorColumn}
      />
    </>
  );
}
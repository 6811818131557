// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bg-custom-1 {
    background-color: #85144b;
  }
  
  .bg-custom-2 {
  background-image: linear-gradient(15deg, #13547a 0%, #80d0c7 100%);
  }

  #navbarScrollingDropdown{
      color: #d0d0d0;
  }`, "",{"version":3,"sources":["webpack://./src/components/MainNavbar/Navbar.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;EAC3B;;EAEA;EACA,kEAAkE;EAClE;;EAEA;MACI,cAAc;EAClB","sourcesContent":[".bg-custom-1 {\r\n    background-color: #85144b;\r\n  }\r\n  \r\n  .bg-custom-2 {\r\n  background-image: linear-gradient(15deg, #13547a 0%, #80d0c7 100%);\r\n  }\r\n\r\n  #navbarScrollingDropdown{\r\n      color: #d0d0d0;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;


import PropTypes from "prop-types";

// Material Dashboard 2 PRO React Components
import MDSnackbar from "components/MDSnackbar";


function UserMessage({type, content, open, close}) {
  
  return (
    <>
      <MDSnackbar
        color={type === "info" ? "info" : type === "success" ? "success" : type === "warning" ? "warning" : "error"}
        icon={type === "info" ? "info" : type === "success" ? "check" : type === "warning" ? "warning" : "error"}
        title={type === "info" ? "Informação" : type === "success" ? "Sucesso" : type === "warning" ? "Aviso" : "Erro"}
        content={content}
        dateTime={""}
        open={open}
        close={close}
      />
    </>
  )
}

UserMessage.defaultProps = {
  type: PropTypes.oneOf(["info", "success", "warning", "error"]),

}  

export default UserMessage;
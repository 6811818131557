import FilterForm from "../../../components/FilterForm/FilterForm";
import TextField from "@mui/material/TextField";
import MDBox from "../../../components/MDBox";
import {Button, FormControlLabel, FormLabel, IconButton, InputAdornment, Radio, RadioGroup} from "@mui/material";
import DisplayStatus from "../../../components/Status/DisplayStatus";
import React, {useState} from "react";
import DisplayType from "../../../components/Type/DisplayType";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import HelpIcon from "@mui/icons-material/Help";
import ClearIcon from "@mui/icons-material/Clear";
import applyRegex from "../../../utils/applyRegex";

const FilterFormTransaction = ({filter, setFilter, setLoadData, setPage, baseUrl}) => {

    const [lgShow, setLgShow] = useState(false);


    const [cardNumber, setCardNumber] = useState()
    const [mid, setMid] = useState()
    const [dateTime, setDateTime] = useState()
    const [dateTimeEnd, setDateTimeEnd] = useState()
    const [value, setValue] = useState()
    const [nsu, setNsu] = useState()
    const [type, setType] = useState("")
    const [status, setStatus] = useState("")
    const [internalCode, setInternalCode] = useState("")

    const renderTooltipName = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Visualização dísponivel nos detalhes de cada transação.
        </Tooltip>
    );

    const displayName = <OverlayTrigger
        placement="right"
        delay={{show: 250, hide: 400}}
        overlay={renderTooltipName}
    >
        <span><HelpIcon fontSize="small"/></span>
    </OverlayTrigger>

    const handlesSubmit = (e) => {
        if (e) {
            e.preventDefault();
        }

        console.log({
            ...(cardNumber && {cardNumber}),
            ...(mid && {mid}),
            ...(dateTime && {dateTime}),
            ...(dateTimeEnd && {dateTimeEnd}),
            ...(value && {value}),
            ...(internalCode && {internalCode}),
            ...(nsu && {hostNsu: nsu}),
            ...(type && {type}),
            ...(status && {status}),
        })

        setFilter({
            ...(cardNumber && {cardNumber}),
            ...(mid && {mid}),
            ...(dateTime && {dateTime}),
            ...(dateTimeEnd && {dateTimeEnd}),
            ...(value && {value}),
            ...(internalCode && {internalCode}),
            ...(nsu && {hostNsu: nsu}),
            ...(type && {type}),
            ...(status && {status}),
        });

        setLgShow(false);
        setLoadData(true);

        setPage(0);
    };

    const clearFilter = () => {
        setCardNumber("");
        setMid("");
        setDateTime("")
        setDateTimeEnd("")
        setValue("")
        setInternalCode("")
        setNsu("")
        setType("")
        setStatus("")

        setFilter({})

        setLgShow(false);
        setLoadData(true);
    };

    const clearAll = () => {
        setCardNumber("");
        setMid("");
        setDateTime("")
        setDateTimeEnd("")
        setValue("")
        setInternalCode("")
        setNsu("")
        setType("")
        setStatus("")

        setFilter({})
    };

    return (
        <FilterForm
            handleSubmit={handlesSubmit} clearFilter={clearFilter}
            clearAll={clearAll}
            lgShow={lgShow} setLgShow={setLgShow}
            showSecondaryButton={Object.keys(filter).length !== 0}
        >
            <TextField
                label="Número do Cartão"
                inputProps={{maxLength: 19}}
                // error={cardNumber === ""}
                // helperText={cardNumber === "" ? "Campo obrigatório!" : ""}
                value={cardNumber}
                onChange={(e) => {
                    const inputNumber = e.target.value.replace(/\D/g, '');
                    setCardNumber(inputNumber);
                }}
                fullWidth
                autoFocus
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {cardNumber && (
                                <IconButton size="small" onClick={() => setCardNumber("")}>
                                    <ClearIcon />
                                </IconButton>
                            )}
                        </InputAdornment>
                    ),
                }}
            />
            <TextField
                label="Código Lógico"
                inputProps={{maxLength: 19}}
                // error={mid === ""}
                // helperText={mid === "" ? "Campo obrigatório!" : ""}
                value={mid}
                onChange={(e) => {
                    const inputNumber = e.target.value.replace(/\D/g, '');
                    setMid(inputNumber);
                }}
                fullWidth
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {mid && (
                                <IconButton size="small" onClick={() => setMid("")}>
                                    <ClearIcon />
                                </IconButton>
                            )}
                        </InputAdornment>
                    ),
                }}
            />
            <TextField
                label="Data de início"
                type="date"
                // error={dateTime === ""}
                // helperText={dateTime === "" ? "Campo obrigatório!" : ""}
                value={dateTime}
                onChange={(e) => setDateTime(e.target.value)}
                fullWidth
                InputLabelProps={{shrink: true}}
                inputProps={{
                    min: "1970-01-01",
                    max: "2100-12-31"
                }}
            />
            <TextField
                label="Data de fim"
                type="date"
                // error={dateTimeEnd === ""}
                // helperText={dateTimeEnd === "" ? "Campo obrigatório!" : ""}
                value={dateTimeEnd}
                onChange={(e) => setDateTimeEnd(e.target.value)}
                fullWidth
                InputLabelProps={{shrink: true}}
                inputProps={{
                    min: "1970-01-01",
                    max: "2100-12-31"
                }}
            />
            <TextField
                label="Valor da Transação"
                inputProps={{maxLength: 19}}
                // error={value === ""}
                // helperText={value === "" ? "Campo obrigatório!" : ""}
                value={value}
                onChange={(e) => {
                    const inputNumber = e.target.value.replace(/[^\d.-]/g, '');
                    setValue(inputNumber);
                }}
                fullWidth
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {value && (
                                <IconButton size="small" onClick={() => setValue("")}>
                                    <ClearIcon />
                                </IconButton>
                            )}
                        </InputAdornment>
                    ),
                }}
            />
            <TextField
                label="NSU"
                inputProps={{maxLength: 10}}
                // error={nsu === ""}
                // helperText={nsu === "" ? "Campo obrigatório!" : ""}
                value={nsu}
                onChange={(e) => {
                    const inputNumber = e.target.value.replace(/\D/g, '');
                    setNsu(inputNumber);
                }}
                fullWidth
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {nsu && (
                                <IconButton size="small" onClick={() => setNsu("")}>
                                    <ClearIcon />
                                </IconButton>
                            )}
                        </InputAdornment>
                    ),
                }}
            />
            <TextField
                label="Código Interno da Loja"
                inputProps={{ maxLength: 50 }}
                value={internalCode}
                onChange={(e) => setInternalCode(applyRegex(e.target.value, /\d+/))}
                fullWidth
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {internalCode && (
                                <IconButton size="small" onClick={() => setInternalCode("")}>
                                    <ClearIcon />
                                </IconButton>
                            )}
                        </InputAdornment>
                    ),
                }}
            />
            <MDBox mb={0.5} pb={0.5}>
                <FormLabel id="demo-row-radio-buttons-group-label" className="text-center">
                    Tipo de Transação
                </FormLabel>
                <RadioGroup
                    row
                    name="row-radio-buttons-group"
                    id="radios-type"
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                    required
                >
                    <FormControlLabel value="" control={<Radio/>} label={<span>Todos</span>}/>
                    <FormControlLabel value="RESGATE" control={<Radio/>} label={<DisplayType type="RESGATE"/>}/>
                    <FormControlLabel value="CARGA" control={<Radio/>} label={<DisplayType type="CARGA"/>}/>
                    <FormControlLabel value="TRANSF_DEBITO" control={<Radio/>}
                                      label={<DisplayType type="TRANSF_DEBITO"/>}/>
                    <FormControlLabel value="TRANSF_CREDITO" control={<Radio/>}
                                      label={<DisplayType type="TRANSF_CREDITO"/>}/>
                    <FormControlLabel value="BAIXA_SALDO_VENCIDO" control={<Radio/>}
                                      label={<DisplayType type="BAIXA_SALDO_VENCIDO"/>}/>
                    <FormControlLabel value="REVALIDACAO" control={<Radio/>}
                                      label={<DisplayType type="REVALIDACAO"/>}/>
                </RadioGroup>
            </MDBox>

            <MDBox mb={0.5} pb={0.5}>
                <FormLabel id="demo-row-radio-buttons-group-label" className="text-center">
                    Status
                </FormLabel>
                <RadioGroup
                    row
                    name="row-radio-buttons-group"
                    id="radios-status"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    required
                >
                    <FormControlLabel value="" control={<Radio/>} label={<span>Todos</span>}/>
                    <FormControlLabel value="PENDENTE" control={<Radio/>} label={<DisplayStatus status="PENDENTE"/>}/>
                    <FormControlLabel value="NEGADA" control={<Radio/>} label={<DisplayStatus status="NEGADA"/>}/>
                    <FormControlLabel value="CONFIRMADA" control={<Radio/>}
                                      label={<DisplayStatus status="CONFIRMADA"/>}/>
                    <FormControlLabel value="DESFEITA" control={<Radio/>} label={<DisplayStatus status="DESFEITA"/>}/>
                </RadioGroup>
            </MDBox>
        </FilterForm>
    )
}
export default FilterFormTransaction
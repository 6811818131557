import React, {useEffect, useState} from "react";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import RedeemIcon from '@mui/icons-material/Redeem';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import "./DisplayType.css"

const DisplayType = ({type}) => {

    const [display, setDisplay] = useState("")

    useEffect(() => {

        switch (type) {
            case "FISICO":
                setDisplay(<span className="type-physicist">
                    <CreditCardIcon fontSize="medium"/> Fisico
                </span>)
                break;
            case "DIGITAL":
                setDisplay(<span className="type-digital">
                    <CreditCardIcon fontSize="medium"/> Digital
                </span>)
                break;
        }

    }, [type]);

    useEffect(() => {

        switch (type) {
            case "CARGA":
                setDisplay(<span className="type-physicist">
                    <CreditCardIcon fontSize="medium"/> Carga
                </span>)
                break;
            case "RESGATE":
                setDisplay(<span className="type-digital">
                    <RedeemIcon fontSize="medium"/> Resgate
                </span>)
                break;
            case "TRANSF_DEBITO":
                setDisplay(<span className="type-physicist">
                    <RedeemIcon fontSize="medium"/> Transferência de Débito
                </span>)
                break;
            case "REVALIDACAO":
                setDisplay(<span className="type-physicist">
                    <CreditScoreIcon fontSize="medium"/> Revalidação
                </span>)
                break;
            case "TRANSF_CREDITO":
                setDisplay(<span className="type-physicist">
                    <RedeemIcon fontSize="medium"/> Transferência de Crédito
                </span>)
                break;case "BAIXA_SALDO_VENCIDO":
                setDisplay(<span className="type-physicist">
                    <RedeemIcon fontSize="medium"/> Baixa
                </span>)
                break;
        }

    }, [type]);

    // const displayDate = ({dias}) => {
    //     switch (dias) {
    //         case "ATUAL":
    //             setDisplay(<span className="status-active">
    //                 <CheckCircleOutlineIcon fontSize="medium"/> Atual
    //             </span>)
    //             break;
    //         case "SEMANA":
    //             setDisplay(<span className="status-active">
    //                  <CheckCircleOutlineIcon fontSize="medium"/> Semanal
    //             </span>)
    //             break;
    //         case "MÊS":
    //             setDisplay(<span className="status-active">
    //                  <CheckCircleOutlineIcon fontSize="medium"/> Mês
    //             </span>)
    //             break;
    //     }
    // }, [dias]);


    return (
        <>
            {display}
        </>
    )
}

export default DisplayType
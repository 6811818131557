import React from "react";

import AccessAlarmsIcon from "@mui/icons-material/AccessAlarms";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

export const statusList = [
    {
        value: "PENDING",
        label: <span className="status-blocked">
            <AccessAlarmsIcon fontSize="medium"/> Pendente
        </span>
    },
    {
        value: "PROCESSING",
        label: <span className="status-blocked">
            <AccessAlarmsIcon fontSize="medium"/> Em processamento
        </span>
    },
    {
        value: "AVAILABLE",
        label: <span className="status-active">
            <CheckCircleOutlineIcon fontSize="medium"/> Disponível
        </span>
    },
    {
        value: "ERROR",
        label: <span className="status-inactive">
            <HighlightOffIcon fontSize="medium"/> Erro
        </span>
    },
    {
        value: "EXPIRED",
        label: <span className="status-expired">
            <AccessTimeFilledIcon fontSize="medium"/> Expirado
        </span>
    },
]
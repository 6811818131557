import React, {useEffect, useState} from "react";
import TextField from "@mui/material/TextField";
import {cnpjMask} from "../../../utils/cnpjMask";
import {validadeCnpj} from "../../../utils/validadeCnpj";
import MDTypography from "../../../components/MDTypography";
import MDBox from "../../../components/MDBox";
import Divider from "@mui/material/Divider";
import SelectInput from "../../../components/SelectInput";
import {formatCode} from "../../../utils/formatCode"
import {phoneMask} from "../../../utils/phoneMask";
import InputStatus from "../../../components/Status/InputStatus";
import '../Store.css';
import BankDetailsInputs from "./inputs/BankDetailsInputs";
import {FormControlLabel} from "@mui/material";
import Switch from "@mui/material/Switch";
import {AutoCompleteInput} from "../../../components/FilteredSelectInput";
import {useAuthContext} from "../../../components/AuthenticationProvider/AuthenticationProvider";
import axios from "axios";

const InputsStore = ({
                         setCorporateName, corporateName,
                         tradingName, setTradingName,
                         setCnpj, cnpj,
                         setInternalCode, internalCode,
                         setSupplierCode, supplierCode,
                         setPhoneNumber, phoneNumber,
                         setEmail, email,
                         cityId, setCityId,
                         setThoroughfare, thoroughfare,
                         setPostalCode, postalCode,
                         setNeighborhood, neighborhood,
                         setAddressNumber, addressNumber,
                         setComplement, complement,
                         setAgency, agency,
                         setAccount, account,
                         setBankCode, bankCode,
                         setStatus, status,
                         setBusinessUnit, businessUnit,
                         centralized, setCentralized,
                         centralizedStoreId, setCentralizedStoreId,
                         centralizedStoreCode, setCentralizedStoreCode,
                         setLoadFee, loadFee,
                         setRedeemFee, redeemFee,
                         setAdministrationFee, administrationFee,
                         setRegional, regional,
                         setFranchiseGroup, franchiseGroup,
                         setErrorMessage,
                         errorMessage,
                         setErrorColumn,
                         errorColumn,
                         setBlockSubmit,
                         // Exclusivos para edit
                         // naoObrigatorio,
                         isCentralizer,
                         disabled,
                         disabledFee,
                         item,
                         isDisabledInput
                     }
) => {

    const authContext = useAuthContext();
    const accessToken = authContext.accessToken;

    const InputLabelProps = {
        style: {
            zIndex: 0
        },
    }

    const baseUrl = `http://${process.env.REACT_APP_GLOBAL_PROCESS_LINK}`

    console.log({item})

    const [errorCode, setErrorCode] = useState(false)
    const [errorCodeText, setErrorCodeText] = useState(false)
    const [errorCnpj, setErrorCnpj] = useState(false)
    const [errorCnpjText, setErrorCnpjText] = useState(false)

    const [cityList, setCityList] = useState([])
    const [businessUnitList, setBusinessUnitList] = useState([])
    const [regionalList, setRegionalList] = useState([])
    const [groupList, setGroupList] = useState([])
    const [storeList, setStoreList] = useState([])
    const [logicCode, setLogicCode] = useState(centralizedStoreCode? centralizedStoreCode : '')
    const [autoCompleteKey, setAutoCompleteKey] = useState(0);

    const [businessUnitData, setBusinessUnitData] = useState()

    useEffect(() => {
        setErrorColumn("")
        setErrorMessage("")
        setBlockSubmit(true)
        setErrorCnpj(false)
        if (cnpj.length === 14) {
            setBlockSubmit(false)
            if (!validadeCnpj(cnpj)) {
                setErrorColumn('cnpj')
                setErrorMessage('Cnpj inválido!')
                setErrorCnpj(true)
                document.getElementById('input-cnpj').focus()
                setBlockSubmit(true)
            }
        }
    }, [cnpj])

    useEffect(() => {
        setErrorCode(false)
        setErrorCodeText(false)
        setErrorCnpj(false)
        setErrorCnpjText(false)
        switch (errorColumn) {
            case "internalCode":
                setErrorCode(true)
                setErrorCodeText(errorMessage)
                document.getElementById('input-code').focus()
                break
            case "cnpj":
                setErrorCnpj(true)
                setErrorCnpjText(errorMessage)
                document.getElementById('input-cnpj').focus()
                break
            case "establishmentCode":
                setErrorEstablishmentCode(true)
                setErrorEstablishmentCodeText(errorMessage)
                break
            case "LogicCode":
                setErrorLogicCode(true)
                setErrorLogicCodeText(errorMessage)
                break
        }
    }, [errorColumn, errorMessage])

    useEffect(async () => {
        if (postalCode.length === 8) {
            let url = `https://viacep.com.br/ws/${postalCode}/json/`

            // const resp = await fetch(url, {mode:'no-cors'})
            const resp = await fetch(url, {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'content-type': 'aplication/json'
                }
            })
            const data = await resp.json()
            if (!data.erro) {

                if (data.logradouro)
                    setThoroughfare(data.logradouro)
                if (data.bairro)
                    setNeighborhood(data.bairro)

                // const cityChosed = cityList.filter(city => city.name === data.localidade && city.state.uf === data.uf)
                // if (cityChosed[0]){
                //     setCityId(cityChosed[0].id)
                // }

                if (addressNumber.length === 0)
                    document.getElementById("input-number").focus()
            }

        }
    }, [postalCode])

    useEffect(() => {
        if (status === "ATIVO") {
            setAutoCompleteKey(prevKey => prevKey + 1);
        }
    }, [status]);


    useEffect(() => {
        // Cria uma instância do axios ou usa headers locais para evitar mudar defaults globalmente
        const fetchData = async () => {
            try {
                const response = await axios.get(`${baseUrl}/BusinessUnit?size=200000000&name=${item.businessUnitName}`, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    }
                });
                setBusinessUnitData(response.data.content);
                console.log(response.data.content);
            } catch (errorReceived) {
                console.error(errorReceived);
            }
        };

        fetchData();

    }, [item]);

    console.log({businessUnitData})


    const onChangeWithLimiter = (e, value, set) => {
        let valueSeted = e.target.value

        const numericValue = valueSeted.replace(/\D/g, ''); // Remove tudo exceto dígitos (0-9)

        if(numericValue.length <= value){
            set(numericValue)
        }
    }

    return (
        <React.Fragment>
            <MDBox mb={0.5} pb={0.5}>
                <MDTypography variant="h5" textTransform="capitalize" fontWeight="light">
                    Dados primários
                </MDTypography>
            </MDBox>
            <TextField
                label="Razão Social"
                required={true}
                inputProps={{
                    maxLength: 60
                }}
                helperText="Campo obrigatório!"
                value={corporateName}
                onChange={(e) => setCorporateName(e.target.value.toUpperCase())}
                fullWidth
                disabled={disabled}
            />
            <TextField
                label="Nome Fantasia"
                required={true}
                inputProps={{
                    maxLength: 60
                }}
                value={tradingName}
                onChange={(e) => setTradingName(e.target.value.toUpperCase())}
                fullWidth
                disabled={disabled}
            />
            <TextField
                label="CNPJ"
                required={true}
                inputProps={{
                    maxLength: 18,
                    minLength: 18
                }}
                value={cnpjMask(cnpj)}
                onChange={(e) => setCnpj(e.target.value
                    .replaceAll('.', '')
                    .replaceAll('-', '')
                    .replaceAll('/', '')
                )}
                error={errorCnpj}
                helperText={errorCnpjText}
                id="input-cnpj"
                fullWidth
                disabled={disabled}
            />
            <TextField
                label="Código interno"
                required={true}
                inputProps={{
                    max: 999999,
                    min: 1,
                    maxLength: 6,
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                }}
                type="text"
                errorText="Campo obrigatório!"
                value={internalCode}
                onChange={(e) => {
                    const newValue = e.target.value.replace(/\D/g, '');
                    if (newValue) { // Verifica se newValue não está vazio
                        formatCode(newValue, setInternalCode, 1, 6);
                    } else {
                        setInternalCode(''); // Limpa o estado se o valor estiver vazio
                    }
                }}
                error={errorCode}
                helperText={errorCodeText}
                id="input-code"
                fullWidth
                disabled={disabled}
            />
            {/*<TextField*/}
            {/*    label="Código do fornecedor"*/}
            {/*    required={true}*/}
            {/*    inputProps={{*/}
            {/*        max: 999999,*/}
            {/*        min: 1,*/}
            {/*        maxLength: 6,*/}
            {/*        inputMode: 'numeric',*/}
            {/*        pattern: '[0-9]*',*/}
            {/*    }}*/}
            {/*    type="text"*/}
            {/*    errorText="Campo obrigatório!"*/}
            {/*    value={supplierCode}*/}
            {/*    onChange={(e) => {*/}
            {/*        const newValue = e.target.value.replace(/\D/g, '');*/}
            {/*        if (newValue) { // Verifica se newValue não está vazio*/}
            {/*            formatCode(newValue, setSupplierCode, 3, 6);*/}
            {/*        } else {*/}
            {/*            setSupplierCode(''); // Limpa o estado se o valor estiver vazio*/}
            {/*        }*/}
            {/*    }}*/}
            {/*    error={errorCode}*/}
            {/*    helperText={errorCodeText}*/}
            {/*    id="supplier-code"*/}
            {/*    fullWidth*/}
            {/*    disabled={disabled}*/}
            {/*/>*/}
            <Divider/>
            <MDBox mb={0.5} pb={0.5}>
                <MDTypography variant="h5" textTransform="capitalize" fontWeight="light">
                    Contato
                </MDTypography>
            </MDBox>
            <TextField
                label="Telefone"
                inputProps={{
                    minLength: 15,
                    maxLength: 15
                }}
                type="text"
                erortext="Campo obrigatório!"
                value={phoneMask(phoneNumber)}
                onChange={(e) => setPhoneNumber(e.target.value)}
                fullWidth
                disabled={disabled}
            />
            <TextField
                label="E-mail"
                required={true}
                inputProps={{
                    maxLength: 60
                }}
                type="email"
                erortext="Campo obrigatório!"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
                disabled={disabled}
            />
            <Divider/>
            <MDBox mb={0.5} pb={0.5}>
                <MDTypography variant="h5" textTransform="capitalize" fontWeight="light">
                    Endereço
                </MDTypography>
            </MDBox>
            <SelectInput
                url={`${baseUrl}/City?size=200000000`}
                list={cityList}
                set={setCityList}
                setObj={setCityId}
                returnList={() => {
                    let returnList = []
                    cityList.forEach((item) => {
                        returnList.push({
                            value: item.id,
                            label: `${item.name} - ${item.state.uf}`
                        })
                    })
                    return returnList
                }}
                placeholder={"Cidade *"}
                defaultValue={cityId}
                required={true}
                fullWidth
                disabled={disabled}
            />
            <TextField
                className='mt-2'
                label="CEP"
                required={true}
                inputProps={{
                    maxLength: 8,
                    minLength: 8
                }}
                erortext="Campo obrigatório!"
                value={postalCode}
                onChange={(e) => {
                    setPostalCode(e.target.value.replace(/\D/, ''))
                }}
                InputLabelProps={InputLabelProps}
                fullWidth
                disabled={disabled}
            />
            <TextField
                label="Logradouro"
                required={true}
                inputProps={{
                    maxLength: 60
                }}
                type="text"
                erortext="Campo obrigatório!"
                value={thoroughfare}
                onChange={(e) => setThoroughfare(e.target.value)}
                InputLabelProps={InputLabelProps}
                fullWidth
                disabled={disabled}
            />
            <TextField
                label="Bairro"
                required={true}
                inputProps={{
                    maxLength: 60
                }}
                type="text"
                erortext="Campo obrigatório!"
                value={neighborhood}
                onChange={(e) => setNeighborhood(e.target.value)}
                InputLabelProps={InputLabelProps}
                fullWidth
                disabled={disabled}
            />
            <TextField
                id="input-number"
                label="Número"
                required={true}
                inputProps={{
                    maxLength: 60
                }}
                type="text"
                erortext="Campo obrigatório!"
                value={addressNumber}
                onChange={(e) => setAddressNumber(e.target.value)}
                InputLabelProps={InputLabelProps}
                fullWidth
                disabled={disabled}
            />
            <TextField
                label="Complemento"
                inputProps={{
                    maxLength: 60
                }}
                erortext="Campo obrigatório!"
                value={complement}
                onChange={(e) => setComplement(e.target.value)}
                InputLabelProps={InputLabelProps}
                fullWidth
                disabled={disabled}
            />

            <Divider/>

            <MDBox mb={0.5} pb={0.5}>
                <MDTypography variant="h5" textTransform="capitalize" fontWeight="light">
                    Dados da franquia
                </MDTypography>
            </MDBox>
            <SelectInput
                url={`${baseUrl}/BusinessUnit?size=200000000`}
                placeholder={"Unidade de negócio *"}
                list={businessUnitList}
                set={setBusinessUnitList}
                setObj={setBusinessUnit}
                defaultValue={businessUnit}
                required={true}
                fullWidth
                disabled={status !== "ATIVO"}
                key={autoCompleteKey}
            />
            <SelectInput
                url={`${baseUrl}/Regional?size=200000000`}
                placeholder={"Regional *"}
                list={regionalList}
                set={setRegionalList}
                setObj={setRegional}
                defaultValue={regional}
                returnList={() => {
                    let returnList = []
                    regionalList.forEach((item) => {
                        returnList.push({
                            value: item.id,
                            label: `${item.code} - ${item.name}`
                        })
                    })
                    return returnList
                }}
                required={true}
                fullWidth
                disabled={disabled}
            />
            <SelectInput
                url={`${baseUrl}/FranchiseGroup?size=200000000`}
                placeholder={"Grupo *"}
                list={groupList}
                set={setGroupList}
                setObj={setFranchiseGroup}
                returnList={() => {
                    let returnList = []
                    groupList.forEach((item) => {
                        returnList.push({
                            value: item.id,
                            label: `${item.code} - ${item.name}`
                        })
                    })
                    return returnList
                }}
                defaultValue={franchiseGroup}
                required={true}
                fullWidth
                disabled={disabled}
            />
            <Divider/>

            <FormControlLabel control={
                <Switch color="secondary" checked={centralized} disabled={disabled} onChange={
                    () => setCentralized(!centralized)
                }/>
            } label="Possui Centralizadora"/>

            {centralized ?
                <>
                    <MDBox mb={0.5} pb={0.5}>
                        <MDTypography variant="h5" textTransform="capitalize" fontWeight="light">
                            Franquia Centralizadora
                        </MDTypography>
                        {isCentralizer && <p className='text-danger' style={{fontSize: '0.9vw'}}>
                            * Caso esta loja possua outras lojas centralizadas nela, ao salvar esta edição,
                            todas as demais lojas serão automaticamente vinculadas à mesma centralizadora que esta.
                        </p>}

                        <AutoCompleteInput
                            sizeToSearch={3}
                            filter={`internalCode=${logicCode}`}
                            value={logicCode}
                            setValue={setLogicCode}
                            firstInputLabel={'Digite 3 dígitos do código interno da loja e uma lista de lojas aparecerá.'}
                            secondInputLabel={'Selecione a loja:'}
                            changeMessage={'Para trocar a centralizadora'}

                            url={`${baseUrl}/Store?size=200000000&centralizer=true`}
                            placeholder={"Centralizadora*"}
                            list={storeList}
                            set={setStoreList}
                            setObj={setCentralizedStoreId}
                            defaultValue={centralizedStoreId}
                            required={true}
                            fullWidth
                            getLabel={(item) => {
                                return `${item.internalCode} - ${item.corporateName}`
                            }}
                            getValueForList={(item) => {
                                return item.id
                            }}
                            key={autoCompleteKey}
                            disabled={status !== "ATIVO"}
                        />
                    </MDBox>
                </> :
                <>
                    <MDBox mb={0.5} pb={0.5}>
                        <MDTypography variant="h5" textTransform="capitalize" fontWeight="light">
                            Dados bancários
                        </MDTypography>

                    </MDBox>
                    <BankDetailsInputs
                        baseUrl={baseUrl}
                        agency={agency} setAgency={setAgency}
                        account={account} setAccount={setAccount}
                        bankCode={bankCode} setBankCode={setBankCode}
                        InputLabelProps={InputLabelProps}
                        disabled={disabled}
                        // naoObrigatorio={naoObrigatorio}
                    />
                </>
            }

            <Divider/>

            <MDBox mb={0.5} pb={0.5}>
                <InputStatus status={status} setStatus={setStatus}/>
            </MDBox>
            <Divider/>
            <MDBox mb={0.5} pb={0.5}>
                <MDTypography variant="h5" textTransform="capitalize" fontWeight="light">
                    Taxas
                </MDTypography>
            </MDBox>
            <p style={{fontSize: '0.8vw'}}>
                * Se o status da loja estiver como bloqueado não é permitido a alteração de taxas.
            </p>
            <p style={{fontSize: '0.8vw'}}>
                * Mantenha os campos em branco para que a loja adote o padrão de taxas da unidade de negócio.
            </p>
            <TextField
                label="Taxa de Carga (%)"
                required={false}
                inputProps={{
                    min: 0,
                    max: 100,
                    maxLength: 3,
                    endAdornment: "%"
                }}
                type="text"
                placeholder={`Se vazio, se considera o valor padrão da unidade de negócio(${businessUnitData ? businessUnitData[0].loadFee : ''}%)`}
                value={loadFee}
                onChange={(e) => onChangeWithLimiter(e, 3, setLoadFee)}
                fullWidth
                disabled={disabledFee}
            />
            <TextField
                label="Taxa Resgate (%)"
                required={false}
                inputProps={{
                    min: 0,
                    max: 100,
                    maxLength: 3,
                    endAdornment: "%"
                }}
                type="text"
                erortext="Campo não obrigatório!"
                placeholder={`Se vazio, se considera o valor padrão da unidade de negócio(${businessUnitData ? businessUnitData[0].redeemFee: ''}%)`}
                value={redeemFee}
                onChange={(e) => onChangeWithLimiter(e, 3, setRedeemFee)}
                InputLabelProps={InputLabelProps}
                fullWidth
                disabled={disabledFee}
            />
            <TextField
                label="Taxa Administrativa (%)"
                required={false}
                inputProps={{
                    min: 0,
                    max: 100,
                    maxLength: 3,
                    endAdornment: "%"
                }}
                type="text"
                erortext="Campo não obrigatório!"
                placeholder={`Se vazio, se considera o valor padrão da unidade de negócio(${businessUnitData ? businessUnitData[0].administrationFee: ''}%)`}
                value={administrationFee}
                onChange={(e) => onChangeWithLimiter(e, 3, setAdministrationFee)}
                InputLabelProps={InputLabelProps}
                fullWidth
                disabled={disabledFee}
            />
        </React.Fragment>
    )
}
export default InputsStore;


import FilterForm from "../../../components/FilterForm/FilterForm";
import TextField from "@mui/material/TextField";
import SelectInput from "../../../components/SelectInput";
import MDBox from "../../../components/MDBox";
import {Button, FormControlLabel, FormLabel, IconButton, Radio, RadioGroup} from "@mui/material";
import DisplayStatus from "../../../components/Status/DisplayStatus";
import React, {useState} from "react";
import DisplayType from "../../../components/Type/DisplayType";
import InputAdornment from "@mui/material/InputAdornment";
import ClearIcon from "@mui/icons-material/Clear";

const FilterFormCard = ({filter, setFilter, setLoadData, setPage}) => {

    const [lgShow, setLgShow] = useState(false);

    const baseUrl = `http://${process.env.REACT_APP_GLOBAL_PROCESS_LINK}`

    const [cardNumber, setCardNumber] = useState("")
    const [businessUnitId, setBusinessUnitId] = useState([])
    const [businessUnitList, setBusinessUnitList] = useState([])
    const [digitalValue, setDigitalValue] = useState("null")
    const [status, setStatus] = useState("")

    const handlesSubmit = (e) => {
        if (e) {
            e.preventDefault();
        }

        setFilter({
            ...(cardNumber && {number: cardNumber}),
            ...(typeof businessUnitId == 'number' && {businessUnitId}),
            ...(status && {status}),
            ...(digitalValue && digitalValue !== 'null' && {digital: digitalValue}),
        });

        setLgShow(false);
        setLoadData(true);

        setPage(0);
    };

    const clearFilter = () => {
        setCardNumber("");
        setBusinessUnitId([]);
        setDigitalValue(null)
        setStatus("");

        setFilter({})

        setLgShow(false);
        setLoadData(true);
    };
    const clearAll = () => {
        setCardNumber("");
        setBusinessUnitId([]);
        setDigitalValue(null)
        setStatus("");

        setFilter({});
    };


    return (
        <FilterForm
            handleSubmit={handlesSubmit} clearFilter={clearFilter}
            lgShow={lgShow} setLgShow={setLgShow}
            clearAll={clearAll}
            showSecondaryButton={Object.keys(filter).length !== 0}
        >
            <TextField
                label="Número do Cartão"
                inputProps={{
                    maxLength: 19,  // Define o número máximo de dígitos para um número de cartão de crédito comum
                }}
                error={cardNumber === "" && false} // Ajuste conforme a lógica de erro desejada
                // helperText={cardNumber === "" ? "Campo obrigatório!" : ""}
                value={cardNumber}
                onChange={(e) => {
                    const inputNumber = e.target.value.replace(/\D/g, ''); // Remove todos os caracteres que não são dígitos
                    setCardNumber(inputNumber);
                }}
                fullWidth
                autoFocus
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {cardNumber && (
                                <IconButton size="small" onClick={() => setCardNumber("")}>
                                    <ClearIcon />
                                </IconButton>
                            )}
                        </InputAdornment>
                    ),
                }}
            />
            <SelectInput
                url={`http://${process.env.REACT_APP_GLOBAL_PROCESS_LINK}/BusinessUnit?size=200000000`}
                placeholder={"Unidade de negócio"}
                list={businessUnitList}
                set={setBusinessUnitList}
                setObj={setBusinessUnitId}
                value={businessUnitId}
                defaultValue={businessUnitId}
                isClearable
                returnList={() => {
                    let returnList = []
                    businessUnitList.forEach((item) => {
                        returnList.push({value: item.id, label: item.name})
                    })
                    return returnList
                }}
                fullWidth
            />
            <MDBox mb={0.5} pb={0.5}>
                <FormLabel id="demo-row-radio-buttons-group-label" className="text-center">
                    Tipo de Cartão
                </FormLabel>
                <RadioGroup
                    row
                    name="row-radio-buttons-group"
                    id="radios-status"
                    value={digitalValue}
                    onChange={(e) => {
                        setDigitalValue(e.target.value)
                    }}
                    required
                >
                    <FormControlLabel value="null" control={<Radio/>} label={<span>Todos</span>}/>
                    <FormControlLabel value="false" control={<Radio/>} label={<DisplayType type="FISICO"/>}/>
                    <FormControlLabel value="true" control={<Radio/>} label={<DisplayType type="DIGITAL"/>}/>
                </RadioGroup>
            </MDBox>


            <MDBox mb={0.5} pb={0.5}>
                <FormLabel id="demo-row-radio-buttons-group-label" className="text-center">
                    Status
                </FormLabel>
                <RadioGroup
                    row
                    name="row-radio-buttons-group"
                    id="radios-status"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    required
                >
                    <FormControlLabel value="" control={<Radio/>} label={<span>Todos</span>}/>
                    <FormControlLabel value="ATIVO" control={<Radio/>} label={<DisplayStatus status="ATIVO"/>}/>
                    <FormControlLabel value="CANCELADO" control={<Radio/>} label={<DisplayStatus status="CANCELADO"/>}/>
                    <FormControlLabel value="EXPIRADO" control={<Radio/>} label={<DisplayStatus status="EXPIRADO"/>}/>
                    <FormControlLabel value="BLOQUEADO" control={<Radio/>} label={<DisplayStatus status="BLOQUEADO"/>}/>
                    <FormControlLabel value="CRIADO" control={<Radio/>} label={<DisplayStatus status="CRIADO"/>}/>
                    <FormControlLabel value="EM_RESOLUCAO" control={<Radio/>}
                                      label={<DisplayStatus status="EM_RESOLUCAO"/>}/>
                    <FormControlLabel value="BAIXADO" control={<Radio/>} label={<DisplayStatus status="BAIXADO"/>}/>
                </RadioGroup>
            </MDBox>
        </FilterForm>
    )
}
export default FilterFormCard
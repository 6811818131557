import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import React from "react";


export default function DefaultErrorMsg(){
    return(
        <Container maxWidth="sm">
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="20vh">
                <div>
                    <Typography variant="h5" color="error" gutterBottom>
                        Ocorreu um erro! :(
                    </Typography>
                    <Typography variant="body1" color="textSecondary" paragraph>
                        Houve um erro inesperado, tente novamente mais tarde! <br/>
                        Caso o erro persista, contate o administrador para obter suporte.
                    </Typography>
                    {/*<Typography variant="body1" color="textSecondary">*/}
                    {/*    Você pode enviar um e-mail para admin@example.com ou ligar para o número (123) 456-7890.*/}
                    {/*</Typography>*/}
                </div>
            </Box>
        </Container>
    )
}
import "./PrimaryButton.css";
import { Button } from "react-bootstrap";

export default function PrimaryButton() {
  return (
    <Button variant="primary" id="custom-btn">
      Iniciar
    </Button>
  );
}

import TextField from "@mui/material/TextField";
import React, {useEffect, useState} from "react";
import InputStatus from "../../../components/Status/InputStatus";

const InputsGroup = ({
                         name, setName,
                         code, setCode,
                         status, setStatus,
                         errorMessage, errorColumn,
                         updateCode
                     }) => {

    const [errorCode, setErrorCode] = useState(false)
    const [errorCodeText, setErrorCodeText] = useState(false)
    const [errorName, setErrorName] = useState(false)
    const [errorNameText, setErrorNameText] = useState(false)

    useEffect(() => {
        setErrorCode(false)
        setErrorCodeText(false)
        setErrorName(false)
        setErrorNameText(false)
        switch (errorColumn) {
            case "code":
                setErrorCode(true)
                setErrorCodeText(errorMessage)
                break
            case "name":
                setErrorName(true)
                setErrorNameText(errorMessage)
                break
        }
    }, [errorColumn, errorMessage])

    return (
        <>
            <TextField
                id="input-with-icon-textfield"
                label="Nome do Grupo Franquia"
                inputProps={{
                    maxLength: 60
                }}
                required={true}
                size="large"
                erortext="Campo obrigatório!"
                value={name}
                onChange={(e) => setName(e.target.value.toUpperCase())}
                error={errorName}
                helperText={errorNameText}
                fullWidth
                autoFocus
            />

            <TextField
                label="Código do Grupo Franquia"
                required={true}
                inputProps={{
                    max: 999999,
                    min: 1,
                    maxLength: 8,
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                }}
                type="text"
                errorText="Campo obrigatório!"
                value={code}
                onChange={(e) => {
                    const newValue = e.target.value.replace(/\D/g, '');
                    setCode(newValue);
                }}
                error={errorCode}
                helperText={errorCodeText}
                fullWidth
            />


            <InputStatus
                status={status}
                setStatus={setStatus}
            />
        </>
    )
}


export default InputsGroup
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import React from "react";
import Box from "@mui/material/Box";

export default function DefaultSuccessMessage({message}) {

    return (
        <Box className="text-center" sx={{color: 'green'}}>
            <CheckCircleIcon sx={{
                fontSize: 40,
                height: '5vw',
                width: '5vw',
            }}/><br/>
            <h4>{message ? message : 'Sucesso!'}</h4>
        </Box>
    )
}
import React, {useState} from "react";
import TableList from "../../../components/TableList/TableList";
import PopupEditRegional from "./PopupEditRegional";

const ListRegional = ({
                          baseUrl, loadData,
                          setLoadData, size, page,
                          setTotalPages, setTotalElements,
                          sort, setSort, filter
                      }) => {
    const [data, setData] = useState([])
    const [error, setError] = useState(false)

    const titles = [
        "Nome",
        "Código"
    ]

    return (
        <TableList
            titles={titles} baseUrl={baseUrl}
            page={page} sort={sort} size={size}
            loadData={loadData} filter={filter}
            setTotalPages={setTotalPages} setTotalElements={setTotalElements}
            setData={setData} setLoadData={setLoadData}
            error={error} setError={setError}
        >
            {data.map((item) =>
                <tr key={item.id}>
                    <td>{item.name}</td>
                    <td>{item.code}</td>
                    <td>
                        <PopupEditRegional
                            item={item}
                            baseUrl={baseUrl}
                            setLoadData={setLoadData}
                        />
                    </td>
                </tr>
            )}
        </TableList>
    )
}

export default ListRegional
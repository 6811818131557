import PopupLayout from "../PopupLayout";


export default function PopupLayoutCardRevalidation({}) {

    const text = <>
        O arquivo deve seguir um formato específico,
        com apenas um dado em cada linha: o número do cartão que será revalidado.
        O número do cartão pode ser inserido completo ou com os últimos 13, 12 ou 9 dígitos. <br/>
        O arquivo precisa ser salvo no formato <b>CSV</b>,
        com uma extensão de arquivo representativa, por exemplo: arquivo-revalidacao-lote1.csv.
    </>

    const layout = <span>
        6049246034060452194<br/>
        6034060452186<br/>
        034060452095<br/>
        060452079
    </span>

    return <PopupLayout processName="Revalidação de Cartões" text={text} layout={layout}/>
}
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MainNavbar from "components/MainNavbar";
import Footer from "examples/Footer";
import Reports from "./components/Reports/Reports";
import Card from "@mui/material/Card";
import Map from "./components/Map/Map";
import {useEffect, useState} from "react";
import { useAuthContext } from "components/AuthenticationProvider/AuthenticationProvider";


function Dashboard() {

    const authContext = useAuthContext();

    const timeToRefresh = 30
    const [count, setCount] = useState(timeToRefresh);
    const [loadData, setLoadData] = useState(true)

    useEffect(() => {
        const intervalId = setInterval(() => {
            setLoadData(true);
            setCount(timeToRefresh - 1)
        }, timeToRefresh * 1000);

        return () => clearInterval(intervalId);
    }, []);


    useEffect(() => {
        const countdownInterval = setInterval(() => {
            if (count > 0) {
                setCount(prevCount => prevCount - 1);
            }
        }, 1000);

        return () => clearInterval(countdownInterval);
    }, [count]);

    return (
        <>
            <Card sx={{height: "100%", bgcolor: '#eeeeee'}}>
                <MainNavbar/>
                <DashboardLayout>
                    <MDBox>

                        <h1>Bem vindo ao Portal Cartão Presente Boticário!</h1>

                        <Reports count={count} loadData={loadData} setLoadData={setLoadData}/>

                        {/* Mapa visível somente para perfis Admin */}
                        {authContext.userInfo.scopeAuth !== 'Store' && 
                            <MDBox>
                                <Grid container spacing={3}>
                                    <Map/>
                                </Grid>
                            </MDBox>
                        }

                    </MDBox>
                </DashboardLayout>
                <footer>
                    <Footer/>
                </footer>
            </Card>
        </>
    );
}

export default Dashboard;
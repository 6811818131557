// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.logMonitoringRequests section{
    padding: 0 1vw;
    height: 70vh;
    width: 85vw;
    margin: 20px auto;
    background: black;
    box-shadow: 0 0 3px #000;
    overflow-y:auto;
    border-radius: 1vw;
    color: white;
}`, "",{"version":3,"sources":["webpack://./src/layouts/monitoringLog/monitoringLog.css"],"names":[],"mappings":";AACA;IACI,cAAc;IACd,YAAY;IACZ,WAAW;IACX,iBAAiB;IACjB,iBAAiB;IACjB,wBAAwB;IACxB,eAAe;IACf,kBAAkB;IAClB,YAAY;AAChB","sourcesContent":["\r\n.logMonitoringRequests section{\r\n    padding: 0 1vw;\r\n    height: 70vh;\r\n    width: 85vw;\r\n    margin: 20px auto;\r\n    background: black;\r\n    box-shadow: 0 0 3px #000;\r\n    overflow-y:auto;\r\n    border-radius: 1vw;\r\n    color: white;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

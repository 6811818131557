import FilterForm from "../../../components/FilterForm/FilterForm";
import TextField from "@mui/material/TextField";
import { FormControlLabel, FormLabel, Radio, RadioGroup, Button } from "@mui/material";
import React, { useState } from "react";
import MDBox from "../../../components/MDBox";
import ClearIcon from '@mui/icons-material/Clear';
import InputAdornment from "@mui/material/InputAdornment"; // Ícone para o botão de limpar
import Ativo from '@mui/icons-material/CheckCircle';
import Migrado from '@mui/icons-material/SwapHorizontalCircleRounded';
import Inativo from '@mui/icons-material/CancelRounded';
import Bloqueado from '@mui/icons-material/BlockRounded';
import Expirado from '@mui/icons-material/AccessTimeFilled';
import InativoBloqueado from '@mui/icons-material/RemoveCircleRounded';

const FilterFormUser = ({ filter, setFilter, setLoadData, setPage }) => {
  const [lgShow, setLgShow] = useState(false);

  const [userName, setUserName] = useState("");
  const [userNickname, setUserNickname] = useState("");
  const [userOrganization, setUserOrganization] = useState("");
  const [userPermission, setUserPermission] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userStatus, setUserStatus] = useState("");

  const handlesSubmit = (e) => {
    if (e) e.preventDefault();

    setFilter({
      ...(userName && { userName }),
      ...(userNickname && { userNickname }),
      ...(userPermission && { userPermission }),
      ...(userEmail && { userEmail }),
      ...(userStatus && { userStatus }),
    });

    setLgShow(false);
    setLoadData(true);
    setPage(0);
  };


    const clearFilter = () => {
        setUserName("");
        setUserNickname("");
        setUserOrganization("");
        setUserPermission("");
        setUserEmail("");
        setUserStatus("");
        setFilter({});

        setLgShow(false);
        setLoadData(true);
    };

  const clearAll = () => {
    setUserName("");
    setUserNickname("");
    setUserOrganization("");
    setUserPermission("");
    setUserEmail("");
    setUserStatus("");
    setFilter({});
  };

  return (
      <FilterForm
          lgShow={lgShow}
          setLgShow={setLgShow}
          handleSubmit={handlesSubmit}
          clearFilter={clearFilter}
          clearAll={clearAll}
          showSecondaryButton={Object.keys(filter).length !== 0}
      >
          <MDBox mb={1} position="relative">
              <TextField
                  label="Nome do Usuário (username. Ex:JULIO.CESAR12)"
                  inputProps={{ maxLength: 60 }}
                  value={userNickname}
                  onChange={(e) => setUserNickname(e.target.value.toUpperCase())}
                  fullWidth
                  autoFocus
                  InputProps={{
                      endAdornment: (
                          <InputAdornment position="end">
                              {userNickname && (
                                  <Button
                                      size="small"
                                      onClick={() => setUserNickname("")}
                                  >
                                      <ClearIcon />
                                  </Button>
                              )}
                          </InputAdornment>
                      ),
                  }}
              />
          </MDBox>

          <MDBox mb={1} position="relative">
              <TextField
                  label="Nome Completo"
                  inputProps={{ maxLength: 60 }}
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                  fullWidth
                  InputProps={{
                      endAdornment: (
                          <InputAdornment position="end">
                              {userName && (
                                  <Button
                                      size="small"
                                      onClick={() => setUserName("")}
                                  >
                                      <ClearIcon />
                                  </Button>
                              )}
                          </InputAdornment>
                      ),
                  }}
              />
          </MDBox>

          <MDBox mb={1} position="relative">
              <TextField
                  label="Email"
                  inputProps={{ maxLength: 100 }}
                  value={userEmail}
                  onChange={(e) => setUserEmail(e.target.value)}
                  fullWidth
                  InputProps={{
                      endAdornment: (
                          <InputAdornment position="end">
                              {userEmail && (
                                  <Button
                                      size="small"
                                      onClick={() => setUserEmail("")}
                                  >
                                      <ClearIcon />
                                  </Button>
                              )}
                          </InputAdornment>
                      ),
                  }}
              />
          </MDBox>

          <MDBox mb={0.5} pb={0.5}>
              <FormLabel id="demo-row-radio-buttons-group-label" className="text-center">
                  Perfis de Acesso
              </FormLabel>
              <RadioGroup
                  row
                  name="row-radio-buttons-group"
                  id="radios-status"
                  value={userPermission}
                  onChange={(e) => setUserPermission(e.target.value)}
                  required
              >
                  <FormControlLabel value="" control={<Radio />} label={<span>Todos</span>} />
                  <FormControlLabel value="ADMIN" control={<Radio />} label={<span>Administrativo</span>} />
                  <FormControlLabel value="ADMIN_MOOZ" control={<Radio />} label={<span>Administrativo Mooz</span>} />
                  <FormControlLabel value="LOJA_PADRAO" control={<Radio />} label={<span>Loja Padrão</span>} />
              </RadioGroup>
          </MDBox>
          <MDBox mb={0.5} pb={0.5}>
              <FormLabel id="demo-row-radio-buttons-group-label" className="text-center">
                  Status do Usuário
              </FormLabel>
              <RadioGroup
                  row
                  name="row-radio-buttons-group"
                  id="radios-status"
                  value={userStatus}
                  onChange={(e) => setUserStatus(e.target.value)}
              >
                  <FormControlLabel value="" control={<Radio />} label={<span>Todos </span>} />
                  <FormControlLabel value="ACTIVE" control={<Radio />} label={<span>Ativo <Ativo color={"success"}/></span>} />
                  <FormControlLabel value="INACTIVE" control={<Radio />} label={<span>Inativo <Inativo color={"error"}/></span>} />
                  <FormControlLabel value="BLOCKED" control={<Radio />} label={<span>Bloqueado <Bloqueado color={"warning"}/></span>} />
                  <FormControlLabel value="BLOCKED_INACTIVED" control={<Radio />} label={<span>Inativo Bloqueado <InativoBloqueado color={"disabled"}/></span>} />
                  <FormControlLabel value="PASSWORD_EXPIRED" control={<Radio />} label={<span>Senha Expirada <Expirado color={"error"}/></span>} />
                  <FormControlLabel value="MIGRATED" control={<Radio />} label={<span>Migrado <Migrado color={"info"}/></span>} />
              </RadioGroup>
          </MDBox>
      </FilterForm>
  );
};

export default FilterFormUser;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import "./PaginationControl.css"
import ReactPaginate from "react-paginate";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const PaginationControl = ({
                                     totalElements,
                                     page,
                                     setPage,
                                     size,
                                     setSize,
                                     setLoadData,
                                     RowPerPages,
                                 }) => {
    const [pageCount, setPageCount] = useState(0);

    useEffect(() => {
        setSize(size);
        setPageCount(Math.ceil(totalElements / size));
    }, [totalElements, size]);

    const handlePageClick = (selected) => {
        setPage(selected.selected);
        setLoadData(true);
    };

    const handlePageSizeChange = (event) => {
        const newSize = parseInt(event.target.value, 10);
        setSize(newSize);
        setPage(0);
        setLoadData(true);
    };

    const pageSizeOptions = RowPerPages ? RowPerPages : [10, 25, 50, 100];

    const startItem = page * size + 1;
    const endItem = Math.min((page + 1) * size, totalElements);

    return (
        <div className="pagination-container">
            <div className="rows-per-page">
                <label className="itens-per-page">Itens por página:</label>
                <select
                    id="rowsPerPage"
                    className="page-size-select"
                    value={size}
                    onChange={handlePageSizeChange}
                >
                    {pageSizeOptions.map((option) => (
                        <option key={option} value={option} className="page-size-option">
                            {option}
                        </option>
                    ))}
                </select>
                <span className="separator"> - </span>
            </div>

            <ReactPaginate
                previousLabel={<NavigateBeforeIcon />}
                nextLabel={<NavigateNextIcon />}
                breakLabel="..."
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                initialPage={page}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
                disabledClassName={"disabled"}
                previousLinkClassName={"previous"}
                nextLinkClassName={"next"}
                pageClassName={"page"}
                pageLinkClassName={"page-link"}
            />

            <span className="items-count">
                <span className="separator-2"> - </span>
                  {startItem} a {endItem} de {totalElements} itens
            </span>
        </div>
    );
};

export default PaginationControl;

import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import "./SelectInput.css";
import BasicLoader from "../BasicLoader/BasicLoader";

const SelectInput = ({
                         url,
                         list,
                         set,
                         setObj,
                         defaultValue,
                         returnList,
                         placeholder,
                         setPlaceholder,
                         required,
                         getLabel,
                         getValueForList,
                         maxMenuHeight,
                         isClearable,
                         disabled
                     }) => {
    const [selectOptions, setSelectOptions] = useState([]);
    const [select, setSelect] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        axios.get(url)
            .then((response) => {
                set(response.data.content !== undefined ? 
                        response.data.collection !== undefined ? 
                            response.data.collection : response.data.content : response.data);
                setLoaded(true);
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
            });
    }, [url, set]);

    useEffect(() => {
        if (typeof setPlaceholder === "function") {
            setPlaceholder(defaultValue);
        }
    }, [defaultValue, setPlaceholder]);

    useEffect(() => {
        if (loaded) {
            const mappedList = typeof returnList === "function" ? returnList() : defaultValues();
            setSelectOptions(mappedList);

            if (defaultValue !== undefined && defaultValue !== "") {
                const defaultItem = mappedList.find((item) => item.value === defaultValue);
                if (defaultItem) {
                    setObj(defaultItem.value);
                }
            }
        }
    }, [list, loaded, returnList, defaultValue, setObj]);

    useEffect(() => {
        if (list.length > 0 || list.collection?.length > 0) {
            const listMapped = typeof returnList === 'function' ? returnList() : defaultValues();
            let item;

            if (defaultValue) {
                item = listMapped.find(item => item.value === defaultValue);
                setObj(item?.value);
            }

            setSelect(
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    id="form_select"
                    required={required}
                    defaultValue={item}
                    isDisabled={disabled || loading}
                    isLoading={loading}
                    isClearable={isClearable}
                    isRtl={false}
                    isSearchable={true}
                    options={listMapped}
                    onChange={(e) => setObj(e?.value)}
                    placeholder={placeholder}
                    maxMenuHeight={maxMenuHeight}
                />
            );
        }
    }, [list, defaultValue, isClearable, loading, maxMenuHeight, placeholder, returnList, setObj]);

    const defaultValues = () => {
        return list?.map((item) => {
            const label = getLabel ? getLabel(item) : item.name;
            const value = getValueForList ? getValueForList(item) : item.id;
            return { value, label };
        });
    };

    return (
        <>
            {!loaded ? (
                <>
                    <BasicLoader height={10} message={"Carregando dados..."} />
                    <input type="text" name="block-submit" hidden required />
                </>
            ) : list.length <= 0 && required && !defaultValue ? (
                <span className="text-danger">
          Não há registros de {placeholder?.replace("*", "")} para ser(em) relacionado(s)!
        </span>
            ) : (
                select
            )}
        </>
    );
};

export default SelectInput;

import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Table  } from "@mui/material";
import { useState } from "react";

import RequestPageIcon from '@mui/icons-material/RequestPage';
import { fetchData } from "api/axiosMethods";

import  { Button as ButtonReact}  from "react-bootstrap";
import formatDateTimeBr from "utils/formatDateTimeBr";

import DownloadOutlined from "@mui/icons-material/DownloadOutlined";


export default function ReportingMonitoring() {

    const reportUrl = `http://${process.env.REACT_APP_PYREPORT_LINK}`;
    const financialManagerBaseUrl = `http://${process.env.REACT_APP_FINANCIAL_MANAGER_LINK}`;

    const [open, setOpen] = useState(false);
    const [reports, setReports] = useState([]);

    function handleClose() {
        setOpen(false);
    }

    async function handleEnter() {
        const financialReports = await fetchData(`${reportUrl}/reports`);
        if (financialReports)
            setReports(financialReports.collection);
    }

    async function downloadFile(reportId, reportFilename) {
        const response = await fetch(`${financialManagerBaseUrl}/financialClose-report/download/${reportId}`);
        if (response.status != 200) {
            console.log('Erro ao baixar arquivo!');
            return;
        }
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = reportFilename.split('/').pop();
        a.click();
     }

    return (
        <div>
            <ButtonReact onClick={() => setOpen(true)}>
                <RequestPageIcon fontSize="medium" /> Arquivos de Relatório
            </ButtonReact>
            <Dialog
                fullWidth={true}
                maxWidth={'xl'}
                open={open}
                onClose={handleClose}
                onTransitionEnter={handleEnter}
            >
                <Box >
                    <DialogTitle>Relatórios de Fechamento Financeiro</DialogTitle>
                    <DialogContent>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Data/Hora Solicitação</th>
                                    <th>Tipo</th>
                                    <th>Status</th>
                                    <th>Data/Hora Geração</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {reports?.map((report) => (
                                    <tr key={report.id}>
                                        <td>{formatDateTimeBr(report.date_created)}</td>
                                        <td>Fechamento Financeiro</td>
                                        <td>{report.status === 'PENDING' ? 'Pendente' : 'Disponível'}</td>
                                        <td>{formatDateTimeBr(report.processing_date)}</td>
                                        <td>
                                        {report.status !== 'PENDING' ? 
                                            <a href="#" onClick={() => downloadFile(report.id, report.file_path)}>Baixar <DownloadOutlined fontSize="medium"/></a>
                                        : ''}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Fechar</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </div>
    );
}
import './FileInput.css'
import {Input} from "@mui/material";
import React from "react";
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';

export default function FileInput({selectedFile, setSelectedFile, format = '.csv'}) {
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file?.name.slice(-4) === format)
            setSelectedFile(file);
        else
            setSelectedFile(null)
    };

    return (
        <>
            <label id='div-input-file'>
                <span id='input-file'>

                    <CreateNewFolderIcon fontSize="medium"/>&nbsp;
                    Importar arquivo
                    <Input
                        type="file"
                        onChange={handleFileChange}
                        inputProps={{accept: '.csv'}}
                        hidden
                    />
                </span>

                {selectedFile ?
                    (<span>
                        Selecionado: {selectedFile?.name}
                    </span>) : (<span>
                        Nenhum arquivo selecionado
                    </span>)
                }
            </label>
            <br/>
        </>
    )
}
import TableList from "components/TableList/TableList";
import DownloadOutlined from "@mui/icons-material/DownloadOutlined";

import { useState } from "react";

export default function ListClearing({baseUrl, 
                                        loadData,
                                        size, 
                                        page,
                                        setTotalPages, 
                                        setTotalElements,
                                        setLoadData,
                                        sort, 
                                        setSort
                                    }) {

    const financialManagerBaseUrl = `http://${process.env.REACT_APP_FINANCIAL_MANAGER_LINK}/clearings`;

    const titles = ["Mês/Ano", "CAP", "CAR", "Nota Débito", ""]

    const [data, setData] = useState([]);
    const [error, setError] = useState(false)


    async function downloadFile(fileName) {
        const response = await fetch(`${financialManagerBaseUrl}/download-file/${fileName}`);
        if (response.status != 200) {
            console.log('Erro ao baixar arquivo!');
            return;
        }
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName.split('/').pop();
        a.click();
     }


    return (
        <TableList
            titles={titles} 
            baseUrl={baseUrl}
            page={page} 
            sort={sort} 
            size={size}
            loadData={loadData}
            setTotalPages={setTotalPages} 
            setTotalElements={setTotalElements}
            setData={setData} 
            setLoadData={setLoadData}
            error={error} 
            setError={setError}
        >
            {data.map((item) =>
                <tr key={item.id}>
                    <td>{item.monthYear}</td>
                    <td>{item.capFile ? <a href="#" onClick={() => downloadFile(item.capFile.fileName)}>Baixar <DownloadOutlined fontSize="medium"/></a> : ''}</td>
                    <td>{item.carFile ? <a href="#" onClick={() => downloadFile(item.carFile.fileName)}>Baixar <DownloadOutlined fontSize="medium"/></a> : ''}</td>
                    <td>{item.dnFile ? <a href="#" onClick={() => downloadFile(item.dnFile.fileName)}>Baixar <DownloadOutlined fontSize="medium"/></a> : ''}</td>
                    <td></td>
                </tr>
            )}
        </TableList>

    );
}
import "./TableList.css";
import Table from "@mui/material/Table";
import React, { useEffect } from "react";
import axios from "axios";
import BasicLoader from "../BasicLoader/BasicLoader";
import DefaultErrorMsg from "../DefaultErrorMsg/DefaultErrorMsg";
import { useAuthContext } from "../AuthenticationProvider/AuthenticationProvider";

const TableList = ({
                       titles,
                       children,
                       loadData,
                       baseUrl,
                       page,
                       size,
                       sort,
                       setTotalPages,
                       setTotalElements,
                       setData,
                       setLoadData,
                       filter,
                       error,
                       setError,
                       className,
                       emptyMessage = "Sem dados encontrados nesta lista no momento"
                   }) => {

    const authContext = useAuthContext();

    useEffect(() => {
        if (loadData) {
            const displaySort = sort && sort !== '' ? sort : 'id,desc';
            let params = { page, size, sort: displaySort };
            if (filter)
                params = Object.assign({}, params, filter);
            const accessToken = authContext.accessToken;
            axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
            axios.get(baseUrl, { params })
                .then(function (response) {
                    setTotalPages(response.data.totalPages);
                    setTotalElements(response.data.totalElements);
                    setData(response.data.content);
                    setError(false);
                    setLoadData(false);
                }).catch(function (errorReceived) {
                // Aqui temos acesso ao erro, quando alguma coisa inesperada acontece:
                console.log(errorReceived);
                setLoadData(false);
                setError(true);
            });
        }
    }, [loadData, authContext.accessToken, baseUrl, page, size, sort, filter]);

    return (
        <>
            {loadData ? (
                <BasicLoader />
            ) : error ? (
                <DefaultErrorMsg />
            ) : (
                <Table id='table-list' className={className}>
                    <thead>
                    <tr>
                        {titles.map((title, index) => (
                            <th key={index}>{title}</th>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    {React.Children.count(children) === 0 ? (
                        <tr>
                            <td colSpan={titles.length} style={{ textAlign: 'center' }}>
                                {emptyMessage}
                            </td>
                        </tr>
                    ) : (
                        children
                    )}
                    </tbody>
                </Table>
            )}
        </>
    );
}

export default TableList;

import React, {useEffect, useState} from "react";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import AddIcon from "@mui/icons-material/Add";
import * as PropTypes from "prop-types";
import "./PopupAddBusinessUnit.css"
import InputsBusinessUnit from "./InputsBusinessUnit";
import PopupAdd from "../../../components/PopupAdd/PopupAdd";
import {formatFloat} from "../../../utils/formatFloat";


function TextInput(props) {
    return null;
}

TextInput.propTypes = {
    leading: PropTypes.func,
    label: PropTypes.string
};
const PopupAddBusinessUnit = ({baseUrl, setLoadData}) => {

    const [name, setName] = useState("")
    const [minChargeValue, setMinChargeValue] = useState("")
    const [maxChargeValue, setMaxChargeValue] = useState("")
    const [chargeMultiplicity, setChargeMultiplicity] = useState("")
    const [chargeValidity, setChargeValidity] = useState("")
    const [range, setRange] = useState("")
    const [status, setStatus] = useState("ATIVO")
    const [errorMessage, setErrorMessage] = useState("")
    const [errorColumn, setErrorColumn] = useState("")
    const [loadFee, setLoadFee] = useState("")
    const [redeemFee, setRedeemFee] = useState("")
    const [administrationFee, setAdministrationFee] = useState("")

    const icon = <WorkOutlineIcon fontSize="large" id="icon-svg"/>

    const button = <>
        Adicionar
        <AddIcon fontSize="medium"/>
    </>

    const title = "Unidade de negócio"

    const inputs = <InputsBusinessUnit
        name={name} setName={setName}
        minChargeValue={minChargeValue} setMinChargeValue={setMinChargeValue}
        maxChargeValue={maxChargeValue} setMaxChargeValue={setMaxChargeValue}
        chargeMultiplicity={chargeMultiplicity}
        setChargeMultiplicity={setChargeMultiplicity}
        chargeValidity={chargeValidity} setChargeValidity={setChargeValidity}
        range={range} setRange={setRange}
        setErrorColumn={setErrorColumn}
        setErrorMessage={setErrorMessage}
        errorMessage={errorMessage}
        errorColumn={errorColumn}
        loadFee={loadFee} setLoadFee={setLoadFee}
        redeemFee={redeemFee} setRedeemFee={setRedeemFee}
        administrationFee={administrationFee} setAdministrationFee={setAdministrationFee}
        status={status} setStatus={setStatus}
        StatusAddOrEdit={false}
    />

    const clearForm = () => {
        setName("")
        setMinChargeValue("")
        setMaxChargeValue("")
        setChargeMultiplicity("")
        setChargeValidity("")
        setRange("")
        setLoadFee("")
        setRedeemFee("")
        setAdministrationFee("")
        setStatus("ATIVO")
        setErrorMessage('')
    }

    const returnData = () => {
        return {
            name,
            minChargeValue: formatFloat(minChargeValue),
            maxChargeValue: formatFloat(maxChargeValue),
            chargeMultiplicity,
            chargeValidity,
            range,
            loadFee,
            redeemFee,
            administrationFee,
            status
        }
    }

    const validateData = () => {

        setErrorMessage("")

        const maxChargeValueInt = parseInt(maxChargeValue)
        const minChargeValueInt = parseInt(minChargeValue)
        const chargeMultiplicityInt = parseInt(chargeMultiplicity)

        if (maxChargeValueInt < minChargeValueInt){
            setErrorMessage("A carga máxima deve ser maior ou igual à carga mínima!")
            return false
        }

        if (maxChargeValueInt % chargeMultiplicityInt !== 0) {
            setErrorMessage("A carga máxima deve ser múltipla da multiplicidade!")
            return false
        }

        if (minChargeValueInt % chargeMultiplicityInt !== 0) {
            setErrorMessage("A carga mínima deve ser múltipla da multiplicidade!")
            return false
        }

        return true
    }

    return (
        <>
            <PopupAdd
                baseUrl={baseUrl} setLoadData={setLoadData}
                inputs={inputs} title={title} icon={icon}
                clearForm={clearForm} returnData={returnData}
                setErrorMessage={setErrorMessage} setErrorColumn={setErrorColumn}
                errorMessage={errorMessage} validateData={validateData}
            />
        </>
    )
}

export default PopupAddBusinessUnit


const aliasTypes = [
    {received: 'TOTAL', display: 'Total'},
    {received: 'Compra A Vista', display: 'Compra à Vista'},
    {received: 'Consulta Saldo', display: 'Consulta de Saldo'},
    {received: 'Carga Gift', display: 'Carga de Cartão'},
    {received: 'Transf Sld Debito', display: 'Transf. Saldo Débito'},
    {received: 'Transf Sld Credito', display: 'Transf. Saldo Crédito'},
    {received: 'Baixa Por Validade', display: 'Baixa por Validade'},
    {received: 'Troca De Chave', display: 'Troca de Chave'},
    {received: 'Carga De Tabelas', display: 'Carga de Tabelas'},
]

export default function displayType(type){
    const objFromList = aliasTypes.filter(alias => alias.received === type)[0]

    return  objFromList ?
        objFromList.display : type
}
import FilterForm from "../../../components/FilterForm/FilterForm";
import TextField from "@mui/material/TextField";
import {InputAdornment, Button, IconButton} from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear'; // Ícone para o botão de limpar
import React, { useState } from "react";
import { FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import DisplayStatus from "../../../components/Status/DisplayStatus";
import MDBox from "../../../components/MDBox";

const FilterFormGroup = ({ filter, setFilter, setLoadData, setPage }) => {
    const [lgShow, setLgShow] = useState(false);

    const [name, setName] = useState("");
    const [code, setCode] = useState("");
    const [status, setStatus] = useState("");

    const handlesSubmit = (e) => {
        if (e) e.preventDefault();

        setFilter({
            ...(name && { name }),
            ...(code && { code }),
            ...(status && { status })
        });

        setLgShow(false);
        setLoadData(true);
        setPage(0);
    };

    const clearAll = () => {
        setName("");
        setCode("");
        setStatus("");

        setFilter({});
    };

    const clearFilter = () => {
        setName("");
        setCode("");
        setStatus("");

        setFilter({});
        setLgShow(false);
        setLoadData(true);
    };

    return (
        <FilterForm
            handleSubmit={handlesSubmit}
            clearFilter={clearFilter}
            clearAll={clearAll}
            lgShow={lgShow}
            setLgShow={setLgShow}
            showSecondaryButton={Object.keys(filter).length !== 0}
        >
            <TextField
                label="Nome"
                inputProps={{
                    maxLength: 60
                }}
                error={name === "" && false} de erro desejada
                value={name}
                onChange={(e) => setName(e.target.value.toUpperCase())}
                fullWidth
                autoFocus
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {name && (
                                <IconButton size="small" onClick={() => setName("")}>
                                    <ClearIcon />
                                </IconButton>
                            )}
                        </InputAdornment>
                    ),
                }}
            />
            <TextField
                label="Código"
                inputProps={{
                    max: 999999,
                    min: 1,
                    maxLength: 8,
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                }}
                type="text"
                error={code === "" && false} // Ajuste conforme a lógica de erro desejada
                // helperText={code === "" ? "Campo obrigatório!" : ""}
                value={code}
                onChange={(e) => {
                    const newValue = e.target.value.replace(/\D/g, '');
                    setCode(newValue);
                }}
                id="input-code"
                fullWidth
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {code && (
                                <IconButton size="small" onClick={() => setCode("")}>
                                    <ClearIcon />
                                </IconButton>
                            )}
                        </InputAdornment>
                    ),
                }}
            />
            <MDBox mb={0.5} pb={0.5}>
                <FormLabel id="demo-row-radio-buttons-group-label" className="text-center">
                    Status
                </FormLabel>
                <RadioGroup
                    row
                    name="row-radio-buttons-group"
                    id="radios-status"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    required
                >
                    <FormControlLabel value="" control={<Radio />} label={<span>Todos</span>} />
                    <FormControlLabel value="ATIVO" control={<Radio />} label={<DisplayStatus status="ATIVO" />} />
                    <FormControlLabel value="INATIVO" control={<Radio />} label={<DisplayStatus status="INATIVO" />} />
                    <FormControlLabel value="BLOQUEADO" control={<Radio />} label={<DisplayStatus status="BLOQUEADO" />} />
                </RadioGroup>
            </MDBox>
        </FilterForm>
    );
};

export default FilterFormGroup;

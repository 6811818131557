import React, {useEffect, useState} from "react";
import AddIcon from "@mui/icons-material/Add";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import axios from "axios";
import Popup from "../Popup/Popup";
import { Button, Form } from "react-bootstrap";
import "./PopupAdd.css";

const PopupAdd = ({
                      baseUrl,
                      setLoadData,
                      inputs,
                      title,
                      icon,
                      clearForm,
                      returnData,
                      setErrorMessage,
                      setErrorColumn,
                      blockSubmit,
                      button,
                      headers,
                      validateData,
                      errorMessage,
                      errorColumn,
                  }) => {
    // modal control
    const [lgShow, setLgShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false); // Estado para controlar popup de sucesso

    const iconSpan = (
        <>
            {icon}
            <h3>{title}</h3>
        </>
    );

    const buttonText = button ? (
        button
    ) : (
        <>
            Adicionar
            <AddIcon fontSize="medium" />
        </>
    );

    const handleSubmit = (e) => {
        e.preventDefault();

        const data = returnData();

        let dataIsValid = true;
        if (typeof validateData === "function") dataIsValid = validateData();

        if (!blockSubmit && dataIsValid) {
            setIsLoading(true); // Ativar o estado de isLoading quando o formulário for enviado

            axios
                .post(baseUrl, data, { headers })
                .then(function (response) {
                    setLgShow(false);
                    if (setLoadData) setLoadData(true);
                    if (clearForm) clearForm();
                    // Exibir popup de sucesso
                    setShowSuccessPopup(true);
                    // Fechar o popup de sucesso após 3 segundos
                    setTimeout(() => {
                        setShowSuccessPopup(false);
                    }, 3000);
                })
                .catch(function (error) {
                    // Aqui temos acesso ao erro, quando alguma coisa inesperada acontece:
                    console.log("Error: " + error);
                    setErrorMessage(error.response.data.message);
                    if (error.response && error.response.status === 409) {
                        setErrorMessage(error.response.data.message);
                        console.log(error.response);
                        setErrorColumn(error.response.data.column);
                    }
                })
                .finally(() => {
                    setIsLoading(false); // Desativar o estado de isLoading após o término da solicitação
                });
        }
    };

    useEffect(() => {
        if(clearForm) clearForm();
    }, [!lgShow]);

    return (
        <>
            {/* Botão e popup */}
            <Popup
                buttonText={buttonText}
                title={title}
                iconSpan={iconSpan}
                lgShow={lgShow}
                setLgShow={setLgShow}
                buttonClass={"button-show"}
            >
                <div>
                    <Form onSubmit={handleSubmit} className="form-inputs">
                        {inputs}

                        {errorMessage && (
                            <div
                                style={{
                                    color: "red",
                                    fontWeight: "bold",
                                    marginTop: "10px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textAlign: "center"
                                }}
                            >
                                {errorMessage}
                            </div>
                        )}

                        <Button type="submit" disabled={isLoading}>
                            {isLoading ? (
                                <>
                  <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                  ></span>
                                    Aguarde...
                                </>
                            ) : (
                                buttonText
                            )}
                        </Button>
                    </Form>
                </div>
            </Popup>

            {/* Popup de sucesso */}
            {showSuccessPopup && (
                <Popup
                    title="Sucesso!"
                    lgShow={showSuccessPopup}
                    setLgShow={setShowSuccessPopup}
                    buttonClass={"button-success"}
                >
                    <div style={{ textAlign: "center" }}>
                        <CheckCircleIcon style={{ color: "green", fontSize: 60 }} />
                        <h3>Cadastro realizado com sucesso!</h3>
                    </div>
                </Popup>
            )}
        </>
    );
};

export default PopupAdd;

import React, {useState} from "react";
import TableList from "../../../components/TableList/TableList";
import {NumberWithPoints} from "../../../utils/numberWithPoints";
import FilterFormTransaction from "./FilterFormTransaction";
import {normalizeTimestemp} from "../../../utils/normalizeTimestemp";
import TransactionDetails from "./TransactionDetails";

const ListTransaction = ({
                             baseUrl, loadData,
                             setLoadData, size, page,
                             setTotalPages, setTotalElements,
                             sort, setSort, updateCode, setPage
                         }) => {

    const [data, setData] = useState([])
    const [error, setError] = useState(false)
    const [filter, setFilter] = useState({})

    const titles = [
        "Data da transação",
        "Loja",
        "Numero do cartão",
        "Valor",
        "Código Lógico",
        "NSU",
        "Status",
        "Tipo"
    ]

    const getTypeLabel = (type) => {
        const typeMap = {
            'TRANSF_DEBITO': 'Transf. de débito',
            'TRANSF_CREDITO': 'Transf. de crédito',
            'BAIXA_SALDO_VENCIDO': 'Baixa',
            'REVALIDACAO': 'Revalidação',
            'CARGA': 'Carga'
        };
        return typeMap[type] || type;
    };

    const getStatusLabel = (status) => {
        const statusMap = {
            'PENDENDTE': 'Pendente',
            'NEGADA': 'Negada',
            'CONFIRMADA': 'Confirmada',
            'DESFEITA': 'Desfeita'
        };
        return statusMap[status] || status;
    };


    return (
        <>
            <FilterFormTransaction filter={filter} setFilter={setFilter} setLoadData={setLoadData} setPage={setPage}/>
            <TableList
                titles={titles} baseUrl={baseUrl}
                page={page} sort={sort} size={size}
                loadData={loadData}
                setTotalPages={setTotalPages} setTotalElements={setTotalElements}
                setData={setData} setLoadData={setLoadData} filter={filter}
                error={error} setError={setError}
            >
                {data.map((item) =>
                    <tr key={item.id}>
                        <td>{normalizeTimestemp(item.dateTime)}</td>
                        <td>{item.corporateName || "---"}</td>
                        <td>{item.cardNumber}</td>
                        <td>R$ {NumberWithPoints(item.value)}</td>
                        <td>{item.mid || "---"}</td>
                        <td>{item.hostNsu || "---"}</td>
                        <td>{getStatusLabel(item.status.status)}</td>
                        <td>{getTypeLabel(item.type.type)}</td>

                        <td>
                            <TransactionDetails item={item}/>
                        </td>
                    </tr>
                )}
            </TableList>
        </>

    )
}

export default ListTransaction
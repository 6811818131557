import MainNavbar from "components/MainNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ListClearing from "./components/ListClearing";
import { useState } from "react";
import PaginationControl from "components/Pagination/PaginationControl";
import {Paper} from "@mui/material";

export default function Clearing() {

	const baseUrl = `http://${process.env.REACT_APP_FINANCIAL_MANAGER_LINK}/clearings`;
	const [loadData, setLoadData] = useState(true);
	const [page, setPage] = useState(0);
	const [size, setSize] = useState(10);
	const [totalPages, setTotalPages] = useState(0);
	const [totalElements, setTotalElements] = useState(0);
	const [sort, setSort] = useState("id");

    return (
        <>
            <MainNavbar/>
            <DashboardLayout>
                <Paper className="paper-list mb-3">
                    <h1>Lista de Fechamentos</h1>

                    <ListClearing                     
                        baseUrl={baseUrl}
                        loadData={loadData}
                        setLoadData={setLoadData}
                        page={page}
                        size={size}
                        setTotalPages={setTotalPages}
                        setTotalElements={setTotalElements}
                        sort={sort} 
                        setSort={setSort}
                    />

                    <PaginationControl
                        totalPages={totalPages}
                        totalElements={totalElements}
                        page={page}
                        setPage={setPage}
                        size={size}
                        setSize={setSize}
                        setLoadData={setLoadData}
                    />

                </Paper>
            </DashboardLayout>
        </>
    );
}
import React, {useEffect, useState} from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Popup from "../../../components/Popup/Popup";
import DownloadIcon from '@mui/icons-material/Download';
import {Button} from "react-bootstrap";
import List from "@mui/material/List";
import {ListItemButton} from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import AdjustIcon from "@mui/icons-material/Adjust";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import axios from "axios";
import ListItem from "@mui/material/ListItem";

const SolicitationDetails = ({item, baseUrl}) => {

    const [lgShow, setLgShow] = useState(false);
    const [displayCards, setDisplayCards] = useState(false);
    const [cards, setCards] = useState([]);

    const button = <>
        Detalhes <VisibilityIcon fontSize="medium"/>
    </>

    const title = "Detalhes da Solicitação"

    const iconSpan = <>
        <VisibilityIcon fontSize="large" id="icon-svg"/>
        {title}
    </>

    const downloadFile = async (e) => {
        e.preventDefault()

        const response = await fetch(`${baseUrl}/getFile/${item.id}`, {
            method: 'GET',
            responseType: 'blob' // Define o tipo de resposta como blob
        });

        if (response.ok) {
            const file = await response.blob();
            const fileUrl = URL.createObjectURL(file);
            const link = document.createElement('a');
            link.href = fileUrl;
            link.download = item.embossingFileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(fileUrl);
        }
    }

    const findCards = (id) => {
        axios.get(`http://${process.env.REACT_APP_CARD_MANAGER_LINK}/Card/request/${id}?size=10`)
            .then(function (response) {
                setCards(response.data.content)
            }).catch(function (error) {
            console.log(error);
        })
    }

    useEffect(() => {
        findCards(item.id)
    }, [])

    return (
        <Popup
            buttonText={button}
            title={title}
            iconSpan={iconSpan}
            lgShow={lgShow} setLgShow={setLgShow}
            buttonId={"links-action"}
        >
            <div className="text-center">
                <List
                    sx={{width: '60%', bgcolor: 'background.paper', marginInline: '20%'}}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                >

                    <ListItemButton sx={{pl: 4}}>
                        <ListItemIcon>
                            <InsertDriveFileIcon/>
                        </ListItemIcon>
                        <ListItemText primary={`Arquivo de embosse disponível!`}/>
                        <Button onClick={(e) => {
                            downloadFile(e)
                        }}>
                            <DownloadIcon fontSize="small"/>
                            Fazer Download
                        </Button>
                    </ListItemButton>
                    <ListItemButton onClick={() => setDisplayCards(!displayCards)}>
                        <ListItemIcon>
                            <AdjustIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Exemplos de cartões"/>
                        {displayCards ? <ExpandLess/> : <ExpandMore/>}
                    </ListItemButton>
                    <Collapse in={displayCards} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {cards.map((card) =>
                                <ListItem sx={{pl: 4, mb: 2}} key={card.id}>
                                    <ListItemIcon>
                                        <CreditCardIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={`Número: ${card.number}`} />
                                </ListItem>
                            )}
                        </List>
                    </Collapse>
                </List>
            </div>

        </Popup>
    )
}

export default SolicitationDetails
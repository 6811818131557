import React, {useState} from "react";
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import PopupAdd from "../../../components/PopupAdd/PopupAdd";
import InputsStore from "./InputsStore";

const PopupAddStore = ({baseUrl, setLoadData, updateCode}) => {

    const [corporateName,setCorporateName] = useState("")
    const [tradingName, setTradingName] = useState("")
    const [cnpj, setCnpj] = useState("")
    const [internalCode, setInternalCode] = useState("")
    // const [supplierCode, setSupplierCode] = useState("")

    // contact
    const [phoneNumber, setPhoneNumber] = useState("")
    const [email, setEmail] = useState("")

    // address
    const [cityId, setCityId] = useState("")
    const [thoroughfare, setThoroughfare] = useState("")
    const [postalCode, setPostalCode] = useState("")
    const [neighborhood, setNeighborhood] = useState("")
    const [addressNumber, setAddressNumber] = useState("")
    const [complement, setComplement] = useState("")

    const [businessUnit, setBusinessUnit] = useState("")
    const [regional, setRegional] = useState("")
    const [franchiseGroup, setFranchiseGroup] = useState("")

    const [status, setStatus] = useState("ATIVO")

    // bank details
    const [agency, setAgency] = useState("")
    const [account, setAccount] = useState("")
    const [bankCode, setBankCode] = useState("")

    // data of centralize
    const [centralized, setCentralized] = useState(false)
    const [centralizedStoreId, setCentralizedStoreId] = useState('')

    // fees
    const [loadFee, setLoadFee] = useState("")
    const [redeemFee, setRedeemFee] = useState("")
    const [administrationFee, setAdministrationFee] = useState("")

    const [errorMessage, setErrorMessage] = useState("")
    const [errorColumn, setErrorColumn] = useState("")
    const [blockSubmit, setBlockSubmit] = useState(false)
    const icon = <DomainAddIcon fontSize="large" id="icon-svg"/>

    const title = "Loja"

    const validateData = () => {
        return !(!centralized && !bankCode)
    }

    const returnData = () => {

        const businessUnitId = parseInt(businessUnit)
        const regionalId = parseInt(regional)
        const franchiseGroupId = parseInt(franchiseGroup)

        return {
            corporateName,
            tradingName,
            cnpj,
            internalCode,
            // supplierCode,
            phoneNumber,
            email,
            cityId,
            thoroughfare,
            postalCode,
            neighborhood,
            addressNumber,
            complement,
            agency,
            account,
            bankCode,
            status,
            businessUnitId,
            loadFee,
            redeemFee,
            administrationFee,
            regionalId,
            franchiseGroupId,
            centralizedStoreId: centralized? centralizedStoreId : null
        }
    }

    const clearForm = () => {
        setErrorColumn("")
        setErrorMessage("")
        setStatus('ATIVO')
        setCorporateName('')
        setTradingName('')
        setCnpj('')
        setInternalCode('')
        // setSupplierCode('')
        setPhoneNumber('')
        setEmail('')
        setCityId('')
        setThoroughfare('')
        setPostalCode('')
        setNeighborhood('')
        setAddressNumber('')
        setComplement('')
        setAgency('')
        setAccount('')
        setBankCode('')
        setBusinessUnit('')
        setLoadFee('')
        setRedeemFee('')
        setAdministrationFee('')
        setRegional('')
        setFranchiseGroup('')
    }

    const inputs = <InputsStore
        setCorporateName={setCorporateName} corporateName={corporateName}
        setTradingName={setTradingName} tradingName={tradingName}
        setCnpj={setCnpj} cnpj={cnpj}
        setInternalCode={setInternalCode} internalCode={internalCode}
        // setSupplierCode={setSupplierCode} supplierCode={supplierCode}
        setPhoneNumber={setPhoneNumber} phoneNumber={phoneNumber}
        setEmail={setEmail} email={email}
        setCityId={setCityId} cityId={cityId}
        setThoroughfare={setThoroughfare} thoroughfare={thoroughfare}
        setPostalCode={setPostalCode} postalCode={postalCode}
        setNeighborhood={setNeighborhood} neighborhood={neighborhood}
        setAddressNumber={setAddressNumber} addressNumber={addressNumber}
        setComplement={setComplement} complement={complement}
        setAgency={setAgency} agency={agency}
        setAccount={setAccount} account={account}
        setBankCode={setBankCode} bankCode={bankCode}
        setStatus={setStatus} status={status}
        centralized={centralized} setCentralized={setCentralized}
        centralizedStoreId={centralizedStoreId} setCentralizedStoreId={setCentralizedStoreId}
        setBusinessUnit={setBusinessUnit} businessUnit={businessUnit}
        setLoadFee={setLoadFee} load={loadFee}
        setRedeemFee={setRedeemFee} redeemFee={redeemFee}
        setAdministrationFee={setAdministrationFee} administrationFee={administrationFee}
        setRegional={setRegional} regional={regional}
        setFranchiseGroup={setFranchiseGroup} franchiseGroup={franchiseGroup}
        setErrorMessage={setErrorMessage} errorMessage={errorMessage}
        setErrorColumn={setErrorColumn} errorColumn={errorColumn}
        setBlockSubmit={setBlockSubmit}
    />

    return (
        <PopupAdd
            baseUrl={baseUrl} setLoadData={setLoadData}
            inputs={inputs} title={title} icon={icon}
            clearForm={clearForm} returnData={returnData}
            setErrorMessage={setErrorMessage} setErrorColumn={setErrorColumn}
            blockSubmit={blockSubmit} validateData={validateData}
            errorMessage={errorMessage}
            errorColumn={errorColumn}
        />
    )
}

export default PopupAddStore
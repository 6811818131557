import FilterForm from "../../../components/FilterForm/FilterForm";
import TextField from "@mui/material/TextField";
import React, {useState} from "react";
import InputAdornment from "@mui/material/InputAdornment";
import ClearIcon from "@mui/icons-material/Clear";
import {Button, IconButton} from "@mui/material";

const FilterFormGroup = ({filter, setFilter, setLoadData, setPage}) => {

    const [lgShow, setLgShow] = useState(false);

    const [name, setName] = useState("")
    const [code, setCode] = useState("")

    const handlesSubmit = (e) => {
        if (e)
            e.preventDefault()

        setFilter({
            ...(name && {name}),
            ...(code && {code}),
        })

        setLgShow(false)
        setLoadData(true)

        setPage(0);
    }

    const clearAll = () => {
        setName("")
        setCode("")

        setFilter({});
    };

    const clearFilter = () => {
        setName("")
        setCode("")

        setFilter({})
        setLgShow(false)
        setLoadData(true)
    }

    return (
        <FilterForm
            handleSubmit={handlesSubmit} clearFilter={clearFilter}
            lgShow={lgShow} setLgShow={setLgShow}
            showSecondaryButton={Object.keys(filter).length !== 0}
            clearAll={clearAll}
        >
            <TextField
                label="Nome da Regional"
                inputProps={{
                    maxLength: 20
                }}
                error={name === "" && false} // Ajuste conforme a lógica de erro desejada
                errortext="Campo obrigatório!"
                value={name}
                onChange={(e) => setName(e.target.value.toUpperCase())}
                fullWidth
                autoFocus
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {name && (
                                <IconButton size="small" onClick={() => setName("")}>
                                    <ClearIcon />
                                </IconButton>
                            )}
                        </InputAdornment>
                    ),
                }}
            />

            <TextField
                label="Código da Regional"
                inputProps={{
                    maxLength: 20
                }}
                error={code === "" && false} // Ajuste conforme a lógica de erro desejada
                errortext="Campo obrigatório!"
                value={code}
                onChange={(e) => setCode(e.target.value.toUpperCase())}
                id="input-code"
                fullWidth
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {code && (
                                <IconButton size="small" onClick={() => setCode("")}>
                                    <ClearIcon />
                                </IconButton>
                            )}
                        </InputAdornment>
                    ),
                }}
            />
        </FilterForm>
    )
}

export default FilterFormGroup
import axios from "axios";

const aliasTypes = [
    {value: 'transaction', display: 'Transações'},
    {value: 'buyInCash', display: 'Compra à Vista'},
    {value: 'charge', display: 'Carga de cartão'},
]

export default async function reportData(type) {

    let data = {}
    const params = {type}

    await axios.get(`http://${process.env.REACT_APP_MONITOR_LINK}/dashboard`, {params})
        .then((response) => {
            let labels = response.data.label
            let typeDisplay = aliasTypes.filter(alias => alias.value === type)[0].display

            data = {
                labels,
                datasets: {label: typeDisplay, data: response.data.data},
            }
        }).catch((err) => {
            console.log(err)
        })

    return data
}



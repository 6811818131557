import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import {Button, FormControlLabel, FormLabel, IconButton, Radio, RadioGroup} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import ClearIcon from "@mui/icons-material/Clear";
import SelectInput from "../../../../components/SelectInput";
import MDBox from "../../../../components/MDBox";
import DisplayStatus from "../../../../components/Status/DisplayStatus";
import FilterForm from "../../../../components/FilterForm/FilterForm";

const FilterLoadCard = ({ filter, setFilter, setLoadData, setPage, baseUrl }) => {
    const [lgShow, setLgShow] = useState(false);
    const [cardNumber, setCardNumber] = useState("");
    const [logicCode, setLogicCode] = useState("");
    const [fileId, setFileId] = useState("");
    const [fileList, setFileList] = useState([]);
    const [status, setStatus] = useState("");
    const [loaded, setLoaded] = useState("");

    const handleSubmit = (e) => {
        if (e) e.preventDefault();

        setFilter({
            ...(cardNumber && { cardNumber }),
            ...(logicCode && { logicCode }),
            ...(fileId > 0 && { fileId }),
            ...(status && { status }),
            ...(loaded !== "" && { loaded }),
        });

        setLgShow(false);
        setLoadData(true);
        setPage(0);
    };

    const clearFilter = () => {
        setCardNumber("");
        setLogicCode("");
        setFileId("");
        setStatus("");
        setLoaded("");

        setFilter({});
        setLgShow(false);
        setLoadData(true);
    };

    const clearAll = () => {
        setCardNumber("");
        setLogicCode("");
        setFileId("");
        setStatus("");
        setLoaded("");

        setFilter({});
    };

    return (
        <FilterForm
            handleSubmit={handleSubmit}
            clearFilter={clearFilter}
            clearAll={clearAll}
            lgShow={lgShow}
            setLgShow={setLgShow}
            showSecondaryButton={Object.keys(filter).length !== 0}
        >
            <TextField
                label="Número do Cartão"
                inputProps={{ maxLength: 19 }}
                value={cardNumber}
                onChange={(e) => {
                    const inputNumber = e.target.value.replace(/\D/g, "");
                    setCardNumber(inputNumber);
                }}
                fullWidth
                autoFocus
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {cardNumber && (
                                <IconButton size="small" onClick={() => setCardNumber("")}>
                                    <ClearIcon />
                                </IconButton>
                            )}
                        </InputAdornment>
                    ),
                }}
            />
            <TextField
                label="Código lógico da loja"
                inputProps={{ maxLength: 15 }}
                value={logicCode}
                onChange={(e) => {
                    const inputNumber = e.target.value.replace(/\D/g, "");
                    setLogicCode(inputNumber);
                }}
                fullWidth
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {logicCode && (
                                <IconButton size="small" onClick={() => setLogicCode("")}>
                                    <ClearIcon />
                                </IconButton>
                            )}
                        </InputAdornment>
                    ),
                }}
            />
            <SelectInput
                url={`${baseUrl}/files?size=200000000`}
                placeholder={"Arquivo"}
                list={fileList}
                set={setFileList}
                setObj={setFileId}
                defaultValue={fileId}
                returnList={() => {
                    let returnList = [{ value: "", label: "Todos" }];
                    fileList.forEach((item) => {
                        returnList.push({ value: item.id, label: item.fileName });
                    });
                    return returnList;
                }}
                fullWidth
            />
            <MDBox mb={0.5} pb={0.5}>
                <FormLabel id="demo-row-radio-buttons-group-label" className="text-center">
                    Status
                </FormLabel>
                <RadioGroup
                    row
                    name="row-radio-buttons-group"
                    id="radios-status"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                >
                    <FormControlLabel value="" control={<Radio />} label={<span>Todos</span>} />
                    <FormControlLabel value="EFETUADA" control={<Radio />} label={<DisplayStatus status="EFETUADA" />} />
                    <FormControlLabel value="REJEITADA" control={<Radio />} label={<DisplayStatus status="REJEITADA" />} />
                    <FormControlLabel value="PENDENTE" control={<Radio />} label={<DisplayStatus status="PENDENTE" />} />
                </RadioGroup>
            </MDBox>
            <MDBox mb={0.5} pb={0.5}>
                <FormLabel id="demo-row-radio-buttons-group-label" className="text-center">
                    Será (re)processado
                </FormLabel>
                <RadioGroup
                    row
                    name="row-radio-buttons-group"
                    id="radios-status"
                    value={loaded}
                    onChange={(e) => setLoaded(e.target.value)}
                >
                    <FormControlLabel value="" control={<Radio />} label={<span>Ambos</span>} />
                    <FormControlLabel value="false" control={<Radio />} label={<span>Sim</span>} />
                    <FormControlLabel value="true" control={<Radio />} label={<span>Não</span>} />
                </RadioGroup>
            </MDBox>
        </FilterForm>
    );
};

export default FilterLoadCard;

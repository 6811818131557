// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.select-monitoring-param button{
    margin-right: 1%;
}
`, "",{"version":3,"sources":["webpack://./src/layouts/monitoring/components/SelectMonitoringParam.css"],"names":[],"mappings":";AACA;IACI,gBAAgB;AACpB","sourcesContent":["\r\n.select-monitoring-param button{\r\n    margin-right: 1%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

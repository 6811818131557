import Popup from "../../../components/Popup/Popup";
import React, {useState} from "react";
import SearchIcon from '@mui/icons-material/Search';
import Card from "react-bootstrap/Card";

export default function PopupLayout({processName, text, layout}) {

    const [lgShow, setLgShow] = useState(false)

    const button = <>
        Ver Layout <SearchIcon fontSize="medium"/>
    </>

    const iconSpan = <>
        <SearchIcon fontSize="large"/>
        <h3>Layout para arquivo de {processName}</h3>
    </>

    return <Popup
        buttonText={button}
        title={`Layout para arquivo de ${processName}`}
        iconSpan={iconSpan}
        lgShow={lgShow} setLgShow={setLgShow}
        buttonVariant={""}
        buttonClass={'btn btn-outline-secondary'}
    >
        <div style={{width: '80%', margin: 'auto'}}>
            <div style={{marginTop: '2%'}} className={'text-center'}>
                {text}
                <b>
                    <br/>
                    Exemplo de conteúdo do arquivo:
                </b>
            </div>
            <Card
                bg={"Secondary".toLowerCase()}
                key={"Secondary"}
                text={"Secondary".toLowerCase() === 'light' ? 'dark' : 'white'}
                style={{width: '100%', marginTop: '2%'}}
                className="mb-2"
            >
                {/*<Card.Header>arquivo-carga-lote.csv</Card.Header>*/}
                <Card.Body>
                    {/*<Card.Title> arquivo-carga-lote.csv </Card.Title>*/}
                    <Card.Text>
                        {layout}
                    </Card.Text>
                </Card.Body>
            </Card>
            <p className={'text-center'}>
                *Os dados do exemplo são meramente ilustrativos*
            </p>
        </div>
    </Popup>
}
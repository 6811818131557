import { useState } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import Divider from "@mui/material/Divider";

/* eslint-disable */
function Popup() {
    const [rememberMe, setRememberMe] = useState(false);

    const handleSetRememberMe = () => setRememberMe(!rememberMe);

    return (
        <BasicLayout>
            <Card>
                <MDTypography
                    variant="h6"
                    fontWeight="regular"
                    color="text"
                    onClick={handleSetRememberMe}
                    sx={{ userSelect: "none", textAlign: "center", mt: 2 }}
                >
                    Configure seu pedido de relatório
                </MDTypography>
                <Divider />
                <MDBox pt={4} pb={3} px={3}>
                    <MDBox component="form" role="form">
                        <MDBox mb={2}>
                            <MDTypography
                                variant="button"
                                fontWeight="regular"
                                color="text"
                                onClick={handleSetRememberMe}
                                sx={{ userSelect: "none", textAlign: "center", mt: 2}}
                            >
                                Data de crédito
                            </MDTypography>
                            <MDInput type="date" fullWidth />
                        </MDBox>
                        <MDBox mb={2}>
                            <MDTypography
                                variant="button"
                                fontWeight="regular"
                                color="text"
                                onClick={handleSetRememberMe}
                                sx={{ userSelect: "none", textAlign: "center", mt: 2 }}
                            >
                                Status
                            </MDTypography>
                            <MDInput type="text" fullWidth />
                        </MDBox>
                        <MDBox display="flex" alignItems="center" ml={-1}>
                        </MDBox>
                        <MDBox mt={4} mb={1}>
                            <MDButton variant="gradient" color="success" fullWidth mb={-10}>
                                GERAR RELATÓRIO
                            </MDButton>
                        </MDBox>
                        <MDBox mt={3} mb={1} textAlign="center">
                        </MDBox>
                    </MDBox>
                </MDBox>
            </Card>
        </BasicLayout>
    );
}

export default Popup;

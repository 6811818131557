import { createContext, useContext, useEffect, useReducer } from "react";

const USER_ACCESS_TOKEN = "_USER_ACCESS_TOKEN";

export const USER_FETCHED = "USER_FETCHED";
export const INVALID_TOKEN = "INVALID_TOKEN";


const accessToken = localStorage.getItem(USER_ACCESS_TOKEN);
let userInfo = null;
if (accessToken) 
  userInfo = extractUserInfoFromToken(accessToken);


function hasPermission(page, action) {
  if (!userInfo) return false;
  return userInfo.authorizations.some((auth) => auth.resource === page && auth.action === action);
}

const initialState = {
  userInfo,
  accessToken: JSON.parse(accessToken),
  hasPermission,
}

const AuthenticationContext = createContext(null);
const AuthenticationDispatchContext = createContext(null);

export function useAuthContext() {
  return useContext(AuthenticationContext);
}

export function useAuthDispatchContext() {
  return useContext(AuthenticationDispatchContext);
}

export function AuthenticationProvider({ children }) {

  const [authentication, dispatch] = useReducer(authenticationReducer, initialState);

  return (
    <AuthenticationContext.Provider value={authentication}>
      <AuthenticationDispatchContext.Provider value={dispatch}>
        {children}
      </AuthenticationDispatchContext.Provider>
    </AuthenticationContext.Provider>
  );
}

function extractUserInfoFromToken(token) {
  return JSON.parse(atob(token.split('.')[1]));
}

function authenticationReducer(authentication, action) {

  switch (action.type) {
    case USER_FETCHED: {
      const userInfo = extractUserInfoFromToken(action.payload);

      localStorage.setItem(USER_ACCESS_TOKEN, JSON.stringify(action.payload));
      return {
        ...authentication,
        accessToken: action.payload,
        userInfo,
        hasPermission,
      }
    }
    case INVALID_TOKEN: {
      localStorage.removeItem(USER_ACCESS_TOKEN);
      return {
        ...authentication,
        accessToken: null,
        userInfo: null,
        hasPermission,
      }
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    } 
  }
}

